import styled from "styled-components";
import {useState} from "react";
import InputTextSetting from "../components/InputTextSetting";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import {useAddFonction} from "../../../../features/fonction/fonction.hooks";

interface FormNewFonctionCtrlProps{
    className?:string;
    Fxs:Fonction[];
    EndCreate:(fx:Fonction)=>void;
}

const colorDefault = "#000"

const FormNewFonctionCtrl = (props:FormNewFonctionCtrlProps)=>{
    const mutation = useAddFonction();
    const CentreQuery = useGetCentreActuel();
    const [libNewFx, setLibNewFx] = useState<string>("")
    const [errorLib, setErrorLib] = useState<string|null>(null)
    const setMyValue = (value:string|null|Choice|number, id:string)=> {
        if(id === "libelle") {
            const myVal: string = value as string;
            if (props.Fxs.map(a => a.libelle.toLowerCase()).indexOf(myVal)!==-1){
                setErrorLib("Cette fonction existe déjà");
            } else if (myVal === "") {
                setErrorLib("Le nom est obligatoire");
            } else {
                setErrorLib(null)
            }
            setLibNewFx(myVal);
        }
    }
    const Submit = ()=>{
        if(CentreQuery.data) {
            const maxEdit = Math.max(...props.Fxs.map(a => (a.ordreAffiche || 0))) + 1;
            const cParameters = CentreQuery.data.parameters;
            const myParamsPointeur = cParameters.find(cp=>cp.Parameter.id === 2)
            const myParamsTR = cParameters.find(cp=>cp.Parameter.id === 5)
            const isPointeur = (!!myParamsPointeur && myParamsPointeur.statut)
            const isTr = (!!myParamsTR && myParamsTR.statut)
            const datas: PostFonction = {
                libelle: libNewFx,
                isProduction: true,
                ordreAffiche: maxEdit,
                isTr: isTr,
                isHorGlobal: true,
                isPointeur: isPointeur,
                color: colorDefault,
                pause:0,
                shiftMode: null,
                maxEntreeAvance: -1,
                maxEntreeRetard: -1,
                maxSortieAvance: -1,
                maxSortieRetard: -1,
                Centre:`/api/centres/${CentreQuery.data.id}`

            }
            mutation.mutate(datas, {
                onSuccess: (newFx: Fonction) => {
                    props.EndCreate(newFx);
                }
            })
        }
    }
    return (
        <div className={`formNewFx ${props.className}`}>
            <div className="wrap_lib">
                <InputTextSetting current={libNewFx} setValue={setMyValue} Error={null} id={"libelle"} label={"Nom de la fonction"} wdLib={"25%"}/>
            </div>
            {!!errorLib &&
                <div className="wrap_error">{errorLib}</div>
            }
            {CentreQuery.data &&
                <div className="wrap_send">
                    <TimedButton size={"sm"} text={"Enregistrer la fonction"} themeColor={"Secondary"} disabled={!!errorLib || mutation.isLoading} onClick={Submit} isPending={mutation.isLoading}/>
                </div>
            }
        </div>
    )
}

const FormNewFonction = styled(FormNewFonctionCtrl)`
    .wrap_error{
        color: red;
        padding: 1rem;
        text-align: center;
    }
    .wrap_lib{
        margin: 30px 0;
    }
    .wrap_send{
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
`

export default FormNewFonction