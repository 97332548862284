import React, {useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {useGetUserHeure} from "../../../../../../features/userHeure/userHeure.hooks";
import TimedSkeleton from "../../../../../../components/atoms/TimedSkeleton/TimedSkeleton";
import {useCentreConnectedData} from "../../../../../../services/hooks";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        datalabels:{
            display:false
        },
        title: {
            display: false,
            text: 'Historiques des heures',
        },
    },
};


interface ChartHistoHeuresCtrlProps{
    className?:string;
    idUser:number;
    dateFrom:string;
    supervisor?:boolean;
}

const ChartHistoHeuresCtrl = ({className, idUser, dateFrom, supervisor}:ChartHistoHeuresCtrlProps)=>{
    const centre = useCentreConnectedData();
    const theme = useTheme();
    const userHeureQuery = useGetUserHeure(idUser, dateFrom, supervisor ? centre : null);
    const [data, setData] = useState<any|null>(null);
    useEffect(()=>{
        if(userHeureQuery.data){
            const times = userHeureQuery.data.times;
            const labels = times.map(t=>t.lib);
            const heuresP:number[] = times.map(t=>t.heuresP);
            const datasets = [
                {
                    label:'Heures planning',
                    data:heuresP,
                    backgroundColor:theme.PrimaryLight
                }
            ]
            setData({labels, datasets})
        }
    }, [userHeureQuery.data, theme])
    return (
        <div className={`ChartHeure ${className}`}>
            {userHeureQuery.isLoading ? <div className={"wrap-wait"}><TimedSkeleton width={"100%"} nbOccurence={1} type={"perso"} heightPerso={"154px"}/></div>: data === null ? 'null':
                <div className={`canvas-container`}>
                    <MyChartBar options={options} data={data}/>
                </div>
            }
        </div>
    )
}

const MyChartBar = ({...props})=>{
    return <Bar options={props.options}  data={props.data} />;
}

const ChartHistoHeures = styled(ChartHistoHeuresCtrl)`
    //height: 300px;
  .wrap-wait{
    width: 95%;
    margin: 0.5rem auto 0 auto;
  }
  .canvas-container {
    height: 154px;
    width: 96%;
    margin: auto;
  }
`

export default ChartHistoHeures;