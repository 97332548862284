import {format} from "date-fns";
import {getDateWeekByDate} from "./TimesFunctions";


export const GetCSTByUserDate = (user:User, dateTo:Date)=>{
    if(!user.startRotationAt){
        return null;
    } else {
        const sequences = user.sequence.sort((a:UserSequence, b:UserSequence)=>a.ordre > b.ordre ? 1 : -1)
        const dateApplic = new Date(new Date(dateTo.getTime()).setHours(12,0,0))
        const StartRotation = new Date(new Date(user.startRotationAt.slice(0,10)).setHours(12,0,0))
        const {Monday:MStart} = getDateWeekByDate(StartRotation);
        const {Monday:MApplic} = getDateWeekByDate(dateApplic);
        const MondayStartOK = new Date(new Date(MStart.getTime()).setHours(12,0,0))
        const MondayApplicOK = new Date(new Date(MApplic.getTime()).setHours(12,0,0))
        const numSemBeet = Math.round(((MondayApplicOK.getTime() - MondayStartOK.getTime())/86400000) / 7);
        const index =(numSemBeet % sequences.length);
        const MySeq = sequences[index];
        if(!MySeq || !MySeq.CentreSemaineType){
            return null;
        }
        return MySeq.CentreSemaineType;
    }
}

export const ConvertSTToPlageTempo = (st:SemaineType, User:User, dateTo:Date):Plage=>{
    return {
        id:st.id*1000,
        User:User,
        UserReplaced:null,
        heureDebut:st.HeureDebut,
        heureFin:st.HeureFin,
        plageAt:format(dateTo, "yyyy-MM-dd"),
        Statut:{id:2, libelle:"Prévisionnel", code:"P", color:"#F7C67F"},
        Acte:st.Acte, Lieu:st.Lieu,
        myTime:(st.HeureFin - st.HeureDebut)/12,
        rang:null,
        segmentation:st.HeureDebut<144 ? "Matin" : "AP"
    }
}