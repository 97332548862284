import styled from "styled-components";
import {TitrePage} from "../../../../../../../assets/styles/elements";
import ChartDispatchActe from "./components/ChartDispatchActe";
import ChartTimesWeekly from "./components/ChartTimesWeekly";
import ChartTimesDaily from "./components/ChartTimesDaily";
import UserCardTempsTr from "./components/UserCardTempsTr";

interface TempsTrOneUserDashCtrlProps{
    className?:string;
    calendrier:CalendrierPaie;
    user:User;
}

const TempsTrOneUserDashCtrl = (props:TempsTrOneUserDashCtrlProps)=>{

    return (
        <div className={`tempsTrOneUserDash ${props.className}`}>
            <div className="block_user">
                <UserCardTempsTr User={props.user} calendrier={props.calendrier}/>
            </div>
            <div className="line_one">
                <div className="block_chart">
                    <ChartDispatchActe User={props.user} Calendrier={props.calendrier}/>
                    <div className="legend_block">Temps par Acte</div>
                </div>
                <div className="block_chart2">
                    <ChartTimesWeekly User={props.user} calendrier={props.calendrier}/>
                    <div className="legend_block">Temps semaine / Contrat</div>
                </div>
            </div>
            <div className="block_chart2">
                <ChartTimesDaily User={props.user} calendrier={props.calendrier}/>
                <div className="legend_block">Temps par jour</div>
            </div>
        </div>
    )
}

const TempsTrOneUserDash = styled(TempsTrOneUserDashCtrl)`
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .line_one{
        display: flex;
        justify-content: flex-start;
        gap: 15px;
        align-items: center;
    }
    .block_user{
        width: 100%;
        padding: 15px;
        background: white;
    }
    .block_chart2{
        flex-grow: 1;
        padding: 15px 15px 0 15px;
        background: white;
        height: 316px;
    }
    .legend_block{
        padding: 1rem 0.5rem;
        text-align: center;
        font-weight: bold;
    }
    .block_chart{
        width: 330px;
        background: white;
        padding: 15px 15px 0 15px;
        
    }
`

export default TempsTrOneUserDash