import styled from "styled-components";
import {TitrePage} from "../../../../../assets/styles/elements";
import {useParams, useSearchParams} from "react-router-dom";
import {useGetListUserMonthTT} from "../../../../../features/tempsTravail/tempsTravail.hooks";
import {useGetAllUser} from "../../../../../features/user/user.hooks";
import React, {useEffect, useState} from "react";
import {useGetCentreActuel} from "../../../../../features/centre/centre.hooks";
import {TimedUserAvatar} from "../../../../../components/molecules/_TimedUserAvatar/TimedUserAvatar";
import {TabMois} from "../../../../../services/constantes/constantes";
import {format} from "date-fns";


interface TempsTrOneCalListCtrlProps{
    className?:string;
    calendrier:CalendrierPaie;
}

const TempsTrOneCalListCtrl = (props:TempsTrOneCalListCtrlProps)=>{
    const UsersQuery = useGetAllUser();
    const [searchParams, setSearchParams] = useSearchParams()
    const ListQuery = useGetListUserMonthTT(props.calendrier.id)
    const CentreQuery = useGetCentreActuel();
    const [listRubriques, setListRubriques] = useState<RubMonthTT[]>([])
    const [isHeuresDom, setIsHeuresDom] = useState(false);
    useEffect(() => {
        if(CentreQuery.data){
            const cParameters = CentreQuery.data.parameters;
            const myParamsHD = cParameters.find(cp=>cp.Parameter.id === 28)
            setIsHeuresDom(!!myParamsHD && myParamsHD.statut)
        }
    }, [CentreQuery.data]);
    useEffect(() => {
        if(ListQuery.data){
            setListRubriques(ListQuery.data.Rubriques);
        }
    }, [ListQuery.data]);
    const GetAvat = (id:number)=>{
        if(!UsersQuery.data || !UsersQuery.data.find(u=>u.id === id)){
            return <span></span>
        } else {
            const user = UsersQuery.data.find(u=>u.id === id) as User;
            return <TimedUserAvatar User={user} RightAddInit isInactif={!user.isActif}/>
        }
    }
    const GetHaveRub = (Rubriques:UserRubMonthTT[], idRub:number, idUser:number)=>{
        const RubriquesConcerned = Rubriques.filter(e=>e.id === idRub);
        if(RubriquesConcerned.length === 0){
            return <span>--</span>
        } else {
            return <div className={`wrap_rubs`}>
                {RubriquesConcerned.map((item, idx)=>(
                     <div className="OneRubUser" key={`RubIn${idUser}${idRub}`}>{item.details}</div>
                ))}
            </div>
        }
    }

    const handleClickLine = (id:number)=>{
        const params:any = {};
        searchParams.forEach((key, value) => {
            params[value] = key;
        });
        setSearchParams({...params, idCollab:id})
    }
    const GetNameMonth = ()=>{
        const myMonth = TabMois.find(m=>m.num === props.calendrier.numMois)
        if(myMonth){
            return `${myMonth.name} ${props.calendrier.numYear}`
        } else {
            return `${props.calendrier.numMois} ${props.calendrier.numYear}`
        }
    }
    return (
        <div className={`tempsTrOneCalList ${props.className}`}>
            <TitrePage>Récapitulatif paie de {GetNameMonth()} du {format(new Date(props.calendrier.startAt.slice(0,10)+"T12:00:00"), "dd/MM/yyyy")} au {format(new Date(props.calendrier.stopAt.slice(0,10)+"T12:00:00"), "dd/MM/yyyy")} </TitrePage>
            <div className="content">{ListQuery.isLoading ? "chargement" :
                <table className="table_in">
                    <thead>
                    <tr>
                        <th colSpan={3}>Collab</th>
                        <th colSpan={2}>Heures Paie</th>
                        <th colSpan={3}>Temps travail</th>
                        <th colSpan={2}>Absences</th>
                        <th colSpan={2}>Evenements et rubriques</th>

                    </tr>
                    <tr>
                    <th>Collaborateur</th>
                            <th>TR</th>
                            <th>Contrats</th>
                            <th>H Supp.</th>
                            <th>H à déduire</th>
                            {isHeuresDom &&
                                <th>Heures Dominicales</th>
                            }
                            <th>A faire</th>
                            <th>Fait</th>
                            <th>Ecart</th>
                            <th>CP</th>
                            <th>Autres</th>
                            <th>Evénements</th>
                            {listRubriques.map(item => (
                                <th key={`Rub${item.id}`}>{item.libelle}</th>
                            ))}

                        </tr>
                    </thead>
                    <tbody>
                    {ListQuery.data?.Users.map(item=>(
                        <tr key={`LineUser${item.id}`} onClick={()=>handleClickLine(item.id)}>
                            <td>{GetAvat(item.id)}</td>
                            <td className={`cell_center`}>{item.TR}</td>
                            <td>{item.Contrats}</td>
                            <td className={`cell_center`}>{Math.round(item.HSupp*100)/100}</td>
                            <td className={`cell_center`}>{Math.round(item.HDeduire*100)/100}</td>
                            {isHeuresDom &&
                                <td>{item.HDominicales}</td>
                            }
                            <td>{Math.round(item.Times.TempsContrat * 100) / 100}</td>
                            <td>{Math.round(item.Times.heures * 100) / 100}</td>
                            <td><div className={`wrap_number ${item.Times.Ecarts<0 ? "negative" : item.Times.Ecarts === 0 ? "neutre" : "positive"}`}>{Math.round(item.Times.Ecarts * 100) / 100}</div></td>
                            <td>
                                {item.AbsencesCP.map((CP, idx) => (
                                    <div className="one_abs" key={`CP${idx}`}>{`${CP.qty} ${CP.libDates}`}</div>
                                ))}
                            </td>
                            <td>
                                {item.AbsenceNoCP.map((noCP, idx) => (
                                    <div className="one_abs"
                                         key={`noCP${idx}`}>{`${noCP.Acte} ${noCP.qty} ${noCP.libDates}`}</div>
                                ))}
                            </td>
                            <td>
                                {item.Events.map((EvUser, idx) => (
                                    <div className="one_ev"
                                         key={`Ev${idx}`}>{EvUser.details}</div>
                                ))}
                            </td>
                            {listRubriques.map(Rub => (
                                <td key={`CellUserRub${Rub.id}${item.id}`}>
                                    {GetHaveRub(item.Rubriques, Rub.id, item.id)}
                                </td>
                            ))}

                        </tr>
                    ))}
                    </tbody>
                </table>
            }</div>
        </div>
    )
}

const TempsTrOneCalList = styled(TempsTrOneCalListCtrl)`
    padding: 1% 2%;
    .wrap_number{
        padding: 0.2rem 0.4rem;
        border-radius: 4px;
        text-align: center;
        font-weight: bold;
        &.negative{
            background: ${props => props.theme.ComplementaryMegaLight};
            color: ${props => props.theme.ComplementaryDark};
        }
        &.positive{
            background: ${props => props.theme.SecondaryMegaLight};
            color: ${props => props.theme.SecondaryDark};
        }
    }
    table {
        width: 100%;
        border-collapse: collapse;
        .cell_center{
            text-align: center;
        }
        thead{
            th{
                padding: 0.5rem;
                border:solid ${props => props.theme.Primary} 1px;
            }
        }
        tbody{
            tr{
                font-size: 14px;
                
                &:hover{
                    cursor: pointer;
                    td{
                        background: ${props=>props.theme.PrimaryMegaLight};
                    }
                }
            }
            td{
                padding: 0.5rem;
            }
        }
    }
`

export default TempsTrOneCalList