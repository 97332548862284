import styled from "styled-components";
import {createContext, useEffect, useState} from "react";
import {format} from "date-fns";
import {useAddPlage, useGetPlageWeekOne, useModifyPlage, useTrashPlage} from "../../../features/plage/plage.hooks";
import {useModal} from "../../../hooks/useModal";
import {useQueryClient} from "react-query";
import {getDateWeekByDate} from "../../functions/TimesFunctions";
import {useGetParamsCentre} from "../../../services/hooks";
import {CopyPlageToSave, getIsHorseMultiSameDay} from "../../functions/PlageFunctions";
import {getAllStatutPlage} from "../../../features/statutPlage/statutPlage.services";
import {ConvertSTToPlageTempo} from "../../functions/SemaineTypeFunctions";
import TimedIconButton from "../../atoms/TimedIconButton/TimedIconButton";
import {FaUmbrellaBeach} from "react-icons/fa";
import {HiSwitchHorizontal} from "react-icons/hi";
import TopCalendar from "../TopCalendar/TopCalendar";
import {IoFlashSharp} from "react-icons/io5";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {BsTrash} from "react-icons/bs";
import ApplicSemType from "./CalendarComponents/ApplicationST/ApplicSemType";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import CustomDragLayerHandleCopy from "./CalendarComponents/CustomDragLayerHandleCopy";
import {TimedLoading} from "../../atoms/TimedWaitUser/TimedLoading";
import HeadDay from "./CalendarComponents/HeadDay";
import OneColumnDay from "./CalendarComponents/OneColumnDay";
import MenuFloatPlage from "./CalendarComponents/MenuFloatPlage";
import ModalNewPlage from "./CalendarComponents/ModalNewPlage";

interface WeeklyCalendarCtrlProps{
    className?:string;
    User:User;
    date:Date;
}
const TimeStart = 60;
const TimeStop = 264;
const heightHour = 40;

interface ConfigPlanning{
    start:number;
    stop:number;
    heightHour:number;
}
const DefaultConfig:ConfigPlanning={
    start:TimeStart,
    stop:TimeStop,
    heightHour:heightHour
}
const roundDownToNearest12 = (value:number)=>{
    return Math.floor(value / 12) * 12;
}
const roundUpToNearest12 = (value:number)=>{
    return Math.ceil(value / 12) * 12;
}

export interface ContextPlanningProps{
    config:ConfigPlanning;
    date:Date;
    GetNiveauPlage?:(p:Plage)=>number;
    GetConflicts?:(p:Plage, is?:boolean)=>Plage[];
    onResize?:(idPlage:number, newEnd:number)=>void;
    onMove?:(idPlage:number, d:Date, newBegin:number)=>void;
    saveModify?:(datas:ModifyPlageFormData)=>void;
    ModifyPlage?:(p:Plage)=>void;
    dropPlage?:(idPlage:number)=>void;
    selectPlage?:(p:Plage, x:number, y:number, w:number, h:number)=>void;
    isActeColor:boolean;
    isActeH:boolean;
    plageCurr:PlageCoord|null;
    plageTempo:TempoPlage|null;
    addPlageTempo?:(d:Date, HD:number, coord:{x:number, xr:number, y:number, yin:number})=>void;
    ModifyPlageTempo?:(pt:TempoPlage)=>void;
    plageShadow:Plage|null;
    setPlageShadow?:(p:Plage|null)=>void;
    saveShadow:()=>void;
    centreST:CentreSemaineType|null;
    User?:User;
    STSaving:boolean;
}
export const ContextPlanning = createContext<ContextPlanningProps>({
    date: new Date(),config:DefaultConfig, isActeColor:false, isActeH:false, plageCurr:null, plageTempo:null, plageShadow:null, saveShadow:()=>{}, centreST:null, STSaving:false
})

export interface PlageCoord extends Plage{
    x:number,
    y:number,
    w:number,
    h:number
}
export interface TempoPlage{
    Acte:Acte|null;
    Lieu:Lieu|null;
    User:User;
    d:Date;
    HD:number;
    HF:number;
    isSaving:boolean;
    coord:{x:number, y:number, xr:number, yin:number}
}
interface ParametrePlage{
    date:string;
    idUser:number;
}

const WeeklyCalendarCtrl = (props:WeeklyCalendarCtrlProps)=>{
    const mutationAdd = useAddPlage();
    const mutationModify = useModifyPlage()
    const mutationTrash = useTrashPlage()
    const [paramsPlage, setParamsPlage] = useState<ParametrePlage>({date:format(props.date, "yyyy-MM-dd"), idUser:props.User.id})
    const PlagesQuery = useGetPlageWeekOne(paramsPlage.date, paramsPlage.idUser);
    const [config, setConfig] = useState<ConfigPlanning>(DefaultConfig)
    const [myPlages, setMyPlages] = useState<Plage[]>([])
    const [myDates, setMyDates] = useState<Date[]>([])
    const [myHours, setMyHours] = useState<number[]>([])
    const {loading:loadParamsActeColor, myParamsOk:isActeColor} = useGetParamsCentre(7);
    const {loading:loadParamsActeH, myParamsOk:isActeH} = useGetParamsCentre(30);
    const [plageCurrent, setPlageCurrent] = useState<PlageCoord|null>(null)
    const [open, setOpen] = useState<boolean>(false)
    const [plageTempo, setPlageTempo] = useState<TempoPlage|null>(null)
    const [plageShadow, setPlageShadow] = useState<Plage|null>(null)
    const [centreSemaineTypeCurr, setCentreSemaineTypeCurr] = useState<CentreSemaineType|null>(null)
    const {open:openSaveToST, toggle:toggleSaveToST} = useModal();
    const [savingCST, setSavingCST] = useState<boolean>(false)
    const queryClient = useQueryClient();
    useEffect(() => {
        queryClient.prefetchQuery(["statut_plages"], getAllStatutPlage)
    }, []);
    useEffect(()=>{
        let starty = config.start;
        console.log("stop "+config.stop)
        const tabNumb:number[] = [];
        while(starty<=config.stop+12){
            tabNumb.push(starty)
            starty+=12;
        }
        console.log(tabNumb);
        setMyHours(tabNumb)
    }, [config])
    useEffect(() => {
        const {Monday, Sunday} = getDateWeekByDate(props.date)
        const tabDay:Date[] = [];
        let dateRotate = new Date(Monday.getTime());
        while(dateRotate<=Sunday){
            const ThisDate = new Date(dateRotate.getTime());
            tabDay.push(ThisDate)
            dateRotate = new Date(ThisDate.getTime() + 86400000)
        }
        setMyDates(tabDay)
    }, [props.date]);
    useEffect(() => {
        if(PlagesQuery.data){
            console.log('reboot')
            setMyPlages(PlagesQuery.data);
        }
    }, [PlagesQuery.data]);
    useEffect(() => {
        setParamsPlage({date:format(props.date, "yyyy-MM-dd"), idUser:props.User.id})
    }, [props]);
    useEffect(() => {
        if(myPlages.length>0){
            const myHST_Start = centreSemaineTypeCurr ? [...centreSemaineTypeCurr.semaineTypes.map(st=>st.HeureDebut)] : [];
            const myHST_End = centreSemaineTypeCurr ? [...centreSemaineTypeCurr.semaineTypes.map(st=>st.HeureFin)] : [];
            const myHFS = [...myPlages.map(p=>p.heureFin), ...myHST_End, (plageTempo?.HF||0)]
            const myHDS = [...myPlages.map(p=>p.heureDebut), ...myHST_Start]
            const minHD = Math.min(...myHDS)
            const maxHF = Math.min(288, Math.max(...myHFS));
            setConfig({start:roundDownToNearest12(minHD)-12, stop:roundUpToNearest12(maxHF), heightHour:heightHour})
        }
    }, [myPlages, plageTempo, centreSemaineTypeCurr]);
    //Fonctions
    const GetPlageDay = (d:Date)=>{
        return myPlages.filter(p=> {
            return format(new Date(p.plageAt.slice(0,10)+"T12:00:00"), "yyyy-MM-dd") === format(d, "yyyy-MM-dd")
        }).sort((a,b)=>a.heureDebut > b.heureDebut ? 1 : -1)
    }
    const GetConflicts = (p:Plage, addId=false)=>{
        const myDate = new Date(p.plageAt.slice(0,10)+"T12:00:00");
        const PlagesConcerned =addId ? GetPlageDay(myDate) : GetPlageDay(myDate).filter(plage=>p.id !== plage.id);
        if(addId){
            console.log(PlagesConcerned);
            console.log(getIsHorseMultiSameDay(p.heureDebut, p.heureFin, PlagesConcerned))
        }
        return getIsHorseMultiSameDay(p.heureDebut, p.heureFin, PlagesConcerned)
    }
    const savePlageShadow = ()=>{
        if(plageShadow){

            const datas = CopyPlageToSave(plageShadow);
            setPlageShadow(null)
            if(GetConflicts(plageShadow).length === 0) {
                setMyPlages(prevPlages => {
                    return [...prevPlages, {...plageShadow, isSaving: true}]
                })
                mutationAdd.mutate(datas, {
                    onError: () => {
                        setMyPlages(prevState => {
                            return [...prevState.filter(p => p.id !== 0)]
                        })
                    }
                })
            }
        }
    }
    const AddNewTempo = (d:Date, HD:number, coord:{x:number, xr:number, y:number, yin:number})=>{
        const myHF = Math.min(288,HD+24)
        setPlageTempo({Acte:null, Lieu:null, d:d, HD:HD, HF:myHF, User:props.User, isSaving:false, coord:coord})
        setOpen(false);
        setPlageCurrent(null);
    }
    const ModifyPLageTempo = (pt:TempoPlage|null)=>{
        setPlageTempo(pt);
    }
    const SelectPlage = (p:Plage, x:number, y:number, w:number, h:number)=>{
        setPlageTempo(null);
        setPlageCurrent({...p, x:x, y:y, w:w, h:h})
        setOpen(true);
    }
    const GetNiveauPlages = (p:Plage)=>{
        const myDate = new Date(p.plageAt.slice(0,10)+"T12:00:00");
        console.log(myDate.toISOString())
        const PlagesConcerned = GetPlageDay(myDate).filter(plage=>p.id !== plage.id && plage.heureDebut <=p.heureDebut);
        console.log(PlagesConcerned);
        return getIsHorseMultiSameDay(p.heureDebut, p.heureFin, PlagesConcerned).length
    }
    const onResize = (idPlage:number, newEnd:number)=>{
        setMyPlages(prevPlages=>
            prevPlages.map(plage=>plage.id === idPlage ? {...plage, heureFin:newEnd} : plage)
        )
        SaveModify({id:idPlage, heureFin:newEnd})
    }
    const onMove = (idPlage:number, d:Date, newBegin:number)=>{
        setMyPlages(prevPlages=>
            prevPlages.map(plage=>plage.id === idPlage ? {...plage, heureDebut:(newBegin), heureFin:(newBegin) + (plage.heureFin - plage.heureDebut), plageAt:format(d, 'yyyy-MM-dd')} : plage)
        )
    }
    const DropPlage = (idPlage:number)=>{
        const myPlage = [...myPlages].find(p=>p.id === idPlage) as Plage;
        if(GetConflicts(myPlage).length>0){
            const myOriginalPlage = PlagesQuery.data?.find(p=>p.id === idPlage) as Plage;
            setMyPlages(prevPlages=>
                prevPlages.map(plage=>plage.id === idPlage ? myOriginalPlage : plage)
            )
        } else {
            const datas:ModifyPlageFormData={
                id:idPlage,
                heureDebut:myPlage.heureDebut,
                heureFin:myPlage.heureFin,
                plageAt:myPlage.plageAt
            }
            SaveModify(datas);
        }
    }
    const ModifyPlage = (p:Plage)=>{
        setMyPlages(prevPlages=>{
            return [...prevPlages.filter(pl=>pl.id!==p.id), p]
        })
    }
    const SaveModify = (datas:ModifyPlageFormData)=>{
        mutationModify.mutate(datas)
    }
    const TrashPlage = (idPlage:number)=>{
        const myPlageSave  = [...myPlages].find(pl=>pl.id === idPlage) as Plage;
        setOpen(false)
        setPlageCurrent(null)
        setMyPlages(prevPlages=>{
            return [...prevPlages.filter(pl=>pl.id !== idPlage)]
        })
        mutationTrash.mutate(idPlage, {
            onError:()=>{
                setMyPlages(prevPlages=>{
                    return [...prevPlages, myPlageSave]
                })
            }
        })
    }
    const HoverCST = (cst:CentreSemaineType)=>{
        if(!savingCST) setCentreSemaineTypeCurr(cst)
    }
    const unHoverCST = ()=>{
        if(!savingCST) setCentreSemaineTypeCurr(null)
    }
    const ClickCST = (cst:CentreSemaineType)=>{
        setCentreSemaineTypeCurr(cst)
        setSavingCST(true);
        const SemaineTypes = cst.semaineTypes;
        const SemApplicables:SemaineType[] = [];
        SemaineTypes.forEach(st=>{
            const heureDeb = st.HeureDebut;
            const heureFin = st.HeureFin;
            const dateTo = new Date(props.date.getTime() + 86400000*st.numJour);
            const PlagesConcerned = [...myPlages].filter(p=> format(dateTo, "yyyy-MM-dd") === format(new Date(p.plageAt.slice(0,10)), "yyyy-MM-dd"))
            if(getIsHorseMultiSameDay(heureDeb, heureFin, PlagesConcerned).length === 0){
                SemApplicables.push(st);
            }
        })
        const SaveTo:AddPlageFormData[] = [];
        SemApplicables.forEach(st=>{
            const dateTo = new Date(props.date.getTime() + 86400000*st.numJour);
            SaveTo.push(CopyPlageToSave(ConvertSTToPlageTempo(st, props.User, dateTo)))
        })
        SaveTo.forEach(i=>{
            mutationAdd.mutate(i, {
                onSuccess:()=>{
                    setSavingCST(false)
                    setCentreSemaineTypeCurr(null);
                }
            })
        })
    }
    return (
        <div className={`weeklyCalendar ${props.className}`}>
            <TopCalendar date={props.date} User={props.User} titleCalendar={"Planning "}>
                <div className="wrap_actions_top">
                    <TimedIconButton isSquare size={"sm"} Icon={<FaUmbrellaBeach/>} themeColor={"Primary"} BackAlw={true} onClick={()=>{}} toolTip={"Nouvelle absence"}/>
                    <TimedIconButton isSquare size={"sm"} Icon={<HiSwitchHorizontal/>} themeColor={"Primary"} BackAlw onClick={toggleSaveToST} toolTip={"Convertir en Semaine type"}/>
                    <TimedIconButton isSquare size={"sm"} Icon={<IoFlashSharp/>} themeColor={"Complementary"} BackAlw onClick={()=>{}} toolTip={"Plage rapide"}/>
                    <TimedIconButton isSquare size={"sm"} Icon={<AiOutlineCheckCircle/>} themeColor={"Secondary"} BackAlw onClick={()=>{}} toolTip={"Changer statut"}/>
                    <TimedIconButton isSquare size={"sm"} Icon={<BsTrash/>} themeColor={"Warning"} BackAlw onClick={()=>{}} toolTip={"Suppression"}/>
                </div>
            </TopCalendar>
            <ApplicSemType isSaving={mutationAdd.isLoading} HoverCST={HoverCST} User={props.User} UnHoverCST={unHoverCST} dateFirst={props.date} ClickCST={ClickCST}/>
            <div className="in_weekly">
                <DndProvider backend={HTML5Backend}>
                    <ContextPlanning.Provider value={{
                        date:props.date,
                        User:props.User,
                        config:config,
                        GetNiveauPlage:GetNiveauPlages,
                        GetConflicts:GetConflicts,
                        isActeColor:isActeColor,
                        isActeH:isActeH,
                        onResize:onResize,
                        onMove:onMove,
                        saveModify:SaveModify,
                        dropPlage:DropPlage,
                        selectPlage:SelectPlage,
                        plageCurr:plageCurrent,
                        plageTempo:plageTempo,
                        addPlageTempo:AddNewTempo,
                        ModifyPlageTempo:ModifyPLageTempo,
                        ModifyPlage:ModifyPlage,
                        plageShadow:plageShadow,
                        setPlageShadow:setPlageShadow,
                        saveShadow:savePlageShadow,
                        centreST:centreSemaineTypeCurr,
                        STSaving:mutationAdd.isLoading
                    }}>
                        {(PlagesQuery.isLoading || loadParamsActeColor || loadParamsActeH) ? <TimedLoading width={"60px"}/> :
                            <div className={`calendar`}>
                                <div className={`Header`}>
                                    <div className="hours_col"/>
                                    <div className="wrap_days">
                                        {myDates.map(d => (
                                            <HeadDay date={d} key={`Date${d.getDay()}`}/>
                                        ))
                                        }
                                    </div>
                                </div>
                                <div className={`body_calendar`}>
                                    <div className="hours_col">
                                        {myHours.map(h => (
                                            <div className={`one_hour_in ${h === config.start ? "first" : ""}`}
                                                 key={`BH${h}`} style={{height: config.heightHour + "px"}}>

                                                {h <= config.stop &&
                                                    <div className="in_hour">{("0" + (h / 12 + 1)).slice(-2)}</div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                    <div className="wrap_days">
                                        {myDates.map(d => (
                                            <OneColumnDay date={d} Plages={GetPlageDay(d)} hours={myHours} key={`Col${d.getDay()}`}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }
                        <ModalNewPlage
                            plageTempo={plageTempo}
                            closeMe={()=>setPlageTempo(null)}
                            idUser={props.User.id}
                        />
                    </ContextPlanning.Provider>
                    <CustomDragLayerHandleCopy/>
                </DndProvider>
            </div>
            <MenuFloatPlage
                closeMe={()=> {
                    setOpen(false)
                    setPlageCurrent(null)
                }}
                open={open}
                plage={plageCurrent}
                TrashPlage={TrashPlage}
            />
        </div>
    )
}

const WeeklyCalendar = styled(WeeklyCalendarCtrl)`
    margin: 30px 0;

    .wrap_actions_top {
        display: flex;
        justify-content: flex-end;
        gap: 4px;
    }

    .in_weekly {
        background: white;
        padding: 0.5rem;
        margin-top: 25px;
        border-radius: 4px;
        
    }

    .hours_col {
        width: 40px;
        flex-shrink: 0;
    }

    .one_col {
        flex: 1 1 0;
        &:not(:last-child) {
            border-right: solid ${props => props.theme.NeutreExtraLight} 1px;
        }
    }
    .one_hour_in{
        border-bottom: solid ${props => props.theme.NeutreExtraLight} 1px;
        position:relative;
        &.first{
            border-top: solid ${props => props.theme.NeutreExtraLight} 1px;
        }
        .in_hour{
            position: absolute;
            font-size: 14px;
            top:100%;
            right: 5px;
            width: calc(100% - 5px);
            transform: translateY(-50%);
            background: white;
            padding-right: 5px;
            text-align: right;
        }
    }
    .wrap_days{
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-start;
    }
    .Header, .body_calendar{
        display: flex;
        justify-content: flex-start;
    }
`

export default WeeklyCalendar