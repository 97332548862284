import styled from "styled-components";
import PoigneeDrag from "../../components/PoigneeDrag";
import RightCard from "../../components/RightCard";
import SquareIndic from "../../components/SquareIndic";
import LibCard from "../../components/LibCard";
import {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Identifier, XYCoord} from "dnd-core";
import {ItemTypes} from "../../../Plannings/SemaineType/ItemTypes";

interface OneCardFxCtrlProps{
    className?:string;
    Fx:Fonction;
    ClickOnEdit:()=>void;
    isCurrent?:boolean;
    Move:(dragIndex: number, hoverIndex: number)=>void
    SaveMove:()=>void;
    index:number;
}
interface DragItem{
    id:number,
    index:number;
}
const OneCardFxCtrl = (props:OneCardFxCtrlProps)=>{
    const id = props.Fx.id;
    const index = props.index;
    const ref = useRef<HTMLDivElement>(null)

    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: ItemTypes.FX,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        drop(item:DragItem){
            props.SaveMove();
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            props.Move(item.index, index)
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.FX,
        item: () => {
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag:true
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
        <div className={`OneCardFx card_draggable ${props.className}`} style={{opacity:opacity}} ref={ref}  data-handler-id={handlerId}>
            <PoigneeDrag/>
            <RightCard isCurrent={props.isCurrent} ClickOn={()=>props.ClickOnEdit()}>
                <SquareIndic backColor={"white"} fontColor={props.Fx.color}><span>A</span></SquareIndic>
                <LibCard text={props.Fx.libelle}/>
            </RightCard>
        </div>
    )
}

const OneCardFx = styled(OneCardFxCtrl)``

export default OneCardFx