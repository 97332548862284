import styled from "styled-components";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useGetOneUser, useGetUser} from "../../../../features/user/user.hooks";
import WeeklyCalendar from "../../../../components/organisms/TimedWeekCalendar/WeeklyCalendar";

interface PlanningIndiHebdo25CtrlProps{
    className?:string;

}

const PlanningIndiHebdo25Ctrl = (props:PlanningIndiHebdo25CtrlProps)=>{
    const UserConnectedQuery = useGetUser();
    const [idUser, setIdUser] = useState<number>(0)
    const UserUserQuery = useGetOneUser(idUser)
    const [date, setDate] = useState<Date|null>(null)
    const [myUser, setMyUser] = useState<User|null>(null)
    const [searchParams] = useSearchParams();
    useEffect(()=>{
        const TabParams = [...Array.from(searchParams)];
        const TabFrom = TabParams.find(tab=>tab[0]==='from');
        const TabCollab = TabParams.find(tab=>tab[0]==='idCollab');
        const Today = new Date((new Date()).setHours(12,0,0));
        if(!TabCollab && UserConnectedQuery.data){
            setIdUser(UserConnectedQuery.data.id)
        } else if(TabCollab) {
            const tabidCollab = TabCollab[1].split("#");
            setIdUser(parseInt(tabidCollab[0]))
        }
        if(!TabFrom){
            setDate(Today);
        } else {
            const TabDat = TabFrom[1].split('/');
            const dateFrom = new Date(`${TabDat[2]}-${TabDat[1]}-${TabDat[0]}T12:00:00`)
            setDate(dateFrom);
        }
    }, [searchParams, UserConnectedQuery.data])
    useEffect(() => {
        if(UserUserQuery.data){
            setMyUser(UserUserQuery.data)
        }
    }, [UserUserQuery.data]);
    return (
        <div className={`planningIndiHebdo25 ${props.className}`}>
            {(UserUserQuery.isLoading || !date || !myUser || UserConnectedQuery.isLoading) ? 'chargement' :
                <WeeklyCalendar date={date} User={myUser}/>
            }
        </div>
    )
}

const PlanningIndiHebdo25 = styled(PlanningIndiHebdo25Ctrl)`
    padding: 1% 2%;
`

export default PlanningIndiHebdo25