import {useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult} from "react-query";
import {getPointeuseByParams, getPointeuseFree} from "./pointeuse.services";

export const useGetPointeuseByParams = (Params:any|undefined|null=null):UseQueryResult<Pointeuse[], Error>=>{
    return useQuery<Pointeuse[], Error>(["pointeuses", Params], ()=>getPointeuseByParams(Params), {
        staleTime:5000,
        enabled:Params!==undefined && Params!==null
    })
}

export const useGetPointeuseFree = (params:ParamsPointage):UseInfiniteQueryResult<PointeusePage, Error>=>{
    return useInfiniteQuery<PointeusePage, Error>(["pointeuse_page", params], ({pageParam=`page=1`})=>getPointeuseFree(pageParam, params),  {
        getNextPageParam: (lastPage, allPages) =>{
            const View = lastPage.views["hydra:next"]
            if(View){
                const TabView = View.split('?')
                return TabView[1];
            }
            return undefined

        },

    })
}