import styled from "styled-components";
import TempsTrSemaineOneUser from "../02_TempsSemaine/TempsTrSemaineOneUser";
import TempsTrAbsencesV2 from "../03_Absences/TempsTrAbsencesV2";
import TempsTrEvents from "../04_Events/TempsTrEvents";
import {useLocation, useNavigate} from "react-router-dom";
import {format} from "date-fns";

interface DetailsTempsTrUserCtrlProps{
    className?:string;
    user:User;
    calendrier:CalendrierPaie;
}

const DetailsTempsTrUserCtrl = (props:DetailsTempsTrUserCtrlProps)=>{
    const navigate = useNavigate();
    const ClickOnHeures = (dateFrom:string, dateTo:string)=>{
        console.log("ClickOnHeures "+dateFrom+" dateTo"+dateTo)
        const myDateFr = format(new Date(dateFrom.slice(0,10)), "dd/MM/yyyy")
        navigate(`../planning_indi_hebdo?from=${myDateFr}&idCollab=${props.user.id}`)
    }
    const Back = ()=>{
        console.log("To Remove")
    }
    return (
        <div className={`detailsTempsTrUser ${props.className}`}>
            <div className="wrap_temps_sem wrap_block">
                <TempsTrSemaineOneUser calendrier={props.calendrier} ClickOnHeures={ClickOnHeures} dateTo={props.calendrier.stopAt.slice(0,10)} Back={Back} dateFrom={props.calendrier.startAt.slice(0,10)} user={props.user}/>
            </div>
            <div className="wrap_abs_Evts ">
                <div className="col_abs wrap_block">
                    <TempsTrAbsencesV2 calendrier={props.calendrier} user={props.user}/>
                </div>
                <div className="col_events wrap_block">
                    <TempsTrEvents calendrier={props.calendrier} user={props.user}/>
                </div>
            </div>
        </div>
    )
}

const DetailsTempsTrUser = styled(DetailsTempsTrUserCtrl)`
    flex-grow: 1;
    padding-right: 10px;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    .wrap_block{
        border:solid ${props => props.theme.Primary} 1px;
        background: white;
        h2{
            padding: 0.5rem 0 0 0.5rem;
            margin-bottom: 15px;
        }
    }
    .wrap_abs_Evts{
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        .col_abs, .col_events{
            flex-grow: 1;
        }
    }
`

export default DetailsTempsTrUser