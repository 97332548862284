import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getAllActes(idCentre:number):Promise<Acte[]>{
    const res:any = await dataFetcher(`actes?Centre[]=${idCentre}&Centre[]=0&pagination=false`, {method:'get'});
    return res["hydra:member"];
}

export function modifyActe(id:number, datas:ActeFD):Promise<Acte>{
    return dataFetcher(`actes/${id}`, {
        method:'put',
        body:datas
    })
}

export function addActe(datas:ActeFD):Promise<Acte>{
    return dataFetcher(`actes`,{
        method:'post',
        body:datas
    })
}

export function getActeById(id:number):Promise<Acte>{
    return dataFetcher(`actes/${id}`, {method:'get'})
}

export function changeOrdreActe(datas:ActesChangeOrdre):Promise<Acte[]>{
    return dataFetcher(`actes/updateOrdre`, {
        method:'post',
        body:datas
    })
}

export function switchActeMaster(id:number):Promise<Acte[]>{
    return dataFetcher(`actes/${id}/switch_master`, {
        method:'get'
    })
}

export function TrashActe(id:number):Promise<any>{
    return dataFetcher(`actes/${id}`, {
        method:"delete"
    })
}

export function putActeHoraires(datas:ActeHoraireFD):Promise<LieuHoraires>{
    return dataFetcher(`acte_horaires/${datas.id}`, {
        method:"put",
        body:datas
    })
}