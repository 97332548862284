import styled from "styled-components";
import {useRef} from "react";
import "./StyleForm.css";

interface TimedTextAreaCtrlProps{
    className?:string;
    label?:string;
    placeholder?:string;
    setValue:(s:string, id:string)=>void;
    Error:string|null;
    id:string;
    isDisabled?:boolean;
    current:string;
}

const TimedTextAreaCtrl = (props:TimedTextAreaCtrlProps)=>{
    const refI = useRef<HTMLTextAreaElement>(null)
    const HandleChange = ()=>{
        if(refI.current){
            props.setValue(refI.current.value, props.id)
        }
    }
    return (
        <div className={`timedTextArea ${props.className}`}>
            {props.label &&
                <label className={"std"}>{props.label}</label>
            }
            <textarea ref={refI} disabled={props.isDisabled} className={"std"} value={props.current} onChange={HandleChange}/>
        </div>
    )
}

const TimedTextArea = styled(TimedTextAreaCtrl)`
    textarea{
        background: ${props => props.theme.colorBackInput};
    }
`

export default TimedTextArea