import styled, {keyframes} from "styled-components";

export const Shine = keyframes`
  to {
    background-position-x: -200%;
  }
`
export const WhiteShadow = styled.div`
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255,255,255,.3);
    position: absolute;
`

export const SkeletonWait = styled.div`
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(110deg, #c6d2d2 8%, #C3C8C8 18%, #C6D2D2FF 33%);
    background-size: 200% 100%;
    animation: ${Shine} 4s linear infinite;
`
