import styled from "styled-components";
import {useEffect, useState} from "react";
import {useGetAllLieuHorairesByLieu, usePutLieuHoraires} from "../../../../features/lieuHoraires/lieuHoraires.hooks";
import {Contraint, DatasSetter, ValideNoError} from "../../../../components/functions/ValideDataFunctions";
import {useModifyLieu} from "../../../../features/lieu/lieu.hooks";
import InputTextSetting from "../components/InputTextSetting";
import InputColorPickerSetting from "../components/InputColorPickerSetting";
import InputSelectSetting from "../components/InputSelectSetting";
import ModifyLoader from "../components/ModifyLoader";
import ChangeHoraireSetting from "../components/ChangeHoraireSetting";
import {useGetNbPlageType} from "../../../../features/plage/plage.hooks";
import {useDebounce} from "../../../../services/hooks";

interface FormEditLieuCtrlProps{
    className?:string;
    Lieu:Lieu;
    Actes:Acte[];
}

interface LieuxEditProps{
    libelle:string;
    backColor:string;
    fontColor:string;
    DefaultActe:Choice|null;
    TypePresence:Choice|null;
    Statut:Choice|null;
}

interface ErrorsProps{
    libelle:string|null;
    backColor:string|null;
    fontColor:string|null;
    DefaultActe:string|null;
    TypePresence:string|null;
    Statut:string|null;
}

interface DetailsProps{
    DefaultActe:InfosProps;
    TypePresence:InfosProps;
    Statut:InfosProps;
}
const TabInfos:DetailsProps = {
    DefaultActe:{keyProps:"DefaultActe", libelle:"Lieu - Acte par défaut", description:"Si vous choisissez un acte, ce dernier sera automatiquement sélectionné dans la création d'une plage"},
    TypePresence:{keyProps:"TypePresence", libelle:"Lieu - Type de présence", description:"En choisissant présent ou absent, vous choisissez si lors de la création d'une plage sur ce lieu, le collaborateur sera considéré comme présent ou non"},
    Statut:{keyProps:"Statut", libelle:"Lieu - Statut du lieu", description:"Si le lieu est inactif il ne pourra plus être choisi dans le futur, il restera cependant présent dans le passé"},
}

const DefaultError:ErrorsProps ={
    libelle:null,
    backColor:null,
    fontColor:null,
    DefaultActe:null,
    TypePresence:null,
    Statut:null,
}


interface OneHoraires{
    id:number;
    start:number;
    libelle:string;
    end:number;
}


const FormEditLieuCtrl = (props:FormEditLieuCtrlProps)=>{
    const mutationModify = useModifyLieu();
    const mutationLH = usePutLieuHoraires();
    const [idLieu, setIdLieu] = useState(props.Lieu.id)
    const PlageNbQuery = useGetNbPlageType({id:idLieu, type:"lieu"})
    const LieuxHorairesQuery = useGetAllLieuHorairesByLieu(idLieu)
    const [mesHoraires, setMesHoraires] = useState<OneHoraires[]>([])
    const choicesType:Choice[] = [{id:0, libelle:"Absence", description:"il s'agit d'une absence spécifique"},{id:1, libelle:"Présent", description:"Il s'agit d'un lieu de travail"}]
    const choicesStatut:Choice[] = [{id:0, libelle:"Inactif", description:"Rendre le lieu inactif pour le futur"},{id:1, libelle:"Actif", description:"Le lieu est sélectionnable"}]
    const [isDirty, setIsDirty] = useState(false);
    const [choicesActes, setChoicesActes] = useState(props.Actes.map(a=>{
        return {id:a.id, libelle:a.libelle}
    }))
    const [myLieu, setMyLieu] = useState<LieuxEditProps>({
        libelle:props.Lieu.libelle,
        backColor:props.Lieu.backColor,
        fontColor:props.Lieu.fontColor,
        DefaultActe:props.Lieu.defaultActe ? {id:props.Lieu.defaultActe.id, libelle:props.Lieu.defaultActe.libelle} : null,
        TypePresence:props.Lieu.isLieuAbs ? {id:0, libelle:"Absence"} : {id:1, libelle:"Présent"},
        Statut:!props.Lieu.isProduction ? {id:0, libelle:"Inactif"} : {id:1, libelle:"Actif"},
    })
    const [myLieuInitial, setMyLieuInitial] = useState<LieuxEditProps>({
        libelle:props.Lieu.libelle,
        backColor:props.Lieu.backColor,
        fontColor:props.Lieu.fontColor,
        DefaultActe:props.Lieu.defaultActe ? {id:props.Lieu.defaultActe.id, libelle:props.Lieu.defaultActe.libelle} : null,
        TypePresence:props.Lieu.isLieuAbs ? {id:0, libelle:"Absence"} : {id:1, libelle:"Présent"},
        Statut:!props.Lieu.isProduction ? {id:0, libelle:"Inactif"} : {id:1, libelle:"Actif"},
    })
    const SaveHeureDebutFinHoraire = (n:number, id:string)=>{
        const tabId = id.split("_");
        const idHoraires = parseInt(tabId[0]);
        const startEnd = tabId[1];
        const horActual = [...mesHoraires];
        const myNewHoraires = horActual.map(h=>{
            if(h.id === idHoraires){
                return {...h, [startEnd]:n}
            } else {
                return h;
            }
        })
        setMesHoraires(myNewHoraires);
        SaveLieuHoraires(myNewHoraires);
    }
    const SaveLieuHoraires = (lhs:OneHoraires[])=>{
        const tabDatas:LieuHoraireFD[] = []
        lhs.forEach(lh=>{
            tabDatas.push({
                id:lh.id,
                start:lh.start,
                end:lh.end
            })
        })
        tabDatas.forEach(i=>{
            mutationLH.mutate(i)
        })
    }
    useEffect(() => {
        console.log(myLieu)
    }, [myLieu]);
    useEffect(() => {
        console.log(props.Lieu)
    }, [props.Lieu]);
    useEffect(() => {
     setMyLieu({
         libelle:props.Lieu.libelle,
         backColor:props.Lieu.backColor,
         fontColor:props.Lieu.fontColor,
         DefaultActe:props.Lieu.defaultActe ? {id:props.Lieu.defaultActe.id, libelle:props.Lieu.defaultActe.libelle} : null,
         TypePresence:props.Lieu.isLieuAbs===true ? {id:0, libelle:"Absence"} : {id:1, libelle:"Présent"},
         Statut:!props.Lieu.isProduction ? {id:0, libelle:"Inactif"} : {id:1, libelle:"Actif"},
     })
        setMyLieuInitial({
            libelle:props.Lieu.libelle,
            backColor:props.Lieu.backColor,
            fontColor:props.Lieu.fontColor,
            DefaultActe:props.Lieu.defaultActe ? {id:props.Lieu.defaultActe.id, libelle:props.Lieu.defaultActe.libelle} : null,
            TypePresence:props.Lieu.isLieuAbs===true ? {id:0, libelle:"Absence"} : {id:1, libelle:"Présent"},
            Statut:!props.Lieu.isProduction ? {id:0, libelle:"Inactif"} : {id:1, libelle:"Actif"},
        })
        setIdLieu(props.Lieu.id)
    }, [props.Lieu]);
    const libLieuDebounce = useDebounce(myLieu.libelle, 900);
    const [isSavable, setIsSavable] = useState(false);
    useEffect(() => {
        if(myLieu.TypePresence) setChoicesActes(props.Actes.filter(a=>a.isPresent === (myLieu.TypePresence?.id===1)).sort((a,b)=>a.libelle > b.libelle ? 1 : -1))
    }, [myLieu]);
    useEffect(() => {
        const myActe = props.Actes.find(a=>a.id === myLieu.DefaultActe?.id)
        if(myActe && !isDirty && myLieu.DefaultActe){
            if((myLieu.TypePresence?.id === 0 && myActe.isPresent) || (myLieu.TypePresence?.id === 1 && !myActe.isPresent)){
                setMyValue(null, "DefaultActe");
            }
        }
    }, [myLieu, isDirty, props.Actes]);
    const TabString = ["libelle", "backColor", "fontColor"]
    const TabChoice = ["DefaultActe","TypePresence","Statut"]
    const [errors, setErrors] = useState<ErrorsProps>(DefaultError)
    const TabNeeds:Contraint[] = [
        {id:"libelle", required:true},
        {id:"backColor", required:true},
        {id:"fontColor", required:true},
    ]

    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        console.log(value)
        console.log(id)
        DatasSetter<LieuxEditProps, ErrorsProps>(value, id as keyof LieuxEditProps, TabString, [], TabChoice, TabNeeds, myLieu, setMyLieu,errors,setErrors)
        if(id === "libelle"){
            setIsSavable(true);
        } else {
            setIsDirty(true);
        }
    }
    useEffect(() => {
        if(LieuxHorairesQuery.data){
            setMesHoraires(LieuxHorairesQuery.data.map(lh=>{
                return {id:lh.id, libelle:lh.libelle, end:lh.end, start:lh.start}
            }))
        }
    }, [LieuxHorairesQuery.data]);
    useEffect(() => {
        if(isDirty){
            SaveModify();
        }
    }, [isDirty]);
    const RealDirty = ()=>{
        const TabKey:(keyof LieuxEditProps)[] = ["backColor", "fontColor", "DefaultActe","TypePresence","Statut"]
        let nbDiff = 0;
        TabKey.forEach(k=>{
            if(myLieu[k]!==myLieuInitial[k]) nbDiff++;
        })
        console.log(nbDiff);
        return nbDiff>0;
    }
    useEffect(() => {
        if(isSavable){
            const datas:LieuFD = {
                libelle:myLieu.libelle,
            }
            mutationModify.mutate({id:props.Lieu.id, datas:datas}, {
                onSuccess:()=>{
                    setIsSavable(false);
                    setMyLieuInitial(lx=>{
                        return {...lx, libelle:libLieuDebounce}
                    })
                }
            })
        }
    }, [libLieuDebounce]);
    const SaveModify = ()=>{
        console.log("ici maj")
        if(ValideNoError<ErrorsProps>(errors) && RealDirty()) {
            const datas: LieuFD = {

                fontColor:myLieu.fontColor,
                backColor:myLieu.backColor,
                isProduction:(myLieu.Statut as Choice).id === 1,
                isLieuAbs:(myLieu.TypePresence as Choice).id === 0,
            }
            if(myLieu.DefaultActe){
                const my = props.Actes.find(a=>a.id === myLieu.DefaultActe?.id||0)
                if(my){
                    datas.defaultActe = `/api/actes/${my.id}`
                }
            } else {
                datas.defaultActe = null;
            }
            mutationModify.mutate({id: props.Lieu.id, datas: datas}, {
                onSuccess:()=>{
                    setIsDirty(false)
                    setMyLieuInitial({...myLieu})
                }
            })
        }

    }
    const TrashLieu = ()=>{
        console.log("Trash")
    }
    return (
        <div className={`formEditLieu ${props.className}`}>
            {mutationModify.isLoading &&
                <ModifyLoader/>
            }
            <div className="wrap_inputs_setting">
                <InputTextSetting current={myLieu.libelle} setValue={setMyValue} Error={errors.libelle} id={"libelle"} label={"Libellé"}/>
                <InputColorPickerSetting current={myLieu.backColor} setValue={setMyValue} label={"Couleur de fond"} id={"backColor"}/>
                <InputColorPickerSetting current={myLieu.fontColor} setValue={setMyValue} label={"Couleur du texte"} id={"fontColor"}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.DefaultActe} label={"Acte par défaut"} id={"DefaultActe"} choices={choicesActes} Current={myLieu.DefaultActe} placeNoChoice={"- Sélectionner un acte -"} ItemInfos={TabInfos.DefaultActe}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.TypePresence} label={"Type de présence"} id={"TypePresence"} choices={choicesType} Current={myLieu.TypePresence} ItemInfos={TabInfos.TypePresence}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.Statut} label={"Statut du lieu"} id={"Statut"} choices={choicesStatut} Current={myLieu.Statut} ItemInfos={TabInfos.Statut}/>
                {mesHoraires.length>0 &&
                    mesHoraires.map(item=>(
                        <ChangeHoraireSetting key={`hor${item.id}`} currentEnd={item.end} errorEnd={null} idEnd={`${item.id}_end`} errorStart={null} setHor={SaveHeureDebutFinHoraire} label={item.libelle} currentStart={item.start} idStart={`${item.id}_start`}/>
                    ))
                }
                {PlageNbQuery.data &&
                    <div className="wrap_delete_poss">
                        {PlageNbQuery.data.total>0 ?
                            <div className="no_delete_poss">Ce lieu est utilisé <strong>{PlageNbQuery.data.total}</strong> fois dans les plages, il n'est pas possible de le supprimer<br/>Vous pouvez cependant le rendre inactif pour le futur</div>:
                            <div className="delete_possible">
                                <span className={`link_delete`} onClick={TrashLieu}>Supprimer ce lieu</span>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const FormEditLieu = styled(FormEditLieuCtrl)`
    position: relative;
    .wrap_delete_poss{
        margin-top: 30px;
    }
    .no_delete_poss{
        line-height: 170%;
    }
`

export default FormEditLieu