import styled from "styled-components";
import {useGetInfosDay} from "../../../../services/hooks";


interface HeadDayCtrlProps{
    className?:string;
    date:Date;
}

const HeadDayCtrl = (props:HeadDayCtrlProps)=>{
    const {libelle, strDate} = useGetInfosDay(props.date)
    return (
        <div className={`headDay ${props.className} one_col`}>
            <div className={`in_head_day`} >
                <div className="lib_day">{libelle}</div>
                <div className="lib_date">{strDate}</div>
            </div>
        </div>
    )
}

const HeadDay = styled(HeadDayCtrl)`
    padding: 0.25rem;
    border-bottom: solid 1px ${props=>props.theme.NeutreExtraLight};
    .in_head_day{
        text-align: center;    
    }
    .lib_day{
        font-weight: bold;
    }
`

export default HeadDay