import styled from "styled-components";
import PageTripleSetting from "../components/PageTripleSetting";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import {TiPlus} from "react-icons/ti";
import TitrePageSetting from "../components/TitrePageSetting";
import {useModal} from "../../../../hooks/useModal";
import LeftPartTriplePage from "../components/LeftPartTriplePage";
import RightPartTriplePage from "../components/RightPartTriplePage";
import {useGetAllPeriodes} from "../../../../features/periode/periode.hooks";
import {useEffect, useState} from "react";
import WrapListCompteurs from "./WrapListCompteurs";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import {useGetAllTypePeriodeByCentre} from "../../../../features/typePeriode/typePeriode.hooks";
import WrapListTypeCompteur from "./WrapListTypeCompteur";
import RightCard from "../components/RightCard";
import FormEditCompteur from "./FormEditCompteur";
import FormEditPeriode from "./FormEditPeriode";
import BtnOutline from "../components/BtnOutline";
import {FaPlus} from "react-icons/fa";
import {TimedModalCentral2} from "../../../../components/molecules/TimedModal/TimedModalCentral2";
import FormNewPeriode from "./FormNewPeriode";
import FormNewCompteur from "./FormNewCompteur";

interface ParamsPeriodesCptrsCtrlProps{
    className?:string;
}

const TabConfig:Choice[]=[
    {id:0, libelle:"Config. Compteur"},
    // {id:1, libelle:"Membres"}
]

const ParamsPeriodesCptrsCtrl = (props:ParamsPeriodesCptrsCtrlProps)=>{
    const {open, toggle} = useModal()
    const {open:openNewPeriode, toggle:toggleNewPeriode} = useModal()
    const CentreQuery = useGetCentreActuel()
    const [idCentre, setIdCentre] = useState<number>(0)
    const TypesPeriodesQuery = useGetAllTypePeriodeByCentre(idCentre)
    const PeriodesQuery = useGetAllPeriodes();
    const [mesPeriodes, setMesPeriodes] = useState<Periode[]>([])
    const [mesTypesPeriodes, setMesTypesPeriodes] = useState<TypePeriode[]>([])
    const [typeSet, setTypeSet] = useState<TypePeriode|null>(null)
    const [periodeSet, setPeriodeSet] = useState<Periode|null>(null)
    const [ConfigCurr, setConfigCurr] = useState<Choice|null>(TabConfig[0])
    useEffect(() => {
        if(CentreQuery.data) setIdCentre(CentreQuery.data.id)
    }, [CentreQuery.data]);
    useEffect(() => {
        if(TypesPeriodesQuery.data){
            setMesTypesPeriodes(TypesPeriodesQuery.data.filter(t=>t.id > 2).sort((a,b)=>a.libelle > b.libelle ? 1 : -1))
        }
    }, [TypesPeriodesQuery.data]);
    useEffect(() => {
        if(PeriodesQuery.data){
            if(typeSet) {
                setMesPeriodes(PeriodesQuery.data.filter(p => p.Type.id > 2 && p.Type.id === typeSet.id).sort((a, b) => a.libelle > b.libelle ? 1 : -1))
            } else {
                setMesPeriodes([]);
                setPeriodeSet(null);
            }
        }
    }, [PeriodesQuery.data, typeSet]);
    // useEffect(() => {
    //     if(mesPeriodes.length>0 && !periodeSet){
    //         setPeriodeSet(mesPeriodes[0])
    //     }
    // }, [mesPeriodes, periodeSet]);
    useEffect(() => {
        if(mesTypesPeriodes.length>0 && !typeSet){
            setTypeSet(mesTypesPeriodes[0])
        }
    }, [mesTypesPeriodes, typeSet]);
    const EndCreatePeriode = (p:Periode)=>{
        setPeriodeSet(p);
        setConfigCurr(null);
        toggleNewPeriode();
    }
    const EndCreateCptr = (cptr:TypePeriode)=>{
        setTypeSet(cptr)
        setPeriodeSet(null)
        setConfigCurr(TabConfig[0])
    }
    const getMaxEndPeriodeSameType = (id=0)=>{
        if(!typeSet){
            return null;
        } else {
            const PeriodeConcerned = PeriodesQuery.data?.filter(p => p.Type.id > 2 && p.Type.id === typeSet.id && p.id!==id).sort((a, b)=>new Date(a.finAt) > new Date(b.finAt) ? -1 : 1)
            if(PeriodeConcerned && PeriodeConcerned.length>0){
                const MaxDateFin = new Date(PeriodeConcerned[0].finAt.slice(0,10)+"T12:00:00")
                return new Date(MaxDateFin.getTime() + 86400000)
            } else {
                return null;
            }
        }
    }
    return (
        <div className={`paramsPeriodesCptrs page_params ${props.className}`}>
            <TitrePageSetting titre={"Compteurs et périodes"}>
                <TimedButton onClick={toggle} themeColor={"Primary"} size={"sm"}>
                    <div className={`wrap_in_btn`}><TiPlus /><span>Créer un compteur</span></div>
                </TimedButton>
            </TitrePageSetting>
            <PageTripleSetting>
                <LeftPartTriplePage titre={"Vos compteurs"}>
                    <WrapListTypeCompteur Types={mesTypesPeriodes} TypeActif={typeSet} setType={(t:TypePeriode)=> {
                        setTypeSet(t)
                        setPeriodeSet(null)
                    }}/>
                </LeftPartTriplePage>
                <LeftPartTriplePage titre={typeSet ? `Paramétrages et périodes du compteur ${typeSet.libelle}` : "Paramétrage et périodes"}>
                    <div className="block_in">
                        <div className="titre_block">Paramétrages</div>
                        <div className="content_block">
                            {TabConfig.map((item)=>(
                                <RightCard isCurrent={item.id === ConfigCurr?.id} ClickOn={()=> {
                                    setConfigCurr(item)
                                    setPeriodeSet(null)
                                }} key={`C${item.id}`}>
                                    <span className={`list_config_lib`}>{item.libelle}</span>
                                </RightCard>
                            ))}
                        </div>
                    </div>
                    <div className="block_in">
                        <div className="titre_block">Périodes du compteur</div>
                        <WrapListCompteurs Periodes={mesPeriodes} PeriodeActive={periodeSet}
                                           setPeriode={(p:Periode)=> {
                                               setPeriodeSet(p);
                                               setConfigCurr(null);
                                           }}/>
                        {mesPeriodes.length === 0 &&
                            <div className="no_periode">Aucune période sur ce compteur</div>
                        }
                        <div className="wrap_add_periode">
                        <BtnOutline Icon={<FaPlus />} libelle={"Ajouter une période"} OnClick={toggleNewPeriode}/>
                        </div>
                    </div>
                </LeftPartTriplePage>
                <RightPartTriplePage titre={"Option compteur"} subtitle={"Paramétrez les options et caractéristiques pour personnaliser ce type de compteur"}>
                    {(ConfigCurr && ConfigCurr.id === 0 && typeSet) ?
                        <FormEditCompteur Type={typeSet} idCentre={idCentre}/>:
                        periodeSet ?
                            <FormEditPeriode Periode={periodeSet} maxDateSameType={getMaxEndPeriodeSameType(periodeSet.id)}/>:
                            <></>
                    }
                </RightPartTriplePage>
            </PageTripleSetting>
            <TimedModalCentral2 closeMe={toggleNewPeriode} Wd={"500px"} open={openNewPeriode} Pending={false} titre={"Nouvelle période"}>
                {typeSet &&
                    <FormNewPeriode TypePeriode={typeSet} EndCreate={EndCreatePeriode} maxDateSameType={getMaxEndPeriodeSameType()}/>
                }
            </TimedModalCentral2>
            <TimedModalCentral2 closeMe={toggle} Wd={"500px"} open={open} Pending={false} titre={"Nouveau Compteur"}>
                <FormNewCompteur EndCreateCptr={EndCreateCptr}/>
            </TimedModalCentral2>
        </div>
    )
}

const ParamsPeriodesCptrs = styled(ParamsPeriodesCptrsCtrl)`
    .wrap_add_periode{
        margin: 20px 0;
        display: flex;
        justify-content: center;
    }
    .block_in{
        &:first-child{
            margin-bottom: 30px;
            border-bottom: solid ${props => props.theme.Primary} 1px;
            padding-bottom: 30px;
        }
        .titre_block{
            font-weight: bold;
            margin-bottom: 12px;
        }
    }
    .content_block{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .list_config_lib{
        padding: 0.25rem;
    }
`

export default ParamsPeriodesCptrs