import React, {useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import {ListChoice} from "./styleInters";
import {useGetAllUser} from "../../../features/user/user.hooks";
import {TimedUserAvatar} from "../../molecules/_TimedUserAvatar/TimedUserAvatar";

interface TimedListChoiceUserProps{
    open:boolean;
    closeMe:()=>void;
    x:number,
    y:number,
    Choices:Choice[],
    setChoice:(c:Choice)=>void;
    id:string;
    Zindex?:number;
}

export const TimedListChoiceUser = ({open, x, y, Choices, setChoice, id, closeMe, Zindex}:TimedListChoiceUserProps)=>{
    const UsersQuery = useGetAllUser();
    const refObjt = useRef<HTMLDivElement>(null)
    useEffect(() => {
        function handleClickOutside(event:any) {
            const myIdInputRef = document.getElementById("input"+id)
            if (refObjt.current && !refObjt.current.contains(event.target) && (!myIdInputRef || !myIdInputRef.contains(event.target))) {
                closeMe();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObjt, id]);
    const GetAvatars = (id:number)=>{
        const myUser = UsersQuery.data?.find(u=>u.id===id)
        if(!myUser){
            return <></>
        } else {
            return <TimedUserAvatar User={myUser} RightAddInit isInactif={false} isInverseName/>
        }
    }
    if(!open){
        return null;
    }
    return ReactDOM.createPortal(
        <ListChoice ref={refObjt} style={{left:x+"px", top:y+"px", zIndex:Zindex ? Zindex : 4}}>
            {Choices.filter(c=>c.libelle!=='').map(item=>(
                <div onClick={()=>setChoice(item)} className={`one_choice_inter`} key={`ChoiceInt${id}${item.id}`}>
                    {GetAvatars(item.id as number)}
                </div>
            ))}
        </ListChoice>, document.body
    )
}