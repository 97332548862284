import styled from "styled-components";
import OnePlageSTTempo from "./OnePlageSTTempo";


interface ZoneCSTTempoCtrlProps{
    className?:string;
    plages:Plage[];
}

const ZoneCSTTempoCtrl = (props:ZoneCSTTempoCtrlProps)=>{

    return (
        <div className={`zoneCstTempo ${props.className}`}>
            {props.plages.map(item=>(
                <OnePlageSTTempo plage={item} key={`TempoCST${item.id}`}/>
            ))}
        </div>
    )
}

const ZoneCSTTempo = styled(ZoneCSTTempoCtrl)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
`

export default ZoneCSTTempo