import styled from "styled-components";
import React, {memo, useEffect, useMemo, useState} from "react";
import logoLong from "../../../assets/images/logo-timed-dark-mode.png";
import {version_timed} from "../../../index";
import {useGetSections} from "../../../features/module/module.hooks";
import {TimedSpinner} from "../../atoms/TimedSkeleton/TimedSpinner";
import {OneSection} from "./OneSection";
import {useGetCentreActuel} from "../../../features/centre/centre.hooks";
import {useGetAlerteCentre} from "../../../features/alerteCentre/alerteCentre.hooks";
import {DirectLinkAlerte} from "./DirectLinkAlerte";
import {clearInterval} from "node:timers";


interface TimedSideBarCtrlProps{
    className?:string;
}



const TimedSideBarCtrl = (props:TimedSideBarCtrlProps)=>{
    const SectionsQuery = useGetSections();
    const CentreQuery = useGetCentreActuel();
    const [idCentre, setIdCentre] = useState(0);
    const AlertesQuery = useGetAlerteCentre(idCentre);
    const [nbAlerte, setNbAlerte] = useState(0);
    const [isSaw, setIsisSaw] = useState(false);
    const [reload, setReload] = useState(false);
    const [showAlert, setShowAlerte] = useState(false);
    const listSections = useMemo(()=>{
        const list:Section[] = [];
        if(SectionsQuery.data && CentreQuery.data){
            SectionsQuery.data.forEach(s=>{
                const Params = s.Parameter;
                if(Params){
                    const centreParams = CentreQuery.data.parameters;
                    const MyParams = centreParams.find(cp=>cp.Parameter.id === Params.id);
                    if(MyParams && MyParams.statut){
                        list.push(s);
                    }
                } else {
                    list.push(s);
                }
            })

        }
        return list
    }, [SectionsQuery.data, CentreQuery.data])
    useEffect(() => {
        const myOtherTimer = window.setInterval(()=>{
            const myTimerView = localStorage.getItem("__time_user_view_alerte");
            if(myTimerView!==""){
                setIsisSaw(true);
            }
        }, 1000)
        return ()=>{
            window.clearInterval(myOtherTimer);
        }
    }, []);
    useEffect(() => {
        const myTimer = window.setInterval(()=>{
            AlertesQuery.refetch();
            setReload(o=>!o);
        }, 600000)
        return ()=>{
            window.clearInterval(myTimer);
        }

    }, [AlertesQuery.refetch]);
    useEffect(() => {
        if(CentreQuery.data){
            setIdCentre(CentreQuery.data.id);
        }
    }, [CentreQuery.data]);
    useEffect(() => {
        if(AlertesQuery.data){
            const newNbAlerte = AlertesQuery.data.nbAlerte;
            if(newNbAlerte>0){
                setShowAlerte(true)
                if(nbAlerte<newNbAlerte){
                    localStorage.setItem("__time_user_view_alerte", "");
                    setIsisSaw(false);
                } else if(nbAlerte === newNbAlerte){
                    const myTimerView = localStorage.getItem("__time_user_view_alerte");
                    if((!myTimerView || myTimerView === '')){
                        setIsisSaw(false);
                    } else {
                        const DateTimeView = new Date(myTimerView);
                        const Now = new Date();
                        const Delay = Math.abs(Now.getTime() - DateTimeView.getTime())/60000; // en minutes
                        if(Delay>60){
                            localStorage.setItem("__time_user_view_alerte", "");
                            setIsisSaw(false);
                        }
                    }
                }
                setNbAlerte(newNbAlerte);
            } else {
                setNbAlerte(0);
                setShowAlerte(false);
            }
        } else {
            setNbAlerte(0);
            setShowAlerte(false);
        }
    }, [AlertesQuery.data, AlertesQuery.isFetched, nbAlerte, reload]);

    return (
        <div className={`timed_side_bar ${props.className}`}>
            <div className={`logo-place`}>
                <img src={logoLong} alt={"Timed application"}/>
            </div>

            <div className={`content_menu`}>
                {showAlert &&
                    <DirectLinkAlerte isHight={!isSaw}/>
                }
                {(SectionsQuery.isLoading || CentreQuery.isLoading) ?  <div className={"wrapper_spinner"}><TimedSpinner/></div> : listSections.length === 0 ? <p>No Sections</p> :
                    listSections.map(item=>(
                        <OneSection Section={item} key={`section_timed_${item.id}`}/>
                    ))
                }
            </div>
            <div className={"num_version"}>{CentreQuery.data?.name} {version_timed}</div>
        </div>
    )
}

const TimedSideBarStyled = styled(TimedSideBarCtrl)`
  height: 100%;
  background: ${props=>props.theme.PrimaryDark};
  width: 230px;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
  padding: 1rem 0;
  .num_version{
    font-size: 13px;
    text-align: center;
    margin-top: 14px;
  }
  .content_menu{
    flex-grow: 1;
    overflow: auto;
    font-size: 14px;
  }
  .logo-place{
    padding: 0 0.5rem;
    text-align: center;
    margin-bottom: 20px;
    img{
      width: 70%;
      height: auto;
    }
  }
`

const TimedSideBar = memo(TimedSideBarStyled)

export default TimedSideBar