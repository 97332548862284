import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getAllVues(idCentre:number|null):Promise<VuePlanning[]>{
    let url = `vue_plannings`;
    if(idCentre){
        url = `vue_plannings?Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url, {method:'get'})
    return res["hydra:member"];
}


export function PostNewLieuVuePlanning(datas:PostLieuVuePlanningProps):Promise<LieuVuePlanning>{
    return dataFetcher(`lieu_vue_plannings`, {
        method:'post',
        body:datas
    })
}

export function TrashLieuVuePlanning(id:number):Promise<any>{
    return dataFetcher(`lieu_vue_plannings/${id}`, {
        method:'delete',
    })
}

export async function GetAllLieuVPByVue(id:number):Promise<LieuVuePlanning[]>{
    const res:any = await dataFetcher(`lieu_vue_plannings?VuePlanning=${id}`)
    return res["hydra:member"]
}

export const PostNewVuePlanning = (datas:PostVuePlanning):Promise<VuePlanning>=>{
    return dataFetcher(`vue_plannings`,{
        method:"post",
        body:datas
    })
}