import React, {lazy, memo, Suspense, useEffect, useState} from "react";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import styled from "styled-components";
import MainLayout from "../../components/organisms/layouts/MainLayout";

import {useGetUser} from "../../features/user/user.hooks";
import TimedSuspense from "../../components/atoms/TimedWaitUser/TimedSuspense";
import {ModalOptIn} from "../Modaux/ModalOptIn";
import {ErrorBoundary} from "../../services/ErrorBoundary";
import {useAddNavigation} from "../../features/monitoringNavigation/monitoringNavigation.hooks";
import {queryClient} from "../../main/App";
import {getISOWeekNumber} from "../../components/functions/TimesFunctions";
import {getPlageWeek} from "../../features/plage/plage.services";
import {version_timed} from "../../index";
import GestionDocumentRooter from "./AdministrationRH/GestionDocuments/GestionDocumentRooter";
import {useGetAllLocationAuth} from "../../features/locationAuth/locationAuth.hooks";
import {NoSuscribe1} from "../../components/organisms/TimedNoSuscribe/NoSuscribe1";
import {ProjectionST} from "./Plannings/ProjectionST/ProjectionST";
import {HistoriqueLive} from "./Pointages/Historique/HistoriqueLive";
import LieuxVues from "./SettingNew/04_LieuxVues/LieuxVues";
import ParamsActes from "./SettingNew/05_Actes/ParamsActes";
import ParamsRubrique from "./SettingNew/03_Rubrique/ParamsRubrique";
import ParamsRempla from "./SettingNew/06_Remplacement/ParamsRempla";
import ParamsNotifications from "./SettingNew/07_Notifications/ParamsNotifications";
import ParamsPeriodesCptrs from "./SettingNew/02_PeriodesCptrs/ParamsPeriodesCptrs";
import ParamsApps from "./SettingNew/08_Apps/ParamsApps";
import ParamsDroits from "./SettingNew/09_Droits/ParamsDroits";
import ParamsFonction from "./SettingNew/10_Fonctions/ParamsFonction";
import TempsTravDispatch from "./TempsActivites/TempsTravaille3/TempsTravDispatch";
import PlanningIndiHebdo25 from "./Plannings/PlanningIndiHebdo/PlanningIndiHebdo25";


interface ApplicationControllerCtrlProps{
    className?:string;

}
const Parrainage  = lazy(()=>import("./SettingNew/11_Parrainage/Parrainage"));
const DemandeModulatoireRooter  = lazy(()=>import("./TempsActivites/DemandeModulatoires/DemandeModulatoireRooter"));
const ParamProfil  = lazy(()=>import("./SettingNew/01_ParamsProfil/ParamProfil"));
const EvtGroup  = lazy(()=>import("./AdministrationRH/EventGroup/EvtGroup"));
const PlanningBesoinP1  = lazy(()=>import("./Plannings/PlanningBesoins/PlanningBesoinP1"));
const GenerationPL  = lazy(()=>import("./Plannings/GenerationPlanning2/GenerationPL"));
const PlanningIndiMensuel2  = lazy(()=>import("./Plannings/PlanningIndiMensuelV2/PlanningIndiMensuel2"));
const NotFound  = lazy(()=>import("../NotFound/NotFound"));
const TempsMedecin2  = lazy(()=>import("./TempsActivites/TempsMedecin/TempsMedecin2"));
const LivePointageLoader  = lazy(()=>import("./Pointages/LivePointageLoader"));
const AbsencesMedecins  = lazy(()=>import("./Remplacements/AbsencesMedecins/AbsencesMedecins"));
const SemaineTypeCentreRooter  = lazy(()=>import("./Plannings/SemaineTypeCentre/SemaineTypeCentreRooter"));
const DemandesModulationRooter  = lazy(()=>import("./TempsActivites/DemandesModulation/DemandesModulationRooter"));
const CompteurEtDroitsRooter  = lazy(()=>import("./CongesAbsences/CompteursEtDroitsAbs/CompteurEtDroitsRooter"));
const TempsTravailleRooter  = lazy(()=>import("./TempsActivites/TempsTravaille2/TempsTravailleRooter"));
const CreneauxRoot  = lazy(()=>import("./Remplacements/Creneaux/CreneauxRoot"));
const FichePersonnelv2 = lazy(()=>import("./AdministrationRH/FichePersonnelv2"));
const PlanningGlobalPersonne2 = lazy(()=>import("./Plannings/PlanningGlobalPersonne/PlanningGlobalPersonne2"));
const TestPage = lazy(()=>import("./Plannings/PlanningIndiHebdo/TestPage"));
const GestionHeuresSuppFull = lazy(()=>import("./TempsActivites/GestionHeuresSupp/GestionHeuresSuppFull"));
const Dashboard2 = lazy(()=>import("./MainStream/Dashboard2"));
const GestionHeureSuppMain = lazy(()=>import("./TempsActivites/GestionHeuresSupp2/GestionHeureSuppMain"));
const PrepaPaie = lazy(()=>import("./AdministrationRH/EtatPreparatoirePaie2/PrepaPaieRooter"));
const PlEquipeBeta = lazy(()=>import("./Plannings/PlanningGlobalEquipeBeta/PlEquipeBeta"));
const AnomaliesPointage = lazy(()=>import("./Pointages/GestionPointages/AnomaliesPointage"));
const MessagerieMain = lazy(()=>import("./Messagerie2/MessagerieMain"));
const FreePointage = lazy(()=>import("./Pointages/FreePointage/FreePointage"));
const TimedAlertes = lazy(()=>import("./MainStream/TimedAlertes"));
const GestionToDoList = lazy(()=>import("./AdministrationRH/ToDoList/GestionToDoList"));
const TempsForfait = lazy(()=>import("./TempsActivites/TempsForfait/TempsForfait"));
const CloturePeriode = lazy(()=>import("./TempsActivites/CloturePeriode/CloturePeriode"))
const PlanningGlobalPersonnes = lazy(()=>import("./Plannings/PlanningGlobalPersonnes/PlanningGlobalPersonnes"))
const PlanningMedecin = lazy(()=>import("./PlanningMedecin/PlanningMedecin"))
const PlanningGlobalEquipe = lazy(()=>import("./Plannings/PlanningGlobalEquipe/PlanningGlobalEquipe"))
const MouvementPersonnel = lazy(()=>import("./AdministrationRH/MouvementPersonnel/MouvementPersonnel"))
const PlanningDashboard = lazy(()=>import("./Plannings/PlanningDashboard/PlanningDashboard"))
const ListRemplacant = lazy(()=>import("./Remplacements/ListeRemplacant/ListRemplacant"))
const DashboardPointage = lazy(()=>import("./Pointages/Dashboard/DashboardPointage"));
const HistoriquePointage = lazy(()=>import("./Pointages/Historique/HistoriquePointage"));
const DashboardRH = lazy(()=>import("./AdministrationRH/DashboardRH/DashboardRH"));
const Dashboard = lazy(()=>import("./MainStream/Dashboard"));
const TicketRestaurant = lazy(()=>import("./AdministrationRH/TicketRestaurant"));
const EtatPreparatoirePaie = lazy(()=>import("./AdministrationRH/EtatPreparatoirePaie/EtatPreparatoirePaie"));
const Trombinoscope = lazy(()=>import("./AdministrationRH/Trombinoscore/Trombinoscope"));
const GenerationPlanning = lazy(()=>import("./Plannings/GenerationPlanning/GenerationPlanning"));
const SuppressionPlanning = lazy(()=>import("./Plannings/SuppressionPlanning/SuppressionPlanning"));
const ModificationPlanning = lazy(()=>import("./Plannings/ModificationPlanning/ModificationPlanning"));
const SemaineType = lazy(()=>import("./Plannings/SemaineType/SemaineType"));
const PlanningGlobalLieu = lazy(()=>import("./Plannings/PlanningGlobalLieu/PlanningGlobalLieu"));
const EtatVisuelAbsence = lazy(()=>import("./Plannings/EtatVisuelAbsence/EtatVisuelAbsence"));
const DashboardTps = lazy(()=>import("./TempsActivites/DashboardTps/DashboardTps"));
const EquivalentTempsPlein = lazy(()=>import("./TempsActivites/EquivalentTempsPlein/EquivalentTempsPlein"));
const TableauCroiseDemiJours = lazy(()=>import("./TempsActivites/TableauCroiseDemiJours/TableauCroiseDemiJours"));
const RechercheEvenements = lazy(()=>import("./TempsActivites/RechercheEvenements/RechercheEvenements"));
const StatsAbsences = lazy(()=>import("./TempsActivites/StatsAbsences/StatsAbsences"));
const GestionPointages = lazy(()=>import("./Pointages/GestionPointages/GestionPointages"));
const DashboardAbs = lazy(()=>import("./CongesAbsences/DashboardAbs/DashboardAbs"));
const DemandesAbs = lazy(()=>import("./CongesAbsences/DemandesAbs/DemandesAbs"));
const Messagerie = lazy(()=>import("./Messagerie/MessagerieIn/Messagerie"));
const News = lazy(()=>import("./VieCentre/News/News"));
const Support = lazy(()=>import("./VieCentre/Support/Support"));
const Documents = lazy(()=>import("./VieCentre/Documents/Documents"));
const DashboardSetting = lazy(()=>import("./Settings/DashboardSetting/DashboardSetting"));
const ProfilCentre = lazy(()=>import("./Settings/ProfilCentre/ProfilCentre"));
const SettingGeneral = lazy(()=>import("./Settings/SettingGeneral/SettingGeneral"));
const SettingFonctions = lazy(()=>import("./Settings/SettingFonctions/SettingFonctions"));
const SettingActes = lazy(()=>import("./Settings/SettingActes/SettingActes"));
const SettingLieux = lazy(()=>import("./Settings/SettingLieux/SettingLieux"));
const SettingVuePlanning = lazy(()=>import("./Settings/SettingVuePlanning/SettingVuePlanning"));
const TauxAffectation = lazy(()=>import("./Plannings/TauxAffectation/TauxAffectation"));
const DashboardCreneaux = lazy(()=>import("./Remplacements/DashCreneaux/DashboardCreneaux"));
const CoherencePlanningCurve = lazy(()=>import("./Plannings/CoherencePlanning/CoherencePlanningCurve"));
const Exports = lazy(()=>import("./TempsActivites/Exports/Exports"));
const EventGroup = lazy(()=>import("./Plannings/EventGroup/EventGroup"));

const ApplicationControllerCtrl = ({className}:ApplicationControllerCtrlProps)=>{
    const LocationsAuthQuery = useGetAllLocationAuth();
    const UserQuery = useGetUser();
    const mutationNavigation = useAddNavigation();
    const location = useLocation();
    const [isAuth, setIsAuth] = useState<boolean>(true)
    const [ModuleToSubscribe, setModuleToSubscribe] = useState<string>('')
    useEffect(() => {
        const Tab = location.pathname.split('/');
        const myRM = (Tab && Tab[2]) ? Tab[2] : 'root';
        mutationNavigation.mutate({roadName:myRM, version:version_timed})
    }, [location.pathname]);
    useEffect(() => {
        const Tab = location.pathname.split('/');
        const myRM = (Tab && Tab[2]) ? Tab[2] : 'root';
        if(LocationsAuthQuery.data){
            const myAuth = LocationsAuthQuery.data.find(l=>l.url === myRM)

            if(myAuth){
                setModuleToSubscribe(myAuth.module);
                setIsAuth(myAuth.auth)
            }

        }
    }, [LocationsAuthQuery.data, location]);
    useEffect(() => {
        const numWeek = "S"+getISOWeekNumber(new Date());
        queryClient.prefetchQuery(["plages_week", {numSem:numWeek, idCentre:null, filters:false, filterV:true}], ()=>getPlageWeek(new Date().toISOString().slice(0,10), null, false, true))
            .then(()=>console.log('prefecth ok '))
    }, []);
    return (
        <div className={`AppController ${className}`}>
            <Suspense fallback={<TimedSuspense/>}>
                <ErrorBoundary>
                    <Routes>
                        <Route element={
                            <MainLayout/>
                        }>
                            {!isAuth ?
                                <Route path={"*"} element={<NoSuscribe1 libModule={ModuleToSubscribe}/>} />:
                                <>
                                    <Route path="" element={<Navigate to="dashboard" />} />
                                    <Route path="/projection_semaine_types" element={<ProjectionST/>} />
                                    <Route path="/historique_live" element={<HistoriqueLive/>} />
                                    <Route path={"/timed_alertes"} element={<TimedAlertes/>}/>
                                    <Route path={"/evenements_groupe"} element={<EvtGroup/>}/>
                                    <Route path="/planning_indi_hebdo_beta" element={<TestPage/>}/>
                                    <Route path="/mouvement_personnel" element={<MouvementPersonnel/>}/>
                                    <Route path={"/live_pointage"} element={<LivePointageLoader />}/>
                                    <Route path="/ged" element={<GestionDocumentRooter/>}/>
                                    <Route path="/planning_global_personne_beta" element={<PlanningGlobalPersonne2/>}/>
                                    <Route path="/dashboard" element={<Dashboard2/>}/>
                                    <Route path="/dashboard2" element={<Dashboard/>}/>
                                    <Route path="/messagerie2/:id" element={<MessagerieMain/>}/>
                                    <Route path="/messagerie2" element={<MessagerieMain/>}/>
                                    <Route path={`/rh_dashboard`} element={<DashboardRH/>}/>
                                    <Route path="/fiche_personnel" element={<FichePersonnelv2/>}/>
                                    {/*<Route path="/fiche_personnel2" element={<FichePersonnel/>}/>*/}
                                    <Route path="/tickets_restaurant" element={<TicketRestaurant/>}/>
                                    <Route path="/etat_preparatoire_paie" element={<EtatPreparatoirePaie/>}/>
                                    <Route path="/prepa_paie/*" element={<PrepaPaie/>}/>
                                    <Route path="/trombinoscope" element={<Trombinoscope/>}/>
                                    <Route path="/generation_planning" element={<GenerationPlanning/>}/>
                                    <Route path="/generation_planning2" element={<GenerationPL/>}/>
                                    <Route path="/group_event" element={<EventGroup/>}/>
                                    <Route path="/suppression_planning" element={<SuppressionPlanning/>}/>
                                    <Route path="/modification_planning" element={<ModificationPlanning/>}/>
                                    <Route path="/planning_indi_hebdo" element={<TestPage/>}/>
                                    <Route path="/planning_indi_mensuel" element={<PlanningIndiMensuel2/>}/>
                                    <Route path="/planning_indi_mensuel2" element={<PlanningIndiMensuel2/>}/>
                                    {/*<Route path="/semaine_type" element={<SemaineType/>}/>*/}
                                    <Route path="/semaine_type" element={<SemaineType isNew={true}/>}/>
                                    <Route path="/semaine_type_centre/*" element={<SemaineTypeCentreRooter/>}/>
                                    <Route path="/planning_dashboard" element={<PlanningDashboard/>}/>
                                    <Route path="/planning_global_lieu" element={<PlanningGlobalLieu/>}/>
                                    <Route path="/planning_global_equipe" element={<PlanningGlobalEquipe/>}/>
                                    <Route path="/planning_global_equipe_beta" element={<PlEquipeBeta/>}/>
                                    {/*<Route path="/planning_global_personne" element={<PlanningGlobalPersonne2/>}/>*/}
                                    <Route path="/planning_global_personne" element={<PlanningGlobalPersonnes/>}/>
                                    <Route path="/planning_medecin" element={<PlanningMedecin/>}/>
                                    <Route path="/planning_besoin" element={<PlanningBesoinP1/>}/>

                                    <Route path="/etat_visuel_absence" element={<EtatVisuelAbsence/>}/>
                                    <Route path="/etat_visuel_absence2" element={<EtatVisuelAbsence/>}/>
                                    <Route path="/taux_affectation" element={<TauxAffectation/>}/>
                                    <Route path="/taux_affectation2" element={<TauxAffectation/>}/>
                                    <Route path="/coherence_planning" element={<CoherencePlanningCurve/>}/>
                                    <Route path="/tps_dashboard" element={<DashboardTps/>}/>
                                    <Route path="/temps_medecin" element={<TempsMedecin2/>}/>
                                    <Route path="/temps_travaille/*" element={<TempsTravailleRooter/>}/>
                                    <Route path="/temps_forfait" element={<TempsForfait/>}/>
                                    <Route path="/demandes_modulation/*" element={<DemandeModulatoireRooter/>}/>
                                    <Route path="/demandes_modulation_old/*" element={<DemandesModulationRooter/>}/>
                                    <Route path="/heures_supp" element={<GestionHeuresSuppFull/>}/>
                                    <Route path="/heures_supp2" element={<GestionHeureSuppMain/>}/>
                                    <Route path="/exports" element={<Exports/>}/>
                                    <Route path="/equivalent_temps_plein" element={<EquivalentTempsPlein/>}/>
                                    <Route path="/stats_activites" element={<TableauCroiseDemiJours/>}/>
                                    <Route path="/recherche_evenements" element={<RechercheEvenements/>}/>
                                    <Route path="/stats_abs" element={<StatsAbsences/>}/>
                                    <Route path="/stats_absence" element={<StatsAbsences/>}/>
                                    <Route path="/dashboard_pointages" element={<DashboardPointage/>}/>
                                    <Route path="/historique_pointages" element={<HistoriquePointage/>}/>
                                    <Route path="/gestion_pointages" element={<GestionPointages/>}/>
                                    <Route path="/anomalies_pointage" element={<AnomaliesPointage/>}/>
                                    <Route path="/free_pointage" element={<FreePointage/>}/>
                                    <Route path="/creneaux/*" element={<CreneauxRoot/>}/>
                                    <Route path="/creneaux2/*" element={<CreneauxRoot/>}/>
                                    <Route path="/creneaux_dashboard" element={<DashboardCreneaux/>}/>
                                    <Route path="/absences_med" element={<AbsencesMedecins/>}/>
                                    <Route path="/list_remplacants" element={<ListRemplacant/>}/>
                                    <Route path="/abs_dashboard" element={<DashboardAbs/>}/>
                                    <Route path="/demandes_abs" element={<DemandesAbs/>}/>
                                    <Route path="/compteurs_droits_abs/*" element={<CompteurEtDroitsRooter/>}/>
                                    <Route path="/messagerie" element={<Messagerie/>}/>
                                    <Route path="/news" element={<News/>}/>
                                    <Route path="/support" element={<Support/>}/>
                                    <Route path="/autres_document" element={<Documents/>}/>
                                    <Route path="/setting_dashboard" element={<DashboardSetting/>}/>
                                    <Route path="/profil_centre" element={<ProfilCentre/>}/>
                                    <Route path="/setting_general" element={<SettingGeneral/>}/>
                                    <Route path="/setting_fonctions" element={<SettingFonctions/>}/>
                                    <Route path="/setting_actes" element={<SettingActes/>}/>
                                    <Route path="/setting_lieux" element={<SettingLieux/>}/>
                                    <Route path="/setting_vue_planning" element={<SettingVuePlanning/>}/>
                                    <Route path="/cloture_periode" element={<CloturePeriode/>}/>
                                    <Route path={"/gestion_list"} element={<GestionToDoList/>}/>
                                    <Route path={"/params_profil"} element={<ParamProfil/>}/>
                                    <Route path={"/params_lieu"} element={<LieuxVues/>}/>
                                    <Route path={"/params_acte"} element={<ParamsActes/>}/>
                                    <Route path={"/params_rubrique"} element={<ParamsRubrique/>}/>
                                    <Route path={"/params_remplacement"} element={<ParamsRempla/>}/>
                                    <Route path={"/params_notifications"} element={<ParamsNotifications/>}/>
                                    <Route path={"/params_periode"} element={<ParamsPeriodesCptrs/>}/>
                                    <Route path={"/params_apps"} element={<ParamsApps/>}/>
                                    <Route path={"/params_droits"} element={<ParamsDroits/>}/>
                                    <Route path={"/params_fonction"} element={<ParamsFonction/>}/>
                                    <Route path={"/parrainages"} element={<Parrainage/>}/>
                                    <Route path={"/temps_trav"} element={<TempsTravDispatch/>}/>
                                    <Route path={"/indi_25"} element={<PlanningIndiHebdo25/>}/>
                                    {/*<Route path={"no_sub"} element={<NoSuscribe1 libModule={"Test"}/>}/>*/}
                                    <Route path="*" element={<NotFound />} />
                                </>
                            }
                        </Route>
                    </Routes>
                </ErrorBoundary>
            </Suspense>
            {UserQuery.data && UserQuery.data.optIn === null &&
                <ModalOptIn user={UserQuery.data}/>
            }
        </div>
    )
}

const ApplicationControllerStyled = styled(ApplicationControllerCtrl)``
const ApplicationController = memo(ApplicationControllerStyled)

export default ApplicationController;