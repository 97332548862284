export interface ContraintX{
    key:string;
    type:"string"|"number"|"Choice"|"Date",
    required:boolean,
    minNumber?:number;
    maxNumber?:number;
    minDate?:Date;
    maxDate?:Date;
    regex?:RegExp;
    MessageNoRegex?:string;
}
interface RetourVerif{
    isValid:boolean;
    Message:string;
}

const ValideField = (value:string|Choice|null, Constr:ContraintX)=>{
    const MyRetour:RetourVerif = {isValid:true, Message:""}
    if(Constr.type === "string"){
        const myVal = value as string;
        if(Constr.required && (myVal === "" || !value)){
            MyRetour.isValid = false;
            MyRetour.Message = "Champ Obligatoire";
        } else if(Constr.regex && !myVal.match(Constr.regex)){
            MyRetour.isValid = false;
            MyRetour.Message = Constr.MessageNoRegex||"Non conforme";
        }
    } else if(Constr.type === "number"){
        const myVal = parseFloat(value as string)
        if(Constr.required && (value === "" || !value)){
            MyRetour.isValid = false;
            MyRetour.Message = "Champ Obligatoire"
        } else if(Constr.minNumber && Constr.maxNumber && (myVal <Constr.minNumber || myVal>Constr.maxNumber)){
            MyRetour.isValid = false;
            MyRetour.Message = `Doit être compris entre ${Constr.minNumber} et ${Constr.maxNumber}`
        } else if(Constr.minNumber && myVal<Constr.minNumber){
            MyRetour.isValid = false;
            MyRetour.Message = `Doit être supérieur à ${Constr.minNumber}`
        } else if(Constr.maxNumber && myVal>Constr.maxNumber){
            MyRetour.isValid = false;
            MyRetour.Message = `Doit être inférieur à ${Constr.maxNumber}`
        }
    } else if(Constr.type === "Choice" && !value){
        MyRetour.isValid = false;
        MyRetour.Message = "Choix Obligatoire";
    }
    return MyRetour;
}

export function DataSetter<TypeObject, TypeError>(key:keyof TypeObject, value:string|Choice|null, myObject:TypeObject, setObject:(o:TypeObject)=>void, Constraints:ContraintX[], Errors:TypeError, setErrors:(o:TypeError)=>void){
    const MyContraintX = Constraints.find(c=>c.key === key)
    setObject({...myObject, [key]:value})
    let MyRetour:RetourVerif = {isValid:true, Message:""}
    if(MyContraintX){
        MyRetour = ValideField(value, MyContraintX)
    }
    setErrors({...Errors, [key]: !MyRetour.isValid ? MyRetour.Message : null})
    return MyRetour;
}

export function ValideObjet<TypeObject, TypeError>(myObject:TypeObject, Errors:TypeError, setErrors:(o:TypeError)=>void, Constraints:ContraintX[]){
    let nbError=0;
    Constraints.forEach(c=>{
        const myValue = myObject[c.key as keyof TypeObject] as string|Choice|null
        const MyRetour = ValideField(myValue, c);
        setErrors({...Errors, [c.key]: !MyRetour.isValid ? MyRetour.Message : null})
        if(!MyRetour.isValid) nbError++;
    })
    return nbError === 0;
}
