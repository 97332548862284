import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {useGetDetailsCredit} from "../calculator/CalculatorCoef";
import {useAddReguleTemps} from "../../../../../../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import CrediterCompteurTemps from "./CrediterCompteurTemps";

interface CrediterCompteurTempsHybCtrlProps{
    className?:string;
    CptHS:CompteurHS;
    time:number;
    dateTo:string;
    user:User;
    closeMe:()=>void;

}

const CrediterCompteurTempsHybCtrl = (props:CrediterCompteurTempsHybCtrlProps)=>{
    const mutation = useAddReguleTemps();
    const [myTime, setMyTime] = useState<string>(Math.round(props.time*100)/100+"")
    const {timeToCredit, coefApplicable} = useGetDetailsCredit(props.user, new Date(props.dateTo),parseFloat(myTime), props.time)
    const refInput = useRef<HTMLInputElement>(null)
    const [error, setError] = useState<boolean>(false)
    useEffect(() => {
        console.log(parseFloat(myTime)>Math.round(props.time*100)/100)
        console.log(myTime, props.time)
        setError(parseFloat(myTime)<=0 || parseFloat(myTime)>Math.round(props.time*100)/100)
    }, [myTime]);
    const handleChange = ()=>{
        if(refInput.current){
            const val = refInput.current.value.replace(',', '.')
            if (/^\d*\.?\d*$/.test(val)) {
                setMyTime(val)
            }
        }
    }
    const Validation = (nb:number)=>{
        const Credit = parseFloat(myTime);
        const majo = nb - Credit;
        const idMotifCred = 14;
        const idMotifMajo = 17;
        const datas:ReguleTpsFD={
            User:`/api/users/${props.user.id}`,
            dateAT:props.dateTo,
            dateTo:props.dateTo,
            MotifReguleTemps:`/api/motif_regule_temps/${idMotifCred}`,
            timeFinal:Credit,
            timeSystem:Credit,
            comentFinalHour:"Credit initial"
        }
        const datasMajo:ReguleTpsFD = {...datas, MotifReguleTemps:`/api/motif_regule_temps/${idMotifMajo}`, timeFinal:majo, timeSystem:majo, comentFinalHour:"Majoration HS"}
        const TabMutation = [datas, datasMajo];
        TabMutation.forEach(i=>{
            mutation.mutate(i, {
                onSuccess:()=>{
                    props.closeMe();
                }
            })
        })
    }
    return (
        <div className={`crediterCompteurTempsHyb ${props.className}`}>
            <div className="wrap_input_time">
                <label className={"opeHyb"}>Nombre d'heures que vous souhaitez créditer
                    (max {Math.abs(Math.round(props.time * 100) / 100)})</label>
                <div className="wrap_input">
                    <input className={"opeHyb"} ref={refInput} value={myTime} type={"text"} onChange={handleChange} disabled={mutation.isLoading}/>
                </div>
            </div>
            {error &&
                <div className="error">Vous devez saisir un nombre entre 0 et {Math.abs(Math.round(props.time * 100) / 100)}</div>
            }
            {(coefApplicable && !error) &&
                <CrediterCompteurTemps timeTo={timeToCredit} ValideTime={Validation} time={props.time} isPending={mutation.isLoading} DCoef={coefApplicable}/>
            }
        </div>
    )
}

const CrediterCompteurTempsHyb = styled(CrediterCompteurTempsHybCtrl)`
    .wrap_input_time{
        margin-bottom: 30px;
    }
    .error{
        text-align: center;
        padding: 0.5rem;
        color: red;
    }
    .wrap_input{
        display: flex;
        justify-content: center;
    }
    label{
        display: block;
        font-weight: bold;
        margin-bottom: 8px;
        text-align: center;
    }
    input{
        border-radius: 4px;
        text-align: center;
        background:${props=>props.theme.colorBackInput};
        border: none;
        height: 38px;
        width: 250px;

    }
`

export default CrediterCompteurTempsHyb