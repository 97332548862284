import styled from "styled-components";
import {useGetAllTypeEvent} from "../../features/typeEvent/typeEvent.hooks";
import {useGetAllTypeDocument} from "../../features/typeDocument/typeDocument.hooks";
import {useAddEvt} from "../../features/user/user.hooks";
import {useEffect, useMemo, useState} from "react";
import {TimedAutoCompleteInter} from "../../components/atoms/TimedFormInteractif/TimedAutoCompleteInter";
import {TimedInputInter} from "../../components/atoms/TimedFormInteractif/TimedInputInter";
import {VerifErrorString} from "../../components/atoms/TimedFormInteractif/Verifs";
import {LabelInter} from "../../components/atoms/TimedFormInteractif/styleInters";
import TimedSwitch from "../../components/atoms/TimedSwitch/TimedSwitch";
import {useGetImageDocument} from "../../services/hooks";
import {TimedDragDropFile} from "../../components/atoms/TimedDragDropFile/TimedDragDropFile";
import TimedIconButton from "../../components/atoms/TimedIconButton/TimedIconButton";
import {BsTrash} from "react-icons/bs";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import {TimedTextAreaInter} from "../../components/atoms/TimedFormInteractif/TimedTextAreaInter";

interface FormNewEvtCtrlProps {
    className?:string;
    user:User;
    closeMe:()=>void;
}
interface StereoEvt{
    eventAt:string,
    File:File|null,
    nameFile:string;
    TypeEvt:Choice|null,
    TypeDoc:Choice|null
    isDiffusable:boolean,
    isPaie:boolean,
    details:string,
    montant:number,
}

const DefaultEvt:StereoEvt ={
    eventAt:'',
    File:null,
    nameFile:'',
    TypeEvt:null,
    TypeDoc:null,
    isDiffusable:true,
    isPaie:false,
    details:'',
    montant:0,
}

interface MyErrors{
    eventAt:string|null,
    TypeEvt:string|null,
    TypeDoc:string|null,
    montant:string|null,
}

const DefaultErrors:MyErrors ={
    eventAt:null,
    TypeEvt:null,
    TypeDoc:null,
    montant:null
}

const FormNewEvtCtrl = ({className, user, closeMe}: FormNewEvtCtrlProps) => {
    const [errors, setErrors] = useState<string|null>(null);
    const [myErrors, setMyErrors] = useState<MyErrors>(DefaultErrors)
    const TypeEventsQuery = useGetAllTypeEvent();
    const TypeDocQuery = useGetAllTypeDocument();
    const mutation = useAddEvt();
    const [MyEvt, setMyEvt] = useState<StereoEvt>(DefaultEvt)
    const [extFile, setExtFile] = useState('');
    const {Image} = useGetImageDocument(extFile === '' ? null : extFile);
    const [needMontant, setNeedMontant] = useState(false);
    useEffect(() => {

        if(MyEvt.TypeEvt && TypeEventsQuery.data){
            const my = TypeEventsQuery.data.find(te=>te.id === MyEvt.TypeEvt?.id)

            if(my) setNeedMontant(my.isMontant)
        }
    }, [MyEvt.TypeEvt, TypeEventsQuery.data]);
    const TabNeed:MyConstraint[] = [
        {id:'eventAt', required:true},
        {id:'TypeEvt', required:true},
        {id:'TypeDoc', required:true},
    ];
    useEffect(()=>{
        if(MyEvt.File){
            const filename = MyEvt.File.name;
            const Tab = filename.split('.');
            const guessExt = Tab[Tab.length-1];
            setMyEvt(ev=>{return {...ev, nameFile:Tab[0]}})
            setExtFile(guessExt);
        } else {
            setExtFile('')
            setMyEvt(ev=>{return {...ev, nameFile:''}})
        }
    }, [MyEvt.File])
    const Submit = ()=>{
        const TypeEvent = TypeEventsQuery.data?.find(x=>x.libelle === MyEvt.TypeEvt?.libelle);
        const eventAt = MyEvt.eventAt;
        const details = MyEvt.details;
        let datasTo:UserEvtAddFormData = {
            type: TypeEvent ? TypeEvent.id : 0,
            id:user.id,
            eventAt:eventAt,
            details:details,
            isPaie:MyEvt.isPaie
        }
        if(MyEvt.File){
            const TypeDocument = TypeDocQuery.data?.find(td=>td.libelle === MyEvt.TypeDoc?.libelle);
            const TypeDocumentId = TypeDocument ? TypeDocument.id : 0;
            const fileTo = MyEvt.File;
            datasTo['TypeDocument'] = TypeDocumentId;
            datasTo['file'] = fileTo;
            datasTo['name'] = MyEvt.nameFile;
            datasTo['isDiffusable'] = MyEvt.isDiffusable;
        }
        if(TypeEvent && TypeEvent.isMontant){
            datasTo['montant'] = parseInt(MyEvt.montant+"")

        }
        mutation.mutate((datasTo), {
            onSuccess:()=>{
                closeMe();
            }, onError:()=>{
                setErrors('Une erreur est survenue, merci de retenter plus tard')
            }
        })
    }
    const listChoiceTypeEvents = useMemo(()=>{
        if(!TypeEventsQuery.data){
            return []
        } else {
            return TypeEventsQuery.data.map(te=>{
                return {id:te.id, libelle:te.libelle}
            })
        }
    }, [TypeEventsQuery.data])
    const listChoiceTypeDoc = useMemo(()=>{
        if(!TypeDocQuery.data){
            return []
        } else {
            return TypeDocQuery.data.map(te=>{
                return {id:te.id, libelle:te.libelle}
            })
        }
    }, [TypeDocQuery.data])
    const setSelect = (elt:Choice|string|number, id:string)=>{
        if(id === 'TypeEvt' || id === "TypeDoc"){
            const myVal = elt as Choice;
            const libelle = myVal.libelle
            const ErrorRetour = VerifErrorString(id, libelle, TabNeed)
            setMyErrors(e=>{
                return {...e, [id]:ErrorRetour==='' ? null : ErrorRetour}
            })
            setMyEvt(p=> {
                return {...p, [id]: elt as Choice}
            });
            if(ErrorRetour ===''){
                setMyErrors(e=>{
                    return {...e, [id]:ErrorRetour}
                })
            }
        } else if(id==="montant"){
            console.log("set the montant")
            const myVal = elt as number;
            setMyErrors(e=>{
                return {...e, montant:null}
            })
            if(myVal<0){
                setMyErrors(e=>{
                    return {...e, montant:"le montant doit être positif"}
                })
            }
            setMyEvt(p=> {
                return {...p, montant: elt as number}
            });
        } else {
            const myVal = elt as string;
            const ErrorRetour = VerifErrorString(id, myVal, TabNeed)
            setMyErrors(e=>{
                return {...e, [id]:ErrorRetour==='' ? null : ErrorRetour}
            })
            setMyEvt(p=> {
                return {...p, [id]: elt as string}
            });
            if(ErrorRetour ===''){
                setMyErrors(e=>{
                    return {...e, [id]:ErrorRetour}
                })
            }
        }
    }
    return (
        <div className={`form_newEvt ${className}`}>
            <div className="wrap_inputsForm">
                <TimedInputInter
                    label={"Date de l'évènement"}
                    id={"eventAt"}
                    setNew={setSelect}
                    type={"date"}
                    Current={MyEvt.eventAt}
                    Error={myErrors.eventAt}
                />
                <TimedAutoCompleteInter
                    id={"TypeEvt"}
                    Choices={[{id: -1, libelle: ''}, ...listChoiceTypeEvents]}
                    setChoice={setSelect}
                    CurrentChoice={MyEvt.TypeEvt ? {id: MyEvt.TypeEvt.id, libelle: MyEvt.TypeEvt.libelle} : {
                        id: -1,
                        libelle: ''
                    }} label={"Type évènement"}
                    Error={myErrors.TypeEvt}
                />
                {needMontant &&
                    <TimedInputInter
                        label={"Montant"}
                        type={"number"}
                        id={"montant"}
                        setNew={setSelect}
                        Current={MyEvt.montant}
                    />
                }
                <TimedTextAreaInter id={"details"} label={"Détails"} setNew={setSelect} Current={MyEvt.details} height={"100px"}/>
                <div className="input_wrap">
                    <LabelInter>Inclure dans la paie</LabelInter>
                    <TimedSwitch
                        setStatut={() => setMyEvt(ev => {
                            return {...ev, isPaie: !ev.isPaie}
                        })}
                        statut={MyEvt.isPaie}
                        themeColor={"Primary"}
                    />
                </div>
            </div>
            {!MyEvt.File ?
                <div className="wrap_d_n_d">
                    <TimedDragDropFile
                        accept={"image/png, image/jpeg, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document "}
                        full={true}
                        onUpload={(files) => {
                            setMyEvt(ev => {
                                return {...ev, File: files[0]}
                            });
                        }}
                        count={1}
                        formats={["png", "jpeg", "jpg", "pdf", "docx", "doc"]}
                    />
                </div>
                    :
                <div className="wrap_infos_doc">
                    <div className="doc_up">
                        <div className="infos_doc">
                            <div className="image_place">{Image}</div>
                            <div className="name_file">{MyEvt.File?.name}</div>
                        </div>
                        {!mutation.isLoading &&
                            <div className={`remove_file`}>
                                <TimedIconButton onClick={() => setMyEvt(ev => {
                                    return {...ev, File: null}
                                })} size={"large"} Icon={<BsTrash/>}
                                                 themeColor={"Warning"}/>
                            </div>
                        }
                    </div>
                    <TimedAutoCompleteInter
                        id={"TypeDoc"}
                        Choices={[{id: -1, libelle: ''}, ...listChoiceTypeDoc]}
                        setChoice={setSelect}
                        CurrentChoice={MyEvt.TypeDoc ? {id: MyEvt.TypeDoc.id, libelle: MyEvt.TypeDoc.libelle} : {
                            id: -1,
                            libelle: ''
                        }} label={"Type Document"}
                        Error={myErrors.TypeEvt}
                    />

                    {MyEvt.nameFile &&
                        <div className="input_wrap">
                            <TimedInputInter Current={MyEvt.nameFile} setNew={setSelect} id={"nameFile"}
                                             label={"Nom Personnalisé"}
                                             type={"text"}/>
                        </div>
                    }
                    <div className="input_wrap">
                        <LabelInter>Diffusable</LabelInter>
                        <TimedSwitch
                            setStatut={() => setMyEvt(ev => {
                                return {...ev, isDiffusable: !ev.isDiffusable}
                            })}
                            statut={MyEvt.isDiffusable}
                            themeColor={"Primary"}
                        />
                    </div>
                </div>

            }
            <div className="wrap_sender">
                {(MyEvt.TypeEvt && MyEvt.TypeEvt.libelle!=='' && MyEvt.eventAt!=='' && (!MyEvt.File || (MyEvt.nameFile!=='' && MyEvt.TypeDoc && MyEvt.TypeDoc.libelle !==''))) &&
                    <TimedButton size={"sm"} text={"Enregistrer"} themeColor={"Primary"} isPending={mutation.isLoading}
                                 onClick={Submit}/>
                }
            </div>
        </div>
    )
}

export const FormNewEvt = styled(FormNewEvtCtrl)`
    .wrap_inputsForm, .wrap_infos_doc {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .wrap_sender{
        display: flex;
        justify-content: flex-end;
    }
    .wrap_infos_doc{
        margin: 15px auto;
    }
    .wrap_d_n_d{
        margin: 15px auto;
    }
    .doc_up{
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        margin-bottom: 20px;
        .infos_doc{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
            .image_place{
                width: 45px;
                img{
                    width: 100%;
                }
            }
            .name_file{
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
`