import styled from "styled-components";

interface TempsTrTRCtrlProps{
    className?:string;
    user:User;
    calendrier:CalendrierPaie
}

const TempsTrTRCtrl = (props:TempsTrTRCtrlProps)=>{
    return (
        <div className={`tempsTrTr ${props.className}`}>TR</div>
    )
}

const TempsTrTR = styled(TempsTrTRCtrl)``

export default TempsTrTR