import styled from "styled-components";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import {useEffect, useState} from "react";
import {Contraint, DatasSetter} from "../../../../components/functions/ValideDataFunctions";
import {useModifyFonction, useTrashFonction} from "../../../../features/fonction/fonction.hooks";
import {useDebounce} from "../../../../services/hooks";
import InputTextSetting from "../components/InputTextSetting";
import InputColorPickerSetting from "../components/InputColorPickerSetting";
import InputSelectSetting from "../components/InputSelectSetting";
import ModifyLoader from "../components/ModifyLoader";
import {useGetAllUser} from "../../../../features/user/user.hooks";

interface FormEditFonctionCtrlProps{
    className?:string;
    Fx:Fonction;
    CancelSet:()=>void;
}

interface FonctionProps{
    libelle:string;
    Pointeur:Choice|null;
    TR:Choice|null;
    color:string;
    RuleEntreeAvance:Choice|null;
    RuleEntreeRetard:Choice|null;
    RuleSortieRetard:Choice|null;
    RuleSortieAvance:Choice|null;
    maxEntreeAvance:number;
    maxEntreeRetard:number;
    maxSortieAvance:number;
    maxSortieRetard:number;
    Pause:number;
    ShiftMode:Choice|null;
    Statut:Choice|null;
}

interface ErrorsProps{
    libelle:string|null;
    Pointeur:string|null;
    TR:string|null;
    color:string|null;
    maxEntreeAvance:string|null;
    maxEntreeRetard:string|null;
    maxSortieAvance:string|null;
    maxSortieRetard:string|null;
    Pause:string|null;
    ShiftMode:string|null;
    Statut:string|null;
}
const DefaultError:ErrorsProps ={
    libelle:null,
    Pointeur:null,
    TR:null,
    color:null,
    maxEntreeAvance:null,
    maxEntreeRetard:null,
    maxSortieAvance:null,
    maxSortieRetard:null,
    Pause:null,
    ShiftMode:null,
    Statut:null,
}

interface DetailsProps{
    Pointeur:InfosProps;
    TR:InfosProps;
    ShiftMode:InfosProps;
    Statut:InfosProps;
    Rule:InfosProps;
}
const TabInfos:DetailsProps = {
    Pointeur:{keyProps:"Pointeur", libelle:"Fonction - Pointeur", description:"Déterminez si la fonction est concernée ou non par le pointage"},
    Statut:{keyProps:"Statut", libelle:"Fonction - Statut de la fonction", description:"Si la fonction est inactive elle ne pourra plus être choisie dans le futur, elle restera cependant présent dans le passé"},
    TR:{keyProps:"TR", libelle:"Fonction - Tickets restaurant", description:"Déterminez si la fonction est concernée ou non par les Tickets restaurant"},
    ShiftMode:{keyProps:"ShiftMode", libelle:"Fonction - Mode pause", description:"Choisissez comment les pauses seront prises en compte"},
    Rule:{keyProps:"Rule", libelle:"Fonction - Rgèle de pointage", description:"Si vous n'appliquez pas de règle de pointage, les pointages appliquées seront les pointages réels"},
}


const FormEditFonctionCtrl = (props:FormEditFonctionCtrlProps)=>{
    const mutation = useModifyFonction();
    const mutationTrash = useTrashFonction();
    const UsersQuery = useGetAllUser();
    const [usersConcerned, setUsersConcerned] = useState<User[]>([])
    const CentreQuery = useGetCentreActuel();
    const [errors, setErrors] = useState<ErrorsProps>(DefaultError)
    const [isSavable, setIsSavable] = useState(false)
    const [isSavableEA, setIsSavableEA] = useState(false)
    const [isSavableER, setIsSavableER] = useState(false)
    const [isSavableSA, setIsSavableSA] = useState(false)
    const [isSavableSR, setIsSavableSR] = useState(false)
    const [isSavablePause, setIsSavablePause] = useState(false)

    const [isTr, setIsTr] =  useState(false);
    const [isPointeur, setIsPointeur] = useState(false);
    const [isPause, setIsPause] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true);
    const choicesStatut:Choice[] = [{id:0, libelle:"Inactif", description:"Rendre la fonction inactive pour le futur"},{id:1, libelle:"Actif", description:"La fonction est sélectionnable"}]
    const choicesPointage:Choice[] = [{id:0, libelle:"Non", description:"Cette fonction n'est pas concernée par le pointage"},{id:1, libelle:"Oui", description:"les collaborateurs de cette fonction devront pointer"}]
    const choicesTR:Choice[] = [{id:0, libelle:"Non", description:"Cette fonction n'est pas concernée par les TR"},{id:1, libelle:"Oui", description:"les collaborateurs bénéficieront des TR"}]
    const choicesRule:Choice[] = [{id:0, libelle:"Non", description:"Le pointage retenu sera le pointage réel"},{id:1, libelle:"Oui", description:"Des conditions de pointages sont applicables"}]
    const choicesShift:Choice[] = [
        {id:0, libelle:"Aucune", description:"Aucune pause"},
        {id:1, libelle:"Pause Méridienne", description:"La pause sera appliquée si elle se situe sur l'heure du midi et si le temps de travail dépasse les 5 heures"},
        {id:2, libelle:"Pause par Plages de travail", description:"Chaque séquence de travail entrainera une pause"},
        {id:3, libelle:"Pause automatique", description:"Si la journée est travaillée, une pause sera appliquée"},
    ]
    const [myFonction, setMyFonction] = useState<FonctionProps>({
        libelle:props.Fx.libelle,
        Pointeur:props.Fx.isPointeur ? choicesPointage[1] : choicesPointage[0],
        TR:props.Fx.isTr ? choicesTR[1] : choicesTR[0],
        color:props.Fx.color,
        maxEntreeAvance:(props.Fx.maxEntreeAvance === null || props.Fx.maxEntreeAvance === undefined) ? -1 : props.Fx.maxEntreeAvance,
        maxEntreeRetard:(props.Fx.maxEntreeRetard === null || props.Fx.maxEntreeRetard === undefined) ? -1 : props.Fx.maxEntreeRetard,
        maxSortieAvance:(props.Fx.maxSortieAvance === null || props.Fx.maxSortieAvance === undefined) ? -1 : props.Fx.maxSortieAvance,
        maxSortieRetard:(props.Fx.maxSortieRetard === null || props.Fx.maxSortieRetard === undefined) ? -1 : props.Fx.maxSortieRetard,
        Pause:props.Fx.pause||0,
        ShiftMode:!props.Fx.shiftMode ? choicesShift[0] : choicesShift.find(s=>s.id === props.Fx.shiftMode) as Choice,
        Statut:props.Fx.isProduction ? choicesStatut[1] : choicesStatut[0],
        RuleEntreeAvance:(props.Fx.maxEntreeAvance === null || props.Fx.maxEntreeAvance===-1 ) ? choicesRule[0] : choicesRule[1],
        RuleEntreeRetard:(!props.Fx.maxEntreeRetard === null || props.Fx.maxEntreeRetard===-1 ) ? choicesRule[0] : choicesRule[1],
        RuleSortieRetard:(!props.Fx.maxSortieRetard === null || props.Fx.maxSortieRetard===-1 ) ? choicesRule[0] : choicesRule[1],
        RuleSortieAvance:(!props.Fx.maxSortieAvance === null || props.Fx.maxSortieAvance===-1 ) ? choicesRule[0] : choicesRule[1],
    })
    useEffect(() => {
        if(UsersQuery.data) {
            setUsersConcerned(UsersQuery.data.filter(u => u.Fonction.id === props.Fx.id))
        }
    }, [UsersQuery.data, props.Fx.id]);
    useEffect(() => {
        setMyFonction({
            libelle:props.Fx.libelle,
            Pointeur:props.Fx.isPointeur ? choicesPointage[1] : choicesPointage[0],
            TR:props.Fx.isTr ? choicesTR[1] : choicesTR[0],
            color:props.Fx.color,
            maxEntreeAvance:(props.Fx.maxEntreeAvance === null || props.Fx.maxEntreeAvance === undefined) ? -1 : props.Fx.maxEntreeAvance,
            maxEntreeRetard:(props.Fx.maxEntreeRetard === null || props.Fx.maxEntreeRetard === undefined) ? -1 : props.Fx.maxEntreeRetard,
            maxSortieAvance:(props.Fx.maxSortieAvance === null || props.Fx.maxSortieAvance === undefined) ? -1 : props.Fx.maxSortieAvance,
            maxSortieRetard:(props.Fx.maxSortieRetard === null || props.Fx.maxSortieRetard === undefined) ? -1 : props.Fx.maxSortieRetard,
            Pause:props.Fx.pause||0,
            ShiftMode:!props.Fx.shiftMode ? choicesShift[0] : choicesShift.find(s=>s.id === props.Fx.shiftMode) as Choice,
            Statut:props.Fx.isProduction ? choicesStatut[1] : choicesStatut[0],
            RuleEntreeAvance:(props.Fx.maxEntreeAvance === null || props.Fx.maxEntreeAvance===-1 ) ? choicesRule[0] : choicesRule[1],
            RuleEntreeRetard:(!props.Fx.maxEntreeRetard === null || props.Fx.maxEntreeRetard===-1 ) ? choicesRule[0] : choicesRule[1],
            RuleSortieRetard:(!props.Fx.maxSortieRetard === null || props.Fx.maxSortieRetard===-1 ) ? choicesRule[0] : choicesRule[1],
            RuleSortieAvance:(!props.Fx.maxSortieAvance === null || props.Fx.maxSortieAvance===-1 ) ? choicesRule[0] : choicesRule[1],
        })
    }, [props.Fx]);
    const LibelleDebounce = useDebounce(myFonction.libelle, 2000);
    const MEADebounce = useDebounce(myFonction.maxEntreeAvance, 2000)
    const MERDebounce = useDebounce(myFonction.maxEntreeRetard, 2000)
    const MSADebounce = useDebounce(myFonction.maxSortieAvance, 2000)
    const MSRDebounce = useDebounce(myFonction.maxSortieRetard, 2000)
    const PauseDebounce = useDebounce(myFonction.Pause, 2000)
    const TabChoices = [
        "Pointeur", "TR", "ShiftMode", "Statut",
        "RuleEntreeAvance", "RuleEntreeRetard", "RuleSortieRetard", "RuleSortieAvance"
    ]
    const TabNeeds:Contraint[] = [
        {id:"libelle", required:true},
        {id:"maxEntreeAvance", min:0},
        {id:"maxEntreeRetard", min:0},
        {id:"maxSortieAvance", min:0},
        {id:"maxSortieRetard", min:0},
    ]
    useEffect(() => {
        console.log(myFonction)
    }, [myFonction]);
    const setMyValue = (value:string|null|Choice|number, id:string)=> {
        console.log(value)
        console.log(id)
        const Error = DatasSetter<FonctionProps, ErrorsProps>(value, id as keyof FonctionProps, ["libelle", "color"], ["Pause", "maxEntreeAvance", "maxEntreeRetard", "maxSortieAvance", "maxSortieRetard"], TabChoices, TabNeeds, myFonction, setMyFonction, errors, setErrors)
        if(!Error){
            switch (id){
                case "libelle":
                    setIsSavable(true);
                    break;
                case "Pointeur":
                    SavePointeur(value as Choice);
                    break;
                case "TR":
                    SaveTR(value as Choice);
                    break;
                case "ShiftMode":
                    SaveShiftMode(value as Choice);
                    break;
                case "Statut":
                    SaveStatut(value as Choice)
                    break;
                case "color":
                    SaveColor(value as string)
                    break;
                case "Pause":
                    setIsSavablePause(true);
                    break;
                case "maxEntreeAvance":
                    setIsSavableEA(true)
                    break;
                case "maxEntreeRetard":
                    setIsSavableER(true)
                    break;
                case "maxSortieAvance":
                    setIsSavableSA(true)
                    break;
                case "maxSortieRetard":
                    setIsSavableSR(true)
                    break;
                default:
                    break;
            }
        }
    }
    useEffect(() => {
        if(myFonction.RuleEntreeAvance?.id === 1 && myFonction.maxEntreeAvance === -1){
            setMyFonction(fx=>{
                return {...fx, maxEntreeAvance:0}
            })
            SaveMax("maxEntreeAvance", 0)
        }
        if(myFonction.RuleEntreeAvance?.id === 0 && myFonction.maxEntreeAvance !== -1){
            setMyFonction(fx=>{
                return {...fx, maxEntreeAvance:-1}
            })
            SaveMax("maxEntreeAvance", -1)
        }
        if(myFonction.RuleEntreeRetard?.id === 1 && myFonction.maxEntreeRetard === -1){
            setMyFonction(fx=>{
                return {...fx, maxEntreeRetard:0}
            })
            SaveMax("maxEntreeRetard", 0)
        }
        if(myFonction.RuleEntreeRetard?.id === 0 && myFonction.maxEntreeRetard !== -1){
            setMyFonction(fx=>{
                return {...fx, maxEntreeRetard:-1}
            })
            SaveMax("maxEntreeRetard", -1)
        }
        if(myFonction.RuleSortieAvance?.id === 1 && myFonction.maxSortieAvance === -1){
            setMyFonction(fx=>{
                return {...fx, maxSortieAvance:0}
            })
            SaveMax("maxSortieAvance", 0)
        }
        if(myFonction.RuleSortieAvance?.id === 0 && myFonction.maxSortieAvance !== -1){
            setMyFonction(fx=>{
                return {...fx, maxSortieAvance:-1}
            })
            SaveMax("maxSortieAvance", -1)
        }
        if(myFonction.RuleSortieRetard?.id === 1 && myFonction.maxSortieRetard === -1){
            setMyFonction(fx=>{
                return {...fx, maxSortieRetard:0}
            })
            SaveMax("maxSortieRetard", 0)
        }
        if(myFonction.RuleSortieRetard?.id === 0 && myFonction.maxSortieRetard !== -1){
            setMyFonction(fx=>{
                return {...fx, maxSortieRetard:-1}
            })
            SaveMax("maxSortieRetard", -1)
        }
    }, [myFonction]);
    const SavePointeur=(c:Choice)=>{
        const datas:PutFonction = {id:props.Fx.id, isPointeur:c.id === 1}
        SaveMe(datas);
    }
    useEffect(() => {
        if(isSavableEA && MEADebounce!==props.Fx.maxEntreeAvance){
            console.log("Hello EA!")
            SaveMax("maxEntreeAvance", MEADebounce)
        }
    }, [MEADebounce, props.Fx.maxEntreeAvance]);
    useEffect(() => {
        if(isSavableER && MERDebounce!==props.Fx.maxEntreeRetard){
            console.log("Hello ER!")
            SaveMax("maxEntreeRetard", MERDebounce)
        }
    }, [MERDebounce, props.Fx.maxEntreeRetard]);
    useEffect(() => {
        if(isSavableSA && MSADebounce!==props.Fx.maxSortieAvance){
            console.log("Hello SA!")
            SaveMax("maxSortieAvance", MSADebounce)
        }
    }, [MSADebounce, props.Fx.maxEntreeAvance]);
    useEffect(() => {
        if(isSavableSR && MSRDebounce!==props.Fx.maxSortieRetard){
            console.log("Hello SR!")
            SaveMax("maxSortieRetard", MSRDebounce)
        }
    }, [MSRDebounce, props.Fx.maxEntreeAvance]);
    const SaveMax = (code:string, v:number)=>{
        console.log("ICI Save max "+code)
        const datas:PutFonction = {id:props.Fx.id, [code]:parseInt(v+"")}
        mutation.mutate(datas, {
            onSuccess:()=>{
                StopSavable(code);
            }
        })
    }
    useEffect(() => {
        console.log(isSavableER ? "IS savable ER" : "IS NOT Savable ER")
    }, [isSavableER]);
    const StopSavable = (code:string)=>{
        switch (code){
            case "maxEntreeAvance":
                setIsSavableEA(false);
                break;
            case "maxEntreeRetard":
                setIsSavableER(false);
                break;
            case "maxSortieAvance":
                setIsSavableSA(false);
                break;
            case "maxSortieRetard":
                setIsSavableSR(false);
                break;
            default:
                break;
        }
    }
    const SaveTR=(c:Choice)=>{
        const datas:PutFonction = {id:props.Fx.id, isTr:c.id === 1}
        SaveMe(datas);
    }
    const SaveShiftMode=(c:Choice)=>{
        const valId = parseInt(c.id+"")
        const datas:PutFonction = {id:props.Fx.id, shiftMode: valId === 0 ? null : parseInt(c.id+"")}
        SaveMe(datas);
    }
    const SaveStatut=(c:Choice)=>{
        const datas:PutFonction = {id:props.Fx.id, isProduction:c.id === 1}
        SaveMe(datas);
    }
    useEffect(() => {
        if(isSavablePause && PauseDebounce !== props.Fx.pause){
            if(PauseDebounce>0){
                setMyValue(choicesShift[1], "ShiftMode")
            } else {
                setMyValue(choicesShift[0], "ShiftMode")
            }
            SavePause(PauseDebounce);
        }
    }, [PauseDebounce]);
    const SavePause = (n:number)=>{
        const datas:PutFonction = {id:props.Fx.id, pause:parseInt(n+"")}
        mutation.mutate(datas, {
            onSuccess:()=>{
                setIsSavablePause(false)
            }
        })
    }
    const SaveColor = (c:string)=>{
        const datas:PutFonction = {id:props.Fx.id, color:c}
        SaveMe(datas);
    }
    const SaveMe = (datas:PutFonction)=>{
        console.log("SAVE ME")
        mutation.mutate(datas);
    }
    useEffect(() => {
        if(CentreQuery.data){
            const cParameters = CentreQuery.data.parameters;
            const myParamsPointeur = cParameters.find(cp=>cp.Parameter.id === 2)
            const myParamsTR = cParameters.find(cp=>cp.Parameter.id === 5)
            setIsPointeur(!!myParamsPointeur && myParamsPointeur.statut)
            setIsTr(!!myParamsTR && myParamsTR.statut)
        }
    }, [CentreQuery.data]);
    useEffect(() => {
        setIsPause(myFonction.Pause > 0)
    }, [myFonction.Pause]);
    useEffect(() => {
        setIsDisabled(props.Fx.id === 1)
    }, [props.Fx.id]);
    useEffect(() => {
        if(LibelleDebounce!=="" && isSavable && props.Fx.libelle !== LibelleDebounce){
            const datas:PutFonction = {id:props.Fx.id, libelle:LibelleDebounce}
            mutation.mutate(datas, {
                onSuccess:()=>{
                    setIsSavable(false)
                }
            })
        }
    }, [LibelleDebounce, isSavable, props.Fx.libelle, props.Fx.id]);
    const TrashFx = ()=>{
        mutationTrash.mutate(props.Fx.id, {
            onSuccess:()=>{
                props.CancelSet();
            }
        })
    }
    return (
        <div className={`formEditFonction ${props.className}`}>
            {(mutation.isLoading || mutationTrash.isLoading) &&
                <ModifyLoader/>
            }
            {isDisabled &&
                <p className="dis_infos">Cette fonction est partagée par tous les centres, elle n'est pas modifiable</p>
            }
            <div className="wrap_inputs_setting">
                <InputTextSetting wdLib={"30%"} current={myFonction.libelle} setValue={setMyValue} Error={errors.libelle} id={"libelle"} label={"Libellé"} disabled={isDisabled}/>
                <InputColorPickerSetting wdLib={"30%"} current={myFonction.color} setValue={setMyValue} label={"Couleur"} id={"color"}/>
                {isTr &&
                    <InputSelectSetting wdLib={"30%"} setValue={setMyValue} Error={errors.TR} label={"Concerné par les Tickets restaurant ?"} id={"TR"} choices={choicesTR} Current={myFonction.TR} ItemInfos={TabInfos.TR}  disabled={isDisabled}/>
                }
                <InputTextSetting  wdLib={"30%"} Error={errors.Pause} label={"Durée de pause automatiquement ajoutée au temps de travail (mn)"} current={myFonction.Pause} setValue={setMyValue} id={"Pause"} type={"number"} min={0}/>
                {isPause &&
                    <div className={`wrap_rules`}>
                        <InputSelectSetting wdLib={"30%"} setValue={setMyValue} Error={errors.ShiftMode} label={"Quel type de pause ?"} id={"ShiftMode"} choices={choicesShift} Current={myFonction.ShiftMode} ItemInfos={TabInfos.ShiftMode}  disabled={isDisabled}/>
                    </div>
                }
                {isPointeur &&
                    <>
                        <InputSelectSetting wdLib={"30%"} setValue={setMyValue} Error={errors.Pointeur} label={"Concerné par le pointage ?"} id={"Pointeur"} choices={choicesPointage} Current={myFonction.Pointeur} ItemInfos={TabInfos.Pointeur}  disabled={isDisabled}/>
                        {(myFonction.Pointeur && myFonction.Pointeur.id === 1) &&
                            <div className={`wrap_rules`}>
                                <div className="one_rule">
                                    <InputSelectSetting wdLib={"30%"} setValue={setMyValue} Error={errors.TR} label={"Souhaitez vous une règle pour l'arrivée en avance ?"} id={"RuleEntreeAvance"} choices={choicesRule} Current={myFonction.RuleEntreeAvance} ItemInfos={TabInfos.Rule}  disabled={isDisabled}/>
                                    {(myFonction.RuleEntreeAvance && myFonction.RuleEntreeAvance.id === 1) &&
                                        <div className="wrap_number_set">
                                            <InputTextSetting  wdLib={"30%"} Error={errors.maxEntreeAvance} label={"Durée maximum d'avance prise en compte (mn) "} current={myFonction.maxEntreeAvance} setValue={setMyValue} id={"maxEntreeAvance"} type={"number"} min={0}/>
                                        </div>
                                    }
                                </div>
                                <div className="one_rule">
                                    <InputSelectSetting wdLib={"30%"} setValue={setMyValue} Error={errors.TR} label={"Souhaitez vous une règle pour l'arrivée en retard ?"} id={"RuleEntreeRetard"} choices={choicesRule} Current={myFonction.RuleEntreeRetard} ItemInfos={TabInfos.Rule}  disabled={isDisabled}/>
                                    {(myFonction.RuleEntreeRetard && myFonction.RuleEntreeRetard.id === 1) &&
                                        <div className="wrap_number_set">
                                            <InputTextSetting  wdLib={"30%"} Error={errors.maxEntreeRetard} label={"Tolérance avant d'être considéré en retard (mn)"} current={myFonction.maxEntreeRetard} setValue={setMyValue} id={"maxEntreeRetard"} type={"number"} min={0}/>
                                        </div>
                                    }
                                </div>
                                <div className="one_rule">
                                    <InputSelectSetting wdLib={"30%"} setValue={setMyValue} Error={errors.TR} label={"Souhaitez vous une règle pour la sortie en avance ?"} id={"RuleSortieAvance"} choices={choicesRule} Current={myFonction.RuleSortieAvance} ItemInfos={TabInfos.Rule}  disabled={isDisabled}/>
                                    {(myFonction.RuleSortieAvance && myFonction.RuleSortieAvance.id === 1) &&
                                        <div className="wrap_number_set">
                                            <InputTextSetting  wdLib={"30%"} Error={errors.maxSortieAvance} label={"Tolérance avant d'être considéré comme parti en avance (mn)"} current={myFonction.maxSortieAvance} setValue={setMyValue} id={"maxSortieAvance"} type={"number"} min={0}/>
                                        </div>
                                    }
                                </div>
                                <div className="one_rule">
                                    <InputSelectSetting wdLib={"30%"} setValue={setMyValue} Error={errors.TR} label={"Souhaitez vous une règle pour la sortie en retard ?"} id={"RuleSortieRetard"} choices={choicesRule} Current={myFonction.RuleSortieRetard} ItemInfos={TabInfos.Rule}  disabled={isDisabled}/>
                                    {(myFonction.RuleSortieRetard && myFonction.RuleSortieRetard.id === 1) &&
                                        <div className="wrap_number_set">
                                            <InputTextSetting  wdLib={"30%"} Error={errors.maxSortieRetard} label={"Durée maximum de temps supplémentaire prise en compte (mn)"} current={myFonction.maxSortieRetard} setValue={setMyValue} id={"maxSortieRetard"} type={"number"} min={0}/>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </>
                }
                <InputSelectSetting wdLib={"30%"} setValue={setMyValue} Error={errors.Statut} label={"Statut"} id={"Statut"} choices={choicesStatut} Current={myFonction.Statut} ItemInfos={TabInfos.Statut}  disabled={isDisabled}/>
                {UsersQuery.data &&
                    <div className="wrap_delete_poss">
                        {usersConcerned.length>0 ?
                            <div className="no_delete_poss"><strong>{usersConcerned.length}</strong> collaborateurs sont concernés par cette fonction, il n'est pas possible de la supprimer<br/>Vous pouvez cependant le rendre inactif pour le futur</div>:
                            <div className="delete_possible">
                                <span className={`link_delete`} onClick={TrashFx}>Supprimer cette fonction</span>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const FormEditFonction = styled(FormEditFonctionCtrl)`
    position: relative;
    .one_rule{
        padding-bottom: 10px;
        border-bottom: solid ${props => props.theme.PrimaryLight} 1px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .wrap_rules{
        padding-left: 35px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 12px;
    }
    .wrap_number_set{
        padding-left: 35px;
    }
    .wrap_delete_poss{
        margin-top: 30px;
    }
    .no_delete_poss{
        line-height: 170%;
    }
`

export default FormEditFonction