import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {useDebounce} from "../../../services/hooks";
import {ErrorInput, InputInter, LabelInter, TextAreaInter} from "./styleInters";

interface TimedTextAreaInterCtrlProps {
    className?:string;
    Current:string;
    setNew:(c:Choice|number|string, id:string)=>void;
    id:string;
    label:string;
    Error?:string|null;
    Disabled?:boolean;
    height:string;
}

const TimedTextAreaInterCtrl = ({className, Current, id, setNew, Error, Disabled, label, height}: TimedTextAreaInterCtrlProps) => {
    const [myValue, setMyValue] = useState<string|number>(Current)
    const myValueDebounce = useDebounce(myValue, 900)
    const refInput = useRef<HTMLTextAreaElement>(null)
    const [myError,setMyError] = useState(Error)
    useEffect(() => {
        setMyValue(Current);
    }, [Current]);
    useEffect(() => {
        setMyError(Error);
    }, [Error]);
    const handleChange = ()=>{
        if(refInput.current){
            setMyValue(refInput.current.value);
        }
    }
    useEffect(() => {
        if(Current!==myValueDebounce) {
            setNew(myValueDebounce, id);
        }
    }, [myValueDebounce]);
    return (
        <div className={`timed_input_inter ${className}`}>
            <LabelInter>{label}</LabelInter>
            <TextAreaInter style={{height:height}} className={`${myError && 'is-error'} ${Current === '' && 'empty'}`} ref={refInput} value={myValue} disabled={Disabled} onChange={handleChange}/>
            {myError &&
                <ErrorInput>{myError}</ErrorInput>
            }
        </div>
    )
}

export const TimedTextAreaInter = styled(TimedTextAreaInterCtrl)`
    width: 100%;
    position: relative;
`