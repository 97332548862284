import styled from "styled-components";

import {useCallback, useContext, useRef} from "react";

import {useDrop} from "react-dnd";

import {format} from "date-fns";
import {ContextPlanning} from "../WeeklyCalendar";
import {ItemsTypeHebdo} from "./ItemsTypeHebdo";
import OnePlage from "./OnePlage";
import PlageTempo from "./PlageTempo";
import PlageShadow from "./PlageShadow";


interface ZoneDropPlageCtrlProps{
    className?:string;
    plages:Plage[];
    date:Date;
}

const ZoneDropPlageCtrl = (props:ZoneDropPlageCtrlProps)=>{
    const ref = useRef<HTMLDivElement>(null)
    const Context = useContext(ContextPlanning)
    const canDrop = useCallback((p:Plage)=>{
        if(Context.GetConflicts){
            return Context.GetConflicts(p).length === 0
        } else {
            return true;
        }
    }, [Context])
    const canDropCopy = useCallback(()=>{
        if(Context.GetConflicts && Context.plageShadow){
            return Context.GetConflicts(Context.plageShadow, true).length === 0
        } else {
            return true;
        }
    }, [Context])
    const Drop = (idPlage:number)=>{
        if(Context.dropPlage){
            Context.dropPlage(idPlage);
        }
    }
    const DropHandlePlage = (p:Plage)=>{
        console.log('drop copy '+p.id)
        Context.saveShadow();
    }
    const [, drop] = useDrop({
        accept: [ItemsTypeHebdo.PLAGE, ItemsTypeHebdo.HANDLEcopy],
        canDrop:(item: Plage, monitor)=> {
            const Type = monitor.getItemType()
            if(Type === ItemsTypeHebdo.PLAGE) {
               return canDrop(item)
            } else {
                return canDropCopy()
            }
        },
        drop:(item:Plage, monitor)=> {
            if (monitor.didDrop()) return;
            if(monitor.getItemType() === ItemsTypeHebdo.PLAGE){
                Drop(item.id)
            } else {
                DropHandlePlage(item)
            }
        },
        hover: (item: Plage, monitor) => {

            const diff = monitor.getDifferenceFromInitialOffset();
            const Type = monitor.getItemType()
            if(diff){
                const increments = Math.round(diff.y / (Context.config.heightHour / 12));
                const newHeureDebut = item.heureDebut + increments;
                if(Type === ItemsTypeHebdo.PLAGE) {
                    if (Context.onMove) {
                        Context.onMove(item.id, props.date, newHeureDebut);
                    }
                } else {
                    if(monitor.isOver() && Context.setPlageShadow) {
                        Context.setPlageShadow({...item, id:0, plageAt:format(props.date, "yyyy-MM-dd"), heureDebut: newHeureDebut, heureFin:(item.heureFin - item.heureDebut)+newHeureDebut})
                    } else if(Context.setPlageShadow) {
                        console.log("is out")
                       Context.setPlageShadow(null)
                    }
                }
            }
        },
    });
    const ClickOneDrop = (e:any)=>{
        e.stopPropagation();
        if(ref.current && Context.addPlageTempo){
            const elt = ref.current.getBoundingClientRect();
            const Zpos = elt.y;
            const clientClick = e.clientY;
            const deltaY = clientClick - Zpos;
            const increments = Math.round(deltaY / (Context.config.heightHour/12));
            const heureDebut = increments+Context.config.start;
            const yPos = ((heureDebut - Context.config.start)/12)*Context.config.heightHour + Zpos;
            const yPosIn = ((heureDebut - Context.config.start)/12)*Context.config.heightHour;
            const xPos = elt.x + elt.width
            Context.addPlageTempo(props.date, heureDebut, {x:xPos, xr:elt.x, y:yPos, yin:yPosIn})
        }
    }
    drop(ref)
    return (
        <div className={`zoneDropPlage ${props.className}`} ref={ref} onClick={ClickOneDrop}>
            {props.plages.map(p=>(
                <OnePlage plage={p} key={`p${p.id}`}/>
            ))}
            {(Context.plageTempo && format(new Date(Context.plageTempo.d), "yyyy-MM-dd") === format(props.date, "yyyy-MM-dd")) &&
                <PlageTempo plageTempo={Context.plageTempo}/>
            }
            {(Context.plageShadow && format(new Date(Context.plageShadow.plageAt.slice(0,10)+"T12:00:00"), "yyyy-MM-dd") === format(props.date, "yyyy-MM-dd")) &&
                <PlageShadow plage={Context.plageShadow}/>
            }
        </div>
    )
}

const ZoneDropPlage = styled(ZoneDropPlageCtrl)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
`

export default ZoneDropPlage