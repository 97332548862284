import styled from "styled-components";
import {TitrePage} from "../../../../assets/styles/elements";
import {useGetCalendrierPaie} from "../../../../features/calendrierPaie/calendrierPaie.hooks";
import {useSearchParams} from "react-router-dom";
import {format} from "date-fns";

interface TempsTrMainCtrlProps{
    className?:string;
}

const TempsTrMainCtrl = (props:TempsTrMainCtrlProps)=>{
    const CalendrierPaieQuery = useGetCalendrierPaie();
    const [searchParams, setSearchParams] = useSearchParams();
    const ClickOne = (c:CalendrierPaie)=>{

        const params:any = {};
        searchParams.forEach((key, value) => {
            params[value] = key;
        });
        const myDateStart = new Date(c.startAt.slice(0,10)+"T12:00:00")
        const myDateStop = new Date(c.stopAt.slice(0,10)+"T12:00:00")

        setSearchParams({...params, from:format(myDateStart, "dd/MM/yyyy"), to:format(myDateStop, "dd/MM/yyyy")})
    }
    return (
        <div className={`tempsTrMain ${props.className}`}>
            <TitrePage>Gestion des temps travaillés</TitrePage>
            <div className="wrap_calendriers">
                {CalendrierPaieQuery.data?.map(item=>(
                    <div className={`one_cal ${item.id}`} key={`${item.id}C`} onClick={()=>ClickOne(item)}>
                        {format(new Date(item.startAt.slice(0,10)+"T12:00:00"), "dd/MM/yyyy")} - {format(new Date(item.stopAt.slice(0,10)+"T12:00:00"), "dd/MM/yyyy")}
                    </div>
                ))}
            </div>
        </div>
    )
}

const TempsTrMain = styled(TempsTrMainCtrl)`
    padding: 1% 2%;
    .wrap_calendriers{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px 10px;
    }
    .one_cal{
        border:solid ${props => props.theme.Primary} 1px;
        padding: 0.5rem;
        &:hover{
            cursor: pointer;
        }
    }
`

export default TempsTrMain