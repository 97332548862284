import styled, {useTheme} from "styled-components";
import {useGetTimesUserWeekly} from "../../../../../../../../features/tempsTravail/tempsTravail.hooks";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';
import {Chart} from 'react-chartjs-2';
import React, {useEffect, useState} from "react";
import {getISOWeekNumber, GetWeeksNum} from "../../../../../../../../components/functions/TimesFunctions";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Tooltip,
    Legend, ChartDataLabels
);
const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top' as const,
            labels: {
                usePointStyle: true, // Active le style personnalisé pour la légende
                generateLabels: (chart) => {
                    const datasets = chart.data.datasets;
                    return datasets.map((dataset, i) => ({
                        text: dataset.label || '',
                        fillStyle: dataset.type === 'bar' ? (dataset.backgroundColor as string) : undefined,
                        strokeStyle: dataset.type === 'line' ? (dataset.borderColor as string) : undefined,
                        pointStyle: dataset.type === 'line' ? 'line' : 'rect',
                        hidden: !chart.isDatasetVisible(i),
                        datasetIndex: i,
                    }));
                },
            },
        },
        tooltip: {
            mode: 'index', // Montre toutes les valeurs au survol
            intersect: false,
        },
        datalabels: {
            display: (context: any) => {
                const value = context.dataset.data[context.dataIndex];
                return value !== 0;
            },
            color: '#fff', // Couleur des labels
            backgroundColor: "#000",
            borderRadius: 5, // Coins arrondis pour le fond
            padding: 6, // Espacement autour du texte
            textAlign:"center",
            formatter: (value:any) => {
                // Affiche le pourcentage
                return  value === 0 ? "" : `${Math.round(value*100)/100}`; // Retour à la ligne avec "\n"
            },
            font: {
                size: 14, // Taille de la police
            },
        },
    },
    scales: {
        x: {
            stacked: true,
            grid: {
                display: false, // Cache les lignes verticales de la grille
            },
        },
        y: {
            stacked: true,
            beginAtZero: true, // Force l’échelle Y à commencer à 0
        },
    },
};

interface ChartTimesWeeklyCtrlProps{
    className?:string;
    User:User;
    calendrier:CalendrierPaie;
}

const ChartTimesWeeklyCtrl = (props:ChartTimesWeeklyCtrlProps)=>{
    const TimesQuery = useGetTimesUserWeekly(props.calendrier.id, props.User.id)
    const [data, setData] = useState<any|null>(null);
    const theme = useTheme();
    useEffect(() => {
        if(TimesQuery.data){
            const labels = TimesQuery.data.map(t=>{
                const dateStart = new Date(t.dateStart.slice(0,10)+"T12:00:00")
                const numSem = getISOWeekNumber(dateStart);
                return `S${numSem}`
            })
            const datasets = [
                {
                    type:'bar' as const,
                    label:"Temps",
                    data:TimesQuery.data.map(t=>t.heures > t.TempsContrat ? t.TempsContrat : t.heures),
                    backgroundColor:theme.PrimaryDark+"50",
                    borderColor:theme.PrimaryDark,
                    borderWidth:1
                },
                {
                    type:'bar' as const,
                    label:"Temps Supp",
                    data:TimesQuery.data.map(t=>t.heures > t.TempsContrat ? t.heures - t.TempsContrat : 0),
                    backgroundColor: theme.Secondary+"50",
                    borderColor:theme.PrimaryDark,
                    borderWidth:1
                },
                {
                    type:'bar' as const,
                    label:"Temps Manquant",
                    data:TimesQuery.data.map(t=>t.heures < t.TempsContrat ? t.TempsContrat - t.heures : 0),
                    backgroundColor: theme.Neutre+"50",
                    borderColor:theme.PrimaryDark,
                    borderWidth:1
                },
                {
                    type:'line' as const,
                    label:"Contrat",
                    data:TimesQuery.data.map(t=>t.TempsContrat),
                    borderColor: theme.Neutre,
                    borderWidth: 2,
                    tension:0.4,
                    fill:false,
                    pointBackgroundColor:theme.Primary,
                    datalabels:{display: false}
                }
            ]
            setData({labels:labels, datasets:datasets})
        }
    }, [TimesQuery.data]);
    return (
        <div className={`chartTimesWeekly ${props.className}`}>
            {TimesQuery.isLoading || !data ? "chargement des données":
                <div className={`canvas-container`}>
                    <MyChart options={options} data={data}/>
                </div>
            }
        </div>
    )
}

const MyChart = ({...props})=>{
    return <Chart type={'bar'} options={props.options}  data={props.data} width={"100%"} height={"100%"} />;
}

const ChartTimesWeekly = styled(ChartTimesWeeklyCtrl)`
    width: 100%;
    height: calc(100% - 51px);
    .canvas-container{
        width: 100%;
        height: 100%;
    }
`

export default ChartTimesWeekly