import styled from "styled-components";
import {createPortal} from "react-dom";
import {useEffect, useRef, useState} from "react";
import {BsCalendarDate, BsPencil, BsTrash} from "react-icons/bs";
import {BiCopyAlt, BiTime} from "react-icons/bi";
import {HiSwitchHorizontal} from "react-icons/hi";
import {useGetInfosDay} from "../../../../services/hooks";
import {PlageCoord} from "../WeeklyCalendar";
import TimedIconButton from "../../../atoms/TimedIconButton/TimedIconButton";
import {ConvertBase5} from "../../../functions/TimesFunctions";


interface MenuFloatPlageCtrlProps{
    className?:string;
    plage:PlageCoord|null;
    closeMe:()=>void;
    open:boolean;
    TrashPlage:(idPlage:number)=>void;
}

const MenuFloatPlageCtrl = (props:MenuFloatPlageCtrlProps)=>{
    const refMenu = useRef<HTMLDivElement>(null)
    const [datePlage, setDatePlage] = useState(new Date())
    const {libelle, strDate} = useGetInfosDay(datePlage)
    const [XPos, setXPos] = useState<number>(props.plage?.x||0)
    const [YPos, setYPos] = useState<number>(props.plage?.y||0)
    useEffect(() => {
        if(props.plage){
            const dayPlage = new Date(props.plage.plageAt.slice(0,10)).getDay();
            const xPo = dayPlage <=3 && dayPlage!==0  ?  ((props.plage?.x||0) + (props.plage?.w||0) + 10) : !props.plage ? 0 : (props.plage.x - 370)
            const yPo = props.plage.y;
            setDatePlage( new Date(props.plage.plageAt.slice(0,10)+"T12:00:00"))
            setXPos(xPo)
            setYPos(yPo)
        }
    }, [props.plage]);


    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refMenu.current && !refMenu.current.contains(event.target) && props.open) {
                props.closeMe();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refMenu, props]);
    const ClickEdit = ()=>{
        console.log("TODO Edit")
    }
    const ClickTrash = ()=>{
        if(props.plage){
            props.TrashPlage(props.plage.id)
        }
    }
    return createPortal(
        <div className={`menuFloatPlage ${props.className} ${props.open ? "visible" : "invisible"}`} ref={refMenu} style={{left:XPos+"px", top:YPos+"px"}}>
            {props.plage &&
            <>
                <div className="wrap_actions">
                    <TimedIconButton isSquare Icon={<BsPencil/>} size={"sm"} themeColor={"Primary"} onClick={ClickEdit}/>
                    {!props.plage.UserReplaced &&
                        <>
                            <TimedIconButton isSquare size={"sm"} Icon={<HiSwitchHorizontal/>} themeColor={"Primary"} onClick={ClickEdit}/>
                            <TimedIconButton isSquare size={"sm"} Icon={<BiCopyAlt/>} themeColor={"Primary"} onClick={ClickEdit}/>
                            <TimedIconButton isSquare size={"sm"} Icon={<BsTrash/>} themeColor={"Warning"} onClick={ClickTrash}/>
                        </>
                    }
                </div>
                <div className="content_menu">
                    <div className={`datePlage iconStyle`}><BsCalendarDate/>{libelle} {strDate}</div>
                    <div className={"lieu_horaires"}>
                        <div className={`lieu iconStyle`}>
                            <div className="wrapColorMarker">
                                <div className="squareColor" style={{backgroundColor:props.plage.Lieu.backColor}}/>
                                <div className={"libMarker"}>{props.plage.Lieu.libelle}</div>
                            </div>
                        </div>
                        <div className={`lieu iconStyle`}>
                            <div className="wrapColorMarker">
                                <div className="squareColor" style={{backgroundColor:props.plage.Acte.backColor}}/>
                                <div className={"libMarker"}>{props.plage.Acte.libelle}</div>
                            </div>
                        </div>
                        <div className="horaires iconStyle">
                            <BiTime/>{ConvertBase5(props.plage.heureDebut)} - {ConvertBase5(props.plage.heureFin)}</div>
                    </div>
                    {props.plage?.Memo &&
                        <div className={"memo"}>{props.plage?.Memo}</div>
                    }
                </div>
            </>
            }
        </div>, document.body
    )
}

const MenuFloatPlage = styled(MenuFloatPlageCtrl)`
    width: 350px;
    background: white;
    z-index: 7;
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.12),0 1px 8px 0 rgba(0,0,0,.2);
    position: fixed;
    
    transition: all 0.3s;
    opacity: 1;
    &.invisible{
        opacity: 0;
        z-index: -12;
        overflow: hidden;
        transform: scale(0);
    }
    .wrap_actions{
        display: flex;
        justify-content: flex-end;
        gap: 4px;
        margin-bottom: 10px;
    }
    .wrapColorMarker{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        .squareColor{
            width: 12px;
            height: 12px;
        }
    }
    .iconStyle{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
    }
    .datePlage{
        font-size: 18px;
        font-weight: bold;
        color:${props=>props.theme.PrimaryDark};
        margin-bottom: 4px;
    }
    .horaires{
        font-size: 14px;
        color:${props=>props.theme.SecondaryDark};
        font-weight: bold;
    }
`

export default MenuFloatPlage