import styled from "styled-components";
import {OneMobParams} from "./ParamsApps";
import {useSetMobParameters} from "../../../../features/centre/centre.hooks";
import TimedSwitch from "../../../../components/atoms/TimedSwitch/TimedSwitch";
import {GrFormClose} from "react-icons/gr";
import React from "react";
import SettingSwitch from "../components/SettingSwitch";

interface FormSetAppsCtrlProps{
    className?:string;
    Centre:CentreComplet;
    MobParameters:OneMobParams[];
    setMobParameters:(tabs:OneMobParams[])=>void;
}

const TabDescrip: { key:string, descr:string }[] = [
    {key:"births", descr:"Accédez à la page des anniversaires des membres du centre"},
    {key:"trombi", descr:"Consultez les photos et nom de vos collègues dans le trombinoscope"},
    {key:"messagerie", descr:"Accédez à la messagerie interne depuis les applications PC et mobile"},
    {key:"plG", descr:"Consultez les plannings des autres membres"},
    {key:"EtatVis", descr:"Visualisez le calendrier des absences de l'ensemble du personnel"},
    {key:"CptAbs", descr:"Consultez le solde de congés payés et autres absences restantes"},
    {key:"CptTps", descr:"Suivez votre compteur temps avec un arrêt au dernier soir"},
    {key:"heures_retenues", descr:"Consultez le détail des heures retenues pour chaque journée en fonction des pointages et ajustements"},
    {key:"creneaux", descr:"Accès aux créneaux de remplacements des médecins"},
    {key:"abs", descr:"Gestion des absences"},
]

const TabKey = ["plG"]

const FormSetAppsCtrl = (props:FormSetAppsCtrlProps)=>{
    const mutationSavedMob = useSetMobParameters();
    const SaveMobParameters = (Tab:OneMobParams[])=>{
        let TabM:any = {};
        Tab.map(i=>{
            TabM[i.key] = {lib:i.lib, collabs:i.collabs, rempla:i.rempla}
            return i;
        })
        mutationSavedMob.mutate(({idCentre:props.Centre.id, params:TabM}));
    }
    const handleChangeMobParameters = (key:string, type:"collab"|"rempla")=>{
        const newMobParams = [...props.MobParameters];
        const old = newMobParams.find(m=>m.key === key);
        if(old) {
            let Moby:OneMobParams[];
            if (type === "collab") {
                const newValue = old.collabs.value === 1 ? 0 : 1;
                const myNew = {...old, collabs: {settable: old.collabs.settable, value: newValue}}
                Moby = [...newMobParams.filter(m => m.key !== key), myNew].sort((a:OneMobParams, b:OneMobParams)=>{
                    return a.lib > b.lib ? 1 : -1
                });

            } else {
                const newValue = old.rempla.value === 1 ? 0 : 1;
                const myNew = {...old, rempla: {settable: old.rempla.settable, value: newValue}}
                Moby = [...newMobParams.filter(m => m.key !== key), myNew].sort((a:OneMobParams, b:OneMobParams)=>{
                    return a.lib > b.lib ? 1 : -1
                })
            }
            props.setMobParameters(Moby);
            SaveMobParameters(Moby);
        }
    }
    const ReturnDescr = (k:string)=>{
        const Obj = TabDescrip.find(i=>i.key === k)
        if(!Obj){
            return k;
        } else {
            return Obj.descr;
        }
    }
    return (
        <div className={`formSetApps ${props.className}`}>
            <table>
                <thead>
                    <tr>
                        <th>Fonctionnalités</th>
                        <th>Administrateurs</th>
                        <th>Collaborateurs</th>
                        <th>Remplaçants</th>
                    </tr>
                </thead>
                <tbody>
                {props.MobParameters.map((item: OneMobParams, idx: number) => (
                    <tr className={`line-mob is-body`} key={`lineMob${idx}`}>
                        <td className={`lib-entry w-30`}>
                            <div className={`body-lib`}>{item.lib}</div>
                            <div className="body_descr">{ReturnDescr(item.key)}</div>
                        </td>
                        <td className={`admin-entry w-23`}>
                            <span className={`body-lib text-center`}>
                                <SettingSwitch disabled={true} setStatut={() => {
                                }} Statut={true}/>
                            </span>
                        </td>
                        <td className={`collab-entry w-23`}>
                            <span className={`body-lib text-center`}>
                                <SettingSwitch
                                    setStatut={() => handleChangeMobParameters(item.key, "collab")}
                                    Statut={item.collabs.value === 1}
                                    disabled={!item.collabs.settable}
                                />
                            </span>
                        </td>
                        <td className={`rempla-entry w-23`}>
                            <SettingSwitch
                                setStatut={() => handleChangeMobParameters(item.key, "rempla")}
                                Statut={item.rempla.value === 1}
                                disabled={!item.rempla.settable}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

        </div>
    )
}

const FormSetApps = styled(FormSetAppsCtrl)`
    padding: 2rem;
    .body-lib{
        font-weight: bold;
    }
    .body_descr{
        font-size: 14px;
    }
    table{
        width: 100%;
        border-collapse: collapse;
        margin: 30px 0;
        th, td{
            padding: 0.5rem;
        }
        td:not(:first-child){
           text-align: center;
        }
        td:first-child{
            font-size: 18px;
        }
        td.w-30{
            width: 30%;
        }
    }
`

export default FormSetApps