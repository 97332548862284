import styled from "styled-components";

interface LibelleSettingCtrlProps{
    className?:string;
    libelle:string;
    subLabel?:string;
    wdLib?:string;
}

const LibelleSettingCtrl = (props:LibelleSettingCtrlProps)=>{
    return (
        <div className={`libelleSetting ${props.className}`}>
            {!props.subLabel ? props.libelle :
                <>
                    <div className="lib">{props.libelle}</div>
                    <div className="sub_lib">{props.subLabel}</div>
                </>
            }
        </div>
    )
}

const LibelleSetting = styled(LibelleSettingCtrl)`
    width: ${props => props.wdLib ? props.wdLib : "20%"};
    font-weight: bold;
    flex-shrink: 0;
    .sub_lib{
        font-weight: normal !important;
        font-size: 14px;
    }
`

export default LibelleSetting