import styled from "styled-components";
import {useGetDetailContrat} from "../../../../../../../../features/tempsTravail/tempsTravail.hooks";
import parse from 'html-react-parser';
import {format} from "date-fns";
import {GetNumJourFr} from "../../../../../../../../components/functions/TimesFunctions";

interface ModalDetailsContratCtrlProps{
    className?:string;
    from:string;
    to:string;
    User:User;
}

const ModalDetailsContratCtrl = (props:ModalDetailsContratCtrlProps)=>{
    const DetailsQuery = useGetDetailContrat(props.User.id, props.from, props.to)
    return (
        <div className={`modalDetailsContrat ${props.className}`}>
            {DetailsQuery.isLoading ? "chargement" :
                <div className={`list_jours_details`}>
                    {DetailsQuery.data?.map((item, idx)=>(
                        <div className={`one_details`} key={`DC${idx}`}>
                            <div className="head_details">
                                <div className="lib_date">{GetNumJourFr(new Date(item.Date+"T12:00:00"))} {format(new Date(item.Date+"T12:00:00"), "dd/MM/yyyy")}</div>
                                <div className={`times_details`}>{Math.round(item.Time*100)/100}h</div>
                            </div>
                            <div className="body_details">
                                <div className={`details`}>{parse(item.Details)}</div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

const ModalDetailsContrat = styled(ModalDetailsContratCtrl)`
    .list_jours_details{
        padding:  0 1rem;
        max-height: 60vh;
        overflow-y: auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 15px;
    }
    .one_details{
        .head_details{
            display: flex;
            justify-content: flex-start;
            gap: 20px;
            .lib_date{
                font-weight: bold;
            }
        }
        .body_details{
            font-size: 14px;
            margin-top: 10px;
            .details{
                border:solid 1px ${props => props.theme.PrimaryLight};
                padding: 1rem;
            }
            ul{
                margin: 15px 0;
                padding-left: 30px;
            }
            p{
                text-align: left!important;
                margin-bottom: 6px;
            }
        }
    }
    
`

export default ModalDetailsContrat