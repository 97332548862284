import styled from "styled-components";
import {useGetAllCentre} from "../../features/centre/centre.hooks";
import {useCancelWMSub, useGetAllUserD, useGetUserByCentre, useSaveWMSub} from "../../features/user/user.hooks";
import React, {useMemo, useState} from "react";
import TimedWaitUser from "../../components/atoms/TimedWaitUser/TimedWaitUser";
import {TimedAutoCompletionCentre} from "../../components/atoms/TimedAutoCompletion/TimedAutoCompletionCentre";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

import {TimedAutoCompletionMulti} from "../../components/atoms/TimedAutoCompletion/TimedAutoCompletionMulti";
import {DNA} from "react-loader-spinner";

interface FormShadowConnectCtrlProps{
    className?:string;
}

const FormShadowConnectCtrl = (props:FormShadowConnectCtrlProps)=>{
    const CentresQuery = useGetAllCentre();
    const [CentreCurrent, setCentreCurrent] = useState<Centre|null>(null)
    const UsersQuery = useGetAllUserD(CentreCurrent ? CentreCurrent.id : 0);
    const [UserShadowCurrent, setUserShadowCurrent] = useState<User|null>(null);
    const mutation = useSaveWMSub();
    const mutationCancel = useCancelWMSub();
    const [Error, setError] = useState('');
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const listAdmin = useMemo(()=>{
        if(!UsersQuery.data){
            return [];
        } else {
            return UsersQuery.data.filter(u=>u.roles.indexOf("ROLE_ADMIN")!==-1);
        }
    }, [UsersQuery.data])
    const handleClickConnexion = ()=>{
        setError('');
        if(UserShadowCurrent) {
            const datas: WMsubFormData = {
                SubstituateBy: `/api/users/${UserShadowCurrent.id}`
            }
            mutation.mutate((datas), {
                onSuccess: () => {
                    setSearchParams({});
                    window.location.reload();
                }, onError: () => {
                    setError("Une erreur est survenue");
                }
            })
        }
    }
    const CancelSub = ()=>{
        let x:void;
        mutationCancel.mutate(x, {
            onSuccess: () => {
                setSearchParams({});
                window.location.reload();
            }, onError: () => {
                setError("Une erreur est survenue");
            }
        })
    }
    const HandleChoiceCentre = (lib:string)=>{
        const myCentre = CentresQuery.data?.find(c=>c.name === lib);
        if(myCentre) {
            setCentreCurrent(myCentre);
        }
    }
    return (
        <div className={`form_shadow_connect ${props.className}`}>
            <div className={`wrap_cancel`}>
                <button type={"button"} className={`btn_cancel`} disabled={mutationCancel.isLoading} onClick={CancelSub}>
                    {mutationCancel.isLoading ?
                        <div className={`loading_btn`}>
                            <DNA
                                visible={true}
                                height="30"
                                width="30"
                                ariaLabel="dna-loading"
                                wrapperStyle={{}}
                                wrapperClass="dna-wrapper"
                            />
                            Connexion en cours...
                        </div>:
                        <div>Je redeviens moi même !</div>
                    }
                </button>
            </div>
            {CentresQuery.isLoading  ? 'chargement...' :
                <>
                    <TimedAutoCompletionMulti onError={false} Choices={CentresQuery.data ? CentresQuery.data.map(c=> {
                        return {id:c.id, libelle:c.name}
                    }) : []} setFieldValue={HandleChoiceCentre} Current={CentreCurrent ? CentreCurrent.name : ''} placeHolder={"Centre"}/>
                    <div className={"user_wrap_choices"}>
                    {!CentreCurrent ?
                        <div className={`noCentre`}>En attente d'un centre</div>:
                        UsersQuery.isLoading ? 'chargement des users...':
                            <div className="choice_users">
                                {listAdmin.map((user:User)=>(
                                    <div className={`one_choice_user`} key={`OcU${user.id}`} onClick={()=>setUserShadowCurrent(user)}>
                                        {user.prenom} {user.nom}
                                    </div>
                                ))}
                            </div>
                    }
                    </div>
                    {UserShadowCurrent &&
                        <div className={`choix_user_def`}>
                            <button type={"button"} className={`btn_choix`} disabled={mutation.isLoading} onClick={handleClickConnexion}>
                                {mutation.isLoading ?
                                    <div className={`loading_btn`}>
                                        <DNA
                                            visible={true}
                                            height="30"
                                            width="30"
                                            ariaLabel="dna-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="dna-wrapper"
                                        />
                                        Connexion en cours...
                                    </div>:
                                    <div>Se connected en temps que <br/>{UserShadowCurrent.prenom} {UserShadowCurrent.nom}</div>
                                }
                            </button>
                        </div>
                    }
                    {Error!=='' &&
                        <div className={"Error"}>{Error}</div>
                    }
                </>
            }
        </div>
    )
}

export const FormShadowConnect = styled(FormShadowConnectCtrl)`
  .wrap_cancel{
    margin: 15px auto;
  }
  .noCentre{
    text-align: center;
    font-weight: bold;
  }
   .user_wrap_choices{
     margin: 15px auto;
     .one_choice_user{
       padding: 0.25rem 0.5rem;
       border-radius: 6px;
       font-size: 14px;
       &:hover{
         cursor: pointer;
         font-weight: bold;
         background: ${props => props.theme.PrimaryMegaLight};
       }
     }
   } 
  .btn_choix{
    width: 100%;
    border-radius: 6px;
    padding: 0.25rem;
    display: block;
    border: solid 1px ${props=>props.theme.Secondary};
    background: ${props=>props.theme.SecondaryExtraLight};
    color: ${props=>props.theme.SecondaryExtraDark};
    &:hover{
      cursor: pointer;
      filter: brightness(95%);  
    }
    &:disabled{
      
    }
  }
  .btn_cancel{
    width: 100%;
    font-weight: bold;
    border-radius: 6px;
    padding: 0.5rem 0.25rem;
    display: block;
    border: none;
    background: ${props=>props.theme.ComplementaryExtraLight};
    color: ${props=>props.theme.ComplementaryExtraDark};
    &:hover{
      cursor: pointer;
      filter: brightness(95%);
    }
    &:disabled{

    }
  }
  .loading_btn{
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
`