import styled from "styled-components";
import {useGetDetailsEvents} from "../../../../../../../features/tempsTravail/tempsTravail.hooks";
import {TimedLoading} from "../../../../../../../components/atoms/TimedWaitUser/TimedLoading";
import {useGetUserRubriqueByUser} from "../../../../../../../features/userRubriqueSpe/userRubriqueSpe.hooks";
import {useModal} from "../../../../../../../hooks/useModal";
import {TimedModalCentral2} from "../../../../../../../components/molecules/TimedModal/TimedModalCentral2";
import {FormNewEvt} from "../../../../../../Forms/FormNewEvt";
import {useGetAllRubrique} from "../../../../../../../features/rubriqueSpe/rubriqueSpe.hooks";
import React, {useEffect, useMemo, useState} from "react";
import {FormAddRubSpeToUser} from "../../../../../../Forms/FormAddRubSpeToUser";
import {ModalGereOneEvt} from "../../../../../../Modaux/ModalGereOneEvt";
import {format} from "date-fns";
import TimedButton from "../../../../../../../components/atoms/TimedButton/TimedButton";
import TimedButtoOutline from "../../../../../../../components/atoms/TimedButton/TimedButtoOutline";


interface TempsTrEventsCtrlProps{
    className?:string;
    user:User;
    calendrier:CalendrierPaie
}

const TempsTrEventsCtrl = (props:TempsTrEventsCtrlProps)=>{
    const EventsQuery = useGetDetailsEvents(props.calendrier.id, props.user.id)
    const RubQuery = useGetUserRubriqueByUser(props.user.id)
    const RubriqueSpeQuery = useGetAllRubrique();
    const {open:OpenNewEvt, toggle:toggleNewEvt} = useModal()
    const {open:OpenNewRubrique, toggle:toggleNewRubrique} = useModal()
    const {open:openOneEvt, toggle:toggleOneEvt} = useModal()
    const [EvtCurr, setEvtCurr] = useState<EventCollab|null>(null);
    const [myRub, setMyRub] = useState<UserRubriqueSpe[]>([])
    useEffect(() => {
        if(RubQuery.data){
            setMyRub(RubQuery.data.filter(r=>r.RubriqueSpe.isJournalPaie))
        }
    }, [RubQuery.data]);
    const listToAdd = useMemo(()=>{
        let list:RubriqueSpe[] = [];
        if(RubriqueSpeQuery.data && RubQuery.data){
            const idsExist = RubQuery.data.map(ur=>ur.RubriqueSpe.id);
            list = RubriqueSpeQuery.data.filter(r=>idsExist.indexOf(r.id)===-1);
        }
        return list;
    }, [RubriqueSpeQuery.data, RubQuery.data])
    const ClickOnEvent = (ev:EventCollab)=>{
        setEvtCurr(ev)
        toggleOneEvt();
    }
    return (
        <div className={`tempsTrEvents ${props.className}`}>
            {(EventsQuery.isLoading || RubQuery.isLoading) ?
                <div className={`wrap_load`}><TimedLoading width={"60px"}/></div>:
                (!EventsQuery.data || !RubQuery.data) ?
                    <div className="error_data">Erreur data</div>:
                    <div className="content_events">
                        <div className="Events">
                            <h3 className={"subCat"}>Evénements</h3>
                            <div className="list_events">
                                <table className={`table_in_temps`}>
                                    <thead>
                                    <tr>
                                        <th className={"left"}>Date</th>
                                        <th className={"left"}>Type</th>
                                        <th>Montant</th>
                                        <th className={"left"}>Details</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {EventsQuery.data.length === 0 ? <tr><td colSpan={4} className={`no_data`}>Aucun évènement</td></tr>:
                                        EventsQuery.data.map((evt)=>(
                                            <tr className={`line_evt`} key={`Evt${evt.id}`} onClick={()=>ClickOnEvent(evt)}>
                                                <td className="dates">{format(new Date(evt.eventAt.slice(0,10)+"T12:00:00"), "dd/MM")}</td>
                                                <td className="type">{evt.TypeEvent.libelle}</td>
                                                <td className="montant center">{evt.montant ? evt.montant : "--"}</td>
                                                <td className="Details">{evt.details ? evt.details : "--"}</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="wrap_action">
                                <TimedButtoOutline size={"sm"} themeColor={"Primary"} text={"Ajouter un évènement"} onClick={toggleNewEvt}/>
                            </div>
                        </div>
                        <div className="Rubriques">
                            <h3 className={"subCat"}>Rubriques</h3>
                            <div className="list_rubs">
                                <table className={`table_in_temps`}>
                                    <thead>
                                    <tr>
                                        <th className={"left"}>Type</th>
                                        <th className={"left"}>Valeur</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {myRub.length === 0 ? <tr><td colSpan={2} className={`no_data`}>Aucune rubrique</td></tr>:
                                        myRub.map((rub)=>(
                                            <tr key={`line_rub${rub.id}`}>
                                                <td className={"left"}>{rub.RubriqueSpe.libelle}</td>
                                                <td className={"left"}>{rub.value}</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                            {listToAdd.length >0 &&
                                <div className="wrap_action">
                                    <TimedButtoOutline size={"sm"} themeColor={"Primary"} text={"Ajouter une rubrique"} onClick={toggleNewRubrique}/>
                                </div>
                            }
                        </div>
                    </div>
            }
            <TimedModalCentral2 closeMe={toggleNewEvt} Wd={"350px"} open={OpenNewEvt} Pending={false}
                                titre={"Nouvel évènement"}>
                <FormNewEvt closeMe={toggleNewEvt} user={props.user}/>
            </TimedModalCentral2>
            <TimedModalCentral2 closeMe={toggleNewRubrique} Wd={"350px"} open={OpenNewRubrique} Pending={false} titre={"Nouvelle rubrique"}>
                <FormAddRubSpeToUser user={props.user} listToAdd={listToAdd} closeMe={toggleNewRubrique}/>
            </TimedModalCentral2>
            <TimedModalCentral2 closeMe={toggleOneEvt} open={openOneEvt} Pending={false} Wd={"350px"} titre={"Gestion Evènement"}>
                {EvtCurr &&
                    <ModalGereOneEvt Event={EvtCurr} closeMe={toggleOneEvt}/>
                }
            </TimedModalCentral2>
        </div>
    )
}

const TempsTrEvents = styled(TempsTrEventsCtrl)`
    flex-grow: 1;
    background: white;
    .wrap_action{
        margin: 15px 0;
    }
    h3{
        margin-bottom: 20px;
    }
    .content_events{
        
        padding: 1rem;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 30px;
    }
`

export default TempsTrEvents