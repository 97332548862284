import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {ErrorInput} from "../TimedFormInteractif/styleInters";

interface TimedInputText324CtrlProps {
    className?:string;
    Current:string;
    setVal:(s:string, id:string)=>void;
    Error:string|null;
    label:string;
    id:string;
}

const TimedInputText324Ctrl = ({className, setVal, Current, Error, label, id}: TimedInputText324CtrlProps) => {
    const [myText, setMyText] = useState<string>(Current)
    const refI = useRef<HTMLInputElement>(null);
    const [focus, setFocus] = useState<boolean>(false);
    useEffect(() => {
        setMyText(Current);
    }, [Current]);
    const onChange = ()=>{
        if(refI.current) setMyText(refI.current.value)
    }
    const Blurry = ()=>{
        setVal(myText, id)
        setFocus(false);
    }
    const Fucusy = (e:any)=>{
        if(e.target.current) e.target.current.select();
        setFocus(true);
    }
    return (
        <div className={`timed_input_text324 ${className} ${Error ? 'on_error' : ''} ${focus ? "focused" : ""}`}>
            <label>{label}</label>
            <input type={"text"} ref={refI} value={myText} onFocus={Fucusy} onBlur={Blurry} onChange={onChange}/>
            {Error &&
                <ErrorInput>{Error}</ErrorInput>
            }
        </div>
    )
}

export const TimedInputText324 = styled(TimedInputText324Ctrl)`
    width: 100%;
    position: relative;
    label{
        font-weight: bold;
        display: block;
        font-size: 14px;
        margin-bottom: 2px;
    }
    &:hover{
        input{
            cursor: pointer;
            filter: brightness(97%);
        }
    }
    &.on_error{
        input{
            border:solid ${props => props.theme.Warning} 1px;
        }
    }
    &.focused{
        input{
            border:solid ${props => props.theme.PrimaryLight} 1px;
        }
    }
    input{
        border-radius: 4px;
        background: ${props => props.theme.colorBackInput};
        border:solid transparent 1px;
        height: 36px;
        padding-left: 5px;
        width: 100%;
    }
`