import {useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import {ListChoice} from "./styleInters";

interface TimedListChoiceProps{
    open:boolean;
    closeMe:()=>void;
    x:number,
    y:number,
    Choices:Choice[],
    setChoice:(c:Choice)=>void;
    id:string;
    Zindex?:number;
}

export const TimedListChoice = ({open, x, y, Choices, setChoice, id, closeMe, Zindex}:TimedListChoiceProps)=>{
    const refObjt = useRef<HTMLDivElement>(null)
    useEffect(() => {
        function handleClickOutside(event:any) {
            const myIdInputRef = document.getElementById("input"+id)
            if (refObjt.current && !refObjt.current.contains(event.target) && (!myIdInputRef || !myIdInputRef.contains(event.target))) {
                closeMe();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObjt, id]);
    if(!open){
        return null;
    }
    return ReactDOM.createPortal(
        <ListChoice ref={refObjt} style={{left:x+"px", top:y+"px", zIndex:Zindex ? Zindex : 4}}>
            {Choices.filter(c=>c.libelle!=='').map(item=>(
                <div onClick={()=>setChoice(item)} className={`one_choice_inter`} key={`ChoiceInt${id}${item.id}`}>{item.libelle}</div>
            ))}
        </ListChoice>, document.body
    )
}