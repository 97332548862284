import styled from "styled-components";
import {FaRegCopy} from "react-icons/fa";

interface HandleCopyDraggyCtrlProps{
    className?:string;
    plage:Plage;
}

const HandleCopyDraggyCtrl = (props:HandleCopyDraggyCtrlProps)=>{

    return (
        <div className={`handleCopyDraggy ${props.className}`}>
            <FaRegCopy/>
        </div>
    )
}

const HandleCopyDraggy = styled(HandleCopyDraggyCtrl)`
    background: rgba(255,255,255,.6);
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    svg{
        margin: auto;
    }
`

export default HandleCopyDraggy