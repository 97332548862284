export function GetToDoMonthUntilDate(MyDateStopCome:Date, Contrats:Contrat[], daysOff:DayOff[]){
    let MyDateStart = new Date(MyDateStopCome.getTime());
    let MyDateStop = MyDateStopCome;
    MyDateStart.setHours(0,0);
    MyDateStop.setHours(0,0);
    MyDateStart.setDate(1);
    let nbDay = 0;
    let starty = MyDateStart;
    const offs = daysOff.map(off=>new Date(off.offAt).getTime())
    while(starty<=MyDateStop){
        if(starty.getDay()!==0 && offs.indexOf(starty.getTime()) === -1){
            nbDay++;
        }
        starty = new Date(starty.getTime() + 86400000);
    }
    if(Contrats.length === 0){
        return 0
    } else {
        const ContratCurr = Contrats.find(c=>c.endAt === null);
        if(!ContratCurr){
            return 0;
        } else {
            const Qte = ContratCurr.quantite;
            const unite = ContratCurr.uniteTps;
            const LibRef = ContratCurr.RefTempsTravail.libelle;
            let coefTps = 6;
            let coefUnite = 1;
            let nbRef = 0;
            if(LibRef === 'Semaine'){
                nbRef = Qte/6 * nbDay
            }else if(LibRef === 'Mois'){
                coefTps = 12*4.33*6;
                nbRef = Qte;
            }else if(LibRef === 'Année'){
                coefTps = 12*4.33*6;
                if(unite === 'j'){
                    nbRef = (Qte*8/12);
                } else if(unite === 'dj'){
                    nbRef = (Qte*8/24);
                }
            }
            if(unite === 'j'){
                coefUnite = 8;
            } else if(unite === 'dj'){
                coefUnite = 4;
            }
            return Math.round(nbRef*100)/100
            //return Math.round(((Qte*coefUnite)/coefTps)*nbDay*100)/100;
        }
    }
}
export function GetGoodContractDate(Contrats:Contrat[], dateLimit:Date){
    const myDate = dateLimit;
    myDate.setHours(12,0,0);

    // const myDate = new Date(dateLimit.setHours(0,0,0));
    if(Contrats.length ===0){
        return null
    }
    let ContratsGoodEntry = Contrats.filter(c=>new Date(c.startAt.slice(0,10))<=myDate);
    if(ContratsGoodEntry.length === 0){
        return null
    }
    let ContratsGoodEndy = ContratsGoodEntry.filter(c=>!c.endAt || new Date(c.endAt.slice(0,10)) >= myDate);
    if(!ContratsGoodEndy){
        return null;
    }
    return ContratsGoodEndy[0];

}

export function GetToDoWeek(Contrats:Contrat[]){
    if(Contrats.length === 0){
        return 0
    } else {
        const ContratCurr = Contrats.find(c=>c.endAt === null);
        if(!ContratCurr){
            return 0;
        } else {
            const Qte = ContratCurr.quantite;
            const unite = ContratCurr.uniteTps;
            const LibRef = ContratCurr.RefTempsTravail.libelle;
            let coefTps = 1;
            let coefUnite = 1;
            if(LibRef === 'Mois'){
                coefTps = 4.333;
            }else if(LibRef === 'Année'){
                coefTps = 12*4.333;
            }
            if(unite === 'j'){
                coefUnite = 8;
            } else if(unite === 'dj'){
                coefUnite = 4;
            }
            return Math.round((Qte*coefUnite)/coefTps*100)/100;
        }
    }
}

export function GetToDoWeekByContrat(ContratCurr:Contrat|null){
    if(!ContratCurr){
        return 0
    }
    const Qte = ContratCurr.quantite;
    const unite = ContratCurr.uniteTps;
    const LibRef = ContratCurr.RefTempsTravail.libelle;
    let coefTps = 1;
    let coefUnite = 1;
    if(LibRef === 'Mois'){
        coefTps = 4.333;
    }else if(LibRef === 'Année'){
        coefTps = 12*4.333;
    }
    if(unite === 'j'){
        coefUnite = 8;
    } else if(unite === 'dj'){
        coefUnite = 4;
    }
    return Math.round((Qte*coefUnite)/coefTps*100)/100;
}