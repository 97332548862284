import styled from "styled-components";
import LibelleSetting from "./LibelleSetting";
import {useRef} from "react";
import ErrorInputSetting from "./ErrorInputSetting";
import BtnOpenHelp from "./BtnOpenHelp";
import WrapHelp from "./WrapHelp";

interface InputSelectSettingCtrlProps{
    className?:string;
    label:string;
    choices:Choice[];
    Current:Choice|null;
    placeNoChoice?:string;
    id:string;
    setValue:(Choice:Choice|string|number|null, id:string)=>void;
    Error:string|null;
    ItemInfos?:InfosProps;
    disabled?:boolean;
    disInfo?:string;
    wdLib?:string;
}

const InputSelectSettingCtrl = (props:InputSelectSettingCtrlProps)=>{
    const refS = useRef<HTMLSelectElement>(null)

    const handleChange = ()=>{
        console.log("changer")
        if (refS.current){
            const my = props.choices.find(c=>c.id.toString() === refS.current?.value )
            console.log(my);
            if(my) props.setValue(my, props.id);
        }
    }
    return (
        <div className={`inputSelectSetting wrap_input_setting ${props.className}`}>
            <div className="in_wrap_input_setting">
                <LibelleSetting libelle={props.label} wdLib={props.wdLib}/>
                <div className="wrap_input_in">
                    <select className={"select_type_setting"} value={props.Current ? props.Current.id : -1} onChange={handleChange} ref={refS} disabled={props.disabled}>
                        {props.placeNoChoice &&
                            <option value={-1}>{props.placeNoChoice}</option>
                        }
                        {props.choices.map(item=>(
                            <option value={item.id} key={`Choice${props.id}${item.id}`}>{item.libelle}</option>
                        ))}
                    </select>
                </div>

                {(props.ItemInfos && !props.disabled) &&
                    <WrapHelp setValue={props.setValue} choices={props.choices} Infos={props.ItemInfos} Current={props.Current} placeNoChoice={props.placeNoChoice}/>
                }
                {(props.disabled && props.disInfo) &&
                    <div className="infos_dis">{props.disInfo}</div>
                }
            </div>
            <ErrorInputSetting Error={props.Error}/>
        </div>
    )
}

const InputSelectSetting = styled(InputSelectSettingCtrl)`
    .infos_dis{
        font-style: italic;
        color: red;
    }
`

export default InputSelectSetting