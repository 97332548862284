import styled from "styled-components";
import React, {useMemo} from "react";
import {format} from "date-fns";
import {TimedUserAvatar} from "../../../../../../../../components/molecules/_TimedUserAvatar/TimedUserAvatar";
import {SwitchDayWorkContrat} from "../../../../../../AdministrationRH/FicheSimple/components/SwitchDayWorkContrat";
import BlockResumeTimes from "./BlockResumeTimes";

interface UserCardTempsTrCtrlProps{
    className?:string;
    calendrier:CalendrierPaie;
    User:User;
}
const TabDays = ["L", "M", "Me", "J", "V", "S", "D"];
const UserCardTempsTrCtrl = (props:UserCardTempsTrCtrlProps)=>{

    const listContrat = useMemo(()=>{
        const list: { contrat:Contrat, libelle:string }[] = []
        const contrats = props.User.contrats;
        let myEnd = new Date(props.calendrier.stopAt.slice(0,10)+"T12:00:00");
        myEnd = new Date(myEnd.setHours(23,59,59));
        const myFrom = new Date(props.calendrier.startAt.slice(0,10)+"T12:00:00");

        const list2:{contrat:Contrat, Start:Date, Stop:Date, startLib:string, stopLib:string}[] = [];
        contrats.forEach(c=>{
            const Start = new Date(c.startAt.slice(0,10)+"T12:00:00");
            const Stop = c.endAt ? new Date(c.endAt.slice(0,10)+"T12:00:00") : new Date("2099-01-01 12:00:00");
            const StartLib= Start<myFrom ? format(myFrom, "dd/MM/yyyy") : format(Start, "dd/MM/yyyy");
            const StopLib = Stop>myEnd ? format(myEnd, "dd/MM/yyyy") : format(Stop, "dd/MM/yyyy");
            if((Start<myEnd) && (Stop>=myFrom)){
                list2.push({contrat:c, Start:Start, Stop:Stop, startLib:StartLib, stopLib:StopLib})
            }
        })
        list2.forEach(liC=>{
            list.push({
                contrat:liC.contrat,
                libelle:list2.length === 1 ? '' : `${liC.startLib}-${liC.stopLib}`
            })
        })
        return list;
    }, [props.User, props.calendrier])

    return (
        <div className={`userCardTempsTr ${props.className}`}>
            <div className={`content_block_user`}>
                <TimedUserAvatar User={props.User} CustomSize={"60px"} isAddFonction={true}
                                 CustomFontSize={"22px"}/>
                <div className="content_infos_block">
                    <div className="one_Info">
                        <div className="title_info">Jours habituellement travaillés</div>
                        <div className={`content_one_info`}>
                            {listContrat.map((item) => (
                                <div className="wrapperJtr" key={`jt${item.contrat.id}`}>
                                    {TabDays.map((keyDay: string, idx: number) => (
                                        <SwitchDayWorkContrat wid={18} isLectureSeule={true} contrat={item.contrat}
                                                              key={`DayW${idx}${item.contrat.id}`} keyDay={keyDay}/>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="one_Info">
                        <div className="title_info">Contrats</div>
                        <div className={`content_one_info`}>
                            {listContrat.map((item) => (
                                <div className="one_contrat" key={`onCt${item.contrat.id}`}>
                                    {item.libelle} {item.contrat.TypeContrat.libelle} {item.contrat.quantite}{item.contrat.uniteTps}/{item.contrat.RefTempsTravail.libelle}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <BlockResumeTimes User={props.User} calendrier={props.calendrier}/>
            </div>
        </div>
    )
}

const UserCardTempsTr = styled(UserCardTempsTrCtrl)`
    .wrapperJtr{
        display: flex;
        gap:4px;
    }
    .content_block_user{
        display: flex;
        justify-content: flex-start;
        gap:80px;
        align-items: center;
    }
    .content_infos_block{

    }
    .one_Info{
        margin-bottom: 12px;
    }
    .title_info{
        font-weight: bold;
        color:${props=>props.theme.Primary};
        margin-bottom: 4px;
    }
`

export default UserCardTempsTr