import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import TimedButton from "../../../../../../../../../components/atoms/TimedButton/TimedButton";
import {ApplicCoef} from "../calculator/CalculatorCoef";

interface CrediterCompteurTempsCtrlProps{
    className?:string;
    DCoef:ApplicCoef;
    time:number;
    timeTo:number;
    ValideTime:(t:number)=>void;
    isPending:boolean;
}

const CrediterCompteurTempsCtrl = (props:CrediterCompteurTempsCtrlProps)=>{
    const [myNbTo, setMyNbTo] = useState<string>(Math.round(props.timeTo*100)/100+"")
    const refInput = useRef<HTMLInputElement>(null)
    useEffect(() => {
        console.log("Changement")
        console.log(props.timeTo)
        setMyNbTo(Math.round(props.timeTo*100)/100+"")
    }, [props.timeTo]);
    const handleChange = ()=>{
        console.log("ici")
        if(refInput.current){
            const val = refInput.current.value.replace(',', '.')
            if (/^\d*\.?\d*$/.test(val)) {
                setMyNbTo(val)
            }
        }
    }
    const Valide = ()=>{
        const valFinal = parseFloat(myNbTo);
        if(valFinal<props.time) props.ValideTime(valFinal);
    }
    return (
        <div className={`crediterCompteurTemps ${props.className}`}>
            <p>Compte tenu de votre paramètrage et du fait que {props.DCoef.explication}<br/> voici la composition du crédit d'heure sur une base de <strong>{Math.round(props.time * 100) / 100} HS</strong></p>
            <ul>
                {props.DCoef.Details.map((item, idx)=>(
                    <li key={`DetailsC${idx}`}>{item.descr}, majoration de <strong>{item.coef}</strong> soit un total de <strong>{Math.round(item.total * 100) / 100}h</strong></li>
                ))}
            </ul>
            <p>Le crédit est selon Timed de <strong>{Math.round(props.timeTo * 100) / 100}h</strong></p>
            <p>Cependant vous pouvez changer selon vos propres calcul {myNbTo}</p>
            <div className="wrap_inputs">
                <label>Total Crédit</label>
                <div className="wrap_input">
                    <input ref={refInput} type={"text"} value={myNbTo+""} onChange={handleChange} disabled={props.isPending}/>
                </div>
            </div>
            <div className="wrap_send">
                <TimedButton size={"sm"} themeColor={"Primary"} text={"Enregistrer"} isPending={props.isPending} disabled={props.isPending} onClick={Valide}/>
            </div>
        </div>
    )
}

const CrediterCompteurTemps = styled(CrediterCompteurTempsCtrl)`
    .wrap_send{
        padding: 1rem;
        display: flex;
        justify-content: center;
    }
    p{
        text-align: left!important;
    }
    ul{
        margin: 30px 0 30px 20px;
    }
    label{
        display: block;
        font-weight: bold;
        margin-bottom: 8px;
        text-align: center;
    }
    .wrap_input{
        display: flex;
        justify-content: center;
    }
    input{
        border-radius: 4px;
        text-align: center;
        background:${props=>props.theme.colorBackInput};
        border: none;
        height: 38px;
        width: 250px;

    }
`

export default CrediterCompteurTemps