import styled from "styled-components";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useModifyEventCollab, useTrashEventCollab} from "../../features/eventCollab/eventCollab.hooks";
import {VerifErrorString} from "../../components/atoms/TimedFormInteractif/Verifs";
import {TimedInputInter} from "../../components/atoms/TimedFormInteractif/TimedInputInter";
import {TimedAutoCompleteInter} from "../../components/atoms/TimedFormInteractif/TimedAutoCompleteInter";
import {TimedTextAreaInter} from "../../components/atoms/TimedFormInteractif/TimedTextAreaInter";
import {LabelInter} from "../../components/atoms/TimedFormInteractif/styleInters";
import TimedSwitch from "../../components/atoms/TimedSwitch/TimedSwitch";
import {useGetAllTypeEvent} from "../../features/typeEvent/typeEvent.hooks";
import {TimedOneDocumentCard} from "../../components/atoms/TimedDocument/TimedOneDocumentCard";
import TimedIconButton from "../../components/atoms/TimedIconButton/TimedIconButton";
import {BsTrash} from "react-icons/bs";
import {useGetOneDocument} from "../../features/userDocument/userDocument.hooks";
import {UserDocument} from "../../features/userDocument/userDocument";
import {IoCloudDownload} from "react-icons/io5";
import ModalConfirmation from "./ModalConfirmation";
import {useModal} from "../../hooks/useModal";
import {TimedInputDate324} from "../../components/atoms/TimedForm324/TimedInputDate324";
import {TimedSelect324} from "../../components/atoms/TimedForm324/TimedSelect324";
import {TimedInputNumber324} from "../../components/atoms/TimedForm324/TimedInputNumber324";

interface ModalGereOneEvtCtrlProps {
    className?:string;
    Event:EventCollab;
    closeMe:()=>void;
}

interface MyErrors{
    eventAt:string|null,
    TypeEvt:string|null,
    TypeDoc:string|null
    montant:string|null
}

const DefaultErrors:MyErrors ={
    eventAt:null,
    TypeEvt:null,
    TypeDoc:null,
    montant:null,
}

interface StereoEvt{
    eventAt:string,
    TypeEvt:Choice|null,
    Document:UserDocument|null,
    isPaie:boolean,
    details:string,
    ready:boolean,
    montant:number
}
const DefaultStereoEvt:StereoEvt={
    eventAt:'',
    montant:0,
    TypeEvt:null,
    isPaie:false,
    details:'',
    ready:false,
    Document:null,
}


const ModalGereOneEvtCtrl = ({className, Event, closeMe}: ModalGereOneEvtCtrlProps) => {
    const mutation = useModifyEventCollab(Event.id);
    const mutationTrash = useTrashEventCollab();
    const [myEvt, setMyEvt] = useState<StereoEvt>(DefaultStereoEvt)
    const DocumentQuery = useGetOneDocument(Event.Document ? Event.Document.id : 0);
    const [isDirty, setIsDirty] = useState(false);
    const [myErrors, setMyErrors] = useState<MyErrors>(DefaultErrors)
    const TypeEventsQuery = useGetAllTypeEvent();
    const {open, toggle} = useModal();
    const TabNeed:MyConstraint[] = [
        {id:'eventAt', required:true},
        {id:'TypeEvt', required:true},
    ];
    const listChoiceTypeEvents = useMemo(()=>{
        if(!TypeEventsQuery.data){
            return []
        } else {
            return TypeEventsQuery.data.map(te=>{
                return {id:te.id, libelle:te.libelle}
            })
        }
    }, [TypeEventsQuery.data])
    const getIsMontant = useMemo(()=>{
        if(!TypeEventsQuery.data || !myEvt.TypeEvt){
            return false
        } else {
            const myTypeEvent = TypeEventsQuery.data?.find(t=>t.id === myEvt.TypeEvt?.id)
            if(myTypeEvent){
                return myTypeEvent.isMontant
            } else {
                return false
            }
        }
    }, [myEvt, TypeEventsQuery.data])
    useEffect(() => {
        console.log(isDirty ? "is dirty" : "isn't dirty")
    }, [isDirty]);
    useEffect(() => {
        setMyEvt({
            eventAt:Event.eventAt.slice(0,10),
            TypeEvt:{id:Event.TypeEvent.id, libelle:Event.TypeEvent.libelle},
            isPaie:Event.isPaie,
            details:Event.details,
            ready:true,
            Document:Event.Document,
            montant:Event.montant
        })
    }, [Event]);
    const setSelect = (elt:Choice|string|number|null, id:string)=>{
        if(id === 'TypeEvt'){
            const myVal = elt as Choice;
            const libelle = myVal.libelle
            const ErrorRetour = VerifErrorString(id, libelle, TabNeed)
            setMyErrors(e=>{
                return {...e, [id]:ErrorRetour==='' ? null : ErrorRetour}
            })
            setMyEvt(p=> {
                return {...p, [id]: elt as Choice}
            });
            if(ErrorRetour ===''){
                setMyErrors(e=>{
                    return {...e, [id]:ErrorRetour}
                })
                console.log("case 4")
                setIsDirty(true)
            }
        } else if(id === 'montant'){
            const myVal = elt as number;
            if(myVal<0){
                setMyErrors(e=>{
                    return {...e, [id]:"le montant doit être positif"}
                })
                setMyEvt(p=> {
                    return {...p, [id]: myVal}
                });
            } else {
                setMyErrors(e=>{
                    return {...e, [id]:null}
                })
                setMyEvt(p=> {
                    return {...p, [id]: myVal}
                });
                console.log("case 3")
                setIsDirty(true);
            }

        } else {
            const myVal = elt as string;
            const ErrorRetour = VerifErrorString(id, myVal, TabNeed)
            setMyErrors(e=>{
                return {...e, [id]:ErrorRetour==='' ? null : ErrorRetour}
            })
            setMyEvt(p=> {
                return {...p, [id]: elt as string}
            });
            if(ErrorRetour ===''){
                setMyErrors(e=>{
                    return {...e, [id]:ErrorRetour}
                })
                if(myVal !== myEvt[id as keyof StereoEvt]) {
                    console.log("case y " + id)
                    setIsDirty(true)
                }
            }
        }
    }
    const downLoad = useCallback(()=>{
        if(DocumentQuery.data){
            window.open(`${process.env.REACT_APP_API_URL_ROOT}${DocumentQuery.data.documentUrl}`);
        }
    }, [DocumentQuery.data])
    const TrashFile = ()=>{
        setMyEvt(ev=>{
            return {...ev, Document:null}
        })
        console.log("case 1")
        setIsDirty(true);
    }
    const TrashMe = ()=>{
        toggle();
        mutationTrash.mutate(Event.id, {
            onSuccess:()=>{
                closeMe();
            }
        })
    }
    useEffect(()=>{
        if(isDirty){
            console.log("call is dirty")
            const myDatas:EventCollabPut = {
                TypeEvent:`/api/type_events/${myEvt.TypeEvt?.id||0}`,
                eventAt:myEvt.eventAt,
                details:myEvt.details,
                isPaie:myEvt.isPaie
            }
            if(!myEvt.Document){
                myDatas.Document = null;
            }
            if(getIsMontant){
                myDatas.montant = myEvt.montant
            }
            mutation.mutate(myDatas, {
                onSuccess:()=>{
                    setIsDirty(false);
                }
            })
        }
    }, [myEvt, isDirty])
    return (
        <div className={`modal_gere_one ${className}`}>
            {!myEvt.ready ? 'chargement' :
                <>
                    <div className="wrap_inputsForm">
                        <TimedInputDate324 Error={myErrors.eventAt} label={"Date de l'évènement"} id={"eventAt"} Current={myEvt.eventAt as string} setVal={setSelect} isDisabled={false}/>
                        <TimedSelect324
                            id={"TypeEvt"}
                            Choices={[{id: -1, libelle: ''}, ...listChoiceTypeEvents]}
                            Current={myEvt.TypeEvt ? {id: myEvt.TypeEvt.id, libelle: myEvt.TypeEvt.libelle} : {
                                id: -1,
                                libelle: ''
                            }}
                            setValue={setSelect}
                            Error={myErrors.TypeEvt}
                            label={"Type évènement"}
                        />
                        {getIsMontant &&
                            <TimedInputNumber324 Error={myErrors.montant} label={"Montant"} id={"montant"} Current={myEvt.montant} setVal={setSelect}/>
                        }
                        <TimedTextAreaInter id={"details"} label={"Détails"} setNew={setSelect} Current={myEvt.details}
                                            height={"100px"}/>
                        <div className="input_wrap">
                            <LabelInter>Inclure dans la paie</LabelInter>
                            <TimedSwitch
                                setStatut={() => {
                                    setMyEvt(ev => {
                                        return {...ev, isPaie: !ev.isPaie}
                                    })
                                    console.log("case x")
                                    setIsDirty(true)
                                }}
                                statut={myEvt.isPaie}
                                themeColor={"Primary"}
                            />
                        </div>
                    </div>
                    {Event.Document &&
                        <div className={`my_doc`}>
                            <h5>Document associé</h5>
                            <div className="wrap_gere_doc">
                                <div className="my_doc">
                                    <TimedOneDocumentCard Type={"Line"} Document={Event.Document}/>
                                </div>
                                <div className="wrap_actions">
                                    <TimedIconButton isSquare={true} isActive={true} onClick={downLoad} size={"sm"}
                                                     Icon={<IoCloudDownload/>} themeColor={"Secondary"}
                                                     toolTip={"télécharger"}/>
                                    <TimedIconButton isSquare={true} isActive={true} onClick={TrashFile} size={"sm"}
                                                     Icon={<BsTrash/>} themeColor={"Warning"}
                                                     toolTip={"Supprimer Document"}/>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
            <div className="action_in">
                {!mutation.isLoading &&
                    <>

                        <TimedIconButton isActive={true} onClick={toggle} size={"sm"} Icon={<BsTrash/>}
                                         toolTip={"Supprimer"} themeColor={"Warning"} isSquare={true}
                                         isPending={mutationTrash.isLoading}/>
                    </>
                }
            </div>
            {open &&
                <ModalConfirmation
                    text={"Êtes vous sur de vouloir supprimer cet Évènement ?"}
                    callBackConfirm={TrashMe}
                    Close={toggle}
                    isPending={mutationTrash.isLoading}
                />
            }
        </div>
    )
}

export const ModalGereOneEvt = styled(ModalGereOneEvtCtrl)`
    position: relative;
    padding-bottom: 60px;
    .wrap_inputsForm {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 15px;
    }

    .wrap_gere_doc {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .wrap_actions {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            gap: 6px;
        }
    }
    .action_in{
        position: absolute;
        bottom: 5px;
        right: 5px;
        display: flex;
        justify-content: flex-end;
        gap: 6px;
    }
`