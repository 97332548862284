import styled, {keyframes} from "styled-components";
import {useContext, useEffect, useState} from "react";
import {ContextPlanning} from "../WeeklyCalendar";
import {SkeletonWait, WhiteShadow} from "./ElementCalendar";
import {ConvertBase5} from "../../../functions/TimesFunctions";


interface OnePlageSTTempoCtrlProps{
    className?:string;
    plage:Plage;
}

const OnePlageSTTempoCtrl = (props:OnePlageSTTempoCtrlProps)=>{
    const Context = useContext(ContextPlanning)
    const [myHeight, setMyHeight] = useState((props.plage.heureFin - props.plage.heureDebut)/12 * Context.config.heightHour)
    const [myTop, setMyTop] = useState((props.plage.heureDebut - Context.config.start)/12 * Context.config.heightHour)
    const [isSaving, setIsSaving] = useState(false)
    useEffect(() => {
        setMyHeight((props.plage.heureFin - props.plage.heureDebut)/12 * Context.config.heightHour)
        setMyTop((props.plage.heureDebut - Context.config.start)/12 * Context.config.heightHour)
        setIsSaving(Context.STSaving)
    }, [props.plage, Context]);
    return (
        <div className={`plage_st_tempo ${props.className}`} style={{top:myTop+"px"}}>
            <div className={`in_tempo ${Context.isActeColor ? "acteColor" : "lieuColor"}`} style={{height:myHeight+"px"}}>
                <WhiteShadow/>
                {isSaving && <SkeletonWait/>}
                <div className="Lieu_acte">{props.plage.Lieu ? props.plage.Lieu.libelle : "Nouveau"} {props.plage.Acte ? `- ${props.plage.Acte.libelle}` : ""}</div>
                <div className="horaires">{ConvertBase5(props.plage.heureDebut)} - {ConvertBase5(props.plage.heureFin)}</div>
            </div>
        </div>
    )
}



const Bouncing = keyframes`
    0%, 100% {
        transform: scale(1);

    }
    50% {
        transform: scale(1.02); /* Étirement */
    }
    
`

const OnePlageSTTempo = styled(OnePlageSTTempoCtrl)`
    position: absolute;
    width: 100%;
    padding-left: 5px;
    left:0;
    z-index: 4;
    animation: ${Bouncing} 1.3s infinite;
    .in_tempo{
        position: relative;
        width: 80%;
        box-shadow: 5px 5px 4px rgba(0,0,0,.4);
        padding: 0.5rem;
        border-radius: 4px;
        background: ${props=>props.plage.Lieu ? props.plage.Lieu.backColor : props.theme.NeutreLight};
        color: ${props=>props.plage.Lieu ? props.plage.Lieu.fontColor : props.theme.PrimaryMegaLight};
        overflow-y: hidden;
        &.acteColor{
            background: ${props=>props.plage.Acte ? props.plage.Acte.backColor : props.theme.NeutreLight};
            color: ${props=>props.plage.Acte ? props.plage.Acte.fontColor : props.theme.PrimaryMegaLight};
        }
    }
`

export default OnePlageSTTempo