import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import TimedButton from "../../../../../../../../components/atoms/TimedButton/TimedButton";
import {useModal} from "../../../../../../../../hooks/useModal";
import {TimedModalCentral2} from "../../../../../../../../components/molecules/TimedModal/TimedModalCentral2";
import ActionHybride from "./ActionHybride";
import {useAddReguleTemps} from "../../../../../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import {format} from "date-fns";
import CrediterCompteurTemps from "./Actions/CrediterCompteurTemps";
import {useGetDetailsCredit} from "./calculator/CalculatorCoef";

interface OneCumulCtrlProps{
    className?:string;
    time:number;
    dateTo:string;
    user:User;
    isAnnual:boolean;
    pos:number;
    CptHS:CompteurHS;
}


const OneCumulCtrl = (props:OneCumulCtrlProps)=>{
    const mutation = useAddReguleTemps();
    const refObj = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false);
    const {open:openHybrid, toggle:toggleHybride} = useModal()
    const {open:openCredit, toggle:toggleCredit} = useModal()
    const {timeToCredit, coefApplicable} = useGetDetailsCredit(props.user, new Date(props.dateTo), props.time)
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    const Abandonner = ()=>{
        SaveEquilibrage(4);
    }
    const Payer = ()=>{
        SaveEquilibrage(3);
    }
    const Crediter = ()=>{
        toggleCredit();
    }
    const Reporter = ()=>{
        const dateHere = new Date(props.dateTo);
        const dateNext= new Date(dateHere.getTime() + 7*86400000)
        const idMotif = props.time < 0 ? 13 : 12;
        const idMotifNext = props.time <0 ? 12 : 13;
        const datas:ReguleTpsFD={
            User:`/api/users/${props.user.id}`,
            dateAT:props.dateTo,
            dateTo:props.dateTo,
            MotifReguleTemps:`/api/motif_regule_temps/${idMotif}`,
            timeFinal:Math.abs(props.time),
            timeSystem:Math.abs(props.time),
            comentFinalHour:"Balance"
        }
        const datasNext  = {...datas, MotifReguleTemps:`/api/motif_regule_temps/${idMotifNext}`, dateAT:format(dateNext, "yyyy-mm-dd"), dateTo:format(dateNext, "yyyy-mm-dd")}
        const TabMutation = [datas, datasNext];
        TabMutation.forEach(i=>{
            mutation.mutate(i, {
                onSuccess:()=>{
                    setOpen(false)
                }
            })
        })
    }
    const Hybride = ()=>{
        //setOpen(false)
        toggleHybride();
    }
    const CloseH = ()=>{
        toggleHybride();
    }
    const SaveEquilibrage = (idMotif:number)=>{
        const datas:ReguleTpsFD={
            User:`/api/users/${props.user.id}`,
            dateAT:props.dateTo,
            dateTo:props.dateTo,
            MotifReguleTemps:`/api/motif_regule_temps/${idMotif}`,
            timeFinal:Math.abs(props.time),
            timeSystem:Math.abs(props.time),
            comentFinalHour:"Balance"
        }
        mutation.mutate(datas, {
            onSuccess:()=>{
                setOpen(false);
            }
        })
    }
    const Debiter = ()=>{
        const datas:ReguleTpsFD={
            User:`/api/users/${props.user.id}`,
            dateAT:props.dateTo,
            dateTo:props.dateTo,
            MotifReguleTemps:`/api/motif_regule_temps/15`,
            timeFinal:Math.abs(props.time),
            timeSystem:Math.abs(props.time),
            comentFinalHour:"Balance"
        }
        mutation.mutate(datas, {
            onSuccess:()=>{
                setOpen(false);
            }
        })
    }
    const ValidationCredit = (nb:number)=>{
        const Credit = props.time;
        const majo = nb - Credit;
        const idMotifCred = 14;
        const idMotifMajo = 17;
        const datas:ReguleTpsFD={
            User:`/api/users/${props.user.id}`,
            dateAT:props.dateTo,
            dateTo:props.dateTo,
            MotifReguleTemps:`/api/motif_regule_temps/${idMotifCred}`,
            timeFinal:Credit,
            timeSystem:Credit,
            comentFinalHour:"Credit initial"
        }
        const datasMajo:ReguleTpsFD = {...datas, MotifReguleTemps:`/api/motif_regule_temps/${idMotifMajo}`, timeFinal:majo, timeSystem:majo, comentFinalHour:"Majoration HS"}
        const TabMutation = [datas, datasMajo];
        TabMutation.forEach(i=>{
            mutation.mutate(i, {
                onSuccess:()=>{
                    setOpen(false)
                    toggleCredit();
                }
            })
        })
    }
    return (
        <div className={`oneCumul ${props.className} ${open ? "opened" : "closed"}`}>
            <div className="in_cumul" ref={refObj}  onClick={()=>setOpen(o=>!o)}>
                <div className={`time ${props.time > 0 ? "positive" : "negative"}`} onMouseDown={(e)=>e.stopPropagation()}>{Math.round(props.time*100)/100}</div>
                {Math.round(props.time*100)/100 !== 0 &&
                    <div className="actions">
                        <div className="list_actions">
                            {props.time<0 ?
                                <TimedButton themeColor={"Primary"} onClick={Abandonner} text={`Abandonner ${Math.abs(Math.round(props.time*100)/100)} h`} size={"sm"} isFull={true}/>:
                                <TimedButton themeColor={"Primary"} onClick={Payer} text={`Payer ${Math.abs(Math.round(props.time*100)/100)} h`} size={"sm"} isFull={true}/>

                            }
                            {(props.time < 0 && props.CptHS.solde > Math.abs(props.time)) &&
                                <TimedButton themeColor={"Primary"} onClick={Debiter} text={`Débiter le compteur HS`} size={"sm"} isFull={true}/>
                            }
                            {props.time>0 &&
                                <TimedButton themeColor={"Primary"} onClick={Crediter} text={`Créditer le compteur HS`} size={"sm"} isFull={true}/>
                            }
                            {!props.isAnnual &&
                                <TimedButton themeColor={"Primary"} onClick={Reporter} text={`Reporter ${Math.abs(Math.round(props.time*100)/100)} h`} size={"sm"} isFull={true}/>
                            }
                            <TimedButton themeColor={"Primary"} onClick={Hybride} text={`Action Personnalisée`} size={"sm"} isFull={true}/>
                        </div>
                    </div>
                }
            </div>
            <TimedModalCentral2 closeMe={toggleHybride} Wd={"30%"} open={openHybrid} Pending={false} titre={"Hybride"}>
                <ActionHybride time={props.time} closeMe={CloseH} isAnnual={props.isAnnual} user={props.user} dateTo={props.dateTo} CptHS={props.CptHS}/>
            </TimedModalCentral2>
            <TimedModalCentral2 closeMe={toggleCredit} Wd={"500px"} open={openCredit} Pending={false} titre={"Crédit"}>
                {coefApplicable &&
                    <CrediterCompteurTemps timeTo={timeToCredit} ValideTime={ValidationCredit} time={props.time} isPending={mutation.isLoading} DCoef={coefApplicable}/>
                }
            </TimedModalCentral2>
        </div>
    )
}

const OneCumul = styled(OneCumulCtrl)`
    width: 100%;
    .in_cumul{
        position: relative;    
    }
    &:hover{
        cursor: pointer;
        .time.negative{
            border-color: ${props => props.theme.ComplementaryDark};
        }
        .time.positive{
            border-color: ${props => props.theme.SecondaryDark};
        }
    }
    .actions{
        position: absolute;
        padding: 0.25rem;
        width: 280px;
        left: ${props=>props.pos > 0.9 ? "auto" : "102%"};
        right: ${props=>props.pos <= 0.9 ? "auto" : "102%"};
        background: white;
        border-radius: 4px;
        box-shadow: 0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12),0 3px 5px -1px rgba(0,0,0,.2);
        display: none;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        .list_actions{
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }
    &.opened{
        .actions{
            display: block;
        }
    }
    
    .time{
        border-radius: 4px;
        border:solid transparent 2px;
        padding: 0.25rem 0.5rem;
        background: ${props => props.theme.NeutreExtraLight};
        width: 100%;
        margin: auto;
        font-size: 18px;
        font-weight: bold;
        &.negative{
            background-image: linear-gradient(45deg, #e7c6c9 23.33%, #f7edee 23.33%, #f7edee 50%, #e7c6c9 50%, #e7c6c9 73.33%, #f7edee 73.33%, #f7edee 100%);
            background-size: 21.21px 21.21px;
            color:${props=>props.theme.ErrorExtraDark};
        }
        &.positive{
            background-image: linear-gradient(45deg, #d5e1c7 23.33%, #f2f6ee 23.33%, #f2f6ee 50%, #d5e1c7 50%, #d5e1c7 73.33%, #f2f6ee 73.33%, #f2f6ee 100%);
            background-size: 21.21px 21.21px;
            color:${props=>props.theme.SuccessExtraDark};
        }
    }
`

export default OneCumul