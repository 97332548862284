import styled from "styled-components";
import {useAddVuePlanning} from "../../../../features/vuePlanning/vuePlanning.hooks";
import {useState} from "react";
import InputTextSetting from "../components/InputTextSetting";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";

interface FormNewVpCtrlProps{
    className?:string;
    VPS:VuePlanning[];
    EndCreateVP:(vp:VuePlanning)=>void;
}

const FormNewVpCtrl = (props:FormNewVpCtrlProps)=>{
    const CentreQuery = useGetCentreActuel();
    const mutation = useAddVuePlanning();
    const [libNewVP, setLibNewVP] = useState<string>("")
    const [errorLib, setErrorLib] = useState<string|null>(null)
    const setMyValue = (value:string|null|Choice|number, id:string)=> {
        if(id === "libelle") {
            const myVal: string = value as string;
            if (props.VPS.map(a => a.libelle.toLowerCase()).indexOf(myVal)!==-1){
                setErrorLib("Cette vue planning existe déjà");
            } else if (myVal === "") {
                setErrorLib("Le nom est obligatoire");
            } else {
                setErrorLib(null)
            }
            setLibNewVP(myVal);
        }
    }
    const Submit = ()=>{

        const datas:PostVuePlanning={
            libelle:libNewVP,
            Centre:`/api/centres/${CentreQuery.data?.id||0}`
        }
        mutation.mutate(datas, {
            onSuccess:(nbVP:VuePlanning)=>{
                props.EndCreateVP(nbVP);
            }
        })
    }
    return (
        <div className={`formNewLieu ${props.className}`}>
            <div className="wrap_lib">
                <InputTextSetting current={libNewVP} setValue={setMyValue} Error={null} id={"libelle"} label={"Nom de la vue"} wdLib={"25%"}/>
            </div>
            {!!errorLib &&
                <div className="wrap_error">{errorLib}</div>
            }
            <div className="wrap_send">
                <TimedButton size={"sm"} text={"Enregistrer la vue"} themeColor={"Secondary"} disabled={!!errorLib || mutation.isLoading} onClick={Submit} isPending={mutation.isLoading}/>
            </div>
        </div>
    )
}

const FormNewVp = styled(FormNewVpCtrl)`
    wrap_error{
        color: red;
        padding: 1rem;
        text-align: center;
    }
    .wrap_lib{
        margin: 30px 0;
    }
    .wrap_send{
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
`

export default FormNewVp