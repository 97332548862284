import styled from "styled-components";
import {useDrag} from "react-dnd";

import {useEffect} from "react";
import {getEmptyImage} from "react-dnd-html5-backend";
import {ItemsTypeHebdo} from "./ItemsTypeHebdo";

interface HandleCopyCtrlProps{
    className?:string;
    plage:Plage;
    canCopy:boolean;
}

const HandleCopyCtrl = (props:HandleCopyCtrlProps)=>{
    const [{isDragging}, drag, preview] = useDrag({
        type:ItemsTypeHebdo.HANDLEcopy,
        canDrag:()=>props.canCopy,
        item: ()=>{
            return props.plage
        },
        collect: (monitor:any) => ({
            isDragging: !!monitor.isDragging(),
        }),

    }, [props.canCopy, props.plage])
    useEffect(() => { // this useEffect hides the default preview
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);
    return (
        <div ref={drag} className={`handleCopy ${isDragging ? "dragging" : ""} ${props.className}`} onMouseDown={(e)=>e.stopPropagation()}>
            <div className="sup_round">
                <div className="round_in"/>
            </div>
        </div>
    )
}

const HandleCopy = styled(HandleCopyCtrl)`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 26px;
    height: 26px;
    background: transparent;
    top: 0;
    left: 0;
    transform: translate(-40%, -40%);
    .sup_round{
        width: 16px;
        margin: auto;
        height: 16px;
        position: relative;
        border-radius: 4px;
        background: ${props => props.theme.PrimaryMegaLight} linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.06) 100%);
        border:solid 1px ${props => props.theme.Primary};
        .round_in{
            position: absolute;
            right: 20%;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            top:-4px;
            background: ${props => props.theme.PrimaryMegaLight} linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.06) 100%);
            border:solid 1px ${props => props.theme.Primary};
        }
    }
    &:hover{
        cursor: grabbing;
    }
`

export default HandleCopy