import styled from "styled-components";

import {useContext, useEffect, useState} from "react";
import {ContextPlanning, TempoPlage} from "../WeeklyCalendar";
import {SkeletonWait} from "./ElementCalendar";
import {ConvertBase5} from "../../../functions/TimesFunctions";



interface PlageTempoCtrlProps{
    className?:string;
    plageTempo:TempoPlage;
}

const PlageTempoCtrl = (props:PlageTempoCtrlProps)=>{
    const Context = useContext(ContextPlanning)
    const [myHeight, setMyHeight] = useState((props.plageTempo.HF - props.plageTempo.HD)/12 * Context.config.heightHour)
    const [myTop, setMyTop] = useState((props.plageTempo.HD - Context.config.start)/12 * Context.config.heightHour)
    const [isSaving, setIsSaving] = useState(false)
    useEffect(() => {
        setMyHeight((props.plageTempo.HF - props.plageTempo.HD)/12 * Context.config.heightHour)
        setMyTop((props.plageTempo.HD - Context.config.start)/12 * Context.config.heightHour)
        setIsSaving(props.plageTempo.isSaving)
    }, [props.plageTempo]);
    return (
        <div className={`plageTempo ${props.className}`} style={{top:myTop+"px"}}>
            <div className={`in_tempo ${Context.isActeColor ? "acteColor" : "lieuColor"}`} style={{height:myHeight+"px"}}>
                {isSaving && <SkeletonWait/> }
                <div className="Lieu_acte">{props.plageTempo.Lieu ? props.plageTempo.Lieu.libelle : "Nouveau"} {props.plageTempo.Acte ? `- ${props.plageTempo.Acte.libelle}` : ""}</div>
                <div className="horaires">{ConvertBase5(props.plageTempo.HD)} - {ConvertBase5(props.plageTempo.HF)}</div>
            </div>
        </div>
    )
}


const PlageTempo = styled(PlageTempoCtrl)`
    position: absolute;
    width: 100%;
    padding-left: 5px;
    left: 0;
    z-index: 4;
    .in_tempo {
        position: relative;
        width: 80%;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
        padding: 0.5rem;
        border-radius: 4px;
        background: ${props => props.plageTempo.Lieu ? props.plageTempo.Lieu.backColor : props.theme.NeutreLight};
        color: ${props => props.plageTempo.Lieu ? props.plageTempo.Lieu.fontColor : props.theme.PrimaryMegaLight};
        overflow-y: hidden;

        &.acteColor {
            background: ${props => props.plageTempo.Acte ? props.plageTempo.Acte.backColor : props.theme.NeutreLight};
            color: ${props => props.plageTempo.Acte ? props.plageTempo.Acte.fontColor : props.theme.PrimaryMegaLight};
        }
    }
`

export default PlageTempo