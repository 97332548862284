import styled from "styled-components";
import {useGetTimesUserCal} from "../../../../../../../../features/tempsTravail/tempsTravail.hooks";
import {useGetCentreActuel} from "../../../../../../../../features/centre/centre.hooks";
import {ReactNode, useEffect, useState} from "react";

interface BlockResumeTimesCtrlProps{
    className?:string;
    User:User;
    calendrier:CalendrierPaie;
}

const BlockResumeTimesCtrl = (props:BlockResumeTimesCtrlProps)=>{
    const TimesQuery = useGetTimesUserCal(props.calendrier.id, props.User.id)
    const CentreQuery = useGetCentreActuel();
    const [isAnnualisation, setIsAnnualisation] = useState(false);
    const [isTempsContratRef, setIsTempsContratRef] = useState(true)
    const [isEcartRef, setIsEcartRef] = useState(true)
    const [TimeRef, setTimeRef] = useState(1)
    const [TimeRefS, setTimeRefS] = useState(1)
    const [heuresContrat, setHeuresContrat] = useState(0)
    const [heuresDid, setHeuresDid] = useState(0)
    const [heuresEq, setHeuresEq] = useState(0)
    const [soldeIn, setSoldeIn] = useState(0)
    const [posDetailsA, setPosDetailsA] = useState<{x:number, y:number}>({x:0, y:0})
    const [contentDetailsA, setContentDetailsA] = useState<ReactNode>(<></>)
    const [isVisibleDetailA, setIsVisibleDetailsA] = useState(false)
    const isTesting = false;
    useEffect(() => {
        if(CentreQuery.data){
            const cParameters = CentreQuery.data.parameters;
            const MyParams = cParameters.find(cp=>cp.Parameter.id ===6)
            if(!!MyParams && MyParams.statut) setIsAnnualisation(true);
        }
    }, [CentreQuery.data]);
    useEffect(() => {
        if(TimesQuery.data && !isTesting){
            const RefS = Math.abs(TimesQuery.data.heures - TimesQuery.data.TempsContrat) > Math.abs(TimesQuery.data.Equilibrage.total) ? Math.abs(TimesQuery.data.heures - TimesQuery.data.TempsContrat) : Math.abs(TimesQuery.data.Equilibrage.total)
            const Ref = (TimesQuery.data.heures - TimesQuery.data.TempsContrat) > 0 ? TimesQuery.data.heures : TimesQuery.data.TempsContrat
            setIsTempsContratRef(TimesQuery.data.heures<TimesQuery.data.TempsContrat)
            setTimeRef(Ref)
            setIsEcartRef(Math.abs(TimesQuery.data.heures - TimesQuery.data.TempsContrat) > Math.abs(TimesQuery.data.Equilibrage.total))
            setTimeRefS(RefS)
            setHeuresDid(TimesQuery.data.heures);
            setHeuresContrat(TimesQuery.data.TempsContrat)
            setHeuresEq(TimesQuery.data.Equilibrage.total)
            setSoldeIn(TimesQuery.data.SoldeIn);
        } else {
            setIsTempsContratRef(true);
            setTimeRef(140)
            setHeuresDid(80);
            setHeuresContrat(140)
            setHeuresEq(10)
            setSoldeIn(0)
        }
    }, [TimesQuery.data, isTesting]);
    const MouseOver = (e:any, content:ReactNode)=>{
        setPosDetailsA({x:e.clientX+15, y:e.clientY+15})
        setContentDetailsA(content)
        setIsVisibleDetailsA(true)
    }
    const MouseLeave = ()=>{
        setIsVisibleDetailsA(false)
    }
    const GetListEquilibrage = ()=>{
        return <ul>
            {TimesQuery.data?.Equilibrage.list.map(item=>(
                <li key={`Eq${item.id}`}>{item.libelle} : {Math.round(item.Time*100)/100}h</li>
            ))}
        </ul>
    }
    return (
        <div className={`blockResumeTimes ${props.className}`}>
            <div className={`details_survol ${isVisibleDetailA ? "visible" : "invisible"}`} style={{left:posDetailsA.x+"px", top:posDetailsA.y+"px"}}>{contentDetailsA}</div>
            {TimesQuery.isLoading ? "" : !TimesQuery.data ? "erreur" :
                <div className="wrap_infos">
                    {isAnnualisation &&
                        <div className="solde_in is_solde">
                            <div className="in_solde">
                                <div
                                    className={`number ${soldeIn > 0 ? "pos" : soldeIn === 0 ? "neutre" : "neg"}`}>
                                    {soldeIn > 0 ? "+" : ""} {Math.round(soldeIn * 100) / 100}h
                                </div>
                                <div className="legend">Compteur<br/>début période</div>
                            </div>
                        </div>
                    }
                    <div className="wrap_comparaison">
                        <div className={`wrapper_time`}>
                            <div className="one_wrapper_in is_contrat"
                                 style={{width: ((heuresContrat / TimeRef) * 100) + "%"}}>
                                <div className="label" onMouseOver={(e)=>MouseOver(e, <p>Temps contrat<br/><span>{Math.round(heuresContrat * 100) / 100}h</span></p>)} onMouseLeave={MouseLeave}>
                                    {Math.round(heuresContrat * 100) / 100}h
                                </div>
                            </div>
                            {!isTempsContratRef &&
                                <div className="one_wrapper_in is_time_pos"
                                     style={{width: (100 - (heuresContrat / TimeRef) * 100) + "%"}}>
                                    <div className="label" onMouseOver={(e)=>MouseOver(e, <p>heures supp<br/><span>{Math.round((TimeRef - heuresContrat) * 100) / 100}h</span></p>)} onMouseLeave={MouseLeave}>
                                        {Math.round((TimeRef - heuresContrat) * 100) / 100}h
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={`wrapper_time`}>
                            <div className="one_wrapper_in is_did"
                                 style={{width: (((heuresDid) / TimeRef) * 100) + "%"}}>
                                <div className="label" onMouseOver={(e)=>MouseOver(e, <p>heures faites<br/><span>{Math.round(heuresDid * 100) / 100}h</span></p>)} onMouseLeave={MouseLeave}>
                                    {Math.round(heuresDid * 100) / 100}h
                                </div>
                            </div>
                            {isTempsContratRef &&
                                <div className="one_wrapper_in is_time_neg"
                                     style={{width: (100 - (heuresDid / TimeRef) * 100) + "%"}}>
                                    <div className="label" onMouseOver={(e)=>MouseOver(e, <p>heures manquantes<br/><span>{Math.round((heuresDid - TimeRef) * 100) / 100}h</span></p>)} onMouseLeave={MouseLeave}>
                                        {Math.round((heuresDid - TimeRef) * 100) / 100}h
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="wrap_comparaison">
                        <div className="wrapper_time_solde">
                            <div
                                className={`one_wrapper_in ${(heuresDid - heuresContrat) < 0 ? "is_solde_neg" : "is_time_pos"}`}
                                style={{width: (((Math.abs(heuresDid - heuresContrat) / TimeRefS) * 100) + "%")}}>
                                <div className="label" onMouseOver={(e)=>MouseOver(e, <p>Ecart période<br/><span>{Math.round((heuresDid - heuresContrat) * 100) / 100}h</span></p>)} onMouseLeave={MouseLeave}>
                                    {Math.round((heuresDid - heuresContrat) * 100) / 100}h
                                </div>
                            </div>
                        </div>
                        <div className="wrapper_time_solde">
                            <div className={`one_wrapper_in is_eq`}
                                 style={{width: (((Math.abs(heuresEq) / TimeRefS) * 100) + "%")}}>
                                <div className="label" onMouseOver={(e)=>MouseOver(e, <p>Equilibrage<br/><span>{Math.round((heuresEq) * 100) / 100}h</span>{GetListEquilibrage()}</p>)} onMouseLeave={MouseLeave}>
                                    {Math.round((heuresEq) * 100) / 100}h
                                </div>
                            </div>
                            <div className="one_wrapper_in is_rest"

                                 style={{width: (100 - ((Math.abs(heuresEq) / TimeRefS) * 100) + "%")}}>
                                <div
                                    className="label" onMouseOver={(e)=>MouseOver(e, <p>Solde période<br/><span>{Math.round((heuresDid + heuresEq - heuresContrat) * 100) / 100}h</span></p>)} onMouseLeave={MouseLeave}>
                                    {Math.round((heuresDid + heuresEq - heuresContrat) * 100) / 100}h
                                </div>
                            </div>
                        </div>
                    </div>
                    {isAnnualisation &&
                        <div className="solde_in is_solde">
                            <div className="in_solde">
                                <div
                                    className={`number ${(soldeIn + heuresDid + heuresEq - heuresContrat) > 0 ? "pos" : (soldeIn + heuresDid + heuresEq - heuresContrat) === 0 ? "neutre" : "neg"}`}
                                    style={{fontWeight: "bold"}}>
                                    {(soldeIn + heuresDid + heuresEq - heuresContrat) > 0 ? "+" : ""} {Math.round((soldeIn + heuresDid + heuresEq - heuresContrat) * 100) / 100}h
                                </div>
                                <div className="legend">Compteur<br/>fin période</div>
                            </div>
                        </div>
                    }

                </div>
            }
        </div>
    )
}

const BlockResumeTimes = styled(BlockResumeTimesCtrl)`
    flex-grow: 1;
    .details_survol{
        position: fixed;
        background: rgba(0,0,0,.7);
        padding: 0.5rem;
        color: white;
        border-radius: 4px;
        transition: all 0.3s;
        z-index: 9;
        text-align: center;
        font-size: 14px;
        ul{
            text-align: left;
            margin-top: 10px;
            li{
                list-style: circle;
            }
        }
        &.invisible{
            opacity: 0;
            z-index: -1;
        }
    }
    .wrapper_chart_solde_out{
        width: 30%;
    }
    .less_eq{
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        background-image: linear-gradient(45deg, #347d84 20.83%, #6eb7be 20.83%, #6eb7be 50%, #347d84 50%, #347d84 70.83%, #6eb7be 70.83%, #6eb7be 100%);
        background-size: 16.97px 16.97px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    .legend{
        font-size: 11px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding-left: 10px;
    }
    .number{
        font-size: 24px;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        &.pos{
            color: ${props => props.theme.SecondaryDark};
        }
        &.neg{
            color: ${props => props.theme.ComplementaryDark};
        }
    }
    .wrapper_solde_out{
        width: 225px;
        border: solid 2px ${props => props.theme.Neutre};
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0.25rem;
        
        .one_delta{
            flex: 1 1 0;
            padding: 0.25rem 0;
            border-bottom:solid ${props=>props.theme.Primary} 1px;
            text-align: center;
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            &:last-child{
               border:none; 
            }
            
        }
    }
    .is_solde {
        width: 140px;
        border: solid 2px ${props => props.theme.Neutre};
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .in_solde {
            font-size: 24px;
            text-align: center;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            font-weight: bold;
            height: 100%;
        }
    }
    .wrap_infos{
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: stretch;
        height: 130px;
    }
    .wrap_comparaison{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
    .wrapper_time, .wrapper_time_solde{
        display: flex;
        flex: 1 1 0;
        justify-content: flex-start;
        
        border-radius: 4px;
    }
    .label{
        padding: 0.25rem;
        font-size: 12px;
        background: black;
        color: white;
        border-radius: 4px;
        text-align: center;
        width: max-content;
        margin: auto;
    }
    .one_wrapper_in{
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        &.is_contrat{
            background: ${props=>props.theme.NeutreSuperLight};
        }
        &.is_did{
            background: ${props=>props.theme.PrimaryDark};
        }
        &.is_time_neg{
            background: rgb(242,243,243);
            background: linear-gradient(90deg, rgba(242,243,243,0) 0%, rgba(242,243,243,1) 100%);
        }
        &.is_solde_neg{
            background: rgb(242,243,243);
        }
        &.is_time_pos{
            background: ${props=>props.theme.SecondaryLight};
        }
        &.is_eq{
            background-image: linear-gradient(45deg, #347d84 20.83%, #6eb7be 20.83%, #6eb7be 50%, #347d84 50%, #347d84 70.83%, #6eb7be 70.83%, #6eb7be 100%);
            background-size: 16.97px 16.97px;
        }
        &.is_rest{
            background-image: linear-gradient(45deg, #f5939a 23.33%, #ffffff 23.33%, #ffffff 50%, #f5939a 50%, #f5939a 73.33%, #ffffff 73.33%, #ffffff 100%);
            background-size: 21.21px 21.21px;
        }
    }
    
`

export default BlockResumeTimes