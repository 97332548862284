import styled from "styled-components";
import {useGetDispatchUserActe} from "../../../../../../../../features/tempsTravail/tempsTravail.hooks";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {Pie} from 'react-chartjs-2';
import React, {useEffect, useState} from "react";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface ChartDispatchActeCtrlProps{
    className?:string;
    Calendrier:CalendrierPaie;
    User:User;
}
export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
            position: 'bottom' as const,
        },
        datalabels: {
            color: '#fff', // Couleur des labels
            backgroundColor:"#000",
            borderRadius: 5, // Coins arrondis pour le fond
            padding: 6, // Espacement autour du texte
            textAlign:"center",
            formatter: (value:any, context:any) => {
                // Affiche le pourcentage
                const total = context.dataset.data.reduce((sum:number, val:number) => sum + val, 0);
                const percentage = ((value / total) * 100).toFixed(1) + '%';
                const label = context.chart.data.labels?.[context.dataIndex];
                return `${label}\n${percentage}`; // Retour à la ligne avec "\n"
            },
            font: {
                size: 14, // Taille de la police
            },
        },
        title: {
            display: false,
            text: 'Historiques des heures',
        },
    },
}

const ChartDispatchActeCtrl = (props:ChartDispatchActeCtrlProps)=>{
    const DispatchQuery = useGetDispatchUserActe(props.Calendrier.id, props.User.id)
    const [data, setData] = useState<any|null>(null);
    useEffect(() => {
        if(DispatchQuery.data){
            const labels = DispatchQuery.data.map(d=>d.Acte.libelle);
            const colors = DispatchQuery.data.map(d=>d.Acte.backColor);
            const datas = DispatchQuery.data.map(d=>d.Time);
            setData({
                labels:labels,
                datasets:[
                    {
                        label:"Temps par acte",
                        data:datas,
                        backgroundColor:colors,
                        borderWidth:1,
                    }
                ]
            })
        }
    }, [DispatchQuery.data]);
    return (
        <div className={`chartDispatchActe ${props.className}`}>
            {DispatchQuery.isLoading || !data ? "chargement des données":
                <div className={`canvas-container`}>
                    <MyChartPie options={options} data={data}/>
                </div>
            }
        </div>
    )
}

const MyChartPie = ({...props})=>{
    return <Pie options={props.options}  data={props.data} width={"250px"} height={"250x"} />;
}

const ChartDispatchActe = styled(ChartDispatchActeCtrl)`
    width: 100%;
    height: 100%;
    .canvas-container{
        width: 250px;
        height: 250px;
        margin: auto;
    }
`

export default ChartDispatchActe