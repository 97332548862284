import styled from "styled-components";
import {
    useGetCompteurHS,
    useGetTimesUserCal,
    useGetTimesUserWeekly
} from "../../../../../../../features/tempsTravail/tempsTravail.hooks";
import {useEffect, useState} from "react";
import {getISOWeekNumber} from "../../../../../../../components/functions/TimesFunctions";
import {format} from "date-fns";
import {useGetCentreActuel} from "../../../../../../../features/centre/centre.hooks";
import {useModal} from "../../../../../../../hooks/useModal";
import {TimedModalCentral2} from "../../../../../../../components/molecules/TimedModal/TimedModalCentral2";
import ModalDetailsContrat from "./components/ModalDetailsContrat";
import OneCumul from "./components/OneCumul";
import OneCumulTotal from "./components/OneCumulTotal";

interface TempsTrSemaineOneUserCtrlProps{
    className?:string;
    user:User;
    calendrier:CalendrierPaie;
    dateFrom:string;
    dateTo:string;
    Back:()=>void;
    ClickOnHeures:(dateFrom:string, dateTo:string)=>void;
}


const TempsTrSemaineOneUserCtrl = (props:TempsTrSemaineOneUserCtrlProps)=>{
    const TimesQuery = useGetTimesUserCal(props.calendrier.id, props.user.id)
    const TimesWeeklyQuery = useGetTimesUserWeekly(props.calendrier.id, props.user.id)
    const CentreQuery = useGetCentreActuel();
    const [isAnnualisation, setIsAnnualisation] = useState(false)
    const {open:OpenDetailsContrat, toggle:ToggleDetailsContrat} = useModal();
    const [semCurr, setSemCurr] = useState<TimesUserWeekly|null>(null)
    const CompteurHSQuery = useGetCompteurHS(props.calendrier.id, props.user.id)
    useEffect(() => {
        if(CentreQuery.data){
            const cParameters = CentreQuery.data.parameters;
            const myParamsA = cParameters.find(cp=>cp.Parameter.id === 6);
            setIsAnnualisation(!!myParamsA && myParamsA.statut)
        }
    }, [CentreQuery.data]);

    const GetReport = (Sem:TimesUserWeekly)=>{
        if(!isAnnualisation){
            return 0;
        }
        if(TimesWeeklyQuery.data && TimesQuery.data) {
            const maDate = format(new Date(Sem.dateStart.slice(0, 10) + "T12:00:00"), "dd/MM")
            const mesDates = TimesWeeklyQuery.data.map(s => format(new Date(s.dateStart.slice(0, 10) + "T12:00:00"), "dd/MM"))
            const myIndex = mesDates.indexOf(maDate)
            console.log(maDate)
            console.log(myIndex)
            if(myIndex>-1){
                let myReport = TimesQuery.data.SoldeIn;
                for(let i=0; i<myIndex; i++){
                    myReport+= TimesWeeklyQuery.data[i].EcartTotal
                }
                return Math.round(myReport*10000)/10000
            } else {
                return 0
            }
        } else {
            return 0
        }

    }
    const ClickOnContrat = (item:TimesUserWeekly)=>{
        setSemCurr(item);
        ToggleDetailsContrat();
    }
    return (
        <div className={`tempsTrSemaineOneUser ${props.className}`}>
            {TimesWeeklyQuery.isLoading ? "chargement..." : !TimesWeeklyQuery.data ? "Errors datas" :
                <div className="content_sema">
                    <div className="wrap_head">
                        <h3 className="subCat">Temps par semaine</h3>
                        <div className="solde_cpt_hs">
                            <div className="lib">Solde Compteur HS : </div>
                            <div className={`solde_hs`}>{CompteurHSQuery.data && CompteurHSQuery.data.solde > 0 ? "+" : ""}{CompteurHSQuery.data?.solde}</div>
                        </div>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th style={{width: "200px"}}></th>
                            {TimesWeeklyQuery.data?.map((item, idx) => (
                                <th key={`HS${idx}`}>S{getISOWeekNumber(new Date(item.dateStart.slice(0, 10) + "T12:00:00"))}</th>
                            ))}
                            <th className={`back_totaux`} style={{width: "200px"}}>Totaux</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Dates</td>
                            {TimesWeeklyQuery.data?.map((item, idx) => (
                                <td className={`td_center is_dates`}
                                    key={`LHs${idx}`}>{format(new Date(item.dateStart.slice(0, 10) + "T12:00:00"), "dd/MM")} - {format(new Date(item.dateStop.slice(0, 10) + "T12:00:00"), "dd/MM")}</td>
                            ))}
                            <td className={`back_totaux`}></td>
                        </tr>
                        {isAnnualisation &&
                            <tr>
                                <td>Reports</td>
                                {TimesWeeklyQuery.data?.map((item, idx) => (
                                    <td className={`td_center`}
                                        key={`LR${idx}`}>{Math.round(GetReport(item) * 100) / 100}</td>
                                ))}
                                <td className={`back_totaux`}></td>
                            </tr>
                        }
                        <tr>
                            <td>Contrats</td>
                            {TimesWeeklyQuery.data?.map((item, idx) => (
                                <td className={`td_center`} key={`LC${idx}`}>
                                    <div className={`wrapper_number is_contrat`} onClick={() => ClickOnContrat(item)}>
                                        {Math.round(item.TempsContrat * 100) / 100}
                                    </div>
                                </td>
                            ))}
                            <td className={`td_center back_totaux`}>
                                {Math.round(TimesWeeklyQuery.data?.reduce((acc: number, item: TimesUserWeekly) => acc + item.TempsContrat, 0) * 100) / 100}
                            </td>
                        </tr>
                        <tr>
                            <td>Heures</td>
                            {TimesWeeklyQuery.data?.map((item, idx) => (
                                <td className={`td_center`} key={`LHR${idx}`}>
                                    <div
                                        className={`wrapper_number ${item.heures < item.TempsContrat ? "is_neg" : "is_pos"}`}
                                        onClick={() => props.ClickOnHeures(item.dateStart, item.dateStop)}>
                                        {Math.round(item.heures * 100) / 100}
                                    </div>
                                </td>
                            ))}
                            <td className={`td_center back_totaux`}>
                                {Math.round(TimesWeeklyQuery.data.reduce((acc: number, item: TimesUserWeekly) => acc + item.heures, 0) * 100) / 100}
                            </td>
                        </tr>
                        <tr>
                            <td>Ecart</td>
                            {TimesWeeklyQuery.data?.map((item, idx) => (
                                <td className={`td_center`}
                                    key={`LHE${idx}`}>
                                    <div className={`wrap_ecart ${item.Ecarts < 0 ? "negatif" : "positif"}`}>
                                        {item.Ecarts > 0 ? "+" : ""}{Math.round(item.Ecarts * 100) / 100}
                                    </div>
                                </td>
                            ))}
                            <td className={`td_center back_totaux`}>{Math.round(TimesWeeklyQuery.data.reduce((acc: number, item: TimesUserWeekly) => acc + item.Ecarts, 0) * 100) / 100}</td>
                        </tr>
                        <tr>
                            <td>Equilibrage</td>
                            {TimesWeeklyQuery.data?.map((item, idx) => (
                                <td className={`td_center`}
                                    key={`LHEq${idx}`}>
                                    <div
                                        className={`wrap_equilibrage ${item.Equilibrage.total > 0 ? "positif" : "negatif"}`}>
                                        {item.Equilibrage.total > 0 ? "+" : ""}{Math.round(item.Equilibrage.total * 100) / 100}
                                    </div>
                                </td>
                            ))}
                            <td className={`td_center back_totaux`}>{Math.round(TimesWeeklyQuery.data.reduce((acc: number, item: TimesUserWeekly) => acc + item.Equilibrage.total, 0) * 100) / 100}</td>
                        </tr>
                        <tr>
                            <td>Cumul</td>
                            {CompteurHSQuery.data && TimesWeeklyQuery.data?.map((item, idx) => (
                                <td className={`td_center`}
                                    key={`LHEc${idx}`}>
                                    <OneCumul
                                        dateTo={item.dateStop}
                                        time={item.EcartTotal + GetReport(item)}
                                        user={props.user}
                                        isAnnual={isAnnualisation}
                                        pos={(idx + 1) / TimesWeeklyQuery.data.length}
                                        CptHS={CompteurHSQuery.data}
                                    />
                                </td>
                            ))}
                            <td className={`td_center back_totaux`}>
                                <div className="cumul_week">
                                    {(!isAnnualisation && TimesWeeklyQuery.data) ?
                                        <OneCumulTotal user={props.user} pos={1} dateTo={props.calendrier.stopAt} time={Math.round(TimesWeeklyQuery.data.reduce((acc: number, item: TimesUserWeekly) => acc + (item.EcartTotal), 0) * 100) / 100} TimesWeeks={TimesWeeklyQuery.data}/> :
                                        <span>{Math.round(TimesWeeklyQuery.data.reduce((acc: number, item: TimesUserWeekly) => acc + (item.EcartTotal), 0) * 100) / 100}</span>
                                    }
                                </div>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td>Details Equilibrage</td>
                            {TimesWeeklyQuery.data?.map((item, idx) => (
                                <td className={`detail_equi`}
                                    key={`DEq${idx}`}>
                                    <div className="wrap_details_eq">
                                        {item.Equilibrage.list.map((itemE, idxE) => (
                                            <div className="one_eq" key={`Eq${idxE}`}>
                                                <div className="lib_eq">{itemE.libelle}</div>
                                                <div
                                                    className="time_eq">{itemE.Time > 0 ? "+" : ""}{Math.round(itemE.Time * 100) / 100}</div>
                                            </div>
                                        ))}
                                    </div>
                                </td>
                            ))}
                            <td className={`td_center back_totaux`}></td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            }
            <TimedModalCentral2 closeMe={ToggleDetailsContrat} Wd={"50%"} open={OpenDetailsContrat} Pending={false} titre={"Details des heures contrats"}>
                {semCurr &&
                    <ModalDetailsContrat User={props.user} from={semCurr.dateStart.slice(0,10)} to={semCurr.dateStop.slice(0,10)}/>
                }
            </TimedModalCentral2>
        </div>
    )
}

const TempsTrSemaineOneUser = styled(TempsTrSemaineOneUserCtrl)`
    .wrap_details_eq{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 4px;
        
        .one_eq{
            display: flex;
            justify-content: space-between;
            padding: 0.25rem;
            align-items: center;
            font-size: 14px;
        }
    }
    .wrap_head{
        display: flex;
        justify-content: space-between;
        .solde_cpt_hs{
            display: flex;
            justify-content: flex-start;
            gap: 6px;
            .solde_hs{
                font-weight: bold;
            }
        }
    }
    .content_sema {
        padding: 1rem;
        background: white;
    }
    .back_totaux{
       background: ${props => props.theme.NeutreMegaLight};
        font-weight: bold;
        
    }
    table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        tfoot{
            tr td{
                border-top: solid ${props => props.theme.NeutreLight} 1px;
            }
        }
        th{
            padding: 0.5rem 0.25rem;
            border-right: solid ${props => props.theme.NeutreLight} 1px;
        }
        td{
            padding: 0.25rem;
            &.back_totaux{
                text-align: right!important;
            }
            &.is_dates{
                font-weight: bold;
            }
            &:first-child{
                font-weight: bold;
            }
            border-right: solid ${props => props.theme.NeutreLight} 1px;
        }
        .td_center {
            text-align: center;
        }
    }
    .wrapper_number{
        border-radius: 4px;
        padding: 0.25rem 0.5rem;
        background: ${props => props.theme.NeutreExtraLight};
        width: 100%;
        margin: auto;
        &:hover{
            cursor: pointer;
            font-weight: bold;
        }
        &.is_pos{
            background: ${props => props.theme.SecondaryExtraLight};
            color:${props => props.theme.SecondarySuperDark};
        }
        &.is_neg{
            background: ${props => props.theme.ComplementaryExtraLight};
            color:${props => props.theme.ComplementaryDark};
        }
    }
    .cell_cumul{
        border-radius: 4px;
        padding: 0.25rem 0.5rem;
        background: ${props => props.theme.NeutreExtraLight};
        width: 100%;
        margin: auto;
        
        font-weight: bold;
        &.negative{
            background-image: linear-gradient(45deg, #e7c6c9 23.33%, #f7edee 23.33%, #f7edee 50%, #e7c6c9 50%, #e7c6c9 73.33%, #f7edee 73.33%, #f7edee 100%);
            background-size: 21.21px 21.21px;
            color:${props=>props.theme.ErrorExtraDark};    
        }
        &.positive{
            background-image: linear-gradient(45deg, #d5e1c7 23.33%, #f2f6ee 23.33%, #f2f6ee 50%, #d5e1c7 50%, #d5e1c7 73.33%, #f2f6ee 73.33%, #f2f6ee 100%);
            background-size: 21.21px 21.21px;
            color:${props=>props.theme.SuccessExtraDark};
        }
    }
    .wrap_ecart{
        border-radius: 4px;
        padding: 0.25rem 0.5rem;
        border-width: 2px;
        border-style: solid;
        font-weight: bold;
        &.positif{
            border-color: ${props=>props.theme.Secondary};
            color:${props=>props.theme.SecondaryDark};
        }
        &.negatif{
            border-color: ${props=>props.theme.Complementary};
            color:${props=>props.theme.ComplementaryDark};
        }
    }
    .wrap_equilibrage{
        text-align: center;
        font-weight: bold;
        &.positif{
            color:${props=>props.theme.SecondaryDark};
        }
        &.negatif{
            color:${props=>props.theme.ComplementaryDark};
        }
        
    }
`

export default TempsTrSemaineOneUser