import styled from "styled-components";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {ErrorInput, LabelInter} from "./styleInters";
import {TimedListChoice} from "./TimedListChoice";
import {TimedListChoiceUser} from "./TimedListChoiceUser";

interface TimedAutoCompleteInterCtrlProps {
    className?:string;
    CurrentChoice:Choice;
    Choices:Choice[];
    setChoice:(c:Choice|string|number, id:string)=>void;
    label:string;
    Error?:string|null;
    id:string;
    isUserChoice?:boolean;
    isColorForce?:boolean;
}

const TimedAutoCompleteInterCtrl = ({className, CurrentChoice, Choices, setChoice, label, Error, id, isUserChoice}: TimedAutoCompleteInterCtrlProps) => {
    const [ChoicePrt, setChoicePrt] = useState(CurrentChoice)
    const [thisChoice, setThisChoice] = useState<string>(CurrentChoice.libelle);
    const refInput = useRef<HTMLInputElement>(null)
    const refInput2 = useRef<HTMLInputElement>(null)
    const [coord, setCoord] = useState({x:0, y:0})
    const [open, setOpen] = useState<boolean>(false);

    const [myError,setMyError] = useState(Error)
    useEffect(() => {
        setMyError(Error);
    }, [Error]);
    useEffect(() => {
        setChoicePrt(CurrentChoice);
    }, [CurrentChoice]);
    useEffect(() => {
        setThisChoice(ChoicePrt.libelle)
    }, [ChoicePrt]);
    const handleChange = ()=>{
        if(refInput.current) {

            const val = refInput.current.value
            setThisChoice(val)
        }
    }
    const GoSuggest = (vl:string)=>{
        const Find = Choices.find(c=>c.libelle === vl);
        setOpen(false);
        if(Find){
            setChoice(Find, id)
        } else {
            setChoice({id:-2, libelle:'NotFind'}, id)
        }
    }
    // useEffect(() => {
    //     if(ThisChoiceDebounce!=='') {
    //         GoSuggest(ThisChoiceDebounce);
    //     }
    // }, [ThisChoiceDebounce]);
   const HandleBlur = ()=>{
       if(!open) {
           GoSuggest(thisChoice);
       }
   }
   const ListChoiceX = useMemo(()=>{
       const list:Choice[] = [];
       Choices.forEach(c=>{
           const regex= new RegExp("^"+thisChoice.toLowerCase()+"");
           if(thisChoice==='' || c.libelle.toLowerCase().match(regex)){
               list.push(c);
           }
       })
       return list;
   }, [Choices, thisChoice])
    const SetMyCoord = ()=>{
        if (refInput.current) {
            refInput.current.select();
            const elt = refInput.current.getBoundingClientRect();
            const top = elt.top + elt.height;
            const left = elt.left;
            const width = elt.width;
            setCoord({x: left+width+5, y: top})
            setOpen(true);
        }
    }
    const CloseList = ()=>{
        setOpen(false)
    }
    const handleChoiceSelect = (c:Choice)=>{
        setThisChoice(c.libelle);
        GoSuggest(c.libelle);
        setOpen(false);

    }
    const HandleKeyDown = (e:any)=>{
        if(e.key === 'Enter' || e.keyCode === 9){
            e.preventDefault();
            if(ListChoiceX.length>0){
                setThisChoice(ListChoiceX[0].libelle)
                GoSuggest(ListChoiceX[0].libelle);
                setOpen(false);

            } else {
                GoSuggest(ChoicePrt.libelle);
            }
        }
    }
    return (
        <div className={`Auto ${className}`}>
            <LabelInter>{label}</LabelInter>
            <div className={`wrap_inputs`}>
                <input id={"input"+id} className={`inputFirst`} ref={refInput} type={"text"} value={thisChoice} onChange={handleChange} onBlur={HandleBlur} onFocus={SetMyCoord} onKeyDown={HandleKeyDown}/>
                <input className={`inputSecond ${thisChoice === '' && 'empty'}`} readOnly={true} ref={refInput2} type={"text"} value={ListChoiceX.length>0 ? ListChoiceX[0].libelle : ''}/>
                {myError &&
                    <ErrorInput>{myError}</ErrorInput>
                }
            </div>
            {isUserChoice ?
                <TimedListChoiceUser Zindex={101} x={coord.x} y={coord.y} Choices={Choices} setChoice={handleChoiceSelect} id={id} open={open} closeMe={CloseList}/>:
                <TimedListChoice Zindex={101} x={coord.x} y={coord.y} Choices={Choices} setChoice={handleChoiceSelect} id={id} open={open} closeMe={CloseList}/>
            }
        </div>
    )
}

export const TimedAutoCompleteInter = styled(TimedAutoCompleteInterCtrl)`
    position: relative;
    z-index: 3;
    .wrap_inputs{
        position: relative;
        width: 100%;
        &:hover{
            input{
                padding: 0.25rem 0.5rem;
                transition: padding-left 0.3s;
                cursor: pointer;
                opacity: 1;
                &.inputSecond{
                    background: ${props=>props.isColorForce ? props.theme.greyForm : props.theme.PrimaryMegaLight};
                }
            }
        }
    }
    .inputFirst, .inputSecond{
        width: 100%;
        border:none;
        border-radius: 4px;
        padding: 0.25rem 0.5rem 0.25rem 0;
        transition: padding-left 0.3s;
        font-size: 16px;
        
    }
    .inputFirst{
        background: transparent;
        &:focus{
            padding: 0.25rem 0.5rem;
            transition: padding-left 0.3s;
            cursor: pointer;
            opacity: 1;
            ~.inputSecond{
                padding: 0.25rem 0.5rem;
                transition: padding-left 0.3s;
                cursor: pointer;
                opacity: 1;
                background: ${props=>props.theme.PrimaryMegaLight};
            }
        }
    }
    .inputSecond{
        opacity: 0;
        color: #ccc;
        cursor: none;
        z-index: -1;
        position: absolute;
        padding: 0.25rem 0.5rem 0.25rem 0;
        left: 0;
        top: 0;
        transition: padding-left 0.3s;
        &.is-error{
            background: ${props=>props.theme.WarningMegaLight};
        }
        &.empty{
            opacity: 1;
            background: ${props=>props.isColorForce ? props.theme.greyForm : props.theme.PrimaryMegaLight};
        }
    }
`