import styled from "styled-components";
import {OneSemaineChoice} from "../GestionHeuresSuppFull";
import {useGetCompteurTempsDateDate} from "../../../../../features/compteurTempsDateDate/compteurTempsDateDate.hooks";
import {useGetAllUser} from "../../../../../features/user/user.hooks";
import {useEffect, useState} from "react";
import {useGetParamsTimed} from "../../../../../hooks/useGetParamsTimed";
import {DNA} from "react-loader-spinner";

interface OneChoiceWeekCardCtrlProps{
    className?:string;
    Semaine:OneSemaineChoice;
    onClick:(s:OneSemaineChoice)=>void;
    SemaineCurr:OneSemaineChoice|null;
}

const OneChoiceWeekCardCtrl = (props:OneChoiceWeekCardCtrlProps)=>{
    const [alertBadChoice, setAlertBadChoice] = useState(false);
    const {TabCollab, TabFx} = useGetParamsTimed();
    const UsersQuery = useGetAllUser();
    const [usersConc, setUsersConc] = useState<User[]>([])
    const CptsQuery = useGetCompteurTempsDateDate(props.Semaine.From.toISOString().slice(0,10), props.Semaine.To.toISOString().slice(0,10), usersConc.map(u=>u.id));
    const [nbResult, setNbResult] = useState<number>(0)
    useEffect(()=>{
        setAlertBadChoice(false);
        if(UsersQuery.data){
            const users = UsersQuery.data;
            if(TabCollab){
                setUsersConc(users.filter(u=>TabCollab.indexOf(u.id.toString())!==-1));
            }else if(TabFx){
                if(TabFx.length === 1 && TabFx[0] === "1"){
                    setUsersConc([]);
                    setAlertBadChoice(true);
                } else {
                    setUsersConc(users.filter(u=>TabFx.indexOf(u.Fonction.id.toString())!==-1));
                }
            } else {
                setUsersConc(users);
            }
        }
    }, [UsersQuery.data, TabCollab, TabFx])
    useEffect(()=>{
        if(usersConc && CptsQuery.data){
            const ids = usersConc.map(u=>u.id);
            const myCpts = CptsQuery.data.filter(c=>ids.indexOf(c.User.id)!==-1)
            let MyNBresult = 0
            ids.forEach(i=>{
                const cptsConcerned = myCpts.filter(c=>c.User.id === i);
                const SoldeOutTotal = cptsConcerned.reduce((acc:number, myCpt:CompteurTempsDateDate)=>acc+(Math.round(((myCpt.heuresFaites+myCpt.heuresNoW+myCpt.regules-myCpt.Pause) - myCpt.heuresAFaire)*100)/100), 0);
                if(SoldeOutTotal!==0){
                    MyNBresult++;
                }
            })
            setNbResult(MyNBresult);
        }
    }, [CptsQuery.data, usersConc])
    const getFormatDate = (d:Date)=>{
        const day = d.getDate()<10 ? "0"+d.getDate() : d.getDate();
        const monthD = (d.getMonth()+1)<10 ? "0"+(d.getMonth()+1) : d.getMonth()+1;
        return `${day}/${monthD}`
    }
    return (
        <div className={`one_choice_sem ${props.className} ${props.SemaineCurr && props.SemaineCurr.id === props.Semaine.id ? 'current' : ''}`} onClick={()=>props.onClick(props.Semaine)}>
            <div className={`content_card ${nbResult === 0 ? 'disabled' : 'fully'}`}>
                <span className={`num_w`}>S{props.Semaine.numSem}</span>
                <span className={`date_w`}>{getFormatDate(props.Semaine.From)} - {getFormatDate(props.Semaine.To)}</span>
                <div className={`result_w`}>
                    {CptsQuery.isLoading ?
                        <span className={`spin_wait`}>
                            <DNA
                                visible={true}
                                height="30"
                                width="30"
                                ariaLabel="dna-loading"
                                wrapperStyle={{}}
                                wrapperClass="dna-wrapper"
                            />
                        </span>:
                        <span>({nbResult})</span>
                    }
                </div>

            </div>
        </div>
    )
}

export const OneChoiceWeekCard = styled(OneChoiceWeekCardCtrl)`
  font-size: 14px;
  &.current{
    .content_card {
      border-left: solid 5px ${props => props.theme.Primary};
    }
  }
  .content_card{
    padding: 1rem 0.5rem;
    border-bottom: ${props => props.theme.NeutreExtraLight} solid 1px;
    transition: transform 0.3s;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    &.disabled{
      color: ${props=>props.theme.PrimaryExtraLight};
    }
    &:hover{
      cursor: pointer;
      background: ${props=>props.theme.PrimaryMegaLight};
      font-weight: bold;
    }
  }
  
`