import styled from "styled-components";
import {useCallback, useEffect, useRef, useState} from "react";
import "./StyleForm.css"

interface TimedSelectChoiceCtrlProps{
    className?:string;
    choiceCurr:Choice|null;
    choices:Choice[];
    label?:string;
    error:string|null;
    setChoice:(c:Choice|null)=>void;
    isRequired?:boolean;
    isSideColor?:boolean;
    isDisabled?:boolean;
}

const TimedSelectChoiceCtrl = (props:TimedSelectChoiceCtrlProps)=>{
    const [open, setOpen] = useState(false)
    const [inputValue, setInputValue] = useState(props.choiceCurr?.libelle||"")
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [filteredOptions, setFilteredOptions] = useState<Choice[]>(props.choices)
    const optionRefs = useRef<Array<HTMLLIElement | null>>([]);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [isValid, setIsValid] = useState(true);
    const [sideColor, setSideColor] = useState("#888")
    useEffect(() => {
        setInputValue(props.choiceCurr?.libelle||"")
    }, [props.choiceCurr]);
    useEffect(() => {
        setIsValid(props.choices.some((option) => option.libelle === inputValue) || inputValue==="")
    }, [inputValue, props.choiceCurr]);
    useEffect(() => {
        if(props.isSideColor && props.choiceCurr && props.choiceCurr.bkgColor) setSideColor(props.choiceCurr.bkgColor)
    }, [props.choiceCurr, props.isSideColor]);
    const handleFocus = () => {
        setOpen(true);
        setFilteredOptions(props.choices);
    };

    // Gérer le filtrage en fonction du texte entré
    const handleInputChange = (e:any) => {
        if(!open) {
            setOpen(true);
            setFilteredOptions(props.choices);
        }
        const value = e.target.value;
        setInputValue(value);
        setFilteredOptions(
            props.choices.filter((option) =>
                option.libelle.toLowerCase().includes(value.toLowerCase())
            )
        );
        setHighlightedIndex(-1); // Réinitialiser l'index mis en surbrillance
    };
    const handleKeyDown = (e:any) => {
        if(filteredOptions.length === 0) return;
        if (e.key === 'ArrowDown') {
            // Aller vers le bas
            setHighlightedIndex((prevIndex) =>
                Math.min(prevIndex + 1, filteredOptions.length - 1)
            );
        } else if (e.key === 'ArrowUp') {
            // Aller vers le haut
            setHighlightedIndex((prevIndex) =>
                Math.max(prevIndex - 1, 0)
            );
        } else if (e.key === 'Enter' && highlightedIndex >= 0) {
            // Sélectionner l'option en surbrillance
            setInputValue(filteredOptions[highlightedIndex].libelle);
            setIsValid(true);
            props.setChoice(filteredOptions[highlightedIndex])
            setOpen(false);
        } else if (e.key === 'Escape') {
            // Fermer la liste

            setOpen(false);
        } else if (e.key === 'Tab' && filteredOptions.length > 0) {
            // Sélectionner le premier élément filtré avec Tab
            setInputValue(filteredOptions[0].libelle);
            props.setChoice(filteredOptions[0])
            setOpen(false);
            setIsValid(true);
            e.preventDefault(); // Empêche le comportement par défaut de Tab
        }
    };

    // Gérer la sélection d'une option par clic
    const handleOptionClick = (option:Choice) => {
        setInputValue(option.libelle);
        setIsValid(true);
        props.setChoice(option)
        setOpen(false);
    };

    useEffect(() => {
        if (highlightedIndex >= 0 && optionRefs.current[highlightedIndex]) {
            optionRefs.current[highlightedIndex]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [highlightedIndex]);




    const handleBlur = useCallback((is:boolean) => {
        const isValidOption = props.choices.some((option) => option.libelle === inputValue);
        if (is) setIsValid(isValidOption || inputValue==="");

        // Si la saisie n'est pas valide, on peut décider de vider l'input
        if (!isValidOption) {
            setInputValue('');
            props.setChoice(null);
        }

        setOpen(false);
    }, [inputValue, props]);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                handleBlur(true);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleBlur]);

    return (
        <div className={`timedSelectChoice ${props.className}`} ref={dropdownRef}>
            {props.label && <label className={"std"}>{props.label}{props.isRequired && "*"}</label>}
            <input
                style={{borderColor:sideColor}}
                disabled={props.isDisabled}
                type="text"
                value={inputValue}
                onFocus={handleFocus}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Choisir une option..."
                className={`dropdown-input std ${(props.isRequired && !isValid) ? "invalide" : ""} ${props.isSideColor ? "leftColor" : ""}`}
            />
            {open && (
                <ul className="dropdown-list">

                    {filteredOptions.length > 0 ?
                        (filteredOptions.map((option, index) => (
                        <li
                            key={option.id}
                            ref={(el) => (optionRefs.current[index] = el)}
                            onClick={() => handleOptionClick(option)}
                            className={`dropdown-option ${
                                index === highlightedIndex ? 'highlighted' : ''
                            }`}
                        >
                            {option.libelle}
                        </li>
                        ))):
                        <li className={`dropdown-option no_results`}>Aucun choix</li>
                    }
                </ul>
            )}
        </div>
    )
}

const TimedSelectChoice = styled(TimedSelectChoiceCtrl)`
    position: relative;
    color:${props=>props.theme.Dark};
    .dropdown {
        position: relative;
        width: 200px;
    }

    .dropdown-input {
        width: 100%;
        padding: 8px;
        background: ${props => props.theme.colorBackInput};
        &.invalide{
            background: ${props => props.theme.ComplementaryExtraLight};
        }
    }

    .dropdown-list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 150px;
        overflow-y: auto;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: white;
        margin-top: 4px;
        z-index: 1000;
    }

    .dropdown-option {
        padding: 8px;
        cursor: pointer;
        &:not(.no_results):hover{
            background-color: ${props => props.theme.PrimaryMegaLight};
        }
        &.no_results{
            cursor: not-allowed;
            color: #888;
            font-style: italic;
        }
    }

    .dropdown-option.highlighted {
        background-color: ${props => props.theme.PrimaryMegaLight};
        color: ${props => props.theme.PrimaryMegaDark};
    }
`

export default TimedSelectChoice