import styled from "styled-components";

import {useContext, useEffect, useState} from "react";
import {RiForbid2Fill} from "react-icons/ri";
import {ContextPlanning, TempoPlage} from "../WeeklyCalendar";
import {useGetAllStatutPlage} from "../../../../features/statutPlage/statutPlage.hooks";
import {useGetAllActes} from "../../../../features/acte/acte.hooks";
import {useGetAllLieu, useGetLieuHorairesByLieu} from "../../../../features/lieu/lieu.hooks";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import {useGetLittsCentre, useGetParamsCentre} from "../../../../services/hooks";
import {ConvertActeToChoice, ConvertLieuToChoice} from "../../../functions/PlageFunctions";
import {useAddPlage, useGetConflitPlage} from "../../../../features/plage/plage.hooks";
import {ContraintX, DataSetter, ValideObjet} from "../../../atoms/TimedForm324/ValidationForm";
import TimedSelectChoice from "../../../atoms/TimedForm324/TimedSelectChoice";
import TimedTextArea from "../../../atoms/TimedForm324/TimedTextArea";
import TimedSwitch from "../../../atoms/TimedForm324/TimedSwitch";
import TimedButton from "../../../atoms/TimedButton/TimedButton";
import WrapperSender from "../../../atoms/TimedForm324/WrapperSender";
import TimedSelectHDHF from "../../../atoms/TimedForm324/TimedSelectHDHF";

interface FormNewPlageCtrlProps{
    className?:string;
    plageTempo:TempoPlage;
    idUser:number;
    closeMe:()=>void;
}

interface OneHoraire{
    libelle:string;
    HD:number;
    HF:number;
}

interface NewPlageProps{
    Lieu:Choice|null;
    Acte:Choice|null;
    Status:Choice|null;
    HeureDebut:number;
    HeureFin:number;
    isSpecial:boolean;
    Memo:string|null
}
const DefaultPlage:NewPlageProps={
    Lieu:null,
    Acte:null,
    Status: {id:2, libelle:"Prévisionnel"},
    HeureDebut:0,
    HeureFin:0,
    isSpecial:false,
    Memo:null,
}
interface ErrorsProps{
    Lieu:string|null,
    Acte:string|null,
    Status:string|null,
    HeureDebut:string|null,
    HeureFin:string|null,
}
const DefaultErrors:ErrorsProps ={
    Lieu:null,
    Acte:null,
    Status:null,
    HeureDebut:null,
    HeureFin:null,
}

const FormNewPlageCtrl = (props:FormNewPlageCtrlProps)=>{
    const option = 1;
    const mutation = useAddPlage();
    const [paramsConflit, setParamsConflit] = useState<GetConflitPlageProps>({id:props.idUser, hf:0, hd:0, date:""})
    const NbConflitQuery = useGetConflitPlage(paramsConflit)
    const Context = useContext(ContextPlanning)
    const StatusQuery = useGetAllStatutPlage();
    const ActesQuery = useGetAllActes();
    const LieuxQuery = useGetAllLieu();
    const CentreQuery = useGetCentreActuel();
    const {myParamsOk:isUrgence} = useGetParamsCentre(19);
    const {value:libUrgence} = useGetLittsCentre("libUrgence");
    const [myPlage, setMyPlage] = useState<NewPlageProps>(DefaultPlage)
    const [Errors, setErrors] = useState<ErrorsProps>(DefaultErrors)
    const [choicesStatus, setChoicesStatus] = useState<Choice[]>([])
    const [choicesActes, setChoicesActes] = useState<Choice[]>([])
    const [choicesLieux, setChoicesLieux] = useState<Choice[]>([])
    const [idLieu, setIdLieu] = useState(0)
    const LieuxHQuery = useGetLieuHorairesByLieu(idLieu);
    const [mesHoraires, setMesHoraires] = useState<OneHoraire[]>([])
    const [myDate, setMyDate] = useState<string>("")
    const [nbConflit, setNbConflit] = useState<number>(0)
    useEffect(() => {
        setParamsConflit({id:props.idUser, hf:myPlage.HeureFin, hd:myPlage.HeureDebut, date:myDate})
    }, [myPlage, myDate, props.idUser]);
    useEffect(()=>{
        if(NbConflitQuery.data){
            setNbConflit(NbConflitQuery.data.nbConflit)
        } else {
            setNbConflit(0);
        }
    }, [NbConflitQuery.data])
    const TabConstraints:ContraintX[]= [
        {key:"Acte", type:"Choice", required:true},
        {key:"Lieu", type:"Choice", required:true},
        {key:"Status", type:"Choice", required:true},
        {key:"HeureDebut", type:"number", required:true, minNumber:0, maxNumber:244},
        {key:"HeureFin", type:"number", required:true, minNumber:0, maxNumber:244},
    ]
    const ConvertHoraires = (list:OneHoraire[])=>{
        const first = list[0];
        const last = list[list.length-1]
        return [...list, {libelle:"journée", HD:first.HD, HF:last.HF}]
    }
    useEffect(() => {
        if(CentreQuery.data){
            const horairesCentre = CentreQuery.data.horaires.sort((a,b)=>a.start > b.start ? 1 : -1).map(ch=>{
                return {libelle:ch.libelle, HD:ch.start, HF:ch.end}
            });
            const tabHorairesCentre = ConvertHoraires(horairesCentre);
            if(!myPlage.Acte && !myPlage.Lieu){
                setMesHoraires(tabHorairesCentre)
            } else if(myPlage.Lieu && !Context.isActeH){
                const horairesLieu = LieuxHQuery.data?.sort((a,b)=>a.start > b.start ? 1 : -1);
                if(!horairesLieu){
                    setMesHoraires(tabHorairesCentre)
                } else {
                    const tabHorairesLieu:OneHoraire[] = ConvertHoraires(horairesLieu.map(hl=>{
                        return {libelle:hl.libelle, HD:hl.start, HF:hl.end}
                    }))
                    setMesHoraires(tabHorairesLieu)
                }
            } else if(myPlage.Acte) {
                const Acte = ActesQuery.data?.find(a=>a.id === myPlage.Acte?.id)
                if(!Acte){
                    setMesHoraires(tabHorairesCentre);
                } else {
                    const horairesActe = Acte.acteHoraires.sort((a,b)=>a.start > b.start ? 1 : -1).map(ch=>{
                        return {libelle:ch.libelle, HD:ch.start, HF:ch.end}
                    });
                    setMesHoraires(ConvertHoraires(horairesActe))
                }
            }
        }
    }, [Context.isActeH, LieuxHQuery.data, myPlage, CentreQuery.data, ActesQuery.data]);
    useEffect(() => {
        const ActeTempo:Choice|null = props.plageTempo.Acte ? ConvertActeToChoice(props.plageTempo.Acte) : null;
        const LieuTempo:Choice|null = props.plageTempo.Lieu ? ConvertLieuToChoice(props.plageTempo.Lieu) : null;
        const mDate = props.plageTempo.d.toISOString().slice(0,10);
        const HeureDebut:number = props.plageTempo.HD;
        const HeureFin:number = props.plageTempo.HF;
        setMyPlage(prevPlage=>{
            return {...prevPlage, Acte:ActeTempo, Lieu:LieuTempo, HeureDebut:HeureDebut, HeureFin:HeureFin}
        })
        setIdLieu(LieuTempo ? parseInt(LieuTempo.id+"") : 0)
        setMyDate(mDate)
    }, [props.plageTempo]);
    useEffect(() => {
        if(StatusQuery.data) setChoicesStatus(StatusQuery.data.map(s=>{
            return {id:s.id, libelle:s.libelle, code:s.code, color:s.color}
        }))
    }, [StatusQuery.data]);
    useEffect(() => {
        if(ActesQuery.data) setChoicesActes(ActesQuery.data.map(a=>{
            return {id:a.id, libelle:a.libelle, color:a.backColor}
        }))
    }, [ActesQuery.data]);
    useEffect(() => {
        if(LieuxQuery.data) setChoicesLieux(LieuxQuery.data.map(a=>{
            return {id:a.id, libelle:a.libelle, color:a.backColor}
        }))
    }, [LieuxQuery.data]);
    const HandleStatus = (c:Choice|null)=>{
        DataSetter<NewPlageProps, ErrorsProps>("Status", c, myPlage, setMyPlage, TabConstraints, Errors, setErrors);
    }
    const HandleActe = (c:Choice|null)=>{
        const myActe = ActesQuery.data?.find(a=>a.id === c?.id)
        if(Context.ModifyPlageTempo){
            if(option === 1){
                DataSetter<NewPlageProps, ErrorsProps>("Acte", c, myPlage, setMyPlage, TabConstraints, Errors, setErrors);
            }
            Context.ModifyPlageTempo({...props.plageTempo, Acte:myActe||null})
        }
    }
    const HandleLieux = (c:Choice|null)=>{
        const myLieu = LieuxQuery.data?.find(a=>a.id === c?.id)
        if(Context.ModifyPlageTempo){
            if(option === 1){
                DataSetter<NewPlageProps, ErrorsProps>("Lieu", c, myPlage, setMyPlage, TabConstraints, Errors, setErrors);
            }
            Context.ModifyPlageTempo({...props.plageTempo, Lieu:myLieu||null, Acte:(myLieu && myLieu.defaultActe) ? myLieu.defaultActe : props.plageTempo.Acte})
        }
    }
    const HandleHoraires = (hd:number, hf:number)=>{
        if(Context.ModifyPlageTempo){
            if(option === 1){
                DataSetter<NewPlageProps, ErrorsProps>("HeureDebut", hd+"", myPlage, setMyPlage, TabConstraints, Errors, setErrors);
                DataSetter<NewPlageProps, ErrorsProps>("HeureDebut", hf+"", myPlage, setMyPlage, TabConstraints, Errors, setErrors);
            }
            Context.ModifyPlageTempo({...props.plageTempo, HD:hd, HF:hf})
        }
    }
    const ClickQuickHoraires = (one:OneHoraire)=>{
        const myHoraires = mesHoraires.find(h=>h.libelle === one.libelle)
        if(myHoraires){
            HandleHoraires(myHoraires.HD, myHoraires.HF)
        }
    }
    useEffect(() => {
        if(myPlage.Lieu){
            const Lieu = LieuxQuery.data?.find(l=>l.id === myPlage.Lieu?.id) as Lieu;
            const ActesConcerned = ActesQuery.data?.filter(a=>a.isPresent === !Lieu.isLieuAbs)
            if(ActesConcerned){
                setChoicesActes(ActesConcerned.map(a=>{
                    return {id:a.id, libelle:a.libelle, color:a.backColor}
                }))
            }
            if(myPlage.Acte){
                const Acte = ActesQuery.data?.find(a=>a.id === myPlage.Acte?.id) as Acte
                if(Acte.isPresent !== !Lieu.isLieuAbs && Context.ModifyPlageTempo){
                    if(option === 1){
                        DataSetter<NewPlageProps, ErrorsProps>("Acte", null, myPlage, setMyPlage, TabConstraints, Errors, setErrors);
                    }
                    Context.ModifyPlageTempo({...props.plageTempo, Acte:null})
                }
            }
        }
    }, [myPlage, ActesQuery.data, LieuxQuery.data, Context, props.plageTempo, Errors]);

    const Submit = ()=>{
        const Valid = ValideObjet<NewPlageProps, ErrorsProps>(myPlage, Errors, setErrors, TabConstraints);
        if(Valid && myDate!==""){
            const myDatas:AddPlageFormData={
                User:`/api/users/${props.idUser}`,
                plageAt:myDate,
                Acte:`/api/actes/${myPlage.Acte?.id}`,
                Lieu:`/api/lieus/${myPlage.Lieu?.id}`,
                heureDebut:myPlage.HeureDebut,
                heureFin:myPlage.HeureFin,
                jPlusUn:myPlage.HeureFin<myPlage.HeureDebut,
                isSpecial:myPlage.isSpecial,
                Memo:myPlage.Memo||"",
                Statut:`/api/statut_plages/${myPlage.Status?.id}`,
                isTreatPointeuse:false
            }
            mutation.mutate(myDatas, {
                onSuccess:()=>{
                    props.closeMe()
                }
            })
        }
    }
    return (
        <div className={`formNewPlage ${props.className}`}>
            <div className="wrap_inputs_verts">
                {(choicesActes.length >0 && choicesLieux.length > 0 && choicesStatus.length>0 && CentreQuery.data) &&
                    <>
                        <TimedSelectChoice isSideColor={true} isRequired choiceCurr={myPlage.Lieu} setChoice={HandleLieux} choices={choicesLieux} error={Errors.Lieu} label={"Lieu"} isDisabled={mutation.isLoading}/>
                        <TimedSelectChoice isSideColor={true} isRequired choiceCurr={myPlage.Acte} setChoice={HandleActe} choices={choicesActes} error={Errors.Acte} label={"Acte"} isDisabled={mutation.isLoading}/>
                        <TimedSelectChoice isRequired choiceCurr={myPlage.Status} setChoice={HandleStatus} choices={choicesStatus} error={Errors.Status} label={"Statut"} isDisabled={mutation.isLoading}/>
                        <div className="wrap_set_horaires">
                            <div className="quicks">
                                {mesHoraires.map((item, idx)=>(
                                    <button className={`btn_quick_hor`} key={`Qh${idx}`} onClick={()=>ClickQuickHoraires(item)} disabled={mutation.isLoading}>{item.libelle}</button>
                                ))}
                            </div>
                            <div className="wrap_horaires">
                                {/* eslint-disable-next-line react/jsx-no-undef */}
                                <TimedSelectHDHF HDCurr={myPlage.HeureDebut} HFCurr={myPlage.HeureFin} setHoraires={HandleHoraires}/>
                            </div>
                        </div>

                        <TimedTextArea isDisabled={mutation.isLoading} label={"Mémo"} current={myPlage.Memo||''} setValue={(v)=>setMyPlage(p=>{
                            return {...p, Memo:v}
                        })} Error={null} id={"myMemo"}/>
                        {(libUrgence && isUrgence) &&
                            <TimedSwitch current={myPlage.isSpecial} switchPos={()=>setMyPlage(p=>{
                                return {...p, isSpecial:!p.isSpecial}
                            })} label={libUrgence||"nc"}/>
                        }
                        <WrapperSender position={"center"}>
                            {(myPlage.Status?.id === 5 || nbConflit === 0) ?
                                <TimedButton disabled={mutation.isLoading} themeColor={"Primary"} size={"sm"} onClick={Submit} text={"Enregistrer"}/>:
                                <div className="conflits">
                                    <RiForbid2Fill /><span>En conflit</span>
                                </div>
                            }
                        </WrapperSender>
                    </>
                }
            </div>
        </div>
    )
}

const FormNewPlage = styled(FormNewPlageCtrl)`
    .conflits{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        padding: 0.5rem 1rem;
        background: ${props => props.theme.ComplementaryLight};
        color: white;
        border-radius: 4px;
    }
    .wrap_set_horaires{
        display: flex;
        justify-content: flex-start;
        .wrap_horaires{
            padding-left: 20px;
        }
        
        .quicks{
            width: 70%;
            padding-right: 20px;
            display: flex;
            flex-direction: column;
            gap: 6px;
            justify-content: center;
            button{
                display: block;
                background: ${props => props.theme.Primary};
                padding: 0.25rem 0.2rem;
                border-radius: 4px;
                color: white;
                border:none;
                position: relative;
                &:hover{
                    cursor: pointer;
                }
                &:active{
                    top: 1px;
                }
            }
        }
    }
`

export default FormNewPlage