import styled from "styled-components";
import {ReactNode, useEffect, useState} from "react";
import {MdChevronLeft, MdChevronRight} from "react-icons/md";
import {
    DateToDateStrFR,
    getDateWeekByDate,
    getLastWeekByDate,
    getNextWeekByDate,
    getNumWeekByDate
} from "../../functions/TimesFunctions";
import {useSearchParams} from "react-router-dom";
import {TitrePage} from "../../../assets/styles/elements";

interface TopCalendarCtrlProps{
    className?:string;
    User:User|null;
    date:Date;
    children?:ReactNode;
    titleCalendar:string;
}

const TopCalendarCtrl = (props:TopCalendarCtrlProps)=>{
    const [datePick, setDatePick] = useState<Date|null>(null)
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(()=>{
        const TabParams = [...Array.from(searchParams)];
        const TabFrom = TabParams.find(tab=>tab[0]==='from');
        const Today = new Date((new Date().setHours(12,0)));
        if(!TabFrom){
            console.log(Today)
            setDatePick(Today);
        } else {
            const TabDat = TabFrom[1].split('/');
            const dateFrom = new Date(`${TabDat[2]}-${TabDat[1]}-${TabDat[0]}T12:00:00`)
            setDatePick(dateFrom);
        }
    }, [searchParams])
    const MoveWeek = (sens:-1|1)=>{
        const params:any = {};
        if(datePick){
            console.log(datePick)
            searchParams.forEach((key, value) => {
                params[value] = key;
            });
            if(sens === -1) {
                const {prevMonday, prevSunday} = getLastWeekByDate(datePick);
                setSearchParams({...params, from:DateToDateStrFR(prevMonday), to:DateToDateStrFR(prevSunday)})
            } else{
                console.log("Move On")
                const {NextMonday, NextSunday} = getNextWeekByDate(datePick);
                console.log(NextMonday);
                console.log(DateToDateStrFR(NextMonday));
                setSearchParams({...params, from:DateToDateStrFR(NextMonday), to:DateToDateStrFR(NextSunday)})
            }
        }
    }
    return (
        <div className={`topCalendar ${props.className}`}>
            <div className="left_part">
                <TitrePage className={"noMargin"}>{props.titleCalendar} {props.User ? `de ${props.User.prenom} ${props.User.nom.toUpperCase()}` : ""} Semaine {getNumWeekByDate(props.date)}</TitrePage>
                <div className="wrap_mover">
                    <button className={`mover_btn left_one`} onClick={()=>MoveWeek(-1)}><MdChevronLeft /></button>
                    <button className={`mover_btn right_one`} onClick={()=>MoveWeek(1)}><MdChevronRight /></button>
                </div>
            </div>
            <div className="right_part">{props.children}</div>
        </div>
    )
}

const TopCalendar = styled(TopCalendarCtrl)`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    .left_part{
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        align-items: center;
        .wrap_mover{
            display: flex;
            justify-content: flex-start;
            gap: 4px;
            align-items: center;
        }
    }
    .mover_btn{
        border:none;
        background: ${props => props.theme.Primary};
        color: white;
        padding: 0.25rem 0.75rem;
        font-size: 16px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        transition-duration: 0.4s;
        position: relative;
        &:active{
            top: 1px;
        }
        &.left_one{
            border-radius: 6px 4px 4px 6px;
        }
        &.right_one{
            border-radius: 4px 6px 6px 4px;
        }
    }
`

export default TopCalendar