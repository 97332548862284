import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getAllEventsCollab(id:number):Promise<EventCollab[]>{
    const res:any = await dataFetcher(`events?User=${id}`, {
        method:'get',
    })
    return res["hydra:member"];
}

export async function getEventsParams(params:ParamsGetEvents|null):Promise<EventCollab[]>{
    const TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `&${TabSearch.join('&')}` : ``
    const res:any = await dataFetcher(`events?pagination=false${search}`, {method:'get'})
    return res["hydra:member"];
}

export async function getAllEventsCollabFull(idCentre:number|null):Promise<EventCollab[]>{
    let url = `events`;
    if(idCentre){
        url = `events?User.Centre=${idCentre}`
    }
    const res:any = await dataFetcher(url, {
        method:'get',
    })
    return res["hydra:member"];
}

export async function getEventsDateDate(dateFrom:string, dateTo:string, idCentre:number):Promise<EventCollab[]>{
    const res:any = await dataFetcher(`events?eventAt[after]=${dateFrom}&eventAt[before]=${dateTo}&User.Centre=${idCentre}&pagination=false`, {
        method:'get',
    })
    return res["hydra:member"];
}

export function trashEvt(id:number):Promise<any>{
    return dataFetcher(`events/${id}`, {method:'DELETE'});
}

export async function getEventByType(id:number):Promise<EventCollab[]>{
    const res:any = await dataFetcher(`events?TypeEvent=${id}`, {method:'get'})
    return res["hydra:member"]
}


export function ChangeStatut(id:number, dateDid:string|null):Promise<EventCollab>{
    return dataFetcher(`events/${id}`, {
        method:'PUT',
        body:{didAt:dateDid}
    })
}

export function ModifyEventCollab(id:number, datas:EventCollabPut):Promise<EventCollab>{
    return dataFetcher(`events/${id}`, {
        method:'PUT',
        body:datas
    })
}
