import styled from "styled-components";
import {useDragLayer, XYCoord} from "react-dnd";
import {ItemsTypeHebdo} from "./ItemsTypeHebdo";
import HandleCopyDraggy from "./HandleCopyDraggy";


interface CustomDragLayerHandleCopyCtrlProps{
    className?:string;
}

function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        }
    }
    const { x, y } = currentOffset
    const transform = `translate(${x}px, ${y}px)`
    return {
        transform,
        WebkitTransform: transform,
    }
}

const CustomDragLayerHandleCopyCtrl = (props:CustomDragLayerHandleCopyCtrlProps)=>{
    const { itemType, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }))
    // if (!isDragging) {
    //     return null
    // }
    function renderItem() {
        switch (itemType) {
            case ItemsTypeHebdo.HANDLEcopy:
                return <HandleCopyDraggy plage={item}/>
            default:
                return null
        }
    }
    return (
        <div className={`customDragLayerHandleCopy ${props.className}`}>
            <div
                style={getItemStyles(initialOffset, currentOffset)}
            >
                {renderItem()}
            </div>
        </div>
    )
}

const CustomDragLayerHandleCopy = styled(CustomDragLayerHandleCopyCtrl)`
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
`

export default CustomDragLayerHandleCopy