import React, {useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import WidgetGraph from "../Components/WidgetGraph";
import {useGetCreneauxFullParams} from "../../../features/creneau/creneau.hooks";
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {TabMois} from "../../../services/constantes/constantes";
import {useCentreConnectedData} from "../../../services/hooks";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    maintainAspectRatio: false,
    forceOverride:true,
    plugins: {
        legend: {
            position: 'top' as const,
            display: false,
        },
        datalabels:{
            display:false
        },
        title: {
            display: false,
            text: 'Historiques des heures',
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

interface WidgetNbHeureVCtrlProps{
    supervisor?:boolean;
}
interface OneMonthTo{
    date:Date,
    numMois:number,
    year:number,
    libelle:string;
}

const WidgetNbHeureVCtrl = ({supervisor}:WidgetNbHeureVCtrlProps)=>{
    const centre = useCentreConnectedData();
    const theme = useTheme();
    const yToday = new Date().getFullYear();
    const mToday = new Date().getMonth();
    const DateFrom = new Date(yToday-1, mToday, 1);
    const Params:any = !supervisor ? {"creneauAt[after]":DateFrom.toISOString(), isTreat:1} : {"creneauAt[after]":DateFrom.toISOString(), isTreat:1, Centre:centre}
    const CreneauxQuery = useGetCreneauxFullParams(Params);
    const [data, setData] = useState<any|null>(null);
    useEffect(()=>{
        if(CreneauxQuery.data){
            const dateEnd = new Date(yToday, mToday, 1);
            let starty = DateFrom;
            let datasAll:any[] = [];
            let datasets:any[] = [];
            let labels:string[] = [];
            let TabMonths:OneMonthTo[] = []
            while(starty <= dateEnd){
                const myDate = starty;
                const MyMonth = TabMois.find(m=>m.num === myDate.getMonth()+1)
                if(MyMonth){
                    labels.push(`${MyMonth.name} ${myDate.getFullYear().toString().slice(-2)}`)
                    TabMonths.push({
                        numMois:MyMonth.num,
                        year:myDate.getFullYear(),
                        libelle:MyMonth.name,
                        date:myDate
                    })
                }
                starty = new Date(starty.getTime() + 86400000*32);
            }
            TabMonths.map(item=>{
                const CreneauxConc = CreneauxQuery.data.filter(c=>new Date(c.creneauAt).getMonth()+1 === item.numMois && item.year === new Date(c.creneauAt).getFullYear())
                const Times = CreneauxConc.reduce((acc, itemC)=>acc+((itemC.heureFin - itemC.heureDebut)/12), 0)
                datasAll.push(Math.round(Times*100)/100)
                return item;
            })
           datasets.push(
               {
                   label:"nb",
                   data:datasAll,
                   backgroundColor: theme.Primary,
               }
           )
            setData({labels, datasets})
        }
    }, [CreneauxQuery.data])
    return (
        <WidgetGraph
            width={"100%"}
            title={"Nb heures remplacées validées"}
            isPending={(CreneauxQuery.isLoading || data === null)}
        >
            {(CreneauxQuery.isLoading || data === null) ? 'chargement...':
                <div className={`canvas-container`}>
                    <MyChartBar options={options} data={data}/>
                </div>
            }
        </WidgetGraph>
    )
}

const MyChartBar = ({...props})=>{
    return <Bar options={props.options}  data={props.data} />;
}

export const WidgetNbHeureV = styled(WidgetNbHeureVCtrl)``