import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult
} from "react-query";
import {
    addReguleTemps, cancelPT, changeFerie, getMetrixHebdo,
    getOneDayOneUser,
    getOnePointeuseTreat,
    getPointeuseTreatPage,
    getPTbyParams, resolutionPause, resolutionPlanning,
    SavePointeuseTreat, SavePointeuseTreatReso
} from "./pointeuseTreat.services";
import {toast} from "react-toastify";


export const useGetPointeuseTreatOneDayOneUser = (idUser:number, dateFor:Date, idCentre:number|null=null):UseQueryResult<PointeuseTreat[], Error>=>{
    const dateForStr = `${dateFor.getFullYear()}-${(`0${dateFor.getMonth()+1}`).slice(-2)}-${(`0${dateFor.getDate()}`).slice(-2)}`
    return useQuery<PointeuseTreat[], Error>(["pointeuse_treat", idUser, dateFor, idCentre], ()=>getOneDayOneUser(idUser, dateForStr, idCentre), {
        staleTime:6000,
        enabled:idUser!==0
    })
}

export const UseGetPointeuseTreatByParams = (params:any|null|undefined):UseQueryResult<PointeuseTreat[], Error>=>{
    return useQuery<PointeuseTreat[], Error>(["pointeuse_treat", params], ()=>getPTbyParams(params), {
        staleTime:60000*5,
        enabled:params!==undefined
    })
}

export const useGetPointeuseTreatInfinite = (params:any|null|undefined=null):UseInfiniteQueryResult<PointeuseTreatPage, Error>=>{
    return useInfiniteQuery<PointeuseTreatPage, Error>(["pointeuse_treat", params], ({pageParam=`pointeuse_treats?page=1`})=>getPointeuseTreatPage(pageParam, params),  {
        getNextPageParam: (lastPage, allPages) =>{return lastPage.nextPage},
        enabled:params!==undefined
    })
}

export const useGetOnePointeuseTreat = (id:number):UseQueryResult<PointeuseTreat, Error>=>{
    return useQuery<PointeuseTreat, Error>(["pointeuse_treat", id], ()=>getOnePointeuseTreat(id), {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}

export const useModifyPointeuseTreat = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PointeuseTreatModifyFormData)=>{
            return SavePointeuseTreat(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["pointeuse_treat"]);
                queryClient.invalidateQueries(["cpt_tps_dd"]);
                queryClient.invalidateQueries(["metrix_hebdo"]);
                queryClient.invalidateQueries(["plage_time"]);
                queryClient.invalidateQueries(["compteur_temps_travail"]);
                queryClient.invalidateQueries(["anomalies"])
                // queryClient.invalidateQueries(["anomalies"]);
            }, onError:()=>{
                toast.error("Une erreur est survenue...");
            }
        }
    )
}
export const useResolutionPT = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PTResoPost)=>{
            return SavePointeuseTreatReso(datas)
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["pointeuse_treat"]);
                queryClient.invalidateQueries(["cpt_tps_dd"]);
                queryClient.invalidateQueries(["metrix_hebdo"]);
                queryClient.invalidateQueries(["plage_time"]);
                queryClient.invalidateQueries(["compteur_temps_travail"]);
                queryClient.invalidateQueries(["anomalies"])
                // queryClient.invalidateQueries(["anomalies"]);
            }, onError:()=>{
                toast.error("Une erreur est survenue...");
            }
        }
    )
}

export const useCancelRegule = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return cancelPT(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["pointeuse_treat"]);
                queryClient.invalidateQueries(["cpt_tps_dd"]);
                queryClient.invalidateQueries(["metrix_hebdo"]);
                queryClient.invalidateQueries(["plage_time"]);
                queryClient.invalidateQueries(["compteur_temps_travail"]);
                queryClient.invalidateQueries(["details_daily"]);
                queryClient.invalidateQueries(["calendrier_paie_list"]);
                queryClient.invalidateQueries(["times_user_weekly"]);
                queryClient.invalidateQueries(["times_user_cal"]);
            }, onError:()=>{
                toast.error("Une erreur est survenue...");
            }
        }
    )
}

export const useAddReguleTemps = ()=>{
    const queryClient = useQueryClient();
    const UserConnected:UserSimple|undefined = queryClient.getQueryData(["user_connected"]);
    const idUserConnected = UserConnected ? UserConnected.id : 0;
    return useMutation(
        (datas:ReguleTpsFD)=>{
            return addReguleTemps(datas, idUserConnected);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["pointeuse_treat"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                queryClient.invalidateQueries(["metrix_hebdo"])
                queryClient.invalidateQueries(["plage_time"]);
                queryClient.invalidateQueries(["compteur_temps_travail"]);
                queryClient.invalidateQueries(["compteur_temps_travail_week"]);
                queryClient.invalidateQueries(["details_daily"]);
                queryClient.invalidateQueries(["calendrier_paie_list"]);
                queryClient.invalidateQueries(["times_user_weekly"]);
                queryClient.invalidateQueries(["times_user_cal"]);
                queryClient.invalidateQueries(["compteur_hs"]);
                toast.success("Régulation ajoutée !")
            }, onError:()=>{
                toast.error("Une erreur est survenue...");
            }
        }
    )
}

export const useChangeFerie = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas: { fd:ChangeFerieFD, id:number })=>{
            return changeFerie(datas.fd, datas.id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["pointeuse_treat"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                queryClient.invalidateQueries(["metrix_hebdo"])
                queryClient.invalidateQueries(["plage_time"])
                queryClient.invalidateQueries(["compteur_temps_travail"])
                toast.success("Jour férié modifié !")
            }, onError:()=>{
                toast.error("Une erreur est survenue...");
            }
        }
    )
}

export const useResolutionPlanning = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (ids:number[])=>{
            return resolutionPlanning(ids);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["pointeuse_treat"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                queryClient.invalidateQueries(["metrix_hebdo"])
                queryClient.invalidateQueries(["plage_time"])
                queryClient.invalidateQueries(["compteur_temps_travail"])
                queryClient.invalidateQueries(["anomalies"])
                // queryClient.invalidateQueries(["anomalies"]);
                toast.success("Anomalie(s) résolue(s) avec le planning !")
            }, onError:()=>{
                toast.error("Une erreur est survenue...");
            }
        }
    )
}

export const useResolutionPause = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:{idPT:number, idFor:number})=>{
            return resolutionPause(data.idPT, data.idFor);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["pointeuse_treat"])
                queryClient.invalidateQueries(["cpt_tps_dd"])
                queryClient.invalidateQueries(["metrix_hebdo"])
                queryClient.invalidateQueries(["plage_time"])
                queryClient.invalidateQueries(["compteur_temps_travail"])
                // queryClient.invalidateQueries(["anomalies"]);
                toast.success("Anomalie(s) résolue(s) avec le planning !")
            }, onError:()=>{
                toast.error("Une erreur est survenue...");
            }
        }
    )
}

export const useGetMetrixHebdo = (id:number, date:string):UseQueryResult<MetrixHebdo>=>{
    return useQuery(["metrix_hebdo", id, date], ()=>getMetrixHebdo(id, date), {
        enabled:id!==0 && date!==''
    })
}