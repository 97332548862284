import React from "react";
import styled, {DefaultTheme} from "styled-components";
import PulseLoader from "../TimedSpinners/PulseLoader";

interface TimedButtoOutlineCtrlProps{
    /**
     * De quelle taille le bouton doit être ?
     */
    size?: "xs" | "sm" | "md" | "l" | "xl";
    /**
     * Couleur du bouton
     */
    themeColor:keyof DefaultTheme
    /**
     * Bouton Rond ou juste radius
     */
    isRounded?:boolean;
    /**
     * Texte dans le bouton
     */
    text?:string;
    children?: React.ReactNode;
    /**
     * Fonction réagissant au click
     */
    onClick?: Function;
    /**
     * Doit-il être désactivé ou non ?
     */
    disabled?: boolean;
    /**
     * Classe HTML du bouton ?
     */
    className?: string;
    /**
     * En train de travailler ?
     */
    isPending?: boolean;
    type?: "button" | "reset" | "submit";
    isFull?:boolean;
    infosSurvol?:string;
}

const TimedButtoOutlineCtrl = ({themeColor, size, disabled, type, text, children, isPending, onClick, isFull, className, isRounded, infosSurvol, ...props }:TimedButtoOutlineCtrlProps)=>{
    const handleClick = ()=>{
        if(onClick){
            onClick();
        }
    }
    return (
        <button onClick={handleClick} type={type} disabled={isPending ? isPending : disabled} className={`ButtonTimed ${isFull ? 'fully' : ''} ${className}`} {...props}>
            {isPending ? <PulseLoader className={'my-waity'} size={"xs"} themeColor={"Neutre"}/> : children||text  }
            {infosSurvol &&
                <div className="infos_survol">{infosSurvol}</div>
            }
        </button>
    )
}

const TimedButtoOutline = styled(TimedButtoOutlineCtrl)`
    border-radius: ${props => props.isRounded ? "25px" : "4px"};
    font-size: ${props=>props.size === "xs" ? "12px" : props.size==="sm" ? "15px" : props.size==="md" ? "18px" : props.size==="l" ? "20px" : "22px"};
    padding: ${props=>props.size === "xs" ? "0.25rem 0.5rem" : "0.5rem 2rem"};
    border-width: 2px;
    border-style: solid;
    background: transparent;
    
  //font-variant: small-caps;
  //letter-spacing: 0.10rem;
  font-weight: 400;
    position: relative;
    .infos_survol{
        position: absolute;
        left: 50%;
        width: max-content;
        padding: 0.5rem;
        background: ${props=>props.theme.Complementary};
        color: white;
        font-size: 14px;
        top: calc(100% + 5px);
        border-radius: 4px;
        transform: translateX(-50%);
        display: none;
    }
    .spanIc{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
    }
  &:hover:not(:disabled){
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
      font-weight: bold;
  }
    &:hover{
        .infos_survol{
            display: block;
        }
    }
  &.fully{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: block;
  }
  .my-waity{
    margin-right: 5px;
  }
  border-color: ${props=>
          props.themeColor === "Primary" ? props.theme.Primary:
                  props.themeColor === "Complementary" ? props.theme.ComplementaryLight:
                  props.themeColor === "NeutreDark" ? props.theme.NeutreDark:
              props.themeColor === "Secondary" ? props.theme.Secondary:
              props.themeColor === "Tertiary" ? props.theme.Tertiary : 
                props.themeColor === "Warning" ? props.theme.Warning:
                props.themeColor === "Success" ? props.theme.SuccessLight
                : props.theme.NeutreLight
  };
  color: ${props=> props.theme[props.themeColor]};
  &:disabled{
    background: ${props=>
            props.themeColor === "Primary" ? props.theme.PrimaryExtraLight:
                    props.themeColor === "Secondary" ? props.theme.SecondaryExtraLight:
                            props.themeColor === "Tertiary" ? props.theme.TertiaryExtraLight :
                                    props.themeColor === "Warning" ? props.theme.WarningExtraLight:
                                            props.themeColor === "Success" ? props.theme.SuccessExtraLight
                                    : props.theme.NeutreExtraLight
    };
      color: ${props=>props.theme.Neutre};
  }
    &:active{
        transform: scale(0.9);
        border: none;
    }
`

TimedButtoOutline.defaultProps={
    themeColor:"Neutre",
    size:"sm",
    isPending:false,
    disabled:false,
    text:"Button",
}

export default TimedButtoOutline;