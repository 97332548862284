import styled from "styled-components";
import {useGetDetailsAbsence} from "../../../../../../../features/tempsTravail/tempsTravail.hooks";
import {TimedLoading} from "../../../../../../../components/atoms/TimedWaitUser/TimedLoading";
import {format} from "date-fns";

interface TempsTrAbsencesV2CtrlProps{
    className?:string;
    user:User;
    calendrier:CalendrierPaie
}

const TempsTrAbsencesV2Ctrl = (props:TempsTrAbsencesV2CtrlProps)=>{
    const DetailsQuery = useGetDetailsAbsence(props.calendrier.id, props.user.id);
    return (
        <div className={`tempsTrAbsencesV2 ${props.className}`}>
            {DetailsQuery.isLoading ? <div className="wrap_load"><TimedLoading width={"60px"}/></div> : !DetailsQuery.data ? "ErrorData" :
                <div className="in_abs">
                    <div className="col_abs">
                        <h3 className="subCat">Les Congés payés</h3>
                        <table className={`table_in_temps`}>
                            <thead>
                            <tr>
                                <th className={"left is_date"}>Dates de l'absence</th>
                                <th className={"is_qty"}>Qté</th>
                                <th className={"left"}>Crée par</th>
                                <th className={"left"}>Validé par</th>
                            </tr>
                            </thead>
                            <tbody>
                            {DetailsQuery.data.CP.list.length === 0 ? <tr>
                                    <td className={`no_data`} colSpan={4}>Aucune absence</td>
                                </tr> :
                                DetailsQuery.data.CP.list.map((item, idx) => (
                                <tr key={`LineCP${idx}`}>
                                    <td>{item.libDates}</td>
                                    <td className={"center"}>{item.qty}</td>
                                    <td className={"left"}>{item.Demande.UserCreate?.prenom} {item.Demande.UserCreate?.nom.toUpperCase()} le {format(new Date(item.Demande.creationAt), "dd/MM/yyyy")}</td>
                                    <td className={"left"}>{!item.Demande.UserLastModifier ? "nc" : `${item.Demande.UserLastModifier?.prenom} ${item.Demande.UserCreate?.nom.toUpperCase()} le ${item.Demande.updateAt ? format(new Date(item.Demande.updateAt), "dd/MM/yyyy") : ""}`}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <div className="wrap_ctr_cp">
                            <table className={`solde_cp`}>
                                <tbody>
                                <tr>
                                    <td>Solde avant la période</td>
                                    <td>
                                        <div className={`wrap_nb ${DetailsQuery.data.CP.SoldeIn > 0 ? "pos" : "neg"}`}>{DetailsQuery.data.CP.SoldeIn > 0 ? "+" : ""}{DetailsQuery.data.CP.SoldeIn}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre de jours pris</td>
                                    <td>
                                        <div className="wrap_nb neg">-{DetailsQuery.data.CP.nbJours}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Solde fin de la période avant acquisition</td>
                                    <td>
                                        <div className={`wrap_nb ${(DetailsQuery.data.CP.SoldeIn - DetailsQuery.data.CP.nbJours) > 0 ? "pos" : "neg"}`}>
                                            {(DetailsQuery.data.CP.SoldeIn - DetailsQuery.data.CP.nbJours) > 0 ? "+" : ""}{Math.round((DetailsQuery.data.CP.SoldeIn - DetailsQuery.data.CP.nbJours) * 100) / 100}
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col_abs">
                        <h3 className="subCat">Les Autres demandes</h3>
                        <table className={`table_in_temps`}>
                            <thead>
                            <tr>
                                <th className={"left is_date"}>Dates de l'absence</th>
                                <th className={"is_qty"}>Qté</th>
                                <th className={"left"}>Type</th>

                                <th className={"left"}>Crée par</th>
                                <th className={"left"}>Validé par</th>
                            </tr>
                            </thead>
                            <tbody>
                            {DetailsQuery.data.AutreDemandes.list.length === 0 ? <tr>
                                    <td className={`no_data`} colSpan={5}>Aucune absence</td>
                                </tr> :
                                DetailsQuery.data.AutreDemandes.list.map((item, idx) => (
                                    <tr key={`LineNoCP${idx}`}>
                                        <td>{item.libDates}</td>
                                        <td className={"center"}>{item.qty}</td>
                                        <td className={"left"}>{item.Acte}</td>
                                        <td className={"left"}>{item.Demande.UserCreate?.prenom} {item.Demande.UserCreate?.nom.toUpperCase()}</td>
                                        <td className={"left"}>{!item.Demande.UserLastModifier ? "nc" : `${item.Demande.UserLastModifier?.prenom} ${item.Demande.UserCreate?.nom.toUpperCase()}`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col_abs">
                        <h3 className="subCat">Les Absences sans demande</h3>
                        <table className={`table_in_temps`}>
                            <thead>
                            <tr>
                                <th className={"left is_date"}>Dates de l'absence</th>
                                <th className={"is_qty"}>Qté</th>
                                <th className={"left"}>Type</th>
                            </tr>
                            </thead>
                            <tbody>
                            {DetailsQuery.data.NoDemandes.list.length === 0 ? <tr>
                                    <td className={`no_data`} colSpan={3}>Aucune absence sans demande</td>
                                </tr> :
                                DetailsQuery.data.NoDemandes.list.map((item, idx) => (
                                    <tr key={`LineNoDem${idx}`}>
                                        <td>{format(new Date(item.Plage.plageAt.slice(0, 10) + "T12:00:00"), "dd/MM/")}</td>
                                        <td>{item.qty}</td>
                                        <td>{item.Plage.Acte.libelle}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                    {DetailsQuery.data.DemandesNoResolve.length >0 &&
                        <div className="col_abs">
                            <h3 className="subCat">Les Absences sans suite</h3>
                            <table className={`table_in_temps`}>
                                <thead>
                                <tr>
                                    <th className={"left is_date"}>Dates de l'absence</th>
                                    <th className={"center"}>Type</th>
                                    <th className={"left"}>Crée par</th>
                                    <th className={"left"}>Statut</th>
                                </tr>
                                </thead>
                                <tbody>
                                {DetailsQuery.data.DemandesNoResolve.map((item, idx)=>(
                                    <tr key={`NoR${idx}`}>
                                        <td>{format(new Date(item.startAt.slice(0, 10) + "T12:00:00"), "dd/MM")} - {format(new Date(item.endAt.slice(0, 10) + "T12:00:00"), "dd/MM")}</td>
                                        <td className={"center"}>{item.Acte.libelle}</td>
                                        <td className={"left"}>{item.UserCreate?.prenom} {item.UserCreate?.nom.toUpperCase()}</td>
                                        <td className={"left"}>{item.Statut.libelle}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

const TempsTrAbsencesV2 = styled(TempsTrAbsencesV2Ctrl)`
    flex-grow: 1;
    background: white;
    .wrap_ctr_cp{
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        table{
            border: solid ${props => props.theme.NeutreLight} 1px;
        }
    }
    h3{
        margin-bottom: 15px;
        color:${props => props.theme.Dark};
        font-size: 20px;
    }
    .in_abs{
        display: flex;
        flex-direction: column;
        gap: 60px;
        background: white;
        padding: 1rem 1rem 2rem 1rem;
        .col_abs{
        }
    }
    
`

export default TempsTrAbsencesV2