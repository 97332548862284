import styled from "styled-components";
import {ReactNode} from "react";

interface WrapperSenderCtrlProps{
    className?:string;
    position:"center"|"left"|"right";
    children:ReactNode;
}

const WrapperSenderCtrl = (props:WrapperSenderCtrlProps)=>{
    return (
        <div className={`wrapperSender ${props.className}`}>{props.children}</div>
    )
}

const WrapperSender = styled(WrapperSenderCtrl)`
    padding: 1rem 0;
    display: flex;
    justify-content: ${props=>props.position === "center" ? "center" : props.position === "left" ? "flex-start" : "flex-end"};
`

export default WrapperSender