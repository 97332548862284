import styled from "styled-components";
import {useEffect, useRef, useState} from "react";

import "./StyleForm.css"
import {ConvertBase5, ConvertToBase5} from "../../functions/TimesFunctions";

interface TimedSelectHDHFCtrlProps{
    className?:string;
    HDCurr:number;
    HFCurr:number;
    setHoraires:(hd:number, hf:number)=>void;
    bySide?:boolean;
    isDisabled?:boolean;
}
const ConvertToHeurex = (base5:number, type:"HH"|"MM")=>{
    const My:string = ConvertBase5(base5);
    if(type === "HH") {
        return My.slice(0, 2);
    } else {
        return My.slice(-2);
    }
}

const ConvertBase5ToHorThis = (base5:number)=>{
    return {HH:ConvertToHeurex(base5, "HH"), MM:ConvertToHeurex(base5, "MM")}
}

interface HorThis{
    HH:string;
    MM:string;
}

const TimedSelectHDHFCtrl = ({setHoraires, ...props}:TimedSelectHDHFCtrlProps)=>{
    const [myHD, setMyHD] = useState<HorThis>(ConvertBase5ToHorThis(props.HDCurr))
    const [myHF, setMyHF] = useState<HorThis>(ConvertBase5ToHorThis(props.HFCurr))
    const [isChange, setIsChange] = useState(false);
    const horRefs = useRef<Array<HTMLInputElement | null>>([]);
    const getValueByIdx = (idx:number)=>{
        switch (idx){
            case 0:
                return myHD.HH;
            case 1:
                return myHD.MM;
            case 2:
                return myHF.HH;
            case 3:
                return myHF.MM
            default:
                return 0;
        }
    }
    useEffect(() => {
        setMyHD(ConvertBase5ToHorThis(props.HDCurr))
    }, [props.HDCurr]);
    useEffect(() => {
        setMyHF(ConvertBase5ToHorThis(props.HFCurr))
    }, [props.HFCurr]);
    const handleElement = (idx:number)=>{
        const myValue = horRefs.current[idx]?.value;
        handleChangeRef(idx, myValue, true)
    }
    useEffect(() => {
        const isValidHD = (myHD.HH.length === 2 && myHD.MM.length === 2)
        const isVaidHF = (myHF.HH.length === 2 && myHF.MM.length === 2)
        if(isValidHD && isVaidHF && isChange){
            const newHD = ConvertToBase5(`${myHD.HH}:${myHD.MM}`)
            const newHF = ConvertToBase5(`${myHF.HH}:${myHF.MM}`)
            setHoraires(newHD, newHF)
            setIsChange(false);
        }
    }, [myHD, myHF, isChange, setHoraires]);
    const handleChangeRef = (idx:number, myValue:string|undefined, change=false)=>{
        const rgx = /^[0-9]*\.?[0-9]*$/;
        if(myValue?.match(rgx)){
            setIsChange(true);
            switch (idx){
                case 0:
                    setMyHD(prevHD=>{
                        return {...prevHD, HH:myValue}
                    })
                    if(myValue?.length === 2 && !!horRefs.current[idx+1] && change){
                        (horRefs.current[idx+1] as HTMLInputElement).focus();
                    }
                    break;
                case 1:
                    setMyHD(prevHD=>{
                        return {...prevHD, MM:myValue}
                    })
                    if(myValue?.length === 2 && !!horRefs.current[idx+1] && change){
                        (horRefs.current[idx+1] as HTMLInputElement).focus();
                    }
                    break;
                case 2:
                    setMyHF(prevHF=>{
                        return {...prevHF, HH:myValue}
                    })
                    if(myValue?.length === 2 && !!horRefs.current[idx+1] && change){
                        (horRefs.current[idx+1] as HTMLInputElement).focus();
                    }
                    break;
                case 3:
                    setMyHF(prevHF=>{
                        return {...prevHF, MM:myValue}
                    })
                    if(myValue?.length === 2 && !!horRefs.current[0] && change){
                        (horRefs.current[0] as HTMLInputElement).focus();
                    }
                    break;
                default:
                    break;
            }
        }
    }
    const Convert2DigitH = (idx:number)=>{
        const myValue = horRefs.current[idx]?.value;
        const valNB = parseInt(myValue||"")
        const Elt =  `0${valNB}`.slice(-2);
        handleChangeRef(idx, Elt)
    }

    return (
        <div className={`timedSelectHdhf ${props.className}`}>
            <div className="one_hor">
                <label className={"std"}>Heure début</label>
                <div className="wrap_inputs_hor">
                    {[0, 1].map(nb => (
                        <div className="wrap_input_time" key={`WrapTime${nb}`}>
                            <input
                                disabled={props.isDisabled}
                                className={"std"}
                                value={getValueByIdx(nb)}
                                type={"text"} key={`H${nb}`}
                                ref={(el) => horRefs.current[nb] = el}
                                onChange={() => handleElement(nb)}
                                onBlur={() => Convert2DigitH(nb)}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="one_hor">
                <label className={"std"}>Heure Fin</label>
                <div className="wrap_inputs_hor">
                    {[2, 3].map(nb => (
                        <div className="wrap_input_time" key={`WrapTime${nb}`}>
                            <input
                                disabled={props.isDisabled}
                                className={"std"}
                                value={getValueByIdx(nb)}
                                type={"text"} key={`H${nb}`}
                                ref={(el) => horRefs.current[nb] = el}
                                onChange={() => handleElement(nb)}
                                onBlur={() => Convert2DigitH(nb)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const TimedSelectHDHF = styled(TimedSelectHDHFCtrl)`
    display: flex;
    flex-direction: ${props => props.bySide ? "row" : "column"};
    gap: 6px;
    .wrap_inputs_hor {
        display: flex;
        justify-content: flex-start;
        gap: 24px;
    }
    .wrap_input_time{
        position: relative;
        &:first-child{
            &:after{
                content:":";
                position: absolute;
                right: -12px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    input {
        background: ${props => props.theme.colorBackInput};
        text-align: center;
        padding-left: 0!important;
    }
`

export default TimedSelectHDHF