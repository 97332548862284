import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {ContextPlanning} from "../WeeklyCalendar";
import {ConvertBase5} from "../../../functions/TimesFunctions";


interface PlageShadowCtrlProps{
    className?:string;
    plage:Plage;
}
interface ConfigPlageProps{
    top:number;
    height:number;
    start:number;
    end:number;
}

const PlageShadowCtrl = (props:PlageShadowCtrlProps)=>{
    const Context = useContext(ContextPlanning)
    const [config, setConfig] = useState<ConfigPlageProps|null>(null)
    const [isConflit, setIsConflit] = useState(true)
    useEffect(() => {
        const startDecal = (props.plage.heureDebut - Context.config.start)/12;
        const top = startDecal*Context.config.heightHour;
        const height = (props.plage.heureFin - props.plage.heureDebut)/12 * Context.config.heightHour;
        if(Context.GetConflicts) setIsConflit(Context.GetConflicts(props.plage).length>0)
        setConfig({top:top, height:height, start:props.plage.heureDebut, end:props.plage.heureFin})
    }, [props.plage, Context]);
    if(!config || isConflit){
        return null;
    } else {
        return (
            <div className={`plageShadow ${props.className}`} style={{top:config.top+"px", height:config.height}}>
                <div className="in_shadow">
                    <div className={`LieuActe`}>{props.plage.Lieu.libelle} - {props.plage.Acte.libelle}</div>
                    <div
                        className={`Horaires`}>{ConvertBase5(config.start)} - {ConvertBase5(config.end)} ({Math.round(props.plage.myTime * 100) / 100}h)
                    </div>
                </div>
            </div>
        )
    }
}

const PlageShadow = styled(PlageShadowCtrl)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;

    .in_shadow {
        width: 90%;
        height: 100%;
        padding: 0.25rem;
        border-width: 1px 1px 1px 6px;
        border-radius: 8px;
        border-style: solid;
        border-color: white white white ${props => props.plage.Statut.color};
        overflow: hidden;
        background: ${props=>props.theme.NeutreExtraLight};
    }
`

export default PlageShadow