import styled from "styled-components";
import {useEffect, useState} from "react";
import {GrClose} from "react-icons/gr";
import {GetCSTByUserDate} from "../../../../functions/SemaineTypeFunctions";
import OneCardCST from "./OneCardCST";


interface ApplicSemTypeCtrlProps{
    className?:string;
    User:User;
    dateFirst:Date;
    HoverCST:(cst:CentreSemaineType)=>void;
    UnHoverCST:()=>void;
    ClickCST:(cst:CentreSemaineType)=>void;
    isSaving:boolean;
}

const ApplicSemTypeCtrl = (props:ApplicSemTypeCtrlProps)=>{
    const [open, setOpen] = useState<boolean>(false)
    const [CentreST, setCentreST] = useState<CentreSemaineType[]>([])
    const [CentreSTApplicable, setCentreSTApplicable] = useState<CentreSemaineType|null>(null)
    const [save, setSave] = useState(false)
    useEffect(() => {
        if(save && !props.isSaving){
            setOpen(false);
            setSave(false);
        } else if(props.isSaving){
            setSave(true);
        }
    }, [props.isSaving]);
    const SwitchOpen = ()=>{
        setOpen(o=>!o)
    }
    useEffect(() => {
        const sequences = props.User.sequence.sort((a,b)=>a.ordre > b.ordre ? 1 : -1);
        if(sequences.length>0){
            setCentreST(sequences.map(s=>s.CentreSemaineType))
        }
    }, [props.User]);
    useEffect(() => {
        setCentreSTApplicable(GetCSTByUserDate(props.User, props.dateFirst))
    }, [props.User, props.dateFirst]);
    const HoverCST = (cst:CentreSemaineType)=>{
        props.HoverCST(cst);
    }
    const UnHoverCST = ()=>{
        props.UnHoverCST()
    }
    const ClickCST = (cst:CentreSemaineType)=>{
        console.log('click on')
        props.ClickCST(cst)
    }
    if(CentreST.length === 0){
        return null;
    } else {
        return (
            <div className={`applicSemType ${props.className}`}>

                <button className={`wrap_cst_close ${open ? "opened": ""}`} onClick={SwitchOpen}><span>{open ?  <div className={`closer`}><span>Fermer</span><GrClose/></div> : "Semaines types"}</span></button>
                <div className={`wrap_csts ${open ? "opened" : ""}`} >
                    <div className="wrapper_in">
                        <div className="wrapper_wrappy">
                            <div className="wrappy_csts">
                                {save &&
                                    <div className="wait_save"/>
                                }
                                {CentreST.map(item=>(
                                    <OneCardCST CST={item} key={`OneCST${item.id}`} isApplicable={item.id === CentreSTApplicable?.id} Hover={()=>HoverCST(item)} unHover={UnHoverCST} Click={()=>ClickCST(item)}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const ApplicSemType = styled(ApplicSemTypeCtrl)`
    display: flex;
    justify-content: flex-start;
    .wait_save{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(255,255,255,.3);
        position: absolute;
    }
    .wrap_csts{
        .wrapper_in{
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            border-width: 1px 1px 1px 0;
            border-color:${props => props.theme.Neutre};
            border-style: solid;
            border-radius: 0 3px 3px 0;
            
        }
        overflow: hidden;
        transition: width 0.3s;
        border:none;
        width: 0;
        border-radius: 0 4px 4px 0;
        background: ${props => props.theme.NeutreExtraLight};
        &.opened{
            width: 50vw;
            transition: width 0.3s;
        }
        height: 40px;
        .wrapper_wrappy{
            position: absolute;
            width: calc(50vw - 20px);
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            height: calc(100% - 4px);
        }
        .close_csts{
            height: 32px;
            background: red;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            padding: 0 1rem;
            border: none;
            color:white;
            svg{
                margin: auto;
            }
        }
        .wrappy_csts{
            position: relative;
            width: 100%;
            overflow-x: auto;
            height: 100%;
            display: flex;
            gap: 14px;
            padding: 4px 0;
        }
    }
    .wrap_cst_close{
        height: 40px;
        width: 120px;
        font-size: 14px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0.5rem;
        background: ${props => props.theme.PrimaryMegaLight};
        border:solid 2px ${props => props.theme.Primary};
        color:${props => props.theme.Primary};
        font-weight: bold;
        .closer{
            display: flex;
            gap: 4px;
            align-items: center;
        }
        &.opened{
            color: white;
            border-radius: 4px 0 0 4px;
            background: ${props => props.theme.Neutre};
            border:solid 2px ${props => props.theme.Neutre};
        }
        &:hover{
            cursor: pointer;
        }
    }
`

export default ApplicSemType