import styled from "styled-components";
import {useSetLitts} from "../../../../features/centre/centre.hooks";
import {useEffect, useState} from "react";
import InputSelectSetting from "../components/InputSelectSetting";
import InputTextSetting from "../components/InputTextSetting";
import ModifyLoader from "../components/ModifyLoader";
import {useDebounce} from "../../../../services/hooks";

interface FormSetParamsRemplaCtrlProps{
    className?:string;
    isSubscribe:boolean;
    isRemplace:boolean;
    isRemplaceI:boolean;
    SwitchRempla:()=>void;
    SwitchRemplaI:()=>void;
    litterals:any;
    Centre:CentreComplet;
}
interface RemplaProps{
    Active:Choice|null;
    HonorairesDirect:Choice|null;
    PcRetro:number;
    RemplaInterne:Choice|null;
    NameCentre:string;
}


interface DetailsProps{
    Active:InfosProps;
    HonorairesDirect:InfosProps;
    RemplaInterne:InfosProps;
}
const TabInfos:DetailsProps = {
    Active:{keyProps:"Active", libelle:"Remplaçants - Actif", description:"En activant le module remplacement vous pourrez émettre des créneaux à des médecins remplaçants"},
    HonorairesDirect:{keyProps:"HonorairesDirect", libelle:"Remplaçants - Honoraires directs", description:"Détermine si vous pratiquez les règlements des honoraires de façon direct ou non"},
    RemplaInterne:{keyProps:"RemplaInterne", libelle:"Remplaçants - Remplacement interne", description:"Permettez-vous de faire des remplacements entre médecins titulaires"},

}


const FormSetParamsRemplaCtrl = (props:FormSetParamsRemplaCtrlProps)=>{
    const mutation = useSetLitts();
    const choiceActive:Choice[] = [{id:0, libelle:"Non", description:"Le module remplacement n'est pas activé"}, {id:1, libelle:"Oui", description:"Le module remplacement est activé"}]
    const choiceHD:Choice[] = [{id:0, libelle:"Non", description:"Vous ne pratiquez pas les honoraires directs"}, {id:1, libelle:"Oui", description:"Vous pratiquez les honoraires directs"}]
    const choiceRI:Choice[] = [{id:0, libelle:"Non", description:"Les remplacements internes ne sont pas autorisés"}, {id:1, libelle:"Oui", description:"Vous pratiquez des remplacements internes"}]
    const [nameCentre, setNameCentre] = useState(props.litterals.hasOwnProperty("NameRempla") ? props.litterals["NameRempla"] : "")
    const nameDebounce = useDebounce(nameCentre, 2500);
    const [errorPC, setErrorPC] = useState<string|null>(null)
    const [isNameSavable, setIsNameSavable] = useState(false);
    const [mySetting, setMySetting] = useState<RemplaProps>({
        Active:props.isRemplace ? choiceActive[1] : choiceActive[0],
        HonorairesDirect:choiceHD[props.litterals["honoDirect"]],
        PcRetro:props.litterals["Retro"],
        RemplaInterne:props.isRemplaceI ? choiceRI[1] : choiceRI[0],
        NameCentre:props.litterals.hasOwnProperty("NameRempla") ? props.litterals["NameRempla"] : ""
    })
    useEffect(() => {
        setMySetting(actual=>{
            return {...actual, Active:props.isRemplace ? choiceActive[1] : choiceActive[0]}
        })
    }, [props.isRemplace]);
    useEffect(() => {
        setMySetting(actual=>{
            return {...actual, HonorairesDirect:choiceHD[props.litterals["honoDirect"]],PcRetro:props.litterals["Retro"],RemplaInterne:props.isRemplaceI ? choiceRI[1] : choiceRI[0],NameCentre:props.litterals.hasOwnProperty("NameRempla") ? props.litterals["NameRempla"] : ""}
        })
    }, [props.litterals]);
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        if(id==="Active"){
            ChangeActive((value as Choice))
        } else if(id === "HonorairesDirect"){
            ChangeHono((value as Choice))
        } else if(id === "PcRetro"){
            ChangePcRetro((value as number))
        } else if(id==="NameCentre"){
            ChangeNameCentre((value as string))
        } else {
            ChangeRemplaInterne((value as Choice))
        }
    }
    const ChangeRemplaInterne = (value:Choice)=>{
        setMySetting(actual=>{
            return {...actual, RemplaInterne:value}
        })
        props.SwitchRemplaI();
    }

    const ChangeActive = (value:Choice)=>{
        setMySetting(actual=>{
            return {...actual, Active:value}
        })
        props.SwitchRempla();
    }
    const ChangeHono = (value:Choice)=>{
        setMySetting(actual=>{
            return {...actual, HonorairesDirect:value}
        })
        const actual = {...props.Centre.literalParameters}
        const newLitts = {...actual, honoDirect:value.id}
        mutation.mutate({idCentre:props.Centre.id, litt:newLitts})
    }
    const ChangePcRetro = (value:number)=>{

        setMySetting(actual=>{
            return {...actual, PcRetro:value}
        })
        if(value < 0 || value > 100){
            setErrorPC("doit être compris entre 0 et 100")
        } else {
            const actual = {...props.Centre.literalParameters}
            const newLitts = {...actual, Retro:value}
            mutation.mutate({idCentre:props.Centre.id, litt:newLitts})
        }
    }
    const ChangeNameCentre = (value:string)=>{
        setNameCentre(value);
        setIsNameSavable(true)
    }
    useEffect(() => {
        if(isNameSavable) {
            const actual = {...props.Centre.literalParameters}
            const newLitts = {...actual, NameRempla: nameDebounce}
            mutation.mutate({idCentre: props.Centre.id, litt: newLitts}, {
                onSuccess:()=>{
                    setIsNameSavable(false)
                }
            })
        }
    }, [nameDebounce, isNameSavable]);
    return (
        <div className={`formSetParamsRempla ${props.className}`}>
            {mutation.isLoading &&
                <ModifyLoader/>
            }
            <div className="wrap_inputs_setting loud">
                <InputSelectSetting setValue={setMyValue} Error={null} label={"Activer le module remplacement"} id={"Active"} choices={choiceActive} Current={mySetting.Active} ItemInfos={TabInfos.Active}/>
                {mySetting.Active?.id === 1 &&
                    <>
                        <InputSelectSetting setValue={setMyValue} Error={null} label={"Honoraires directs"} id={"HonorairesDirect"} choices={choiceHD} Current={mySetting.HonorairesDirect} ItemInfos={TabInfos.HonorairesDirect}/>
                        <InputTextSetting type={"number"} current={mySetting.PcRetro} setValue={setMyValue} Error={errorPC} id={"PcRetro"} label={"Pourcentage de rétrocession en %"}/>
                        <InputSelectSetting setValue={setMyValue} Error={null} label={"Remplacements internes"} id={"RemplaInterne"} choices={choiceHD} Current={mySetting.RemplaInterne} ItemInfos={TabInfos.RemplaInterne}/>
                        <InputTextSetting current={nameCentre} setValue={setMyValue} Error={null} id={"NameCentre"} label={`Nom du centre sur les contrats (ex : ${props.Centre.name})`}/>
                    </>
                }
            </div>
        </div>
    )
}

const FormSetParamsRempla = styled(FormSetParamsRemplaCtrl)`
    position: relative;
    .loud{
        padding: 2rem;
    }
`

export default FormSetParamsRempla