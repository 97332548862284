import jwt_decode from "jwt-decode";

const localStorageKey = "__timed_user_token__";
const localStorageKeyR = "__timed_user_refreshtoken__";

let refreshingFunc:any = undefined;

export function fileFetcher<T>(url:string, fileName:string):Promise<T|void>{
    const token = window.localStorage.getItem(localStorageKey);
    const headers:any = { Authorization : `Bearer ${token}` }
    return window.fetch(`${process.env.REACT_APP_API_URL_ROOT}${url}`, {method:'get', headers:headers})
        .then((response) => response.blob())
        .then((blob)=>{
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            // @ts-ignore
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            if(link.parentNode) link.parentNode.removeChild(link);
        })
}

const refreshToken = async <T>(): Promise<string>=>{
    const refresh_token =window.localStorage.getItem(localStorageKeyR)
    if(!refresh_token){
        return '';
        removeToken();
    }

    const body = {refresh_token:window.localStorage.getItem(localStorageKeyR)}

    let response = await fetch(`${process.env.REACT_APP_API_URL}/token/refresh`, {
        method:'POST',
        headers:{ "content-type": "application/json" },
        body: JSON.stringify(body)
    })
    const data = await response.json()
    if(data.status !== 200){
        removeToken();
    }
    localStorage.setItem("__timed_user_token__", JSON.stringify(data.token).replace(/['"]+/g, ""));
    localStorage.setItem("__timed_user_refreshtoken__", JSON.stringify(data.refresh_token).replace(/['"]+/g, ""));
    return JSON.stringify(data.token).replace(/['"]+/g, "")
}

export async function dataFetcher<T>(endpoint: string, { body, ...customConfig }: any = {}, isNoContentType:boolean = false): Promise<T | never> {
    let token = window.localStorage.getItem(localStorageKey);

    const headers: any = !isNoContentType ? { "content-type": "application/json" } : {};
    if (token) {
        const jwtDecode:any = jwt_decode(token)
        const exp =jwtDecode['exp'];
        const Today = new Date();
        const timeToday = Today.getTime();
        if(timeToday/1000>exp){
            if(!refreshingFunc) refreshingFunc = refreshToken;
            token = await refreshingFunc();
        }
        headers.Authorization = `Bearer ${token}`;
    }
    const config = {
        method: body ? "POST" : "GET",
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };
    if (body) {
        config.body = !isNoContentType ? JSON.stringify(body) : body;

    }
    return window.fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config).then(async (response) => {
        if (response.status === 401) {
            if (endpoint === "login") {
                throw new Error("401Error");
            }
            //removeToken();
            throw new Error("pas autorisé");
        }
        if (response.ok) {
            if (response.status === 204) {
                return;
            }
            return await response.json();
        } else {
            const errorMessage = await response.text();
            const errorCode = response.status;
            throw new Error(errorCode + errorMessage);
        }
    });
}

export function removeToken() {
    let paramsF = window.location.search;
    let params = "";
    if(paramsF.length>1){
        params = paramsF;
    }
    localStorage.setItem("q", JSON.stringify(params));
    window.localStorage.removeItem(localStorageKey);
    window.localStorage.removeItem(localStorageKeyR);
    window.location.assign(window.location.href);
}
