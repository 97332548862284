import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import {useGetAllStatutPlage} from "../../../../features/statutPlage/statutPlage.hooks";
import {ContextPlanning} from "../WeeklyCalendar";
import TimedSelectChoice from "../../../atoms/TimedForm324/TimedSelectChoice";


interface ChangeStatusCtrlProps{
    className?:string;
    plage:Plage;
    open:boolean;
    CloseMe:()=>void;
}

const ChangeStatusCtrl = (props:ChangeStatusCtrlProps)=>{
    const StatutQuery = useGetAllStatutPlage();
    const Context = useContext(ContextPlanning);
    const [statut, setStatut] = useState<Choice>({id:props.plage.Statut.id, libelle:props.plage.Statut.libelle})
    useEffect(() => {
        setStatut({id:props.plage.Statut.id, libelle:props.plage.Statut.libelle})
    }, [props.plage]);
    const HandleChoice = (c:Choice|null)=>{
        setStatut(c as Choice);
        if(Context.ModifyPlage && StatutQuery.data && !!c){
            const myStatut = StatutQuery.data.find(s=>s.id === c.id) as StatutPlage
            Context.ModifyPlage({...props.plage, Statut:myStatut})
            if(Context.saveModify){
                Context.saveModify({id:props.plage.id, Statut:`/api/statut_plages/${myStatut.id}`})
            }
        }
        props.CloseMe();
    }
    return (
        <div className={`changeStatus ${props.open ? "visible" : "invisible"} ${props.className}`}>
            {StatutQuery.data &&
                <TimedSelectChoice choiceCurr={statut} setChoice={HandleChoice} choices={StatutQuery.data.map(s=> {
                    return {id: s.id, libelle: s.libelle}
                })} error={null}/>
            }
        </div>
    )
}

const ChangeStatus = styled(ChangeStatusCtrl)`
    width: 100%;
    height: 0;
    transition: height 0.3s;
    overflow: hidden;
    &.visible{
        height: 38px;
        overflow: visible;
    }
`

export default ChangeStatus