import styled from "styled-components";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import {TiPlus} from "react-icons/ti";
import TitrePageSetting from "../components/TitrePageSetting";
import {useModal} from "../../../../hooks/useModal";
import PageDoubleSetting from "../components/PageDoubleSetting";
import LeftPartDoublePage from "../components/LeftPartDoublePage";
import OneBlockLeftPart from "../components/OneBlockLeftPart";
import RightPartDoublePage from "../components/RightPartDoublePage";
import {useEffect, useState} from "react";
import {useGetFonctions} from "../../../../features/fonction/fonction.hooks";
import WrapListFx from "./WrapListFx";
import FormEditFonction from "./FormEditFonction";
import {TimedModalCentral2} from "../../../../components/molecules/TimedModal/TimedModalCentral2";
import FormNewFonction from "./FormNewFonction";

interface ParamsFonctionCtrlProps{
    className?:string;
}

const ParamsFonctionCtrl = (props:ParamsFonctionCtrlProps)=>{
    const {open:openNew, toggle:toggleNew} = useModal()
    const FonctionsQuery = useGetFonctions()
    const [FxSet, setFxSet] = useState<Fonction|null>(null)
    const [mesFonction, setMesFonctions] = useState<Fonction[]>([])
    useEffect(() => {
        if(FonctionsQuery.data){
            setMesFonctions(FonctionsQuery.data)
        }
    }, [FonctionsQuery.data]);
    useEffect(() => {
        if(!FxSet){
            setFxSet(mesFonction.filter(a=>a.isProduction && a.id !==1).sort((a,b)=>(a.id||0) > (b.id||0) ? 1 : -1)[0])
        }
    }, [mesFonction, FxSet]);
    const EndCreate = (fx:Fonction)=>{
        setFxSet(fx);
        toggleNew();
    }
    return (
        <div className={`paramsFonction page_params ${props.className}`}>
            <TitrePageSetting titre={"Gestion des fonctions"}>
                <TimedButton onClick={toggleNew} themeColor={"Primary"} size={"sm"}>
                    <div className={`wrap_in_btn`}><TiPlus /><span>Créer une fonction</span></div>
                </TimedButton>
            </TitrePageSetting>
            <PageDoubleSetting>
                <LeftPartDoublePage titre={"Vos Fonctions"}>
                    <div className="multi_block">
                        <OneBlockLeftPart titre={"Fonctions actives"}>
                            <WrapListFx Fxs={mesFonction.filter(fx=>fx.isProduction)} setFxActif={setFxSet} FxActif={FxSet}
                                          isActifView={true}/>
                        </OneBlockLeftPart>
                        <OneBlockLeftPart titre={"Fonctions Inactives"}>
                            <WrapListFx Fxs={mesFonction.filter(fx=>!fx.isProduction)} setFxActif={setFxSet} FxActif={FxSet}
                                        isActifView={false}/>
                        </OneBlockLeftPart>
                    </div>
                </LeftPartDoublePage>
                <RightPartDoublePage titre={FxSet ? "Configuration de la fonction "+FxSet.libelle : "Configuration fonction"} subtitle={"Modifier les paramètres de la fonction en cours"}>
                    {FxSet &&
                        <FormEditFonction Fx={FxSet} CancelSet={()=>setFxSet(null)}/>
                    }
                </RightPartDoublePage>
            </PageDoubleSetting>
            <TimedModalCentral2 closeMe={toggleNew} Wd={"500px"} open={openNew} Pending={false} titre={"Nouvelle fonction"}>
                <FormNewFonction Fxs={mesFonction} EndCreate={EndCreate}/>
            </TimedModalCentral2>
        </div>
    )
}

const ParamsFonction = styled(ParamsFonctionCtrl)``

export default ParamsFonction