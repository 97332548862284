import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import TimedButton from "../../../../../../../../../components/atoms/TimedButton/TimedButton";
import {useAddReguleTemps} from "../../../../../../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import {format} from "date-fns";

interface ActionNumberCtrlProps{
    className?:string;
    time:number;
    user:User;
    dateTo:string;
    closeMe:()=>void;
    label:string;
    idMotif:number;
}

const ActionNumberCtrl = (props:ActionNumberCtrlProps)=>{
    const ref = useRef<HTMLInputElement>(null)
    const [nb, setNb] = useState<string>((Math.abs(Math.round(props.time*100)/100))+"")
    const mutation = useAddReguleTemps();

    const [error, setError] = useState<boolean>(false)
    useEffect(() => {
        console.log(parseFloat(nb)>Math.round(props.time*100)/100)
        console.log(nb, props.time)
        setError(parseFloat(nb)<=0 || parseFloat(nb)>Math.abs(Math.round(props.time*100)/100))
    }, [nb, props.time]);
    const handleChange = ()=>{
        if(ref.current){
            const val = ref.current.value.replace(',', '.')
            if (/^\d*\.?\d*$/.test(val)) {
                setNb(val)
            }
        }
    }
    const Submit = ()=>{
        const myNB = parseFloat(nb);
        if(props.idMotif === 13){
            const dateHere = new Date(props.dateTo);
            const dateNext= new Date(dateHere.getTime() + 7*86400000)
            const idMotif = props.time < 0 ? 13 : 12;
            const idMotifNext = props.time <0 ? 12 : 13;
            const datas:ReguleTpsFD={
                User:`/api/users/${props.user.id}`,
                dateAT:props.dateTo,
                dateTo:props.dateTo,
                MotifReguleTemps:`/api/motif_regule_temps/${idMotif}`,
                timeFinal:myNB,
                timeSystem:myNB,
                comentFinalHour:"Balance"
            }
            const datasNext  = {...datas, MotifReguleTemps:`/api/motif_regule_temps/${idMotifNext}`, dateAT:format(dateNext, "yyyy-MM-dd"), dateTo:format(dateNext, "yyyy-MM-dd")}
            const TabMutation = [datas, datasNext];
            TabMutation.forEach(i=>{
                mutation.mutate(i, {
                    onSuccess:()=>{
                        props.closeMe()
                    }
                })
            })
        } else{
            const datas:ReguleTpsFD={
                User:`/api/users/${props.user.id}`,
                dateAT:props.dateTo,
                dateTo:props.dateTo,
                MotifReguleTemps:`/api/motif_regule_temps/${props.idMotif}`,
                timeFinal:myNB,
                timeSystem:myNB,
                comentFinalHour:"Balance"
            }
            mutation.mutate(datas, {
                onSuccess:()=>{
                    props.closeMe();
                }
            })
        }
    }
    return (
        <div className={`ActionNumber ${props.className}`}>
            {/*<p>{format(new Date(new Date(props.dateTo).getTime() + 7*86400000), "yyyy-MM-dd")}</p>*/}
            <label className={"opeHyb"}>{props.label} (max {Math.abs(Math.round(props.time*100)/100)})</label>
            <div className="wrap_input">
                <input className={"opeHyb"} ref={ref} value={nb} onChange={handleChange} disabled={mutation.isLoading}/>
            </div>
            {error ?
                <div className="error">Vous devez saisir un nombre entre 0
                    et {Math.abs(Math.round(props.time * 100) / 100)}</div>:
                <div className="wrap_save">
                    <TimedButton size={"sm"} themeColor={"Primary"} text={"Enregistrer"} onClick={Submit} disabled={mutation.isLoading} isPending={mutation.isLoading}/>
                </div>
            }
        </div>
    )
}

const ActionNumber = styled(ActionNumberCtrl)`
`

export default ActionNumber