import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    addLieu,
    changeOrdreLieu,
    getAllLieux,
    getAllLieuxByCentre,
    GetLieuxHorairesByIDLieu,
    modifyLieu,
    TrashLieu
} from "./lieu.services";
import {toast} from "react-toastify";

export const useGetAllLieu = (idCentre:number|null=null, FilterVue=false):UseQueryResult<Lieu[], Error>=>{
    return useQuery<Lieu[], Error>(["lieux", idCentre, FilterVue], ()=>getAllLieux(idCentre, FilterVue), {
        staleTime:60000,
        refetchOnWindowFocus:false,
    })
}

export const useGetAllLieuByCentre = (idCentre:number):UseQueryResult<Lieu[], Error>=>{
    return useQuery<Lieu[], Error>(["lieux_centre", idCentre], ()=>getAllLieuxByCentre(idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:idCentre!==0
    })
}


export const useModifyLieu = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:{id:number, datas:LieuFD})=>{
            return modifyLieu(data.id, data.datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["lieux"]);
                queryClient.invalidateQueries(["lieux_centre"]);
            },
            onError:()=>{
                toast.error("impossible de modifié le lieu")
            }
        }
    )
}

export const useAddLieu = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:LieuFD)=>{
            return addLieu(data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["lieux"]);
                queryClient.invalidateQueries(["lieux_centre"]);
                toast.success("lieu ajouté")
            },
            onError:()=>{
                toast.error("impossible d'ajouter le lieu")
            }
        }
    )
}
export const useTrashLieu = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return TrashLieu(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["lieux"]);
                queryClient.invalidateQueries(["lieux_centre"]);
            },
            onError:()=>{
                toast.error("impossible de supprimer le lieu")
            }
        }
    )
}

export const useChangeOrdreLieu = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:LieuChangeOrdre)=>{
            return changeOrdreLieu(data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["lieux"]);
                queryClient.invalidateQueries(["lieux_centre"]);
            },
            onError:()=>{
                toast.error("impossible de modifier le lieu le lieu")
            }
        }
    )
}

export const useGetLieuHorairesByLieu = (id:number):UseQueryResult<LieuHoraires[], Error>=>{
    return useQuery(["lieu_horaires_lieu", id], ()=>GetLieuxHorairesByIDLieu(id), {
        enabled:id!==0
    })
}