import styled from "styled-components";
import {usePostNewPeriode} from "../../../../features/periode/periode.hooks";
import {useEffect, useState} from "react";
import {Contraint, DatasSetter, ValideNoError} from "../../../../components/functions/ValideDataFunctions";
import InputTextSetting from "../components/InputTextSetting";
import InputDateSetting from "../components/InputDateSetting";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";

interface FormNewPeriodeCtrlProps{
    className?:string;
    TypePeriode:TypePeriode;
    EndCreate:(p:Periode)=>void;
    maxDateSameType:Date|null;
}
interface PeriodeProps{
    libelle:string;
    dateDebut:string;
    dateFin:string;
}
const PeriodeDefault:PeriodeProps={
    libelle:"",
    dateDebut:"",
    dateFin:"",
}
interface ErrorsProps{
    libelle:string|null;
    dateDebut:string|null;
    dateFin:string|null;
}
const DefaultErrors:ErrorsProps={
    libelle:null,
    dateDebut:null,
    dateFin:null,
}


const FormNewPeriodeCtrl = (props:FormNewPeriodeCtrlProps)=>{
    const mutation = usePostNewPeriode();
    const [myPeriode, setMyPeriode] = useState<PeriodeProps>(PeriodeDefault)
    const [errors, setErrors] = useState<ErrorsProps>(DefaultErrors)
    const [errorDate, setErrorDate] = useState(false)
    const [minDateFin, setMinDateFin] = useState<Date>(new Date(new Date(new Date().getTime()-86400000).setHours(23,59,59)))
    const [isDisabledDateFin, setIsDisabledDateFin] = useState<boolean>(true)
    useEffect(() => {
        if(props.TypePeriode.TypeFrequence && props.TypePeriode.nbOccurence){
            setIsDisabledDateFin(true);
        } else {
            setIsDisabledDateFin(false);
        }
    }, [props.TypePeriode]);
    const TabNeeds:Contraint[] = [
        {id:"libelle", required:true},
        {id:"dateDebut", required:true},
        {id:"dateFin", required:true},
    ]
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        console.log(id)
        console.log(value)
        DatasSetter<PeriodeProps, ErrorsProps>(value, id as keyof PeriodeProps, ["libelle", "dateDebut", "dateFin"], [], [], TabNeeds, myPeriode, setMyPeriode,errors,setErrors)
    }
    useEffect(() => {
        console.log(myPeriode)
    }, [myPeriode]);
    useEffect(() => {
        if(myPeriode.dateDebut!=="" && myPeriode.dateFin!==""){
            const dateDebutDate = new Date((new Date(myPeriode.dateDebut)).setHours(12,0,0))
            const dateFinDate = new Date((new Date(myPeriode.dateFin)).setHours(12,0,0))
            console.log(dateDebutDate, dateFinDate);
            if(dateFinDate<=dateDebutDate){
                const textErrorDebut =  !errors.dateDebut ?  "la date de fin doit être supérieure à la date de début" : errors.dateDebut+" la date de fin doit être supérieure à la date de début"
                const textErrorFin =  !errors.dateFin ?  "la date de fin doit être supérieure à la date de début" : errors.dateFin+" la date de fin doit être supérieure à la date de début"
                setErrors(errs=>{
                    return {...errs, dateDebut:textErrorDebut, dateFin:textErrorFin}
                })
                setErrorDate(true);
            }else {
                setErrors(errs=>{
                    return {...errs, dateDebut:null, dateFin:null}
                })
                setErrorDate(false);
            }
        }
    }, [myPeriode.dateDebut, myPeriode.dateFin, errors.dateDebut, errors.dateFin]);
    useEffect(() => {
        if(myPeriode.dateDebut!=="" && !errors.dateDebut && props.TypePeriode.TypeFrequence){
            const myDateDebut = new Date(myPeriode.dateDebut+"T12:00:00")
            console.log(myDateDebut);
            setMinDateFin(myDateDebut);
            let myNewDateFin:string = ""
            if(props.TypePeriode.TypeFrequence.id === 1){
                const coef = (props.TypePeriode.nbOccurence||1) * (7*86400000);
                const myDateFinX = new Date(myDateDebut.getTime()+coef);
                const myDateFin = new Date(myDateFinX.getTime() - 86400000);
                myNewDateFin = myDateFin.toISOString().slice(0,10);
            } else if(props.TypePeriode.TypeFrequence.id === 2){
                const montDateDebut = myDateDebut.getMonth();
                let myNewMonth = montDateDebut+(props.TypePeriode.nbOccurence||1)
                if(myNewMonth>11){
                    myNewMonth = myNewMonth - 11;
                }
                const myDateFinX = new Date(myDateDebut.getFullYear(), myNewMonth, myDateDebut.getDate(), 12, 0, 0);
                const myDateFin = new Date(myDateFinX.getTime() - 86400000);
                myNewDateFin = myDateFin.toISOString().slice(0,10);
            } else {
                const yearDateDebut = myDateDebut.getFullYear();
                const newYear = yearDateDebut+(props.TypePeriode.nbOccurence||1)
                const myDateFinX = new Date(newYear, myDateDebut.getMonth(), myDateDebut.getDate(), 12,0,0)
                const myDateFin = new Date(myDateFinX.getTime() - 86400000);
                myNewDateFin = myDateFin.toISOString().slice(0,10);
            }
            setMyPeriode(per=>{
                return {...per, dateFin:myNewDateFin}
            })
        }
    }, [props.TypePeriode, myPeriode.dateDebut, errors.dateDebut]);
    const Submit = ()=>{
        if(ValideNoError<ErrorsProps>(errors) && !errorDate){
            const datas:PostPeriode={
                libelle:myPeriode.libelle,
                Type:`/api/type_periodes/${props.TypePeriode.id}`,
                debutAt:myPeriode.dateDebut.slice(0,10),
                finAt:myPeriode.dateFin.slice(0,10)
            }
            mutation.mutate(datas, {
                onSuccess:(p:Periode)=>{
                    props.EndCreate(p);
                }
            })
        }
    }
    return (
        <div className={`formNewPeriode ${props.className}`}>
            <div className="wrap_inputs_setting">
                <InputTextSetting current={myPeriode.libelle} setValue={setMyValue} Error={errors.libelle} id={"libelle"}
                                  label={"Libellé"}/>
                <InputDateSetting current={myPeriode.dateDebut} setValue={setMyValue} Error={errors.dateDebut}
                                  id={"dateDebut"} label={"Date début"}
                                  minDate={props.maxDateSameType ? props.maxDateSameType.toISOString().slice(0,10) : undefined}/>
                <InputDateSetting current={myPeriode.dateFin} setValue={setMyValue} Error={errors.dateFin} id={"dateFin"}
                                  label={"Date fin"} disabled={isDisabledDateFin}
                                  minDate={minDateFin.toISOString().slice(0, 10).slice(0,10)}/>
                <div className="wrap_send">
                    <TimedButton size={"sm"} text={"Enregistrer la période"} themeColor={"Secondary"}
                                 disabled={!ValideNoError<ErrorsProps>(errors) || errorDate || mutation.isLoading} onClick={Submit}
                                 isPending={mutation.isLoading}/>
                </div>
            </div>
        </div>
    )
}

const FormNewPeriode = styled(FormNewPeriodeCtrl)`
    .wrap_send{
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
`

export default FormNewPeriode