import styled from "styled-components";
import TitrePageSetting from "../components/TitrePageSetting";
import {TiPlus} from "react-icons/ti";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import PageDoubleSetting from "../components/PageDoubleSetting";
import LeftPartDoublePage from "../components/LeftPartDoublePage";
import RightPartDoublePage from "../components/RightPartDoublePage";
import {useEffect, useState} from "react";
import OneBlockLeftPart from "../components/OneBlockLeftPart";
import WrapListLieux from "./WrapListLieux";
import "../styles/StyleParams.css"
import {useGetAllLieu} from "../../../../features/lieu/lieu.hooks";
import FormEditLieu from "./FormEditLieu";
import {useGetAllActes} from "../../../../features/acte/acte.hooks";
import {useGetVuePl} from "../../../../features/vuePlanning/vuePlanning.hooks";
import WrapVuePl from "./components/WrapVuePl";
import FormEditVP from "./FormEditVP";
import {useModal} from "../../../../hooks/useModal";
import {TimedModalCentral2} from "../../../../components/molecules/TimedModal/TimedModalCentral2";
import FormNewLieu from "./FormNewLieu";
import FormNewVp from "./FormNewVp";

interface LieuxVuesCtrlProps{
    className?:string;
}

const LieuxVuesCtrl = (props:LieuxVuesCtrlProps)=>{
    const [lieuOnSet, setLieuOnSet] = useState<Lieu|null>(null)
    const [vuePlSet, setVuePlSet] = useState<VuePlanning|null>(null)
    const LieuxQuery = useGetAllLieu();
    const ActesQuery = useGetAllActes();
    const VuePlanningQuery = useGetVuePl();
    const {open, toggle} = useModal();
    const {open:openNewVP, toggle:toggleNewVP} = useModal();
    useEffect(() => {
        if(VuePlanningQuery.data) setVuePlSet(VuePlanningQuery.data[0])
    }, [VuePlanningQuery.data]);
    useEffect(() => {
        if(!lieuOnSet && LieuxQuery.data){
            const lieuxActifs = LieuxQuery.data.filter(l=>l.isProduction).sort((a,b)=>a.ordreEdit > b.ordreEdit ? 1 :-1)
            if(lieuxActifs.length>0) {
                setLieuOnSet(lieuxActifs[0])
            }
        }
    }, [LieuxQuery.data, lieuOnSet]);
    const ClickNewLieux = ()=>{
        toggle()
    }
    const ClickNewVuePlanning = ()=>{
        toggleNewVP();
    }
    const EndCreateLieu = (l:Lieu)=>{
        setLieuOnSet(l)
        toggle();
    }
    const EndCreateVp = (vp:VuePlanning)=>{
        setVuePlSet(vp);
        toggleNewVP()
    }
    return (
        <div className={`lieuxVues page_params ${props.className}`}>
            <TitrePageSetting titre={"Gestion des lieux"}>
                <TimedButton onClick={ClickNewLieux} themeColor={"Primary"} size={"sm"}>
                    <div className={`wrap_in_btn`}><TiPlus /><span>Créer un lieu</span></div>
                </TimedButton>
                <TimedButton onClick={ClickNewVuePlanning} themeColor={"Primary"} size={"sm"}>
                    <div className={`wrap_in_btn`}><TiPlus /><span>Créer vue-planning</span></div>
                </TimedButton>
            </TitrePageSetting>
            <div className="wrap_two_params">
                <PageDoubleSetting>
                    <LeftPartDoublePage titre={"Vos Lieux"}>
                        <div className="multi_block">
                            <OneBlockLeftPart titre={"Lieux actifs"}>
                                <WrapListLieux LieuActif={lieuOnSet} setLieuxActif={setLieuOnSet} isActifView/>
                            </OneBlockLeftPart>
                            <OneBlockLeftPart titre={"Lieux inactifs"}>
                                <WrapListLieux LieuActif={lieuOnSet} setLieuxActif={setLieuOnSet} isActifView={false}/>
                            </OneBlockLeftPart>
                        </div>
                    </LeftPartDoublePage>
                    <RightPartDoublePage titre={lieuOnSet ? `Configuration du lieu : ${lieuOnSet.libelle}` : "Configuration du Lieu"} subtitle={"Modifier les paramètres du lieu en cours"}>
                        {(lieuOnSet && ActesQuery.data) &&
                            <FormEditLieu Lieu={lieuOnSet} Actes={ActesQuery.data||[]}/>
                        }
                    </RightPartDoublePage>
                </PageDoubleSetting>
                <PageDoubleSetting>
                    <LeftPartDoublePage titre={"Vos Vues plannings"}>
                        <OneBlockLeftPart>
                            {vuePlSet &&
                                <WrapVuePl Vues={VuePlanningQuery.data||[]} setVue={setVuePlSet} VueActive={vuePlSet}/>
                            }
                        </OneBlockLeftPart>
                    </LeftPartDoublePage>
                    <RightPartDoublePage titre={"Configuration de la vue-planning"} subtitle={"Cochez les lieux qui sont inclus dans la vue planning"}>
                        {vuePlSet &&
                            <FormEditVP Vue={vuePlSet} Lieux={LieuxQuery.data||[]}/>
                        }
                    </RightPartDoublePage>
                </PageDoubleSetting>
            </div>
            <TimedModalCentral2 closeMe={toggle} Wd={"500px"} open={open} Pending={false} titre={"Nouveau lieu"}>
                <FormNewLieu EndCreateLieu={EndCreateLieu} Lieux={LieuxQuery.data||[]}/>
            </TimedModalCentral2>
            <TimedModalCentral2 closeMe={toggleNewVP} Wd={"500px"} open={openNewVP} Pending={false} titre={"Nouvelle vue"}>
                <FormNewVp VPS={VuePlanningQuery.data||[]} EndCreateVP={EndCreateVp}/>
            </TimedModalCentral2>
        </div>
    )
}

const LieuxVues = styled(LieuxVuesCtrl)`
    .wrap_two_params{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`

export default LieuxVues