import {useQuery, UseQueryResult} from "react-query";
import {
    GetCompteurHS,
    GetDetailAbs, GetDetailEvts,
    GetDetailsContrat, GetDetailsHeuresDaily,
    GetUserDispatchActe,
    GetUSersMonthTT,
    GetUserTimesCalendrier,
    GetUserTimesWeekly
} from "./tempsTravail.services";


export const useGetListUserMonthTT = (id:number):UseQueryResult<CalendrierPaieList, Error>=>{
    return useQuery(["calendrier_paie_list", id], ()=>GetUSersMonthTT(id), {
        enabled:id!==0
    })
}



export const useGetDispatchUserActe = (idCal:number, idUser:number):UseQueryResult<OneActeDispatch[], Error>=>{
    return useQuery(["acte_user_dipatch", {idCal, idUser}], ()=>GetUserDispatchActe(idUser, idCal), {
        enabled:idUser!== 0 && idCal!==0
    })
}

export const useGetTimesUserWeekly = (idCal:number, idUser:number):UseQueryResult<TimesUserWeekly[], Error>=>{
    return useQuery(["times_user_weekly", {idCal, idUser}], ()=>GetUserTimesWeekly(idUser, idCal), {
        enabled:idUser!== 0 && idCal!==0
    })
}

export const useGetTimesUserCal = (idCal:number, idUser:number):UseQueryResult<TimesUserCalendrier, Error>=>{
    return useQuery(["times_user_cal", {idCal, idUser}], ()=>GetUserTimesCalendrier(idUser, idCal), {
        enabled:idUser!== 0 && idCal!==0
    })
}

export const useGetDetailContrat = (idUser:number, from:string, to:string):UseQueryResult<OneDetailContrat[], Error>=>{
    return useQuery(["details_contrat", {idUser, from, to}], ()=>GetDetailsContrat(idUser, from, to), {
        enabled:idUser!== 0 && from!=="" && to!==""
    })
}

export const useGetDetailsHeuresDaily = (idUser:number, from:string, to:string):UseQueryResult<DetailsDaily, Error>=>{
    return useQuery(["details_daily", {idUser, from, to}], ()=>GetDetailsHeuresDaily(idUser, from, to), {
        enabled:idUser!== 0 && from!=="" && to!==""
    })
}

export const useGetDetailsAbsence = (idCal:number, idUser:number):UseQueryResult<DetailsAbsence, Error>=>{
    return useQuery(["times_details_abs", {idCal, idUser}], ()=>GetDetailAbs(idUser, idCal), {
        enabled:idUser!== 0 && idCal!==0
    })
}

export const useGetDetailsEvents = (idCal:number, idUser:number):UseQueryResult<EventCollab[], Error>=>{
    return useQuery(["times_details_evts", {idCal, idUser}], ()=>GetDetailEvts(idUser, idCal), {
        enabled:idUser!== 0 && idCal!==0
    })
}

export const useGetCompteurHS = (idCal:number, idUser:number):UseQueryResult<CompteurHS, Error>=>{
    return useQuery(["compteur_hs", {idCal, idUser}], ()=>GetCompteurHS(idUser, idCal), {
        enabled:idUser!== 0 && idCal!==0
    })
}