import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    ChangeStatut,
    getAllEventsCollab,
    getAllEventsCollabFull,
    getEventByType,
    getEventsDateDate, getEventsParams, ModifyEventCollab,
    trashEvt
} from "./eventCollab.services";
import {toast} from "react-toastify";


export const useChangeDidEvent = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:EventCollabDid)=>ChangeStatut(datas.id, datas.dateDid)
        ,{
            onSuccess:(NewEvent:EventCollab)=>{
                queryClient.invalidateQueries(["events"]);
                queryClient.invalidateQueries(["events", NewEvent.id]);
                toast.success("Tâche modifiée", {})
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    )
}

export const useGetAllEventsCollab = (id:number):UseQueryResult<EventCollab[], Error>=>{
    return useQuery<EventCollab[], Error>(["events", id], ()=>getAllEventsCollab(id), {
        staleTime:5000,
        refetchOnWindowFocus:false
    })
}

export const useGetEventCollabByParams = (params:ParamsGetEvents|null):UseQueryResult<EventCollab[], Error>=>{
    return useQuery<EventCollab[], Error>(["events", params], ()=>getEventsParams(params), {
        staleTime:5000,
        refetchOnWindowFocus:false,
        enabled:params!==null
    })
}

export const useGetEventsDateDate = (dateFrom:string, dateTo:string, idCentre:number|null=null):UseQueryResult<EventCollab[], Error>=>{
    const queryClient = useQueryClient();
    if(!idCentre) {
        const userConnected: User | undefined = queryClient.getQueryData(["user_connected"]);
        idCentre = userConnected ? userConnected.Centre.id : 0;
    }
    return useQuery<EventCollab[], Error>(["events_dates", dateFrom, dateTo, idCentre], ()=>getEventsDateDate(dateFrom, dateTo, idCentre||0), {
        staleTime:5000,
        refetchOnWindowFocus:false,
        enabled:dateTo!=='' && dateFrom!=='' && idCentre!==null && idCentre!==0
    })
}

export const useGetAllEvents = (idCentre:number|null=null):UseQueryResult<EventCollab[], Error>=>{
    const queryClient = useQueryClient();
    const userConnected:User|undefined = queryClient.getQueryData(["user_connected"]);
    let myIdCentre:number|null = null;
    if(!idCentre && userConnected){
        myIdCentre = userConnected.Centre.id;
    }
    return useQuery<EventCollab[], Error>(["events", idCentre], ()=>getAllEventsCollabFull(myIdCentre), {
        staleTime:5000,
        refetchOnWindowFocus:false
    })
}

export const useGetEventsCollabByType = (id:number):UseQueryResult<EventCollab[], Error>=>{
    return useQuery<EventCollab[], Error>(["eventsbytype", id], ()=>getEventByType(id), {
        staleTime:5000,
        enabled:id!==0
    })
}

export const useTrashEventCollab = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>trashEvt(id)
        ,{
            onSuccess:()=>{
                queryClient.invalidateQueries(["events"]);
                toast.success("Évènement supprimé", {})
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    )
}

export const useModifyEventCollab = (id:number)=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:EventCollabPut)=>ModifyEventCollab(id, datas),
        {
            onSuccess:()=>{
                queryClient.invalidateQueries(["events"]);
                toast.success("Évènement modifié", {})
            },
            onError: () => {
                toast.error("Une erreur est survenue, merci de retenter plus tard")
            },
        }
    )
}