import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {HexColorPicker} from "react-colorful";
import LibelleSetting from "./LibelleSetting";
import SquareColorSetting from "./SquareColorSetting";

interface InputColorPickerSettingCtrlProps{
    className?:string;
    current:string;
    label:string;
    id:string;
    setValue:(Choice:Choice|string|number|null, id:string)=>void;
    wdLib?:string;
}

const InputColorPickerSettingCtrl = (props:InputColorPickerSettingCtrlProps)=>{
    const [openColorPicker, setOpenColorPicker] = useState(false)
    const [myColor, setMyColor] = useState<string>(props.current)
    const refWrapBack = useRef<HTMLDivElement>(null)
    // useEffect(() => {
    //     setMyColor(props.current)
    // }, [props.current]);
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refWrapBack.current && !refWrapBack.current.contains(event.target)) {
                setOpenColorPicker(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refWrapBack]);
    const handleColor = (a:string)=>{
        setMyColor(a);
    }
    const handleClickSave = ()=>{
        props.setValue(myColor, props.id)
        setOpenColorPicker(false);
    }
    return (
        <div className={`inputColorPickerSetting wrap_input_setting ${props.className}`} ref={refWrapBack}>
            <div className="in_wrap_input_setting">
                <LibelleSetting libelle={props.label} wdLib={props.wdLib}/>
                <div className="wrap_choice">
                    <SquareColorSetting ClickOn={()=>setOpenColorPicker(o=>!o)} color={props.current}/>
                    {openColorPicker &&
                        <div className="wrap_picker">
                            <HexColorPicker color={props.current} onChange={handleColor} />
                            <div className="tester" style={{background:myColor}}/>
                            <button className={`btn_save_color`} onClick={handleClickSave}>Choisir cette couleur</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

const InputColorPickerSetting = styled(InputColorPickerSettingCtrl)`
    .wrap_choice{
        position: relative;    
    }
    .btn_save_color{
        margin: 5px 0;
        display: block;
        width: 100%;
        background: ${props => props.theme.Primary};
        color: white;
        text-align: center;
        border: none;
        border-radius: 4px;
        padding: 5px 0;
        font-size: 16px;
        &:hover{
            cursor: pointer;
        }
    }
    .wrap_picker{
        position: absolute;
        left: calc(100% + 18px);
        top: 50%;
        transform: translateY(-20%);
        z-index: 9;
        padding: 0.5rem;
        background: white;
        border-radius: 4px;
        //border: solid ${props => props.theme.Primary} 1px;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
        .tester{
            width: 100%;
            height: 30px;
            border-radius: 8px;
            margin-top: 4px;
            border: solid ${props => props.theme.Neutre} 1px;
        }
    }
`

export default InputColorPickerSetting