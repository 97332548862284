import styled from "styled-components";
import {ErrorInput, InputInter, LabelInter} from "./styleInters";
import {useEffect, useRef, useState} from "react";
import {useDebounce} from "../../../services/hooks";

interface TimedInputInterCtrlProps {
    className?:string;
    label:string;
    Current:string|number;
    setNew:(value:string|number|Choice, id:string)=>void;
    type:"text"|"date"|"datetime"|"number";
    id:string;
    Error?:string|null;
    Disabled?:boolean;
    TimeValidator?:boolean;
    noDebounce?:boolean;
    isColorForce?:boolean;
}

const TryConvertTime = (search:string)=>{
    if(search === ''){
        return '';
    }
    let searchAjust = search.length === 1 ? `0${search}00` : search.length === 2 ? `${search}00` : search;
    const valueInt = parseInt(searchAjust.slice(-2));
    if(valueInt%5!==0) {
        const RoundValue = Math.floor(valueInt / 5) * 5;
        const StrValue = RoundValue < 10 ? `0${RoundValue}` : RoundValue;
        searchAjust = searchAjust.slice(0,2)+StrValue;
    }
    if(+searchAjust.slice(0,2) > 24){
        searchAjust = searchAjust.slice(1,2)+searchAjust.slice(0,1)+searchAjust.slice(-2);
    }
    return searchAjust.slice(0,2)+":"+searchAjust.slice(-2);
}

const TimedInputInterCtrl = ({className, label, Current, setNew, type, id, Error, Disabled, TimeValidator, noDebounce, isColorForce}: TimedInputInterCtrlProps) => {
    const [myValue, setMyValue] = useState<string|number>(Current)
    const myValueDebounce = useDebounce(myValue, noDebounce ? 0 : 900)
    const myValueDebounce2 = useDebounce(myValue, 500)
    const refInput = useRef<HTMLInputElement>(null)
    const [myError,setMyError] = useState(Error)
    useEffect(() => {

        setMyValue(Current);
    }, [Current]);
    useEffect(() => {
        setMyError(Error);
    }, [Error]);
    const handleChange = ()=>{

        if(refInput.current){

            setMyValue(refInput.current.value);
        }
    }
    useEffect(() => {
        if(TimeValidator){
            setMyValue(TryConvertTime(myValueDebounce2 as string))
        }
    }, [myValueDebounce2]);
    useEffect(() => {
        if(Current!==myValueDebounce) {
            setNew(myValueDebounce, id);
        }
    }, [myValueDebounce]);
    return (
        <div className={`timed_input_inter ${className}`}>
            <LabelInter>{label}</LabelInter>
            <InputInter className={`${myError && 'is-error'} ${myValue === '' ? 'empty' : ''} ${isColorForce ? "color_force" : ""}`} ref={refInput} type={type} value={myValue} disabled={Disabled} onChange={handleChange}/>
            {myError &&
                <ErrorInput>{myError}</ErrorInput>
            }
        </div>
    )
}

export const TimedInputInter = styled(TimedInputInterCtrl)`
    width: 100%;
    position: relative;
`