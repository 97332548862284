import styled from "styled-components";
import {useAddUserRubrique} from "../../features/userRubriqueSpe/userRubriqueSpe.hooks";
import {useState} from "react";

interface FormAddRubSpeToUserCtrlProps {
    className?:string;
    user:User;
    listToAdd:RubriqueSpe[];
    closeMe:()=>void;
}

const FormAddRubSpeToUserCtrl = ({className, user, listToAdd, closeMe}: FormAddRubSpeToUserCtrlProps) => {
    const [errors, setErrors] = useState<string|null>(null);
    const mutationAddRub = useAddUserRubrique();
    const HandleAddNewRub = (myIdSelect:number)=>{
        if(myIdSelect!==0){
            const myData:UserRubriqueSpeNewFD={
                RubriqueSpe:`/api/rubrique_spes/${myIdSelect}`,
                User:`/api/users/${user.id}`
            }
            mutationAddRub.mutate((myData), {
                onSuccess:()=>{
                    closeMe()
                }, onError:()=>{
                    setErrors('Une erreur est survenue, merci de retenter plus tard');
                }
            });
        }
    }
    return (
        <div className={`form_add_rub_user ${className}`}>
            {errors &&
                <div className="errorMe">{errors}</div>
            }
            <div className="wrapper_rub">
                {listToAdd.map(item=>(
                    <div className="one_rub_add" key={`ORA${item.id}`} onClick={()=>HandleAddNewRub(item.id)}>{item.libelle}</div>
                ))}
            </div>
        </div>
    )
}

export const FormAddRubSpeToUser = styled(FormAddRubSpeToUserCtrl)`
    .errorMe{
        margin-bottom: 10px;
        background: ${props => props.theme.WarningLight};
        padding: 0.5rem;
        text-align: center;
        color:white;
    }
    .wrapper_rub{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .one_rub_add{
        width: 100%;
        padding: 0.25rem;
        border:solid ${props => props.theme.PrimaryMegaLight} 1px;
        border-radius: 4px;
        text-align: center;
        &:hover{
            cursor: pointer;
            background:${props => props.theme.PrimaryMegaLight};
        }
    }
`