import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export function GetUSersMonthTT(id:number):Promise<CalendrierPaieList[]>{
    return dataFetcher(`temps_trav/get_list_paie/${id}`)
}



export function GetUserDispatchActe(idUser:number, idCal:number):Promise<OneActeDispatch[]>{
    return dataFetcher(`temps_trav/get_dispatch_user_acte/${idCal}?User=${idUser}`)
}

export function GetUserTimesWeekly(idUser:number, idCal:number):Promise<TimesUserWeekly[]>{
    return dataFetcher(`temps_trav/get_temps_travail_user_weekly/${idCal}?User=${idUser}`)
}

export function GetUserTimesCalendrier(idUser:number, idCal:number):Promise<TimesUserWeekly[]>{
    return dataFetcher(`temps_trav/get_balance_centre/${idCal}?User=${idUser}`)
}

export function GetDetailsContrat(idUser:number, from:string, to:string):Promise<OneDetailContrat[]>{
    return dataFetcher(`temps_trav/get_details_contrats_fromto/${idUser}?From=${from}&To=${to}`)
}

export function GetDetailsHeuresDaily(idUser:number, from:string, to:string):Promise<DetailsDaily>{
    return dataFetcher(`temps_trav/details_heures_day/${idUser}?From=${from}&To=${to}`)
}

export function GetDetailAbs(idUser:number, idCal:number):Promise<DetailsAbsence>{
    return dataFetcher(`temps_trav/get_absences/${idCal}?User=${idUser}`)
}

export function GetDetailEvts(idUser:number, idCal:number):Promise<EventCollab[]>{
    return dataFetcher(`temps_trav/get_events/${idCal}?User=${idUser}`)
}

export function GetCompteurHS(idUser:number, idCal:number):Promise<CompteurHS>{
    return dataFetcher(`temps_trav/get_compteur_hs/${idCal}?User=${idUser}`)
}