import React from "react";
import styled from "styled-components";

interface TimedDragDropFileCtrlProps {
    className?: string;
    onUpload: (files: File[]) => void;
    count?: number;
    formats?: string[];
    accept?: string;
    full?:boolean;
}

type DragDropMessage = {
    show: boolean;
    text?: string;

    type?: "error" | "success";
};

function TimedDragDropFileCtrl(props: TimedDragDropFileCtrlProps) {
    const [dragActive, setDragActive] = React.useState(false);
    const [message, setMessage] = React.useState<DragDropMessage>({ show: false });

    const showMessage = (text: DragDropMessage["text"], type: DragDropMessage["type"], timeout: number) => {
        setMessage({
            show: true,
            text,
            type,
        });

        setTimeout(
            () =>
                setMessage({
                    show: false,
                    text: undefined,
                    type: undefined,
                }),
            timeout
        );
    };

    // ref
    const inputRef = React.useRef<HTMLInputElement>(null);

    // handle drag events
    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const controlFiles = (files: File[]) => {
        if (props.count && props.count < files.length) {
            showMessage(
                `Seulement ${props.count} fichiers ${props.count !== 1 ? "s" : ""} peuvent êtres téléversés en une fois`,
                "error",
                2000
            );
            return;
        }

        //check if some uploaded file is not in one of the allowed formats
        if (
            props.formats &&
            files.some((file) => !props.formats?.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase())))
        ) {
            showMessage(
                `Seulement les fichiers avec le format suivant sont acceptables : ${props.formats.join(", ")}`,
                "error",
                2000
            );
            return;
        }

        if (files && files.length) {
            showMessage("Fichier chargé !", "success", 1000);
            props.onUpload(files);
        }
    };

    const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const files = Array.from(e.dataTransfer.files);
        controlFiles(files);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            const files = Array.from(e.currentTarget.files);
            controlFiles(files);
        }
    };

    const onButtonClick = () => {
        inputRef?.current?.click();
    };

    return (
        <div className={props.className} onDragEnter={handleDrag}>
            <input
                type="file"
                id="input-file-upload"
                multiple={true}
                ref={inputRef}
                onChange={onInputChange}
                accept={props.accept}
            />
            <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className={(dragActive ? "dragActive" : "") + " " + message.type}
            >
                {message.show ? (
                    message.text
                ) : (
                    <div>
                        <p>Glissez déposez votre fichier ici ou</p>
                        <button className="upload-button" onClick={onButtonClick}>
                            Téléversez un fichier
                        </button>
                    </div>
                )}
            </label>
            {dragActive && (
                <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                ></div>
            )}
        </div>
    );
}
export const TimedDragDropFile = styled(TimedDragDropFileCtrl)((props) => ({
    height: "100px",
    width: props.full ? "100%": "200px",
    position: "relative",
    fontSize: "1.2rem",
    border: "2px dashed "+props.theme.PrimaryExtraLight,
    background:"white",
    borderRadius: "4px",
    overflow: "hidden",

    "#input-file-upload": {
        display: "none",
    },

    label: {
        height: "100%",
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",

        "&.dragActive": {
            backgroundColor: props.theme.PrimaryExtraLight,
        },

        "&.error": {
            backgroundColor: props.theme.WarningExtraLight,
        },
        "&.success": {
            backgroundColor: props.theme.SecondaryExtraLight,
        },
    },

    button: {
        cursor: "pointer",
        padding: "0.25rem",
        fontSize: "1rem",
        border: "none",
        backgroundColor: "transparent",

        "&:hover": {
            textDecoration: "underline",
        },

        "&:focus-visible": {
            outline: "2px solid blue",
        },
    },

    "#drag-file-element": {
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: "1rem",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    },
}));
TimedDragDropFile.defaultProps = {
    count: 1,
};
