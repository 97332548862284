import styled from "styled-components";
import {useContext} from "react";
import {format} from "date-fns";
import {ContextPlanning} from "../WeeklyCalendar";
import {ConvertSTToPlageTempo} from "../../../functions/SemaineTypeFunctions";
import ZoneCSTTempo from "./ZoneCSTTempo";
import ZoneDropPlage from "./ZoneDropPlage";



interface OneColumnDayCtrlProps{
    className?:string;
    date:Date;
    Plages:Plage[];
    hours:number[];
}

const OneColumnDayCtrl = (props:OneColumnDayCtrlProps)=>{
    const Context = useContext(ContextPlanning)
    const GetPlageByST = ()=>{
        if(!Context.User || !Context.centreST){
            return []
        } else {
            const plages:Plage[] = [];
            Context.centreST.semaineTypes.forEach(st=>{
                const dateTo = new Date(Context.date.getTime() + 86400000*st.numJour)
                if(format(dateTo, "yyyy-MM-dd") === format(props.date, "yyyy-MM-dd") && Context.User){
                    const pseudoPlage = ConvertSTToPlageTempo(st, Context.User, props.date);
                    if(Context.GetConflicts && Context.GetConflicts(pseudoPlage).length === 0) {
                        plages.push(pseudoPlage)
                    }
                }
            })
            return plages;
        }
    }
    return (
        <div className={`oneColumnDay ${props.className} one_col`}>
            <div className="back_grid">
                {props.hours.map(h => (
                    <div className={"one_hour_in"} key={`BH${h}`} style={{height:Context.config.heightHour+"px"}}/>
                ))}
            </div>
            {Context.centreST &&
                <ZoneCSTTempo plages={GetPlageByST()}/>
            }
            <ZoneDropPlage plages={props.Plages} date={props.date}/>
        </div>
    )
}

const OneColumnDay = styled(OneColumnDayCtrl)`
    
    position: relative;
    .back_grid{
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
`

export default OneColumnDay