import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {useAddReguleTemps} from "../../../../../../../../../features/pointeuseTreat/pointeuseTreat.hooks";
import TimedButton from "../../../../../../../../../components/atoms/TimedButton/TimedButton";

interface DebiterCompteurTempsCtrlProps{
    className?:string;
    CptHS:CompteurHS;
    time:number;
    user:User;
    dateTo:string;
    closeMe:()=>void;
}

const DebiterCompteurTempsCtrl = (props:DebiterCompteurTempsCtrlProps)=>{
    const ref = useRef<HTMLInputElement>(null)
    const [nb, setNb] = useState<string>((Math.round(props.time*100)/100)+"")
    const mutation = useAddReguleTemps();
    const [error, setError] = useState<string>("")
    useEffect(() => {
        if(nb === "") setNb("0")
    }, [nb]);
    useEffect(() => {
        let myError = "";
        if(parseFloat(nb)<=0 || parseFloat(nb)>Math.round(props.time*100)/100){
            myError = `Vous devez saisir un nombre entre 0
                    et ${Math.abs(Math.round(props.time * 100) / 100)}`
        } else if(parseFloat(nb)>props.CptHS.solde){
            myError = `Vous ne pouvez pas rendre le compteur négatif`;
        }
        setError(myError)
    }, [nb, props.CptHS.solde, props.time]);
    const handleChange = ()=>{
        if(ref.current){
            const val = ref.current.value.replace(',', '.')
            if (/^\d*\.?\d*$/.test(val)) {
                setNb(val)
            }
        }
    }
    const Submit = ()=>{
        const myNB = parseFloat(nb);
        const datas:ReguleTpsFD={
            User:`/api/users/${props.user.id}`,
            dateAT:props.dateTo,
            dateTo:props.dateTo,
            MotifReguleTemps:`/api/motif_regule_temps/4`,
            timeFinal:myNB,
            timeSystem:myNB,
            comentFinalHour:"Balance"
        }
        mutation.mutate(datas, {
            onSuccess:()=>{
                props.closeMe();
            }
        })
    }
    return (
        <div className={`debiterCompteurTemps ${props.className}`}>
            <label className={"opeHyb"}>Nombre d'heures que vous souhaitez abandonner
                (max {Math.abs(Math.round(props.time * 100) / 100)})</label>
            <div className="wrap_input">
                <input className={"opeHyb"} ref={ref} value={nb} onChange={handleChange} disabled={mutation.isLoading}/>
            </div>
            {error!=="" ?
                <div className="error">{error}</div>:
                <div className="wrap_save">
                    <TimedButton size={"sm"} themeColor={"Primary"} text={"Enregistrer"} onClick={Submit}
                                 disabled={mutation.isLoading} isPending={mutation.isLoading}/>
                </div>
            }
        </div>
    )
}

const DebiterCompteurTemps = styled(DebiterCompteurTempsCtrl)``

export default DebiterCompteurTemps