import styled from "styled-components";

interface OneCardCSTCtrlProps{
    className?:string;
    CST:CentreSemaineType;
    Hover:()=>void;
    unHover:()=>void;
    Click:()=>void;
    isApplicable:boolean;
}

const OneCardCSTCtrl = (props:OneCardCSTCtrlProps)=>{
    return (
        <div className={`oneCardCst ${props.className} ${props.isApplicable ? "applicable" : ""}`} onMouseOver={()=>props.Hover()} onMouseOut={()=>props.unHover()} onClick={()=>props.Click()}>
            <div className="in_card">
                <span>{props.CST.namePerso ? props.CST.namePerso : `Rotation ${props.CST.numRotation}`}</span>
            </div>
        </div>
    )
}

const OneCardCST = styled(OneCardCSTCtrl)`
    height: 100%;
    background: white;
    .in_card{
        min-width: max-content;
        height: 100%;
        display: flex;
        font-weight: bold;
        justify-content: center;
        flex-direction: column;
        padding: 0 .5rem;
        font-size: 14px;
        border-left: solid 3px transparent;
        border-radius: 3px 3px 0 0;
        color:${props=>props.theme.Primary};
    }
    
        border-radius: 4px;
    &.applicable{
        .in_card{
            border-color: ${props=>props.theme.Primary};
        }
    }
    &:hover{
        cursor: pointer;
        .in_card {
            color: ${props => props.theme.Dark};
        }
    }
`

export default OneCardCST