import styled from "styled-components";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";

import {useDrag} from "react-dnd";

import {getEmptyImage} from "react-dnd-html5-backend";
import {ContextPlanning} from "../WeeklyCalendar";
import {ItemsTypeHebdo} from "./ItemsTypeHebdo";
import ChangeStatus from "./ChangeStatus";
import HandleCopy from "./HandleCopy";
import {ConvertBase5} from "../../../functions/TimesFunctions";


interface OnePlageCtrlProps{
    className?:string;
    plage:Plage;
}
interface ConfigPlageProps{
    left:number;
    height:number;
    start:number;
    end:number;
    top:number;
    backColor:string;
    fontColor:string;
    isAbs:boolean;
    isCren:boolean;
}

const OnePlageCtrl = (props:OnePlageCtrlProps)=>{
    const [configPlage, setConfigPlage] = useState<ConfigPlageProps|null>(null)
    const [isResizing, setIsResizing] = useState(false)
    const [initialPosY, setInitialPosY] = useState<number|null>(null)
    const Context = useContext(ContextPlanning)
    const refPlage = useRef<HTMLDivElement>(null)
    const [isOpenStatut, setOpenStatut] = useState<boolean>(false)

    useEffect(() => {
        const startDecal = (props.plage.heureDebut - Context.config.start)/12;
        const top = startDecal*Context.config.heightHour;
        const height = (props.plage.heureFin - props.plage.heureDebut)/12 * Context.config.heightHour;
        let left = 0;
        if(Context.GetNiveauPlage){
            left = Context.GetNiveauPlage(props.plage)*10;
        }
        setConfigPlage({
            left:left,
            start:props.plage.heureDebut,
            end:props.plage.heureFin,
            top:top,
            height:height,
            backColor:Context.isActeColor ? props.plage.Acte.backColor : props.plage.Lieu.backColor,
            fontColor:Context.isActeColor ? props.plage.Acte.fontColor : props.plage.Lieu.fontColor,
            isAbs:props.plage.Acte.isDemAbs,
            isCren:!!props.plage.UserReplaced
        })
    }, [props.plage, Context]);
    const handleResizeStart = (e: React.MouseEvent) => {
        e.stopPropagation();
        setInitialPosY(e.clientY);
        setIsResizing(true);
    };
    const handleResize = useCallback((e: MouseEvent) => {
        if (!isResizing || !initialPosY || !configPlage) return;

        const deltaY = e.clientY - initialPosY;
        const increments = Math.round(deltaY / (Context.config.heightHour/12)); //
        const newHeureFin = props.plage.heureFin + increments;

        if (Context.onResize && newHeureFin > props.plage.heureDebut && newHeureFin <= Context.config.stop) { // Limiter la durée maximale
            const newHeight = (newHeureFin - props.plage.heureDebut)/12 * Context.config.heightHour;
            setConfigPlage(prevCoord=>!prevCoord ? null : {...prevCoord, height:newHeight, end:newHeureFin})
        }
    }, [Context, initialPosY, props.plage, configPlage, isResizing]);
    const handleResizeEnd = useCallback(() => {
        if(Context.onResize){
            Context.onResize(props.plage.id, configPlage?.end||0)
        }
        setIsResizing(false);
        window.removeEventListener('mousemove', handleResize);
        window.removeEventListener('mouseup', handleResizeEnd);
    }, [configPlage, Context, handleResize, props.plage])

    // Activer le redimensionnement sur `mousemove` et `mouseup`
    useEffect(() => {

        if (isResizing) {
            window.addEventListener('mousemove', handleResize);
            window.addEventListener('mouseup', handleResizeEnd);
        }
        return () => {
            window.removeEventListener('mousemove', handleResize);
            window.removeEventListener('mouseup', handleResizeEnd);
        };
    }, [isResizing, handleResize, handleResizeEnd]);
    const [{isDragging}, drag, preview] = useDrag({
        type:ItemsTypeHebdo.PLAGE,
        canDrag:()=>!isResizing,
        item: ()=>{
            return props.plage
        },
        collect: (monitor:any) => ({
            isDragging: !!monitor.isDragging(),
        }),

    }, [isResizing, props.plage])
    useEffect(() => { // this useEffect hides the default preview
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);
    const ClickOnPlage = (e:any)=>{
        e.stopPropagation();
        if(refPlage.current && Context.selectPlage && !isOpenStatut && !isResizing){
            const elt = refPlage.current.getBoundingClientRect();
            Context.selectPlage(props.plage, elt.x, elt.y, elt.width, elt.height)

        }
    }
    const ClickOnStatut = (e:any)=>{
        e.stopPropagation();
        setOpenStatut(true);
    }
    if(!configPlage){
        return null;
    } else {
        drag(refPlage)
        return (
            <div ref={refPlage} className={`onePlage ${isDragging ? "on_drag" : ""} ${Context.plageCurr?.id === props.plage.id ? "current" : ""} ${props.className}`} style={{height:(configPlage.height)+"px", top:configPlage.top+"px", left:((3+configPlage.left)+"px")}}>
                <div className="in_plage" style={{height:(configPlage.height)+"px", background:props.plage.Lieu.backColor, backgroundColor:configPlage.backColor, color:configPlage.fontColor}} onClick={ClickOnPlage}>
                    <ChangeStatus open={isOpenStatut} CloseMe={()=>setOpenStatut(false)} plage={props.plage}/>
                    {!configPlage.isAbs &&
                        <HandleCopy canCopy={true} plage={props.plage}/>
                    }
                    <div className={`infos_plage ${configPlage.height < 70 ? "hidden" : "visible"}`}>
                        <div className={"codeStatut"} onClick={ClickOnStatut}>{props.plage.Statut.code}</div>
                        <div className={`LieuActe`}>{props.plage.Lieu.libelle} - {props.plage.Acte.libelle}</div>
                        <div className={`Horaires`}>{ConvertBase5(configPlage.start)} - {ConvertBase5(configPlage.end)} ({Math.round(props.plage.myTime*100)/100}h)</div>
                        {props.plage.DemandeAbs &&
                            <div className="valo_time">Temps : {Math.round(props.plage.myTime*100)/100}</div>
                        }
                        {!!props.plage.Memo &&
                            <div>{props.plage.Memo}</div>
                        }
                        {props.plage.UserReplaced &&
                            <div className={`user_replace`}>Remplace : {props.plage.UserReplaced.prenom} {props.plage.UserReplaced.nom}</div>
                        }
                        {!props.plage.UserReplaced &&
                            <div className={"zone_extanded"} onMouseDown={(e)=>handleResizeStart(e)}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const OnePlage = styled(OnePlageCtrl)`
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;
    
    &.on_drag{
        cursor: grabbing;
        .in_plage{
            box-shadow: 1px 1px 3px rgba(0,0,0,.2);
        }
    }
    &.current{
        .in_plage{
            box-shadow: 0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12),0 3px 5px -1px rgba(0,0,0,.2);
        }
    }
    .zone_extanded{
        height: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        &:hover{
            cursor: row-resize;
        }
    }
    .hand_copy{
        width: 22px;
        height: 22px;
        position: absolute;
        right: -11px;
        top:-11px;
        background: white;
    }
    .in_plage{
        width: 90%;
        padding: 0.25rem;
        border-width: 1px 1px 1px 6px;
        border-radius: 8px;
        border-style: solid;
        border-color: white white white ${props => props.plage.Statut.color};
        overflow: hidden;
        .handleCopy{
            opacity: 0;
        }
        &:hover{
            .handleCopy{
                opacity: 1;
            }
        }
    }
    .infos_plage{
        position: relative;
        height: 100%;
        &.hidden{
            opacity: 0;
        }
    }
    .codeStatut{
        width: 22px;
        height: 22px;
        background: white;
        position: absolute;
        right: 5px;
        top: 5px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        color: ${props=>props.theme.Dark};
        text-align: center;
        &:hover{
            cursor: pointer;
        }
    }
    .LieuActe{
        padding-right: 35px;
    }
`

export default OnePlage