import styled from "styled-components";
import {useGetAllTypeFrequence, usePostNewTypePeriode} from "../../../../features/typePeriode/typePeriode.hooks";
import {useGetAllActes} from "../../../../features/acte/acte.hooks";
import {useEffect, useState} from "react";
import {
    Contraint,
    DatasAnalyser,
    DatasSetter, ValideNoError,
} from "../../../../components/functions/ValideDataFunctions";
import InputTextSetting from "../components/InputTextSetting";
import InputSelectSetting from "../components/InputSelectSetting";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";

interface FormNewCompteurCtrlProps{
    className?:string;
    EndCreateCptr:(cptr:TypePeriode)=>void;
}

interface CompteurProps{
    libelle:string;
    unite:Choice|null;
    Acte:Choice|null;
    TypeFrequence:Choice|null;
    nbOccurence:number;

}
const DefautCptr:CompteurProps={
    libelle:"",
    unite:null,
    Acte:null,
    TypeFrequence:null,
    nbOccurence:1
}
interface ErrorProps{
    libelle:string|null;
    unite:string|null;
    Acte:string|null;
    TypeFrequence:string|null;
    nbOccurence:string|null;
}
const ErrorDefault:ErrorProps={
    libelle:null,
    unite:null,
    Acte:null,
    TypeFrequence:null,
    nbOccurence:null,
}

const FormNewCompteurCtrl = (props:FormNewCompteurCtrlProps)=>{
    const mutation = usePostNewTypePeriode()
    const TFreqQuery = useGetAllTypeFrequence();
    const ActesQuery = useGetAllActes();
    const choicesUnite:Choice[] = [{id:"J", libelle:"Jours", description:"Permet de comptabiliser le compteur en jours"}, {id:"H", libelle:"Heures", description:"Permet de comptabiliser le compteur en heures"}]
    const [choicesActe, setChoicesActe] = useState<Choice[]>([])
    const [choicesTypeFreq, setChoicesTypeFreq] = useState<Choice[]>([])
    const [myCptr, setMyCptr] = useState<CompteurProps>(DefautCptr)
    const [errors, setErrors] = useState<ErrorProps>(ErrorDefault)
    const TabChoice=["unite", "Acte", "TypeFrequence"]
    const TabNumber = ["nbOccurence"]
    const TabString = ["libelle"]
    const TabNeeds:Contraint[] = [
        {id:"libelle", required:true},
        {id:"TypeFrequence", required:true},
        {id:"nbOccurence", required:true, min:1},
        {id:"unite", required:true},
    ]
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        console.log(id)
        console.log(value)
        DatasSetter<CompteurProps, ErrorProps>(value, id as keyof CompteurProps, TabString, TabNumber, TabChoice, TabNeeds, myCptr, setMyCptr,errors,setErrors)
    }
    useEffect(() => {
        if(ActesQuery.data){
            setChoicesActe(ActesQuery.data.map(a=>{
                return {id:a.id, libelle:a.libelle}
            }))
        }
    }, [ActesQuery.data]);
    useEffect(() => {
        if(TFreqQuery.data){
            setChoicesTypeFreq(TFreqQuery.data.map(tf=>{
                return {id:tf.id, libelle:tf.libelle}
            }))
        }
    }, [TFreqQuery.data]);
    const Submit = ()=>{
        if(DatasAnalyser<CompteurProps, ErrorProps>(["libelle", "nbOccurence"], TabString, TabNumber, TabNeeds, myCptr, errors, setErrors) === 0){
            const datas:PostTypePeriode = {
                libelle:myCptr.libelle,
                unite:(myCptr.unite as Choice).id as string,
                Acte:`/api/actes/${myCptr.Acte?.id||0}`,
                TypeFrequence:`/api/type_frequence_periodes/${myCptr.TypeFrequence?.id||0}`,
                nbOccurence:myCptr.nbOccurence
            }
            mutation.mutate(datas, {
                onSuccess:(cptr:TypePeriode)=>{
                    props.EndCreateCptr(cptr)
                }
            })
        }
    }
    return (
        <div className={`formNewCompteur ${props.className}`}>
            <div className={"wrap_inputs_setting"}>
                <InputTextSetting current={myCptr.libelle} setValue={setMyValue} Error={errors.libelle} id={"libelle"}
                                  label={"Libellé"}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.unite} label={"Unité"} id={"unite"}
                                    choices={choicesUnite} Current={myCptr.unite}
                                    placeNoChoice={"-- Choix de l'unité --"}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.Acte} label={"Acte associé"} id={"Acte"}
                                    choices={choicesActe} Current={myCptr.Acte}
                                    placeNoChoice={"-- Choix de l'acte --"}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.TypeFrequence} label={"Type de fréquence"}
                                    id={"TypeFrequence"} choices={choicesTypeFreq} Current={myCptr.TypeFrequence}
                                    placeNoChoice={"-- Choix de la fréquence --"}
                />
                <InputTextSetting type={"number"} current={myCptr.nbOccurence} setValue={setMyValue}
                                  Error={errors.nbOccurence} id={"nbOccurence"} label={"Amplitude"}
                />
                <div className="wrap_send">
                    <TimedButton size={"sm"} text={"Enregistrer le compteur"} themeColor={"Secondary"}
                                 disabled={!ValideNoError<ErrorProps>(errors) || mutation.isLoading}
                                 onClick={Submit}
                                 isPending={mutation.isLoading}/>
                </div>
            </div>
        </div>
    )
}

const FormNewCompteur = styled(FormNewCompteurCtrl)`
    .wrap_send{
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
`

export default FormNewCompteur