import styled, {keyframes} from "styled-components";

import {createPortal} from "react-dom";

import {useEffect, useRef, useState} from "react";
import {TempoPlage} from "../WeeklyCalendar";
import FormNewPlage from "./FormNewPlage";

interface ModalNewPlageCtrlProps{
    className?:string;
    plageTempo:TempoPlage|null;
    closeMe:()=>void;
    idUser:number;
}

const ModalNewPlageCtrl = (props:ModalNewPlageCtrlProps)=>{
    const refObjet = useRef<HTMLDivElement>(null)
    const [myPlageTempo, setMyPlageTempo] = useState<TempoPlage|null>(props.plageTempo)
    const [isLeft, setIsLeft] = useState<boolean>(false)
    useEffect(() => {
        setIsLeft((!!props.plageTempo && props.plageTempo.d && [6,0].indexOf(props.plageTempo.d.getDay())!==-1))
    }, [props.plageTempo]);
    useEffect(() => {
        setMyPlageTempo(props.plageTempo)
    }, [props.plageTempo]);
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObjet.current && !refObjet.current.contains(event.target)) {
                props.closeMe();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObjet, props]);
    return createPortal(
        <div ref={refObjet} className={`modalNewPlage ${isLeft ? "is_left" : ""} ${!myPlageTempo && "invisible" } ${props.className}`} style={{left:(isLeft && myPlageTempo) ? (myPlageTempo.coord.xr - 370)+"px" : myPlageTempo ? myPlageTempo.coord.x : 0, top:myPlageTempo ? myPlageTempo.coord.y-(myPlageTempo.HD>=216 ? 150 : 0) : 0}}>

            <div className="header">Nouvelle plage</div>
            <div className="body">
                {myPlageTempo &&
                    <FormNewPlage plageTempo={myPlageTempo} idUser={props.idUser} closeMe={()=>props.closeMe()}/>
                }
            </div>
        </div>, document.body
    )
}

const BounceAnimation = keyframes`
    0%, 100% {
        transform: scaleY(1);
        
    }
    30% {
        transform: scaleY(0.95); /* Compression */
    }
    50% {
        transform: scaleY(1.05); /* Étirement */
    }
    70% {
        transform: scaleY(1); /* Compression légère */
    }
`

const ModalNewPlage = styled(ModalNewPlageCtrl)`
    width: 350px;
    
    background: white;
    z-index: 7;
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.12),0 1px 8px 0 rgba(0,0,0,.2);
    position: fixed;
    animation: ${BounceAnimation} 0.7s forwards;
    min-height: 350px;
    transform-origin: center;
    
    .header{
        font-weight: bold;
    }
    .body{
        padding: 10px 0;
    }
    &.invisible{
        display: none;
    }
    
`

export default ModalNewPlage