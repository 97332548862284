import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import CrediterCompteurTempsHyb from "./Actions/CrediterCompteurTempsHyb";
import "./style/StyleOperationHybride.css"
import DebiterCompteurTemps from "./Actions/DebiterCompteurTemps";
import ActionNumber from "./Actions/ActionNumber";

interface ActionHybrideCtrlProps{
    className?:string;
    time:number;
    closeMe:()=>void;
    isAnnual:boolean;
    user:User;
    dateTo:string;
    CptHS:CompteurHS;
}

const ActionHybrideCtrl = (props:ActionHybrideCtrlProps)=>{
    const refSelect = useRef<HTMLSelectElement>(null)
    const [choices, setChoices] = useState<Choice[]>([])
    const [myChoice, setMyChoice] = useState<Choice|null>(null)
    useEffect(() => {
        const list:Choice[] = [{id:-1,libelle:"Choisissez votre action"}];
        if(props.time < 0){
            list.push({id:0, libelle:"Abandonner des heures", description:"Nombre d'heures que vous souhaitez abandonner", numberIndicator:4})
            list.push({id: 1, libelle: "Débiter des heures sur un compteur"})
            list.push({id: 5, libelle: "Déduire des heures de la paie", description:"Nombre d'heures que vous souhaitez déduire", numberIndicator:10})
        } else {
            list.push({id:3, libelle:"Payer des heures", description:"Nombre d'heures que vous souhaitez payer", numberIndicator:3})
            list.push({id: 4, libelle: "Créditer des heures sur un compteur"})
        }
        if(!props.isAnnual){
            list.push({id: 2, libelle: "Reporter des heures", description:"Nombre d'heures que vous souhaitez reporter", numberIndicator:13})
        }
        setChoices(list);
    }, [props.time, props.isAnnual, props.CptHS]);
    const handleChange = ()=>{
        console.log("change")
        if(refSelect.current){
            const myRef = refSelect.current.value;
            const my = choices.find(c=>c.id === parseInt(myRef))
            if(my) setMyChoice(my)
        }
    }
    return (
        <div className={`actionHybride ${props.className}`}>
            <div className="wrap_choix_action">
                <label>Votre Action</label>
                <div className="wrap_select">
                    <select className={"list"} value={myChoice ? myChoice.id : -1} onChange={handleChange} ref={refSelect}>
                        {choices.map(item=>(
                            <option key={`O${item.id}`} value={item.id}>{item.libelle}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="content_choice">
                {(!myChoice || myChoice.id === -1) ?
                    <p>En attente d'une action</p> :
                    [0, 2, 3, 5].indexOf(parseInt(myChoice.id + "")) !== -1 ?
                        <div className="action_number">
                            <ActionNumber
                                dateTo={props.dateTo}
                                time={props.time}
                                user={props.user}
                                closeMe={props.closeMe}
                                label={myChoice.description || ""}
                                idMotif={myChoice.numberIndicator || 0}
                            />
                        </div> :
                        myChoice.id === 1 ?
                            <div className="debit">
                                <DebiterCompteurTemps
                                    closeMe={props.closeMe}
                                    dateTo={props.dateTo}
                                    time={props.time}
                                    user={props.user}
                                    CptHS={props.CptHS}
                                />
                            </div> :
                            <div className="credit">
                                <CrediterCompteurTempsHyb
                                    CptHS={props.CptHS}
                                    closeMe={props.closeMe}
                                    dateTo={props.dateTo}
                                    time={props.time}
                                    user={props.user}
                                />
                            </div>

                }
            </div>
        </div>
    )
}

const ActionHybride = styled(ActionHybrideCtrl)`
    display: flex;
    flex-direction: column;
    gap: 25px;

    .wrap_select {
        display: flex;
        justify-content: center;
    }

    select.list {
        border-radius: 4px;
        border: solid ${props => props.theme.PrimaryLight} 1px;
        height: 38px;
        padding-left: 5px;
        width: 250px;
    }

    .wrap_choix_action {
        padding-bottom: 1rem;
        border-bottom: solid ${props => props.theme.NeutreExtraLight} 1px;
        label{
            display: block;
            font-weight: bold;
            margin-bottom: 8px;
            text-align: center;
        }
        
    }
`

export default ActionHybride