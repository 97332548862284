import styled, {useTheme} from "styled-components";

import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import React, {useEffect, useState} from "react";
import {format} from "date-fns";
import {useGetPlageTimeUserDD} from "../../../../../../../../features/plageTime/plageTime.hooks";
import TimedSkeleton from "../../../../../../../../components/atoms/TimedSkeleton/TimedSkeleton";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
export const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        datalabels:{
            display:false
        },
        title: {
            display: false,
            text: 'Historiques des heures',
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

interface ChartTimesDailyCtrlProps{
    className?:string;

    User:User;
    calendrier:CalendrierPaie;
}



const ChartTimesDailyCtrl = (props:ChartTimesDailyCtrlProps)=>{
    const theme = useTheme();
    const [data, setData] = useState<any|null>(null);

    const PlageTimesQuery = useGetPlageTimeUserDD(props.User.id, new Date(props.calendrier.startAt.slice(0,10)+"T12:00:00"), new Date(props.calendrier.stopAt.slice(0,10)+"T12:00:00"))

    useEffect(()=>{
        if(PlageTimesQuery.data){
            const times = PlageTimesQuery.data;
            let myCurrent = new Date(props.calendrier.startAt.slice(0,10)+"T12:00:00");
            let last = new Date(props.calendrier.stopAt.slice(0,10)+"T12:00:00");
            let labels:string[] = [];
            let TimesWork:number[] = [];
            let TimesNoWorks:number[] = [];
            let TimesRegules:number[] = [];
            while(myCurrent<=last){
                labels.push(("0"+myCurrent.getDate()).slice(-2))
                const Plages = times.filter(p=>format(new Date(p.plageAt.slice(0,10)+"T12:00:00"), "yyyy-MM-dd") === format(myCurrent, "yyyy-MM-dd"))
                TimesNoWorks.push(Plages.reduce((acc:number, item)=>(acc+item.timeNoWork), 0))
                TimesWork.push(Plages.reduce((acc, item)=>(acc+item.timeDid), 0))
                TimesRegules.push(Plages.reduce((acc, item)=>(acc+item.timeRegule), 0))
                myCurrent = new Date(myCurrent.getTime() + 86400000);
            }
            const datasets = [
                {
                    label:'Heures Travaillées',
                    data:TimesWork,
                    backgroundColor: theme.PrimarySuperDark,
                },
                {
                    label:'Heures Non Travaillées',
                    data:TimesNoWorks,
                    backgroundColor: theme.PrimaryLight,
                },
                {
                    label:'Heures Regules',
                    data:TimesRegules,
                    backgroundColor: theme.TertiarySuperLight,
                },
            ]
            setData({labels, datasets})
        }
    }, [PlageTimesQuery.data, props.calendrier])

    return (
        <div className={`ChartHeureDay ${props.className}`}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            {PlageTimesQuery.isLoading ? <div className={"wrap-wait"}><TimedSkeleton width={"100%"} nbOccurence={1} type={"perso"} heightPerso={"154px"}/></div>: data === null ? 'null':
                <div className={`canvas-container`}>
                    <MyChartBar options={options} data={data}/>
                </div>
            }
        </div>
    )
}


const MyChartBar = ({...props})=>{
    return <Bar options={props.options}  data={props.data} width={"100%"} height={"100%"} />;
}

const ChartTimesDaily = styled(ChartTimesDailyCtrl)`
    width: 100%;
    height: calc(100% - 51px);
    .canvas-container{
        width: 100%;
        height: 100%;
    }
`

export default ChartTimesDaily