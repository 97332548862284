import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {changeOrdreFx, CreateFx, deleteFx, getAllFonction, getAllFonctionByCentre, ModifyFx} from "./fontion.services";
import {changeOrdreLieu} from "../lieu/lieu.services";
import {toast} from "react-toastify";

export const useGetFonctions = (idCentre:number|null=null):UseQueryResult<Fonction[], Error>=>{
    return useQuery(["fonctions", idCentre], ()=>getAllFonction(idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false
    })
}

export const useGetFonctionsByCentre = (idCentre:number):UseQueryResult<Fonction[], Error>=>{
    return useQuery(["fonctions_centre", idCentre], ()=>getAllFonctionByCentre(idCentre), {
        staleTime:60000,
        refetchOnWindowFocus:false,
        enabled:idCentre!==0
    })
}


export const useModifyFonction = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PutFonction)=>{
            return ModifyFx(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["fonctions_centre"]);
                queryClient.invalidateQueries(["fonctions"]);

            }
        }
    )
}

export const useAddFonction = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PostFonction)=>{
            return CreateFx(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["fonctions_centre"]);
                queryClient.invalidateQueries(["fonctions"]);
            }
        }
    )
}

export const useTrashFonction = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return deleteFx(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["fonctions_centre"]);
                queryClient.invalidateQueries(["fonctions"]);
            }
        }
    )
}

export const useChangeOrdreFx = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:FxChangeOrdre)=>{
            return changeOrdreFx(data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["fonctions_centre"]);
                queryClient.invalidateQueries(["fonctions"]);
            },
            onError:()=>{
                toast.error("impossible de modifier ordre des fonctions")
            }
        }
    )
}

