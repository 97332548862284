import styled from "styled-components";

interface SettingSwitchCtrlProps{
    className?:string;
    Statut:boolean;
    setStatut:()=>void;
    disabled?:boolean;

}

const SettingSwitchCtrl = (props:SettingSwitchCtrlProps)=>{
    return (
        <div className={`settingSwitch ${props.className}`} onClick={()=>props.setStatut()}>
            <div className={`wrapper_switcher ${props.Statut ? "active" : "inactive"} ${props.disabled ? "disabled" : "hoverable"}`}>
                {props.disabled &&
                    <div className="shadow_disabled"/>
                }
                <div className="switcher">
                    <div className="in_switcher"/>
                </div>
            </div>
        </div>
    )
}

const WidSwitcher = 50;

const SettingSwitch = styled(SettingSwitchCtrl)`
    width: 100%;
    .wrapper_switcher{
        margin: auto;
        width: ${WidSwitcher}px;
        height: ${WidSwitcher/2}px;
        background: ${props => props.Statut ? props.theme.SecondaryMegaLight : props.theme.ComplementaryMegaLight};
        position: relative;
        border-radius: 4px;
        box-shadow: 1px 1px 3px rgba(0,0,0,.4) inset;
        &.hoverable:hover{
            cursor: pointer;
            filter: brightness(95%);
            .switcher{
                .in_switcher{
                    filter: brightness(90%);
                }
            }
        }
        .shadow_disabled{
            position: absolute;
            z-index: 4;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,.8);
        }
        &.active{
            .switcher{
                left:50%;
                border-radius: 0 4px 4px 0;
                transition: all 0.3s;
                .in_switcher{
                    transition: all 0.3s;
                    border-radius: 0 4px 4px 0;    
                    background: ${props=>props.theme.Secondary};
                    border: solid 1px ${props=>props.theme.SecondaryDark};
                    box-shadow: 0px 0px 3px rgba(0,0,0,.4)
                }
            }
        }
        &.inactive{
            .switcher{
                left:0;
                border-radius: 4px 0 0 4px;
                transition: all 0.3s;
                .in_switcher{
                    transition: all 0.3s;
                    border-radius: 4px 0 0 4px;
                    background: ${props=>props.theme.Complementary};
                    border: solid 1px ${props=>props.theme.ComplementaryDark};
                    box-shadow: 0px 0px 3px rgba(0,0,0,.4)
                }
            }
        }
    }
    .switcher{
        position: absolute;
        padding: 2px;
        transition: all 0.3s;
        width: ${WidSwitcher/2}px;
        height: 100%;
        top:0;
        z-index: 1;
        .in_switcher{
            transition: all 0.3s;
            z-index: 2;
            width: 100%;
            height: 100%;
        }
    }
`

export default SettingSwitch