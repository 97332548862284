import styled from "styled-components";
import {UserDocument} from "../../../features/userDocument/userDocument";
import {useEffect, useState} from "react";
import {useGetImageDocument} from "../../../services/hooks";
import {useGetOneDocument} from "../../../features/userDocument/userDocument.hooks";

interface TimedOneDocumentCardCtrlProps {
    className?:string;
    Document:UserDocument;
    Type:"Icon"|"Line",
    WidthDate?:boolean;
    Overring?:boolean;
}

const TimedOneDocumentCardCtrl = ({className, Document, WidthDate, Overring, Type}: TimedOneDocumentCardCtrlProps) => {
    const [myDocument, setMyDocument] = useState(Document)
    const DocumentQuery = useGetOneDocument(Document.id);
    const [ext, setExt] = useState<string|null>(null)
    const {Image} = useGetImageDocument(ext);
    useEffect(() => {
        if(DocumentQuery.data) {
            setMyDocument(DocumentQuery.data);
        }
    }, [DocumentQuery.data]);
    useEffect(() => {
        const TabA = myDocument.documentUrl.split('/');
        const nb = TabA.length;
        const fileName = TabA[nb-1];
        const TabB = fileName.split('.');
        const nbB = TabB.length;
        const ext = TabB[nbB-1];
        setExt(ext);
    }, [myDocument]);
    return (
        <div className={`one_doc_card ${className} ${Overring ? '--hovering' : ''} ${Type}`}>
            <div className="icon_place">{Image}</div>
            <div className="document_info">
                <div className={`name_doc`} data-infos={"mer"}><span>{myDocument.namePerso}</span></div>
                {WidthDate &&
                    <div className={`dateDoc`}>{new Date(myDocument.creationAt).toLocaleDateString()}</div>
                }
            </div>
        </div>
    )
}

export const TimedOneDocumentCard = styled(TimedOneDocumentCardCtrl)`
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    flex-direction: ${props=>props.Type === "Line" ? "row" : "column"};
    width: ${props=>props.Type === "Line" ? "auto" : "110px"}; 
    padding: 0.2rem;
    border-radius: 4px;
    &.--hovering:hover{
        background: ${props => props.theme.PrimaryMegaLight};
        cursor: pointer;
    }
    .icon_place{
        width: 50px;
        margin: auto;
        img{
            width: 100%;
            height: auto;
        }
    }
    &.Icon {
        .document_info {
            font-size: 12px;
            width: 100%;

            .name_doc {
                text-align: center;
                overflow-wrap: break-word;
            }

            .dateDoc {
                text-align: center;
            }
        }
    }
    &.Line{
        align-items: center;
        .document_info {
            font-size: 12px;
            width: 100%;
            flex-grow: 1;
            .name_doc {
                text-align: left;
                overflow-wrap: break-word;
            }

            .dateDoc {
                text-align: center;
            }
        }
    }
`