import styled from "styled-components";
import {useCallback, useEffect, useState} from "react";
import {useChangeOrdreActe} from "../../../../features/acte/acte.hooks";
import OneCardFxInactif from "./components/OneCardFxInactif";
import update from "immutability-helper";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import OneCardActe from "../05_Actes/components/OneCardActe";
import OneCardFx from "./components/OneCardFx";
import {useChangeOrdreFx} from "../../../../features/fonction/fonction.hooks";

interface WrapListFxCtrlProps{
    className?:string;
    Fxs:Fonction[];
    FxActif:Fonction|null;
    isActifView:boolean;
    setFxActif:(fx:Fonction)=>void;
}

const WrapListFxCtrl = (props:WrapListFxCtrlProps)=>{
    const mutation = useChangeOrdreFx();
    const [myFxs, setMyFxs] = useState<Fonction[]>([])
    useEffect(()=>{
        console.log("log 1")
        if(props.Fxs.length>0){
            setMyFxs(props.Fxs.filter(l=>l.isProduction === props.isActifView).sort((a,b)=>(a.id||0) > (b.id||0) ? 1 : -1));
        }
    }, [props.Fxs, props.isActifView])
    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setMyFxs((prevCards: Fonction[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as Fonction],
                ],
            }),
        )
        //SaveMove();
    }, [])
    const SaveMove = ()=>{
        const ids = myFxs.map(l=>l.id).join('_');
        const datas:FxChangeOrdre = {fonctions:ids};
        mutation.mutate((datas))
    }
    if(props.Fxs.length === 0){
        return null;
    } else {
        return (
            <div className={`WrapListFx wrap_list ${props.className}`}>
                {props.isActifView ?
                    <>
                        {myFxs.find(fx=>fx.id === 1)!==undefined &&
                        <OneCardFxInactif
                            Fx={myFxs.find(fx=>fx.id === 1) as Fonction}
                            isCurrent={props.FxActif?.id === 1}
                            ClickOnEdit={() => props.setFxActif(myFxs.find(fx=>fx.id === 1) as Fonction)}
                        />
                        }
                        <DndProvider backend={HTML5Backend}>
                            {myFxs.filter(fx=>fx.id!==1).map((item, idx)=>(
                                <OneCardFx
                                    ClickOnEdit={()=>props.setFxActif(item)}
                                    Fx={item}
                                    key={`Fx${item.id}`}
                                    isCurrent={props.FxActif?.id === item.id}
                                    index={idx}
                                    Move={moveCard}
                                    SaveMove={SaveMove}
                                />
                            ))}
                        </DndProvider>
                    </>
                    :
                    <>
                        {myFxs.map(item => (
                            <OneCardFxInactif
                                key={`Fx${item.id}`}
                                Fx={item}
                                isCurrent={props.FxActif?.id === item.id}
                                ClickOnEdit={() => props.setFxActif(item)}
                            />
                        ))}
                </>
                }
            </div>
        )
    }
}

const WrapListFx = styled(WrapListFxCtrl)``

export default WrapListFx