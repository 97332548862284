import {useEffect, useState} from "react";
import {useGetCentreActuel} from "../../../../../../../../../features/centre/centre.hooks";
import {GetGoodContractDate} from "../../../../../../../../../components/functions/ContratFunction";

export interface DetailsCoef{
    descr:string;
    coef:number;
    total:number;
    
}

export interface ApplicCoef{
    explication:string;
    Details:DetailsCoef[];
}

export const useGetDetailsCredit = (user:User, dateTo:Date, time:number, timeDepart=time)=>{
    const CentreQuery = useGetCentreActuel()
    const [timeToCredit, setTimeToCredit] = useState(time)
    const [coef1, setCoef1] = useState(1.25)
    const [coef2, setCoef2] = useState(1.5)
    const [coef3, setCoef3] = useState(1.1)
    const [coef4, setCoef4] = useState(1.25)
    const [coefApplicable, setCoefApplicable] = useState<ApplicCoef|null>(null)
    useEffect(() => {
        if(CentreQuery.data) {
            const litts: any = CentreQuery.data.literalParameters;
            if(litts.hasOwnProperty('HS1')){
                setCoef1(parseFloat(litts["HS1"]))
            }
            if(litts.hasOwnProperty('HS2')){
                setCoef2(parseFloat(litts["HS2"]))
            }
            if(litts.hasOwnProperty('HS3')){
                setCoef3(parseFloat(litts["HS3"]))
            }
            if(litts.hasOwnProperty('HS4')){
                setCoef4(parseFloat(litts["HS4"]))
            }
        }
    }, [CentreQuery.data]);
    useEffect(() => {
        if(time > 0) {
           
            const GoodContrat = GetGoodContractDate(user.contrats, dateTo)
            const TimeRound = Math.round(time*100)/100
            if (GoodContrat) {
                if (GoodContrat.quantite < 35) {
                    const limit1 = GoodContrat.quantite / 10;
                    const DetailCoef:DetailsCoef[] = [];
                    let Total = 0;
                    if (timeDepart > limit1) {
                        const TimeCoef4 = timeDepart - limit1;
                        const TimeCoef3 = time -TimeCoef4
                        DetailCoef.push({descr:`Pour ${Math.round(TimeCoef3*100)/100}h`, coef:coef3, total:TimeCoef3*coef3})
                        const timeSupp = Math.round((TimeCoef4)*100)/100;
                        DetailCoef.push({descr:`Pour ${timeSupp}`, coef:coef4, total:TimeCoef4*coef4})
                        Total += (limit1*coef3 + timeSupp*coef4)
                    } else{
                        DetailCoef.push({descr:`Pour ${TimeRound}h`, coef:coef3, total:time*coef3})
                        Total += time*coef3
                    }
                    setCoefApplicable({explication:"le collaborateur est à temps partiel", Details:DetailCoef})
                    setTimeToCredit(Total);

                } else {
                    const DetailCoef:DetailsCoef[] = [];
                    let Total = 0;
                    if (timeDepart >= 8) {
                        const TimeCoef2 = timeDepart - 8;
                        const TimeCoef1 = time - TimeCoef2;
                        DetailCoef.push({descr:`Pour ${Math.round(TimeCoef1*100)/100}h`, coef:coef1, total:TimeCoef1*coef1})
                        const timeSupp = Math.round((TimeCoef2)*100)/100;
                        DetailCoef.push({descr:`Pour ${timeSupp}h`, coef:coef2, total:TimeCoef2*coef2})
                        Total += (TimeCoef1*coef1 + TimeCoef2*coef2)
                    } else {
                        DetailCoef.push({descr:`Pour ${TimeRound}h`, coef:coef1, total:time*coef1})
                        Total+=time*coef1;
                    }
                    setCoefApplicable({explication:"le collaborateur est à temps complet", Details:DetailCoef})
                    console.log(Total)
                    setTimeToCredit(Total)
                }
            } else {
                const DetailCoef:DetailsCoef[] = [];
                let Total = 0;
                if (timeDepart >= 8) {
                    const TimeCoef2 = timeDepart - 8;
                    const TimeCoef1 = time - TimeCoef2;
                    DetailCoef.push({descr:`Pour ${Math.round(TimeCoef1*100)/100}h`, coef:coef1, total:TimeCoef1*coef1})
                    const timeSupp = Math.round((TimeCoef2)*100)/100;
                    DetailCoef.push({descr:`Pour ${timeSupp}h`, coef:coef2, total:TimeCoef2*coef2})
                    Total += (TimeCoef1*coef1 + TimeCoef2*coef2)
                } else {
                    DetailCoef.push({descr:`Pour ${TimeRound}h`, coef:coef1, total:time*coef1})
                    Total+=time*coef1;
                }
                setCoefApplicable({explication:"le collaborateur est à temps complet", Details:DetailCoef})
                console.log(Total)
                setTimeToCredit(Total)
            }
        }
    }, [time, timeDepart]);


    return {timeToCredit, coefApplicable}

} 