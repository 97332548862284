import styled, {keyframes} from "styled-components";
import Logo from "../../../assets/images/Logo-Small-Timed-Light.png"

interface TimedLoadingCtrlProps {
    className?:string;
    width?:string;
}

const TimedLoadingCtrl = (props: TimedLoadingCtrlProps) => {
    return (
        <div className={`timed_loading ${props.className}`}>
            <div className="rotateWait"></div>
            <img src={Logo} alt="Logo" />
        </div>
    )
}

const AnimationRotate = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
`

export const TimedLoading = styled(TimedLoadingCtrl)`
    position: relative;
    width: ${props => props.width ? props.width : "120px"};
    text-align: center;
    img{
        width: 90%;
        height: auto;
        margin: auto;
    }
    .rotateWait{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: ${props=>props.theme.BodyBackground} ${props=>props.theme.Primary} ${props=>props.theme.BodyBackground};
        animation:${AnimationRotate} 2s infinite;
    }
`