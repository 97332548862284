import styled from "styled-components";
import TempsTrOneUserDash from "../01_Dashboard/TempsTrOneUserDash";
import React, {ElementType, useEffect, useState} from "react";
import {TitrePage} from "../../../../../../../assets/styles/elements";
import {format} from "date-fns";
import {TabMois} from "../../../../../../../services/constantes/constantes";
import TempsSemaineSwitcher from "../02_TempsSemaine/TempsSemaineSwitcher";
import {useGetCentreParams} from "../../../../../../../services/hooks";
import TempsTrEvents from "../04_Events/TempsTrEvents";
import TempsTrTR from "../05_TicketsResto/TempsTrTR";
import TempsTrAbsencesV2 from "../03_Absences/TempsTrAbsencesV2";
import DetailsTempsTrUser from "../06_Details/DetailsTempsTrUser";

interface TempsTrOneUserMainCtrlProps{
    className?:string;
    calendrier:CalendrierPaie;
    user:User;
}

interface OneChoiceTempsTr extends Choice{
    Component:ElementType<{
        className?: string;
        user:User;
        calendrier:CalendrierPaie
    }>
}

const ChoiceTitle:OneChoiceTempsTr[] = [
        {id:0, libelle:"Tableau de bord", Component:TempsTrOneUserDash},
        {id:1, libelle:"Details", Component:DetailsTempsTrUser},
        // {id:2, libelle:"Absences", Component:TempsTrAbsencesV2},
        // {id:3, libelle:"Evènements", Component:TempsTrEvents},
    ]

const TempsTrOneUserMainCtrl = (props:TempsTrOneUserMainCtrlProps)=>{
    const [myMenus, setMyMenus] = useState<OneChoiceTempsTr[]>(ChoiceTitle)
    const isTR = useGetCentreParams(null, 5)
    const [myCurrentDisplay, setMyCurrentDisplay] = useState(ChoiceTitle[0])
    const GetNameMonth = ()=>{
        const myMonth = TabMois.find(m=>m.num === props.calendrier.numMois)
        if(myMonth){
            return `${myMonth.name} ${props.calendrier.numYear}`
        } else {
            return `${props.calendrier.numMois} ${props.calendrier.numYear}`
        }
    }
    useEffect(() => {
        if(isTR){
            setMyMenus(prevState => [...prevState, {id:4, libelle:"Tickets restaurants", Component:TempsTrTR}])
        }
    }, [isTR]);
    return (
        <div className={`tempsTrOneUserMain ${props.className}`}>
            <TitrePage>Temps travail {props.user.prenom} {props.user.nom.toUpperCase()} de {GetNameMonth()} du {format(new Date(props.calendrier.startAt.slice(0,10)+"T12:00:00"), "dd/MM/yyyy")} au {format(new Date(props.calendrier.stopAt.slice(0,10)+"T12:00:00"), "dd/MM/yyyy")} </TitrePage>
            <div className="wrap_menus">
                {ChoiceTitle.map(item=>(
                    <div className={`one_choice_menu ${item.id === myCurrentDisplay.id ? "current" : ""}`} key={`M${item.id}`} onClick={()=>setMyCurrentDisplay(item)}>{item.libelle}</div>
                ))}
            </div>
            <myCurrentDisplay.Component user={props.user} calendrier={props.calendrier}/>
        </div>
    )
}

const TempsTrOneUserMain = styled(TempsTrOneUserMainCtrl)`
    padding: 0 2%;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
    overflow-y: hidden;
    h3.subCat{
        margin-bottom: 15px;
        color:${props => props.theme.Dark};
        font-size: 20px;
    }
    .wrap_menus{
        background: white;
        padding: 0.5rem;
        display: flex;
        justify-content: flex-start;
        .one_choice_menu{
            padding: 0.25rem 0.5rem;
            color:${props=>props.theme.Dark};
            &.current{
                font-weight: bold;
                border-bottom: solid 2px ${props => props.theme.Dark};
            }
            &:hover{
                font-weight: bold;
                cursor: pointer;
            }
        }
    }
    table{
        width: 100%;
        border-collapse: collapse;
        &.solde_cp{
            width: 450px;
            td{
                padding: 0.25rem;
                .wrap_nb{
                    padding: 0.25rem 0.5rem;
                    border-radius: 4px;
                    text-align: center;
                    font-weight: bold;
                    &.pos{
                        background: ${props => props.theme.SecondaryExtraLight};
                        color: ${props => props.theme.SecondaryExtraDark};
                    }
                    &.neg{
                        background: ${props => props.theme.ComplementaryExtraLight};
                        color: ${props => props.theme.ComplementaryExtraDark};
                    }
                }
            }
        }
        &.table_in_temps{
            th, td{
                padding: 0.5rem;
                
                &.left{
                    text-align: left;
                }
            }
            th{
                border-bottom:solid 1px ${props => props.theme.NeutreLight};
                color: ${props=>props.theme.NeutreSuperDark};
                &.is_date{
                    width: 250px;
                }
                &.is_qty{
                    width: 110px;
                }
            }
            
            tbody tr{
                &:nth-child(odd){
                    background: #f4f5f5;
                }
            }
            td{
                &.center{
                    text-align: center;
                }
                
                &.no_data{
                    text-align: center;
                    color:${props=>props.theme.NeutreLight};
                    font-style: italic;
                }
            }
        }
    }
`

export default TempsTrOneUserMain