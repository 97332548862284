import styled from "styled-components";

interface TimedSwitchCtrlProps{
    className?:string;
    current:boolean;
    switchPos:()=>void;
    label?:string;
    isDisabled?:boolean;
}

const TimedSwitchCtrl = (props:TimedSwitchCtrlProps)=>{
    return (
        <div className={`timedSwitch ${props.className}`}>
            <label>
                {props.label &&
                    <span className={`label_switch`}>{props.label}</span>
                }
                <input disabled={props.isDisabled} type={"checkbox"} checked={props.current} onChange={()=>props.switchPos()}/>
                <div className="switcher">
                    <div className="switchy">
                        <div className="in_switchy"/>
                    </div>
                </div>

            </label>
        </div>
    )
}

const TimedSwitch = styled(TimedSwitchCtrl)`
    position: relative;
    .label_switch{
        font-weight: bold;
    }
    input {
        position: absolute;
        left: -9999px;
        opacity: 0;
    }
    label{
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        align-items: center;
        &:hover{
            cursor: pointer;
        }
    }
    .switcher {
        width: 48px;
        height: 24px;
        position: relative;
        background: #e5e3e3;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2),
        inset 0 0 0 1px rgba(0, 0, 0, 0.06);

        .switchy {
            position: absolute;
            padding: 2px;
            transition: left 0.2s;
            .in_switchy {
                width: 100%;
                height: 100%;
                border-radius: 2px;
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
                0 0 0 1px rgba(0, 0, 0, 0.05);
                background: white linear-gradient(
                        to bottom,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(0, 0, 0, 0.06) 100%);
            }
            z-index: 2;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;

        }
    }

    input:checked ~ .switcher {
        background: #a9d88c;

        .switchy {
            left: 50%;
            transition: left 0.2s;
        }
    }
`

export default TimedSwitch