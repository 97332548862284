import styled from "styled-components";
import {useEffect, useState} from "react";
import {useModifyActe, usePutActeHoraires, useTrashActe} from "../../../../features/acte/acte.hooks";
import {useGetNbPlageType} from "../../../../features/plage/plage.hooks";
import {Contraint, DatasSetter, ValideNoError} from "../../../../components/functions/ValideDataFunctions";
import InputTextSetting from "../components/InputTextSetting";
import InputColorPickerSetting from "../components/InputColorPickerSetting";
import InputSelectSetting from "../components/InputSelectSetting";
import ModifyLoader from "../components/ModifyLoader";
import ChangeHoraireSetting from "../components/ChangeHoraireSetting";

interface FormEditActeCtrlProps{
    className?:string;
    Acte:Acte;
    setActeActif:(a:Acte|null)=>void;
}

interface PropsActe{
    libelle:string;
    backColor:string;
    fontColor:string;
    TypePresence:Choice|null;
    DemAbs:Choice|null;
    Statut:Choice|null;
    Master:Choice|null;
}
interface ErrorsProps{
    libelle:string|null;
    backColor:string|null;
    fontColor:string|null;
    TypePresence:string|null;
    DemAbs:string|null;
    Statut:string|null;
    Master:string|null;
}

interface DetailsProps{
    TypePresence:InfosProps;
    Statut:InfosProps;
    DemAbs:InfosProps;
    Master:InfosProps;
}
const TabInfos:DetailsProps = {
    TypePresence:{keyProps:"TypePresence", libelle:"Acte - Type de présence", description:"En choisissant présent ou absent, vous choisissez si lors de la création d'une plage sur cet acte, le collaborateur sera considéré comme présent ou non"},
    Statut:{keyProps:"Statut", libelle:"Acte - Statut de l'acte", description:"Si l'acte est inactif il ne pourra plus être choisi dans le futur, il restera cependant présent dans le passé"},
    DemAbs:{keyProps:"DemAbs", libelle:"Acte - Statut de l'acte", description:"Si l'acte nécessite une demande d'absence, le collaborateur devra passer par cette demande sur son application mobile"},
    Master:{keyProps:"Master", libelle:"Acte - Propriété master", description:"Si l'acte est un acte 'Chef d'équipe', il pourra être utilisé pour former des équipes"},
}

const DefaultError:ErrorsProps ={
    libelle:null,
    backColor:null,
    fontColor:null,
    TypePresence:null,
    DemAbs:null,
    Statut:null,
    Master:null,
}

interface OneHoraires{
    id:number;
    start:number;
    libelle:string;
    end:number;
}


const FormEditActeCtrl = (props:FormEditActeCtrlProps)=>{
    const mutationAH = usePutActeHoraires();
    const mutationModify = useModifyActe();
    const mutationTrash = useTrashActe();
    const [idActe, setIdActe] = useState(props.Acte.id)
    const [mesHoraires, setMesHoraires] = useState<OneHoraires[]>([])
    const PlageNbQuery = useGetNbPlageType({id:idActe, type:"acte"})
    const choicesType:Choice[] = [{id:0, libelle:"Absence", description:"il s'agit d'une absence spécifique"},{id:1, libelle:"Présent", description:"Il s'agit d'un acte de travail"}]
    const choicesStatut:Choice[] = [{id:0, libelle:"Inactif", description:"Rendre l'acte inactif pour le futur"},{id:1, libelle:"Actif", description:"L'acte est sélectionnable"}]
    const choicesDemAbs:Choice[] = [{id:0, libelle:"Non", description:"Vous pourrez créer des plages sur cette acte directement"},{id:1, libelle:"Oui", description:"Cette absence nécessite une demande d'absence"}]
    const choicesMAster:Choice[] = [{id:0, libelle:"Non", description:"Il ne s'agit pas d'un acte permettant d'être chef d'équipe"},{id:1, libelle:"Oui", description:"Cet acte peut être utilisé comme chef d'équipe"}]
    const [isDirty, setIsDirty] = useState(false);
    const [myActe, setMyActe] = useState<PropsActe>({
        libelle:props.Acte.libelle,
        backColor:props.Acte.backColor,
        fontColor:props.Acte.fontColor,
        TypePresence:!props.Acte.isPresent ? {id:0, libelle:"Absence"} : {id:1, libelle:"Présent"},
        Statut:!props.Acte.isProduction ? {id:0, libelle:"Inactif"} : {id:1, libelle:"Actif"},
        DemAbs:props.Acte.isDemAbs ? choicesDemAbs[1] : choicesDemAbs[0],
        Master:props.Acte.isMaster ? choicesMAster[1] : choicesMAster[0]
    })
    const [myActeInitial, setMyActeInitial] = useState<PropsActe>({
        libelle:props.Acte.libelle,
        backColor:props.Acte.backColor,
        fontColor:props.Acte.fontColor,
        TypePresence:!props.Acte.isPresent ? {id:0, libelle:"Absence"} : {id:1, libelle:"Présent"},
        Statut:!props.Acte.isProduction ? {id:0, libelle:"Inactif"} : {id:1, libelle:"Actif"},
        DemAbs:props.Acte.isDemAbs ? choicesDemAbs[1] : choicesDemAbs[0],
        Master:props.Acte.isMaster ? choicesMAster[1] : choicesMAster[0]
    })
    const TabString = ["libelle", "backColor", "fontColor"]
    const TabChoice = ["TypePresence","Statut", "DemAbs", "Master"]
    const [errors, setErrors] = useState<ErrorsProps>(DefaultError)
    const TabNeeds:Contraint[] = [
        {id:"libelle", required:true},
        {id:"backColor", required:true},
        {id:"fontColor", required:true},
    ]
    const SaveHeureDebutFinHoraire = (n:number, id:string)=>{
        const tabId = id.split("_");
        const idHoraires = parseInt(tabId[0]);
        const startEnd = tabId[1];
        const horActual = [...mesHoraires];
        const myNewHoraires = horActual.map(h=>{
            if(h.id === idHoraires){
                return {...h, [startEnd]:n}
            } else {
                return h;
            }
        })
        setMesHoraires(myNewHoraires);
        SaveLieuHoraires(myNewHoraires);
    }
    const SaveLieuHoraires = (lhs:OneHoraires[])=>{
        const tabDatas:ActeHoraireFD[] = []
        lhs.forEach(lh=>{
            tabDatas.push({
                id:lh.id,
                start:lh.start,
                end:lh.end
            })
        })
        tabDatas.forEach(i=>{
            mutationAH.mutate(i)
        })
    }
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        console.log(value)
        console.log(id)
        DatasSetter<PropsActe, ErrorsProps>(value, id as keyof PropsActe, TabString, [], TabChoice, TabNeeds, myActe, setMyActe,errors,setErrors)
        if(id === "TypePresence"){
            const myVal:Choice = value as Choice;
            if(myVal.id === 1){
                setMyActe(acte=>{
                    return {...acte, DemAbs:choicesDemAbs[0]}
                })
            } else{
                setMyActe(acte=>{
                    return {...acte, Master:choicesMAster[0]}
                })
            }
        }
        if(id === "libelle"){
            setTimeout(()=>{
                setIsDirty(true);
            }, 900)
        } else {
            setIsDirty(true);
        }
    }
    useEffect(() => {
        setMyActe({
            libelle:props.Acte.libelle,
            backColor:props.Acte.backColor,
            fontColor:props.Acte.fontColor,
            TypePresence:!props.Acte.isPresent ? {id:0, libelle:"Absence"} : {id:1, libelle:"Présent"},
            Statut:!props.Acte.isProduction ? {id:0, libelle:"Inactif"} : {id:1, libelle:"Actif"},
            DemAbs:props.Acte.isDemAbs ? choicesDemAbs[1] : choicesDemAbs[0],
            Master:props.Acte.isMaster ? choicesMAster[1] : choicesMAster[0]
        })
        setMyActeInitial({
            libelle:props.Acte.libelle,
            backColor:props.Acte.backColor,
            fontColor:props.Acte.fontColor,
            TypePresence:!props.Acte.isPresent ? {id:0, libelle:"Absence"} : {id:1, libelle:"Présent"},
            Statut:!props.Acte.isProduction ? {id:0, libelle:"Inactif"} : {id:1, libelle:"Actif"},
            DemAbs:props.Acte.isDemAbs ? choicesDemAbs[1] : choicesDemAbs[0],
            Master:props.Acte.isMaster ? choicesMAster[1] : choicesMAster[0]
        })
        setIdActe(props.Acte.id)
        setMesHoraires(props.Acte.acteHoraires)
    }, [props.Acte]);
    useEffect(() => {
        if(isDirty){
            SaveModify();
        }
    }, [isDirty]);
    const RealDirty = ()=>{
        const TabKey:(keyof PropsActe)[] = ["libelle", "backColor", "fontColor","TypePresence","Statut"]
        let nbDiff = 0;
        TabKey.forEach(k=>{
            if(myActe[k]!==myActeInitial[k]) nbDiff++;
        })
        console.log(nbDiff);
        return nbDiff>0;
    }
    const SaveModify = ()=>{
        console.log("ici maj")
        if(ValideNoError<ErrorsProps>(errors) && RealDirty()) {
            console.log(myActe.Master);
            console.log(myActe.Master?.id === 1);
            const datas: ActeFD = {
                libelle:myActe.libelle,
                fontColor:myActe.fontColor,
                backColor:myActe.backColor,
                isProduction:(myActe.Statut as Choice).id === 1,
                isPresent:(myActe.TypePresence as Choice).id === 1,
                isDemAbs:(myActe.DemAbs as Choice).id === 1,
                isMaster:(myActe.Master as Choice).id === 1,
            }

            mutationModify.mutate({id: props.Acte.id, datas: datas}, {
                onSuccess:()=>{
                    setIsDirty(false)
                    setMyActeInitial({...myActe})
                }
            })
        }

    }
    const TrashActe = ()=>{
        mutationTrash.mutate(idActe, {
            onSuccess:()=>{
                props.setActeActif(null)
            }
        })
    }
    return (
        <div className={`formEditActe ${props.className}`}>
            {(mutationModify.isLoading || mutationTrash.isLoading) &&
                <ModifyLoader/>
            }
            <div className="wrap_inputs_setting">
                <InputTextSetting current={myActe.libelle} setValue={setMyValue} Error={errors.libelle} id={"libelle"} label={"Libellé"}/>
                <InputColorPickerSetting current={myActe.backColor} setValue={setMyValue} label={"Couleur de fond"} id={"backColor"}/>
                <InputColorPickerSetting current={myActe.fontColor} setValue={setMyValue} label={"Couleur du texte"} id={"fontColor"}/>
                <InputSelectSetting setValue={setMyValue} Error={errors.TypePresence} label={"Type de présence"} id={"TypePresence"} choices={choicesType} Current={myActe.TypePresence} ItemInfos={TabInfos.TypePresence}/>
                {myActe.TypePresence?.id === 0 ?
                    <InputSelectSetting setValue={setMyValue} Error={errors.DemAbs} label={"Nécessite une demande d'absence"} id={"DemAbs"} choices={choicesDemAbs} Current={myActe.DemAbs} ItemInfos={TabInfos.DemAbs}/>:
                    <InputSelectSetting setValue={setMyValue} Error={errors.Master} label={"Acte de chef d'équipe"} id={"Master"} choices={choicesMAster} Current={myActe.Master} ItemInfos={TabInfos.Master}/>
                }
                {mesHoraires.length>0 &&
                    mesHoraires.map(item=>(
                        <ChangeHoraireSetting key={`hor${item.id}`} currentEnd={item.end} errorEnd={null} idEnd={`${item.id}_end`} errorStart={null} setHor={SaveHeureDebutFinHoraire} label={item.libelle} currentStart={item.start} idStart={`${item.id}_start`}/>
                    ))
                }
                <InputSelectSetting setValue={setMyValue} Error={errors.Statut} label={"Statut de l'acte"} id={"Statut"} choices={choicesStatut} Current={myActe.Statut} ItemInfos={TabInfos.Statut}/>
                {PlageNbQuery.data &&
                    <div className="wrap_delete_poss">
                        {PlageNbQuery.data.total>0 ?
                            <div className="no_delete_poss">Cet acte est utilisé <strong>{PlageNbQuery.data.total}</strong> fois dans les plages, il n'est pas possible de le supprimer<br/>Vous pouvez cependant le rendre inactif pour le futur</div>:
                            <div className="delete_possible">
                                <span className={`link_delete`} onClick={TrashActe}>Supprimer cet acte</span>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

const FormEditActe = styled(FormEditActeCtrl)`
    position: relative;
    .wrap_delete_poss{
        margin-top: 30px;
    }
    .no_delete_poss{
        line-height: 170%;
    }
`

export default FormEditActe