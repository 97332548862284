import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import TimedButton from "../../../../../../../../components/atoms/TimedButton/TimedButton";
import {format} from "date-fns";
import {useAddReguleTemps} from "../../../../../../../../features/pointeuseTreat/pointeuseTreat.hooks";

interface OneCumulTotalCtrlProps{
    className?:string;
    TimesWeeks:TimesUserWeekly[];
    time:number;
    dateTo:string;
    pos:number;
    user:User;
}

const OneCumulTotalCtrl = (props:OneCumulTotalCtrlProps)=>{
    const mutation = useAddReguleTemps();
    const refObj = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false);
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    const OneReport = (myNB:number, dateHere:Date)=>{
        const dateNext= new Date(dateHere.getTime() + 7*86400000)
        const idMotif = props.time < 0 ? 13 : 12;
        const idMotifNext = props.time <0 ? 12 : 13;
        const datas:ReguleTpsFD={
            User:`/api/users/${props.user.id}`,
            dateAT:format(dateHere, "yyyy-MM-dd"),
            dateTo:format(dateHere, "yyyy-MM-dd"),
            MotifReguleTemps:`/api/motif_regule_temps/${idMotif}`,
            timeFinal:myNB,
            timeSystem:myNB,
            comentFinalHour:"Balance"
        }
        const datasNext  = {...datas, MotifReguleTemps:`/api/motif_regule_temps/${idMotifNext}`, dateAT:format(dateNext, "yyyy-mm-dd"), dateTo:format(dateNext, "yyyy-mm-dd")}
        return [datas, datasNext];
    }
    const MultiReport = ()=>{
        const TabDatas:ReguleTpsFD[] = [];
        const LenTW = props.TimesWeeks.length;
        props.TimesWeeks.sort((a,b)=>new Date(a.dateStart) > new Date(b.dateStart) ? 1 : -1).forEach((item, idx)=> {
            if(idx<(LenTW-1)){
                const NextTW = props.TimesWeeks[idx+1] as TimesUserWeekly;
                const ThisTab = OneReport(Math.abs(item.EcartTotal), new Date(NextTW.dateStop))
                ThisTab.forEach(i=>TabDatas.push(i))
            }
        })
        console.log(TabDatas);
    }
    const Abandonner = ()=>{
        MultiReport();
    }
    const Payer = ()=>{
        console.log("Paiement")
    }
    const Crediter = ()=>{
        console.log("Créditer")
    }
    const Débiter = ()=>{
        console.log("Débiter")
    }
    const Hybride = ()=>{
        console.log("Hybride")
    }
    return (
        <div className={`oneCumulTotal ${props.className} ${open ? "opened" : "closed"}`}>
            <div className="in_cumul" ref={refObj}  onClick={()=>setOpen(o=>!o)}>
                <div className={`time ${props.time > 0 ? "positive" : "negative"}`} onMouseDown={(e)=>e.stopPropagation()}>{Math.round(props.time*100)/100}</div>
                <div className="actions">
                    <div className="list_actions">
                        {props.time<0 ?
                            <TimedButton themeColor={"Primary"} onClick={Abandonner} text={`Abandonner ${Math.abs(Math.round(props.time*100)/100)} h`} size={"sm"} isFull={true}/>:
                            <TimedButton themeColor={"Primary"} onClick={Payer} text={`Payer ${Math.abs(Math.round(props.time*100)/100)} h`} size={"sm"} isFull={true}/>
                        }
                        <TimedButton themeColor={"Primary"} onClick={Hybride} text={`Action Personnalisée`} size={"sm"} isFull={true}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const OneCumulTotal = styled(OneCumulTotalCtrl)`
    width: 100%;
    .in_cumul{
        position: relative;
    }
    &:hover{
        cursor: pointer;
        .time.negative{
            border-color: ${props => props.theme.ComplementaryDark};
        }
        .time.positive{
            border-color: ${props => props.theme.SecondaryDark};
        }
    }
    .actions{
        position: absolute;
        padding: 0.25rem;
        width: 280px;
        left: ${props=>props.pos > 0.9 ? "auto" : "102%"};
        right: ${props=>props.pos <= 0.9 ? "auto" : "102%"};
        background: white;
        border-radius: 4px;
        box-shadow: 0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12),0 3px 5px -1px rgba(0,0,0,.2);
        display: none;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        .list_actions{
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }
    &.opened{
        .actions{
            display: block;
        }
    }
    .time{
        border-radius: 4px;
        border:solid transparent 2px;
        padding: 0.25rem 0.5rem;
        background: ${props => props.theme.NeutreExtraLight};
        width: 100%;
        margin: auto;
        font-size: 18px;
        font-weight: bold;
        &.negative{
            background-image: linear-gradient(45deg, #e7c6c9 23.33%, #f7edee 23.33%, #f7edee 50%, #e7c6c9 50%, #e7c6c9 73.33%, #f7edee 73.33%, #f7edee 100%);
            background-size: 21.21px 21.21px;
            color:${props=>props.theme.ErrorExtraDark};
        }
        &.positive{
            background-image: linear-gradient(45deg, #d5e1c7 23.33%, #f2f6ee 23.33%, #f2f6ee 50%, #d5e1c7 50%, #d5e1c7 73.33%, #f2f6ee 73.33%, #f2f6ee 100%);
            background-size: 21.21px 21.21px;
            color:${props=>props.theme.SuccessExtraDark};
        }
    }
`

export default OneCumulTotal