import styled from "styled-components";
import SquareIndic from "../../components/SquareIndic";
import LibCard from "../../components/LibCard";
import RightCard from "../../components/RightCard";
import PoigneeDrag from "../../components/PoigneeDrag";

interface OneCardFxInactifxCtrlProps{
    className?:string;
    Fx:Fonction;
    isCurrent:boolean;
    ClickOnEdit:()=>void;
}

const OneCardFxInactifxCtrl = (props:OneCardFxInactifxCtrlProps)=>{
    return (
        <div className={`OneCardFxInactifx  card_draggable ${props.className}`}>
            <PoigneeDrag isNoDrag/>
            <RightCard isCurrent={props.isCurrent} ClickOn={()=>props.ClickOnEdit()}>
                <SquareIndic backColor={"white"} fontColor={props.Fx.color}><span>A</span></SquareIndic>
                <LibCard text={props.Fx.libelle}/>
            </RightCard>
        </div>
    )
}

const OneCardFxInactifx = styled(OneCardFxInactifxCtrl)``

export default OneCardFxInactifx