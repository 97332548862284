import styled from "styled-components";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import {useGetCalendrierPaie} from "../../../../features/calendrierPaie/calendrierPaie.hooks";
import {useEffect, useState} from "react";
import {useGetAllUser} from "../../../../features/user/user.hooks";
import {TimedLoading} from "../../../../components/atoms/TimedWaitUser/TimedLoading";
import TempsTrMain from "./TempsTrMain";
import TempsTrOneCalList from "./TempsTrOneCal/TempsTrOneCalList";
import TempsTrOneUserMain from "./TempsTrOneCal/TempsTrOneUser/00_Main/TempsTrOneUserMain";

interface TempsTravDispatchCtrlProps{
    className?:string;
}

const TempsTravDispatchCtrl = (props:TempsTravDispatchCtrlProps)=>{
    const {From, TabCollab} = useGetParamsTimed();
    const [calendrierPaie, setCalendrierPaie] = useState<CalendrierPaie|null>(null);
    const [user, setUser] = useState<User|null>(null);
    const UsersQuery = useGetAllUser();
    const CalendrierPaieQuery = useGetCalendrierPaie();
    useEffect(() => {
        if(CalendrierPaieQuery.data){
            if(From) {
                const My = CalendrierPaieQuery.data.find(c => new Date(c.startAt.slice(0, 10) + "T12:00:00") <= From && new Date(c.stopAt.slice(0, 10) + "T12:00:00") >= From)
                if (My) {
                    setCalendrierPaie(My)
                } else {
                    setCalendrierPaie(null);
                }
            } else {
                setCalendrierPaie(null)
            }
        }
    }, [From, CalendrierPaieQuery.data]);
    useEffect(() => {
        if(UsersQuery.data ){
            if(TabCollab) {
                const myId = parseInt(TabCollab[0]);
                setUser(UsersQuery.data.find(u => u.id === myId) as User)
            } else {
                setUser(null);
            }
        }
    }, [TabCollab, UsersQuery.data])
    return (
        <div className={`tempsTravDispatch ${props.className}`}>
            {UsersQuery.isLoading || CalendrierPaieQuery.isLoading ?
                <div className="wrap_load"><TimedLoading/></div>
                :
                calendrierPaie && !user ? <TempsTrOneCalList calendrier={calendrierPaie}/>:
                    calendrierPaie && user ? <TempsTrOneUserMain user={user} calendrier={calendrierPaie}/> : <TempsTrMain/>
            }
        </div>
    )
}

const TempsTravDispatch = styled(TempsTravDispatchCtrl)`
    height: 100%;
    .wrap_load{
        display: flex;
        justify-content: center;
        padding: 5rem;
    }
`

export default TempsTravDispatch