import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    getAllTypeFrequence,
    getAllTypePeriodeByCentre,
    postNewTypePeriode,
    putNewTypePeriode
} from "./typePeriode.services";


export const useGetAllTypePeriodeByCentre = (id:number):UseQueryResult<TypePeriode[], Error>=>{
    return useQuery(["type_periodes", id], ()=>getAllTypePeriodeByCentre(id), {
        enabled:id!==0
    })
}

export const useGetAllTypeFrequence = ():UseQueryResult<TypeFrequence[]>=>{
    return useQuery(["type_frequence"], getAllTypeFrequence)
}

export const usePostNewTypePeriode = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PostTypePeriode)=>{
            return postNewTypePeriode(datas)
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["type_periodes"])
            }
        }
    )
}

export const usePutNewTypePeriode = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PutTypePeriode)=>{
            return putNewTypePeriode(datas)
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["type_periodes"])
            }
        }
    )
}