import styled from "styled-components";
import {SectionContext} from "./OneSection";
import React, {useContext, useEffect, useState} from "react";
import {BsCalendarDate} from "react-icons/bs";
import {TimedSideLink} from "./TimedSideLink";
import {ContextSideBar, SideBarContext} from "../../organisms/layouts/MainLayout";
import {useLocation} from "react-router-dom";



interface SectionAccordionCtrlProps{
    className?:string;
    Modules:Module[];
}

const SectionAccordionCtrl = (props:SectionAccordionCtrlProps)=>{
    const [open, setOpen] = useState(false);
    const [isCurrent, setIsCurrent] = useState(false);
    const ContextSection = useContext(SectionContext);
    const myContextSideBar:SideBarContext = useContext(ContextSideBar)
    const location = useLocation();
    useEffect(() => {
        const pathname = location.pathname.split("/")[2];
        const modulesUrls = props.Modules.map(m=>m.url)
        if(modulesUrls.indexOf(pathname)!==-1 && !myContextSideBar.SectionOpened && !open && myContextSideBar.setSectionOpened){
            myContextSideBar.setSectionOpened(ContextSection.Section||null);
        }
    }, [props.Modules, ContextSection.Section, myContextSideBar.SectionOpened, location]);
    useEffect(() => {
        if(myContextSideBar && myContextSideBar.SectionOpened?.id !== ContextSection.Section?.id){
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [myContextSideBar, ContextSection]);
    const getIcon = (lib:string)=>{
        const myIconTab = ContextSection.TabIcon?.find(i=>i.lib===lib);
        if(myIconTab!==undefined){
            return myIconTab.comp;
        } else {
            return <BsCalendarDate/>
        }
    }
    const handleClick = ()=>{
        if(myContextSideBar && myContextSideBar.setSectionOpened && ContextSection) {
            if (!open) {
                myContextSideBar.setSectionOpened(ContextSection.Section||null);
            } else {
                myContextSideBar.setSectionOpened(null);
            }
        }
    }
    return(
        <div className={`section_accordion ${props.className} ${open ? 'opened' : 'closed'}`}>
            <div className={`title_section ${props.className} ${open ? 'opened' : 'closed'}`} onClick={handleClick} style={ContextSection.styleTitle}>
                <div className="icon_place">{getIcon(ContextSection.Section?.icon||'')}</div>
                <div className="lib_place">{ContextSection.Section?.libelle}</div>
            </div>
            <div className={`content_accordion`} aria-expanded={!open}>
                {props.Modules.sort((a,b)=>a.ordreAffichage > b.ordreAffichage ? 1 :-1).map(item=>(
                    <TimedSideLink Module={item} key={`one_side_link_${item.id}`}/>
                ))}
            </div>
        </div>
    )
}

export const SectionAccordion = styled(SectionAccordionCtrl)`
  &.opened{
    background: ${props => props.theme.PrimarySuperDark};
  }
  .title_section{
    font-size: 15px;
    &.opened{
      background: ${props => props.theme.Primary};
    }
    &:hover{
      cursor: pointer;
      background: ${props => props.theme.PrimaryLight};
    }
  }
  .content_accordion{
    width: 100%;
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  }
  .content_accordion[aria-expanded="true"] {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
`